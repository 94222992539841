import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { Button, Icon } from "semantic-ui-react";
import { IDescount, IDescountCreateOrEditParams, IDescountFilterParams, IDescountValues } from "../../api/Interfaces/managment/ICrudDescount";
import { ICrudDescount, typeOfError } from '../../services/interfaces/Managment/ICrudDescount';
import { CreateDescount, DeleteDescount, EditDescount, GetDescounts } from "../../services/services/CrudDescount";
import ButtonBox from '../utils/ButtonBox';
import GeneralList from '../utils/GeneralList';
import GeneralModalDelete from "../utils/GeneralModalDelete";
import NewModalDescount from "./modals/CrudDescount/NewModalDescount";
import ReactSelect from 'react-select';
import { GetProfesionals } from "../../services/services/Users";
import { typeOfActionForModal, typeOfModal } from "../../services/interfaces/utils/IUtilDeclaration";
import { _DEFAULT_VALUES_DESCOUNT } from "./defaultValues";
import { useSelector } from "react-redux";

const CrudDescount: React.FC<ICrudDescount> = ({setDataToast})=>{   
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);
    const [isLoaderActive, setIsLoaderActive] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [loaderSelect, setLoaderSelect] = React.useState({profesional: false});
    const [pagesToRender, setPagesToRender] = React.useState(1);
    const [dataToRender, setDataToRender] = React.useState<IDescount[]>([]);
    const [values, setValues] = React.useState<IDescountValues>(_DEFAULT_VALUES_DESCOUNT.values);
    const [errorNotification, setErrorNotificacion] = React.useState<typeOfError>(_DEFAULT_VALUES_DESCOUNT.errorNotification);
    const [filterObj, setFilterObj] = React.useState<IDescountFilterParams>(_DEFAULT_VALUES_DESCOUNT.filterObj);
    const [openModal, setOpenModal] = React.useState<any>(_DEFAULT_VALUES_DESCOUNT.modals);
    const [profesionalList, setProfesionalList] = React.useState<any>([]);

     useEffect(()=>{
        (hasManagment && GetAllPromises());
    },[]);


    function GetAllPromises(){
        try{
            LoadInfoForTable();
            GetProfesionalList();
        }
        catch(err: any){
            MessageNotification(err.message, "error");
        }
    }

    const GetProfesionalList = React.useCallback(async () => {
        try {
        setLoaderSelect({ profesional: true });
        const { status, data } = await GetProfesionals({});
        if (status === 200) {
            const RSUserList = data?.results?.map((item) => ({
            value: item.id,
            label: `${item.apellido}, ${item.nombre}`,
            })) ?? [];
            setProfesionalList(RSUserList);
        }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        }finally{setLoaderSelect({profesional: false})}
    }, []);

    const FilterComponents = () =>(
        <Grid container direction="row" className="content-filter-list">
           <div className="filter-input">
            <ReactSelect
                className="filter-input"
                placeholder="Profesionales..."
                options={profesionalList}
                isLoading={loaderSelect.profesional}
                isClearable={true}
                value={filterObj.profesional?.value === null ? null : filterObj.profesional}
                onChange={(e)=> setFilterObj({...filterObj, profesional: e === null ? _DEFAULT_VALUES_DESCOUNT.filterObj.profesional : e})}
            />
            </div>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter filter-input"
                    clearable
                    style={{ margin: 8 }}
                    value={filterObj.fechaDesde}
                    placeholder="Fecha Desde"
                    onChange={(date)=>HandleChangeInputFilter(date, "fechaDesde")}
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                  />
                  <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter filter-input"
                    clearable
                    style={{ margin: 8 }}
                    value={filterObj.fechaHasta}
                    placeholder="Fecha Hasta"
                    onChange={(date)=>HandleChangeInputFilter(date, "fechaHasta")}
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                  />
            </MuiPickersUtilsProvider>


        </Grid>);
    const ButtonNew = () =>(
        <Button
            className='btn-new-filter'
            onClick={()=>OpenModalSelected("open","create")}
            icon
            labelPosition='left'
            >
            <Icon name='plus' />
            Nuevo
        </Button>
    );
    
    //#region LISTAR - EDITAR - ELIMINAR - CREAR
        async function LoadInfoForTable(pageSelected: number = 1){
            try{
                setIsLoaderActive(true);
                const params = {...filterObj, pagina: pageSelected || page}
                const {status, data} = await GetDescounts(params);
                if (status === 200){
                    setDataToRender(data?.results ?? []);
                    setPagesToRender(data?.quantity ?? 1);
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
        async function OnCreateDescount(){
            try{
                let {response, objError} = ValidateInputs(values);
                if (response.isValidated){
                    OpenModalSelected("close", "create");
                    setIsLoaderActive(true);
                    const params: IDescountCreateOrEditParams = {fecha: values.fecha, monto: values.monto, profesionalId: values.profesionalId, descripcion: values.descripcion}
                    const {status} = await CreateDescount(params);
                    
                    if (status === 200){
                        MessageNotification("Descuento creado correctamente.", "success");
                        setValues(_DEFAULT_VALUES_DESCOUNT.values);
                        LoadInfoForTable();
                    }
                }else{
                    setErrorNotificacion(objError);
                    MessageNotification(response.message, "error");
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
        async function OnEditDescount(){
            try{
                let {response, objError} = ValidateInputs(values);
                if (response.isValidated){
                    OpenModalSelected("close", "edit");
                    setIsLoaderActive(true);
                    const params: IDescountCreateOrEditParams = {fecha: values.fecha, monto: values.monto, profesionalId: values.profesionalId, descripcion: values.descripcion}
                    const {status} = await EditDescount(values.id, params);
                    
                    if (status === 200){
                        MessageNotification("Descuento editado correctamente.", "success");
                        setValues(_DEFAULT_VALUES_DESCOUNT.values);
                        LoadInfoForTable();
                    }
                }else{
                    setErrorNotificacion(objError);
                    MessageNotification(response.message, "error");
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
        async function OnDeleteDescount(){
            try{
                OpenModalSelected("close", "delete");
                setIsLoaderActive(true);
                const {status} = await DeleteDescount(openModal.delete.data.id);
                
                if (status === 200){
                    MessageNotification("Descuento eliminado correctamente.", "success");
                    setValues(_DEFAULT_VALUES_DESCOUNT.values);
                    LoadInfoForTable();
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
    //#endregion

    //#region UTILS
        function PrevToAction(e: any,item: IDescount){
            if (item.fechaPago === null){
                const data = {
                    id: item.id,
                    profesionalId: item.profesional.id,
                    profesionalName: item.profesional.nombre,
                    fecha: item.fecha,
                    monto: item.monto,
                    descripcion: item.descripcion
                }
                
                OpenModalSelected("open",e.currentTarget.name,data);
            }else{
                MessageNotification("No es posible editar o eliminar este registro con una 'Fecha de Pago' establecida.","error");
            }
        }
        function HandleChangeInputFilter(e: any, nameOfProperty: string){
            setFilterObj({...filterObj, [nameOfProperty]: (e === null ? e : (e instanceof Date ? e : e.target.value))});
            if (e instanceof Date && nameOfProperty === "fechaHasta"){
                if (filterObj.fechaHasta !== null && filterObj.fechaDesde !== null && filterObj.fechaDesde > filterObj.fechaHasta){
                    MessageNotification("El campo 'Fecha Hasta' debe de ser mayor al campo 'Fecha Desde'.", "error");
                    return;
                }
            }
        }
        function HandleChangeInputModal(e: any, nameOfProperty: string){
            setValues({...values, [nameOfProperty]: e instanceof Date ? e : (!isNaN(e.target.value) && e.target.value !== "" ? +e.target.value : e.target.value)});
        }
        function HandleSearch(){
            LoadInfoForTable();
        }
        function ValidateInputs(dataOfInputs: IDescountValues){
            let response = {isValidated: true, message: ""};
            let objError = {fecha: false, profesionalId: false, monto: false, descripcion: false};
            const valuesOfObj = Object.values(dataOfInputs);
            const keysOfObj = Object.keys(dataOfInputs);

            let notNullValues = valuesOfObj.findIndex(item => item === null || item === "");
            //validas si alguna propiedad esta vacia.
            if (notNullValues !== -1){
                response = {isValidated: false, message: "Todos los campos son obligatorios."};
                valuesOfObj.forEach((item,i)=>{
                    if(item === null || item === ""){
                        objError = {...objError, [keysOfObj[i]]: true};
                    }
                })
            }else{
                let notNegativeNumbers = valuesOfObj.findIndex(item => typeof item === "number" && +item < 0);
                //validas que no haya numeros en negativo.
                if (notNegativeNumbers !== -1){
                    response = {isValidated: false, message: "Los campos numéricos deben ser mayores o iguales a cero."}
                    valuesOfObj.forEach((item,i)=>{
                    if(typeof item === "number" && +item < 0){
                            objError = {...objError, [keysOfObj[i]]: true};
                        }
                    })
                }
            }
            return {response, objError};
        }
        function OpenModalSelected(action: typeOfActionForModal, modal: typeOfModal, data?: any){ 
            const keys = Object.keys(openModal);
            const indexOfKey = Object.keys(openModal).findIndex(item => item === modal);
            const keyOfModalToOpen = keys[indexOfKey];
            
            setOpenModal({...openModal, [keyOfModalToOpen]: {show: action === "open" ? true : false, data}});

            if (action === "close"){
                setErrorNotificacion(_DEFAULT_VALUES_DESCOUNT.errorNotification);
                setValues(_DEFAULT_VALUES_DESCOUNT.values);
            }else{
                if (modal !== "create" && data) setValues(data);
            }
        }
        function HandleChangePage(event: any, pageSelected: number){
            
            setPage(pageSelected);
            LoadInfoForTable(pageSelected);
        }
        function MessageNotification(message: string, type: string){
            setDataToast({
                active: true,
                message,
                type
            });
        }
    //#endregion

    return(
    <>
        <GeneralList
        titleOfSection="Descuentos"
        isLoaderActive={isLoaderActive}
        dataToRender={dataToRender}
        initialPage={page}
        numPagesToRender={pagesToRender}
        FilterComponent={FilterComponents}
        ButtonNew={ButtonNew}
        onChangePage={HandleChangePage}
        HandleSearch={HandleSearch}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Saldado</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha de Pago</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Nombre Profesional</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Monto</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Descripción</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: any) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <Checkbox checked={Boolean(item.fechaPago) || item.liquidacionId}/>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.fechaPago ? new Date(item.fechaPago).toLocaleDateString() : "--"}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.profesional.nombre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {item.monto}
                            </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.descripcion ?? "--"}
                            </TableCell>
                            <TableCell className='p-cell'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e)=>PrevToAction(e,item)}/>
                                    <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e)=>PrevToAction(e,item)}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>
        
        {/* MODALS */}
        {openModal.create.show && 
            <NewModalDescount 
            titleOfModal="Nuevo"
            modal="create"
            openModal={openModal.create.show} 
            values={values}
            errorNotification={errorNotification}
            HandleChangeInput={HandleChangeInputModal}
            setValues={setValues}
            MessageNotification={MessageNotification}
            OpenModalSelected={OpenModalSelected}
            SendValues={OnCreateDescount}/>
        }
        {openModal.edit.show &&
            <NewModalDescount 
            titleOfModal="Editar"
            modal="edit"
            openModal={openModal.edit.show} 
            values={values}
            errorNotification={errorNotification}
            HandleChangeInput={HandleChangeInputModal}
            setValues={setValues}
            MessageNotification={MessageNotification}
            OpenModalSelected={OpenModalSelected}
            SendValues={OnEditDescount}/>
        }
        {openModal.delete.show &&
            <GeneralModalDelete
            contentText={`¿Está seguro de eliminar el descuento para el profesional: ${openModal.delete.data.profesionalName}?`}
            openModal={openModal.delete.show}
            HandleClose={()=>OpenModalSelected("close", "delete")}
            HandleSubmit={OnDeleteDescount}
            />
        }
    </>
    )
}

export default CrudDescount;