import React, { useState, useEffect } from "react";
import api from "../../api";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Paper,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TableCell,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
} from "@material-ui/core";
import { Table, Icon, Grid, Button, Card } from "semantic-ui-react";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContentText from "@material-ui/core/DialogContentText";
import CardContent from "@material-ui/core/CardContent";
import { Options } from "../../enums";

export default function Module({ setDataToast }) {
  const [viewData, setViewData] = useState();
  const [dataTable, setDataTable] = useState();
  const [error, setError] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [name, setName] = useState("");
  const [defaultValues, setDefaultValues] = useState({
    id: undefined,
    name: "",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    id: undefined,
    open: false,
  });
  const [isView, setView] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const handleClickOpen = () => {
    setOpenNew(true);
  };
  const handleCloseNew = () => {
    setOpenNew(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const closePreDelete = () => {
    setDeleteDialog({ ...deleteDialog, open: false });
  };
  const handleImputChange = (e) => {
    setName(e.target.value);
  };
  const handleImputChangeEdit = (e) => {
    setDefaultValues({ ...defaultValues, name: e.target.value });
  };
  function preDelete(id) {
    setDeleteDialog({ open: true, id: id });
  }
  useEffect(() => {
    let options = JSON.parse(localStorage.getItem("optionConfig_Module"));
    options.forEach((element) => {
      if (element == Options.isView) {
        setView(true);
      } else if (element == Options.isCreate) {
        setCreate(true);
      } else if (element == Options.isEdit) {
        setEdit(true);
      } else if (element == Options.isDelete) {
        setDelete(true);
      }
    });
  }, []);

  function onClickCreate() {
    if (name.trim()) {
      let body = {
        nombre: name.trim(),
      };

      api
        .postModule(body) //VER ENDPOINT!!!!!!!

        .then(() => {
          setDataToast({
            active: true,
            message: "Nombre creado correctamente.",
            type: "success",
          });

          onClickVerList();
        })

        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
        });

      setError(false);
      handleCloseNew();
    } else {
      setError(true);
    }
  }
  function getModuleEdit(id) {
    api
      .getModule(id)
      .then((res) => {
        let data = res;

        setDefaultValues({
          id: data[0].id,
          name: data[0].nombre,
        });

        setOpenEdit(true);
        setViewData(true);
      })
      .catch((err) => {
        setViewData(false);
        setDataToast({
          active: true,
          type: "error",
          message: "Ocurrió un error.",
        });
      });
  }
  function Edit() {
    if (defaultValues.name.trim()) {
      let body = {
        nombre: defaultValues.name.trim(),
      };

      api
        .putModule(defaultValues.id, body)
        .then((res) => {
          setDataToast({
            active: true,
            message: "Nombre editado correctamente.",
            type: "success",
          });
          onClickVerList();
        })

        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
        });

      setError(false);
      handleCloseEdit();
    } else {
      setError(true);
    }
  }
  function removeModule(id) {
    closePreDelete();
    api
      .deleteModule(id)
      .then((res) => {
        let newDataTable = [];
        dataTable.forEach((module) => {
          if (module.id !== id) {
            newDataTable.push(module);
          }
        });
        setDataTable(null);
        setDataTable(newDataTable);
        setDataToast({
          active: true,
          message: "Dado de baja con éxito",
          type: "success",
        });

        onClickVerList();
      })

      .catch((err) => {
        if (err.response.data.errores) {
          setDataToast({
            active: true,
            message: err.response.data.errores,
            type: "error",
          });
        } else {
          setDataToast({
            active: true,
            message: "Ocurrio un error.",
            type: "error",
          });
        }
      });
  }
  const onClickVerList = () => {
    api
      .getModulesList()
      .then((res) => {
        let data = res;

        let list = [];

        data.forEach((item) => {
          if (item.id !== 0) {
            list.push({
              id: item.id,
              nombre: item.nombre,
            });
          }
        });

        setDataTable(null);
        setDataTable(list);
      })

      .catch((err) => {
        setDataToast({
          active: true,
          type: "error",
          message: "Ocurrió un error.",
        });
      });
  };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Card className="color-border-dash">
            <CardContent className="center-responsive-filters">
              <Grid.Row style={{ marginBottom: 30 }}>
                <span style={{ fontSize: 27 }}>Módulo</span>
                {isCreate == true && (
                  <Button
                    className="btn-new-filter"
                    onClick={() => handleClickOpen()}
                    icon
                    labelPosition="left"
                  >
                    <Icon name="plus" />
                    Nuevo
                  </Button>
                )}
                <Dialog
                  open={openNew}
                  onClose={handleCloseNew}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Nuevo</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      type="text"
                      placeholder="Ingrese nombre"
                      name="nombre"
                      onChange={handleImputChange}
                      fullWidth
                      error={error}
                    />
                  </DialogContent>

                  <DialogActions
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={handleCloseNew} color="blue">
                      Cancelar
                    </Button>
                    <Button onClick={onClickCreate} color="blue">
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid.Row>
              <Grid.Row>
                <Button
                  className="btn-search-filter"
                  primary
                  icon
                  labelPosition="left"
                  onClick={() => onClickVerList()}
                  type="submit"
                >
                  <Icon name="search" />
                  Buscar
                </Button>
              </Grid.Row>
            </CardContent>
          </Card>
        </Grid.Row>
        {dataTable && dataTable.length > 0 && (
          <Grid.Row>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <Table aria-label="spanning table">
                <TableHead colSpan={2} className="color-letter-inTable">
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="letter-title-table tex "
                    >
                      <b>N° Modulo</b>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="letter-title-table"
                    >
                      <b>Nombre</b>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="letter-title-table"
                    >
                      <b>Opciones</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map(({ id, nombre }) => (
                    <TableRow key={id}>
                      <TableCell
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {id}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {nombre}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {isEdit == true && (
                          <IconButton
                            className="btn-circle-orange"
                            onClick={() => getModuleEdit(id)}
                          >
                            <CreateIcon />
                          </IconButton>
                        )}
                        {isDelete == true && (
                          <IconButton
                            className="btn-circle-red"
                            onClick={() => preDelete(id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid.Row>
        )}
        {dataTable && dataTable.length === 0 && (
          <Grid.Row style={{ textAlign: "-webkit-center" }}>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <TableCell>
                <h5>No se encontraron resultados</h5>
              </TableCell>
            </TableContainer>
          </Grid.Row>
        )}
      </Grid>

      {viewData && (
        <Dialog
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Editar</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              name="nombre"
              placeholder="Ingrese nuevo nombre"
              onChange={handleImputChangeEdit}
              value={defaultValues.name}
              fullWidth
              error={error}
            />
          </DialogContent>

          <DialogActions
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => handleCloseEdit()} color="blue">
              Cancelar
            </Button>

            <Button onClick={() => Edit()} color="blue">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {deleteDialog != undefined && (
        <Dialog open={deleteDialog.open} onClose={setOpenDelete}>
          <DialogTitle id="form-dialog-title">Borrar registro</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro de eliminar el registro del modulo N°{" "}
              {deleteDialog.id}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closePreDelete()}>Cancelar</Button>
            <Button onClick={() => removeModule(deleteDialog.id)} color="red">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
