import React from 'react';
import GeneralModalShow from '../../../utils/GeneralModalShow';
import EditorText from '../../../QuillEditoText/EditorText';
import { IDetailtNotification } from '../../../../services/interfaces/Configurations/IDetailNotificationNews';

const DetailNotification: React.FC<IDetailtNotification> = ({
    titleOfModal,
    openModal,
    newsSelected,
    HandleCloseDetailNotification
}) => {
    return(
    <GeneralModalShow
    title={titleOfModal}
    HandleClose={HandleCloseDetailNotification}
    openModal={openModal}
    styles="contModalShowDetailNews"
    >
        <EditorText
        isReadOnly={true}
        text={newsSelected.cuerpo}
        handleOnChange={()=>{}}
        placeholder={`${newsSelected.cuerpo !== null || newsSelected !== "" ? newsSelected.cuerpo : "No se asigno información acerca de la novedad." }`}
        />
    </GeneralModalShow>)
}

export default DetailNotification;