import { Tooltip, makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../../api';
import { Options } from '../../../../enums';

  const useStyles = makeStyles({
		btnList: {display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end', marginBottom: 10},
        colorBtn: {backgroundColor: "#e0e1e2", color:'rgba(0,0,0,.6)'}
    });

export default function PlanForOs({setDataToast}){
	const redirect = useHistory();
    const classStyle = useStyles();
    const loadForPage = 1;
    const { control } = useForm({});
	const {idOs} = useParams();
	
    //#region VARIABLES DE ESTADO
    const [page, setPage] = useState(1);
    const [loadingActive, setLoadingActive] = useState(false);
    const [dataTable, setDataTable] = useState([{}]);
    const [openNew, setOpenNew] = useState(false);
    const [isCreate, setCreate] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

	const [openDelete, setOpenDelete] = useState({
		mostrar: false,
		idPlan: null,
		nombre: null
	});
    const [errors, setErrors] = useState({
		association: '',
	});
    const [newValue, setNewValue] = useState({
		id: null,
        nombre: null
    });
    const [editValues, setEditValues] = useState({
        idPlan: null,
        nombre: null,
		idOs: null
    })
    //#endregion
    
    useEffect(()=>{
		setLoadingActive(true);
        let optionCruds = JSON.parse(localStorage.getItem('optionCruds'));
		optionCruds.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
		LoadData();
    },[])

    const LoadData = async (nroPage = 1)=>{
		try{
			let includedDelete = false;
			let {data} = await api.getSocialWorksPlan(+idOs,includedDelete);
			setDataTable(data);
		}
		catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setLoadingActive(false);
		}
    }
    const OnClickCreate = async () => {
		setOpenNew(false);
		setLoadingActive(true);
		try{
				if(newValue.nombre){
					let bodyParams = {
						nombre: newValue.nombre,
						obraSocial: {}
					}
					let query = await api.CreatePlanForSocialWork(idOs,bodyParams);
					if(query.status === 200 && query.data != null){
						HandleErrorOrSuccess('success', "Plan creado correctamente.");
					}
				}else{
					HandleErrorOrSuccess('error',"Los campos no pueden estar vacíos.");
				}
			}catch(err){
				HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
			}
			finally{
				setNewValue({
					nombre: null,
					plan: null,
				});
				setLoadingActive(false);
				LoadData();
		}
    }
     const OnClickEdit = async ()=>{
		setOpenEdit(false);
		setLoadingActive(true);
		try{

			if(editValues.nombre){
				let bodyParams = {
					nombre: editValues.nombre,
					obraSocial: {}
				}
			let query = await api.UpdatePlanForSocialWork(editValues.idPlan,editValues.id, bodyParams);
			if(query.status === 200){
				HandleErrorOrSuccess('success',"Plan editado correctamente.");
			}
		}else{
			HandleErrorOrSuccess('error',"Los campos no pueden estar vacíos.");
		}
	}catch(err){
		HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
	}
	finally{
		setOpenEdit(false);
		setEditValues({
			id: null,
			nombre: null
		});
		setLoadingActive(false);
		LoadData();
		}
    }
	const OnClickDelete = async ()=>{
		setOpenDelete({...openDelete, mostrar: false});
		setLoadingActive(true);
		try{
			let query = await api.DeletePlanForSocialWork(openDelete.idPlan,idOs);
			if(query.status === 200){
				HandleErrorOrSuccess('success',"Plan dado de baja correctamente.");
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setOpenDelete({
				mostrar: false,
				id: null,
				nombre: null,
			});
			setLoadingActive(false);
			LoadData();
		}
	}
    //#region UTILIDADES
	const HandleErrorOrSuccess = (type,message)=>{
		setDataToast({
			active: true,
			type: type,
			message: message
		});
	}
	const PreDelete = (IdPlanSocialWork, namePlanSocialWork)=>{
		setOpenDelete({
			idPlan: IdPlanSocialWork,
			nombre: namePlanSocialWork,
			mostrar: true,
		});
    }
    const HandleOpenEdit = (idPlan, name)=>{
        setOpenEdit(true);
		setEditValues({
			idPlan: idPlan,
			id: idOs,
			nombre: name
		});
    }
    const HandleCloseEdit = ()=>{
        setOpenEdit(false);
    }
    const HandleClickOpen = ()=>{
        setOpenNew(true);
    }
    const HandleCloseNew = ()=>{
        setOpenNew(false);
    }
	const HandleCloseDelete = ()=>{
		setOpenDelete(false);
	}
    //#endregion

    return(<>
        <Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className='header-section'>
								<div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>Planes</span>
								</div>
								<div>
									<Button
										className='btn-back-filter'
										onClick={() => redirect.push('/configuraciones/obras-sociales/asignar')}
									>
										Volver
									</Button>
								{isCreate && 
									<Button
										className='btn-new-filter'
										onClick={() => HandleClickOpen()}
										icon
										labelPosition='left'
									>
										<Icon name='plus' />
										Nuevo
									</Button>}
								</div>
								<Dialog
									open={openNew}
									onClose={HandleCloseNew}
									aria-labelledby='form-dialog-title'
								>
									<DialogTitle id='form-dialog-title'>Nuevo</DialogTitle>
									<DialogContent>
										<TextField
											style={{ margin: "10px 0" }}
											size='small'
											id='nombre'
											label='Nombre'
											value={newValue.nombre}
											onChange={(e) => {
												setNewValue({
                                                    ...newValue,
                                                    nombre: e.target.value
                                                });
											}}
											variant='outlined'
										/>
									</DialogContent>

									<DialogActions
										style={{
											textAlign: 'center',
											justifyContent: 'center',
										}}
									>
										<Button onClick={HandleCloseNew} className={classStyle.colorBtn}>
											Cancelar
										</Button>
										<Button onClick={OnClickCreate} color='blue'>
											Aceptar
										</Button>
									</DialogActions>
								</Dialog>
							</Grid.Row>
							<Grid.Row>
							</Grid.Row>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={2} className='color-letter-inTable'>
										<TableRow>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Nombre</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map(({id,nombre}) => (
											<TableRow key={id}>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
                                                    {nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{isEdit && (
														<IconButton
															className='btn-circle-orange'
															onClick={() => HandleOpenEdit(id, nombre)}
														>
															<Tooltip placement='top' title="Editar">
																<CreateIcon />
															</Tooltip>
														</IconButton>
													)}
													{isDelete && (
														<IconButton
															className='btn-circle-red'
															onClick={() =>
																PreDelete(id, nombre)
															}
														>
															<Tooltip placement='top' title="Eliminar">
																<DeleteIcon />
															</Tooltip>
														</IconButton>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
			</Grid>

			{openEdit && (
				<Dialog
					open={openEdit}
					onClose={HandleCloseEdit}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
					<DialogContent>
						<TextField
							style={{ margin: "10px 0" }}
							size='small'
							id='nombre'
							label='Nombre'
							value={editValues.nombre}
							onChange={(e) => {
								setEditValues({
									...editValues,
									nombre: e.target.value,
								});
							}}
							variant='outlined'
						/>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={() => HandleCloseEdit()} className={classStyle.colorBtn}>
							Cancelar
						</Button>

						<Button onClick={() => OnClickEdit()} color='green'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openDelete.mostrar &&
			<Dialog open={openDelete.mostrar} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de eliminar el siguiente Plan: {openDelete.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseDelete()}>Cancelar</Button>
					<Button
						onClick={() => OnClickDelete()}
						color='red'
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
			}
    </>)
}