import React from 'react';
import GeneralList from '../utils/GeneralList';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Button, Icon } from 'semantic-ui-react';
import ButtonBox from '../utils/ButtonBox';
import { GetProfitWithHoldings } from '../../services/services/ProfitWithHoldings';
import { StatusCode } from '../../enums';
import { IProfitWithHoldings } from '../../services/interfaces/Managment/IProfitWithHoldings';
import { IProfitWithHoldingsResponse } from '../../api/Interfaces/managment/IProfitWithHoldingsResponse';
import { _DEFAULT_VALUES_PROFIT, _DEFAULT_VALUES_PROFIT_MODAL } from './defaultValues';
import NewProfitWithHoldings from './modals/ProfitWithHoldings/NewProfitWithHoldings';
import { useSelector } from 'react-redux';

const ProfitWithHoldings: React.FC<IProfitWithHoldings> = ({setDataToast})=>{
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);
    const [dataToRender, setDataToRender] = React.useState<IProfitWithHoldingsResponse[]>([]);
    const [values, setValues] = React.useState<IProfitWithHoldingsResponse>(_DEFAULT_VALUES_PROFIT);
    const [openModals, setOpenModals] = React.useState<any>(_DEFAULT_VALUES_PROFIT_MODAL);
    const [loadingActive, setLoadingActive] = React.useState(false);

    React.useEffect(()=>{
        (hasManagment && LoadInfoForTable());
    },[])

    const ButtonNew = () =>(
        <Button
            className='btn-new-filter'
            onClick={()=>setOpenModals({...openModals, new: {show: true}})}
            icon
            labelPosition='left'
            >
            <Icon name='plus' />
            Nuevo
        </Button>
    );
    
    async function LoadInfoForTable(){
        try{
            setLoadingActive(true);
            const {data, status} = await GetProfitWithHoldings();
            if (status === StatusCode.Ok){
                setDataToRender(data.results);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores ?? "Ocurrio un error.", "error");
        }finally{
            setLoadingActive(false);
        }
    }

    function HandleToEditHolding(profitWithHoldingSelected){
        setValues(profitWithHoldingSelected);
        setOpenModals({...openModals, edit: {show: true}});
    }
    function HandleCloseModal(){
        setValues(_DEFAULT_VALUES_PROFIT);
        setOpenModals(_DEFAULT_VALUES_PROFIT_MODAL);
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }
    return(
        <>
        <GeneralList
        HandleSearch={LoadInfoForTable}
        dataToRender={dataToRender}
        isLoaderActive={loadingActive}
        titleOfSection='Retenciones de Ganancias'
        ButtonNew={ButtonNew}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Más de pesos</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>A Pesos</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Pesos</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Más el porcentaje</b>
                        </TableCell>
                         <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Sobre excluido</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: any) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {new Intl.NumberFormat("en-US").format(item.masDePesos)}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {new Intl.NumberFormat("en-US").format(item.aPesos)}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {new Intl.NumberFormat("en-US").format(item.pesos)}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                % {item.masElPorcentaje}
                            </TableCell>
                             <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {new Intl.NumberFormat("en-US").format(item.sobreExcluido)}
                            </TableCell>
                            <TableCell className='c-text-center'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={()=>HandleToEditHolding(item)}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>

        {openModals.new.show &&
        <NewProfitWithHoldings 
        titleOfModal='Nuevo'
        openModal={openModals.new.show}
        isCreate={true}
        values={values}
        HandleCloseModal={HandleCloseModal}
        MessageNotification={MessageNotification}
        ReloadData={LoadInfoForTable}
        />}

        {openModals.edit.show &&
        <NewProfitWithHoldings 
        titleOfModal='Editar'
        openModal={openModals.edit.show}
        values={values}
        isCreate={false}
        HandleCloseModal={HandleCloseModal}
        MessageNotification={MessageNotification}
        ReloadData={LoadInfoForTable}
        />}
        </>
    )
}

export default ProfitWithHoldings;