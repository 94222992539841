import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { IGeneralModalTabs } from '../../services/interfaces/utils/IGeneralModalTabs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{
          padding: "24px 0"
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const GeneralModalTabs: React.FC<IGeneralModalTabs> = ({
  arrPanels, 
  isView,
  arrTittleOfPanels, 
  viewPanelDefault, 
  setDefaultValues,
  setSectionSelected
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(viewPanelDefault);

  const handleChange = (event, newValue: number) => {
    setValue(newValue);
    (setSectionSelected !== undefined && setSectionSelected(newValue));
    (setDefaultValues !== undefined && setDefaultValues());
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {arrTittleOfPanels.map((item, index) => (
            <Tab label={item} key={index} disabled={isView && index !== viewPanelDefault}/>
          ))}
        </Tabs>
      </AppBar>
      {arrPanels.map((Comp, index) => (
        <TabPanel value={value} index={index} key={index}>
          {Comp}
        </TabPanel>
      ))}
    </div>
  );
}

export default GeneralModalTabs;
