import { Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import CheckIcon from "@material-ui/icons/CheckSharp";
import CloseIcon from "@material-ui/icons/CloseSharp";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import TableRow from "@material-ui/core/TableRow";
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import moment from "moment";
import { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { StatusOfDocument, TypeRole } from "../../enums";
import PopupImage from "../utils/popupImage";
import "./Period.css";
import { useSelector } from "react-redux";
import api from "../../api";

export default function PeriodAuditList({
  reloadData,
  MessageNotification,
  selected,
  setSelected,
  dataTable,
  role,
  setLoadingActive,
  IsFilterObjValid
}) {
  const hasManagment = useSelector(({ managment }) => managment.hasManagment);

  useEffect(() => {
    const newSelecteds = dataTable
      .filter((n) => (n.estadoId === StatusOfDocument.Envoiced || n.estadoId === StatusOfDocument.Verified) && n.imagenes.length > 0)
      .map((n) => n.documentoId);
    setSelected(newSelecteds);
  }, []);

  const handleClickCheck = (event, docSelected) => {
    if (!IsFilterObjValid()) return;

    const selectedIndex = selected.indexOf(docSelected.documentoId);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Si el documento no está seleccionado, agregarlo
      newSelected = [...selected, docSelected.documentoId];
    } else {
      // Si ya está seleccionado, removerlo
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1)
      ];
    }
    setSelected(newSelected);

    //--------------------------Update--------------------------//
    if (docSelected.estadoId !== StatusOfDocument.Envoiced) {
      const body = {
        periodoId: docSelected.periodoId,
        profesionalId: docSelected.profesionalId,
        doc: { documentoId: docSelected.documentoId, marcada: event.target.checked }
      }
      UpdateVerifiedDocuments(body);
    }
  };

  async function UpdateVerifiedDocuments(params) {
    try {
      setLoadingActive(true);
      await api.UpdateVerifiedDocuments(params.periodoId, params.profesionalId, params.doc);
    } catch (error) {
      MessageNotification(error.response.data.errores[0], "error");
    } finally {
      reloadData();
      setLoadingActive(false);
    }
  }

  const isSelected = (documentoId) => selected.indexOf(documentoId) !== -1;
  const isClosedReviewDate = (fechaFinRevision) => {
    let today = new Date();
    let endReviewDate = new Date(fechaFinRevision)
    return endReviewDate <= today;
  }

  const TableRowData = ({ item }) => {
    const isItemSelected = isSelected(item.documentoId);
    const isEnvoiced = StatusOfDocument.Envoiced === item.estadoId;
    const isEndReviewDate = isClosedReviewDate(item.fechaFinRevision);
    return (
      <TableRow colSpan={6} key={item.documentoId}>
        <TableCell className="letter-body-table p-cell">
          <Tooltip title={isEndReviewDate ? "No es posible modificar el estado si el periodo se encuentra cerrado" : ""}>
            <span> {/* Envuelve el Checkbox en un span para evitar que Tooltip interfiera con el deshabilitado */}
              <Checkbox
                disabled={isEnvoiced || isEndReviewDate}
                onClick={(event) => handleClickCheck(event, item)}
                className="no-padding-checkbox p-cell"
                checked={isItemSelected || isEnvoiced}
              />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className="p-cell">
          {moment(item.fechaPrimeraSesion).format("DD-MM-YYYY")}
        </TableCell>
        <TableCell className="p-cell">
          {item.apellidoPaciente} {item.nombrePaciente}
        </TableCell>
        <TableCell className="p-cell">{item.documentoPaciente}</TableCell>
        <TableCell className="p-cell">{item.obraSocial}</TableCell>
        <TableCell className="p-cell">{item.numeroAutorizacion}</TableCell>
        <TableCell className="p-cell">{item.cantidadSesiones}</TableCell>
        <TableCell className="p-cell">{item.apellidoProfesional}, {item.nombreProfesional}</TableCell>
        {TypeRole.Admin === role &&
          <TableCell className="p-cell">
            {item.estadoId === StatusOfDocument.Verified ?
              <Tooltip title="Verificado" placement='top' arrow>
                <CheckIcon className='c-icon-success' />
              </Tooltip>
              : item.estadoId === StatusOfDocument.NotVerified ?
                <Tooltip title="No Verificado" placement='top' arrow>
                  <CloseIcon className='c-icon-cancel' />
                </Tooltip>
                : item.estadoId === StatusOfDocument.Envoiced && hasManagment ?
                  <Tooltip title="Facturado" placement='top' arrow>
                    <AttachMoneyOutlinedIcon className="c-icon-edit" />
                  </Tooltip>
                  : item.estadoId === StatusOfDocument.NotEnvoiced && hasManagment ?
                    <Tooltip title="No Facturado" placement='top' arrow>
                      <MoneyOffOutlinedIcon className='c-icon-cancel' />
                    </Tooltip>
                    :
                    <Tooltip title="Pendiente" placement='top' arrow>
                      <CheckBoxOutlineBlankIcon className='c-icon-edit' />
                    </Tooltip>}
          </TableCell>}
        <TableCell className="p-cell">
          <PopupImage
            reloadData={reloadData}
            MessageNotification={MessageNotification}
            dontDelete={false}
            data={item.imagenes}
            document={item.documentoId}
            title={
              "Imágenes de: " +
              item.nombrePaciente +
              " " +
              item.apellidoPaciente
            }
          />
        </TableCell>
      </TableRow>
    );
  };

  // const handleSelectAllClick = (event) => {
  //   const newSelecteds = dataTable
  //     .filter((n) => n.estadoId !== StatusOfDocument.Envoiced && n.imagenes.length > 0)
  //     .map((n) => n.documentoId);

  //   setSelected(event.target.checked ? newSelecteds : []);

  //   //--------------------------Update--------------------------//
  //   // Creo un array de objetos con {documentoId, marcada}
  //   const selectedDocuments = dataTable.map((doc) => {
  //     const isSelected = newSelecteds.includes(doc.documentoId);
  //     return isSelected
  //       ? { documentoId: doc.documentoId, marcada: event.target.checked }
  //       : { documentoId: doc.documentoId, marcada: false };
  //   });

  //   if (dataTable.every(x => x.estadoId === StatusOfDocument.Verified || x.estadoId === StatusOfDocument.NotVerified)) {
  //     let body = {
  //       periodoId: dataTable[0].periodoId,
  //       profesionalId: dataTable[0].profesionalId,
  //       docs: selectedDocuments,
  //     }
  //     UpdateVerifiedDocuments(body);
  //   }
  // }

  return (
    <>
      <Grid.Row>
        <TableContainer component={Paper} id="color-letter-table-tasks">
          <Table aria-label="spanning table">
            <TableHead className="color-letter-inTable">
              <TableRow>
                <TableCell className="letter-title-table not-padding-table-task">
                  {/* <Checkbox
                    checked={
                      dataTable.length > 0 &&
                      selected.length === dataTable.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all desserts" }}
                  /> */}
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Fecha</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Paciente</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>DNI</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Obra Social</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Nro. Autorización</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Sesiones</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Profesional</b>
                </TableCell>
                {TypeRole.Admin === role &&
                  <TableCell className="letter-title-table">
                    <b>Estado</b>
                  </TableCell>}
                <TableCell className="letter-title-table">
                  <b>Opciones </b>
                </TableCell>
              </TableRow>
            </TableHead>
            {dataTable && (
              <TableBody>
                {dataTable.map((item) => (
                  <TableRowData key={item.documentoId} item={item} />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid.Row>
    </>
  );
}