import _ from "lodash";
import { Grid, Button } from "semantic-ui-react";
import "./SocialWork.css";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import api from "../../api";
import PopupImage from "../utils/popupImage";
import DownloadImage from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";
import { Tooltip } from "@material-ui/core";

export default function SocialWorkList({
	dataTable,
	totalCount,
	loadForPage,
	reloadData,
	isView,
	isEdit,
	isCreate,
	isDelete,
	isExport,
	page,
	setPage,
	setDataToast,
	isProfessional,
	isCollege,
	isSocialWork,
	setLoadingActive
}) {
	const handleChange = (event, value) => {
		setPage(value);
	};

	const DownloadImages = (item) => {
		if (item.imagenes.length > 1) {
			item.imagenes.forEach((element) => {
				apiDownloadImage(
					element,
					item.documentoId,
					item.periodoMes,
					item.documentoPaciente
				);
			});
		} else {
			apiDownloadImage(
				item.imagenes,
				item.documentoId,
				item.periodoMes,
				item.documentoPaciente
			);
		}
	};

	const apiDownloadImage = (images, document, period, documentPatient) => {
		setLoadingActive(true);
		let image = {
			documentoId: !document ? document[0] : document,
			imagenId: !images.id ? images[0].id : images.id,
		};
		api
			.imagesDownload(image)
			.then((res) => {
				let extension = '.png';
				let tempFileName = '';
				if (period != '') {
					tempFileName =
						'Imagen - ' + period + '-' + documentPatient.toString();
				} else {
					tempFileName = 'Imagen - ' + documentPatient.toString();
				}
				let fileName = `${tempFileName}${extension}`;

				let blob = new Blob([res.data], {
					type: 'image/png',
				});
				saveAs(blob, fileName);

				MessageNotificacion('Imagen descargada correctamente', 'success')
			})
			.catch((err) => {
				MessageNotificacion(err?.response?.data?.errores, 'error');
			})
			.finally(() => setLoadingActive(false));
	};

	const MessageNotificacion = (message, type)=>{
		setDataToast({
			active: true,
			message: message ?? 'Acaba de ocurrir un error',
			type: type,
		})
	}

	const TableRowData = ({ item }) => {
		return (
			<TableRow colSpan={6} key={item.documentoId}>
				<TableCell className='p-cell'>
					{moment(item.fechaRegistro).format('DD-MM-YYYY')}
				</TableCell>
				{!isProfessional && (
					<TableCell className='p-cell'>{item.periodoMes}</TableCell>
				)}
				{isCollege && <TableCell className='p-cell'>{item.regional}</TableCell>}
				<TableCell className='p-cell'>
					{item.apellidoPaciente} {item.nombrePaciente}
				</TableCell>
				<TableCell className='p-cell'>
					{item.apellidoProfesional} {item.nombreProfesional} 
				</TableCell>
				<TableCell className='p-cell'>{item.documentoPaciente}</TableCell>
				<TableCell className='p-cell'>{item.obraSocial}</TableCell>
				<TableCell className='p-cell'>{item.numeroAutorizacion}</TableCell>
				<TableCell className='p-cell'>
					<Button.Group>
						{/* {item.imagenes.length > 0 && ( */}
						<PopupImage
							reloadData={reloadData}
							MessageNotification={MessageNotificacion}
							setDataToast={setDataToast}
							dontDelete={isDelete == true && item.periodoId === null}
							data={item.imagenes}
							document={item.documentoId}
							title={
								'Imágenes de: ' +
								item.apellidoPaciente +
								' ' +
								item.nombrePaciente
							}
						/>
						{/* )} */}
						{isExport == true && (
							<IconButton
								className='btn-circle-yellow'
								onClick={() => DownloadImages(item)}
								disabled={item.imagenes.length === 0}
							>
								<Tooltip placement="top" title="Descargar">
									<DownloadImage />
								</Tooltip>
							</IconButton>
						)}
					</Button.Group>
				</TableCell>
			</TableRow>
		);
	};

	return (
		<>
			{/* <DialogDelete
        open={alertDelete}
        setOpen={setAlertDelete}
        title={dataSweetAlert.title}
        content={dataSweetAlert.content}
        actionConfirm={actionConfirm}
      /> */}
			{dataTable && dataTable.length > 0 && (
				<Grid.Row>
					<TableContainer component={Paper} id='color-letter-table-tasks'>
						<Table aria-label='spanning table'>
							<TableHead>
								<TableRow colSpan={6} />
							</TableHead>
							<TableHead className='color-letter-inTable'>
								<TableRow>
									<TableCell className='letter-title-table not-padding-table-task'>
										<b>Fecha</b>
									</TableCell>
									{!isProfessional && (
										<TableCell className='letter-title-table not-padding-table-task'>
											<b>Período</b>
										</TableCell>
									)}
									{isCollege && (
										<TableCell className='letter-title-table not-padding-table-task'>
											<b>Regional</b>
										</TableCell>
									)}
									<TableCell className='letter-title-table'>
										<b>Paciente</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Profesional</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>DNI</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Obra Social</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Nro. Autorización</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Opciones </b>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{dataTable.map((item) => (
									<TableRowData key={item.documentoId} item={item} />
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid.Row>
			)}
			<Grid.Row centered>
				{dataTable && dataTable.length > 0 && (
					<Pagination
						className='pagination'
						count={totalCount}
						page={page}
						onChange={handleChange}
					/>
				)}
			</Grid.Row>
			<Grid.Row centered>
				{false && dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
			</Grid.Row>
		</>
	);
}
