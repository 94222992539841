import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Button, Grid, Icon } from 'semantic-ui-react';
import api from "../../../api";
import { IAssociation, IDefaultValues, ILoadersSelect, IRegional } from '../../../api/Interfaces/managment/ICrudRegional';
import { typeOfActionForModal, typeOfModal } from "../../../services/interfaces/utils/IUtilDeclaration";
import ButtonBox from '../../utils/ButtonBox';
import GeneralList from "../../utils/GeneralList";
import DeleteModalRegional from './modals/DeleteModalRegional';
import NewModalRegional from "./modals/NewModalRegional";
import { ICrudRegional } from '../../../services/interfaces/Managment/ICrudRegional';

const _DEFAULT_VALUES: IDefaultValues = {
	_VALUES: {
		nombre: null,
		correo: null,
		cuit: null,
		colegio: null,
		requiereGestion: false
	},
	_MODALS: {
		create: { show: false, data: null },
		edit: { show: false, data: null },
		delete: { show: false, data: null }
	},
	_FILTER_OBJ: { accountName: null, association: null },
	_STATE_OBJ: { requiresManagment: false }
}

const CrudRegional: React.FC<ICrudRegional> = ({ setDataToast }) => {
	//#region Estados del componente
	const [openModal, setOpenModal] = React.useState(_DEFAULT_VALUES._MODALS);
	const [values, setValues] = React.useState(_DEFAULT_VALUES._VALUES);
	const [filterObj, setFilterObj] = React.useState(_DEFAULT_VALUES._FILTER_OBJ);
	const [loaders, setLoaders] = React.useState<ILoadersSelect>({ associations: false });
	const [associations, setAssociations] = useState<IAssociation[]>([]);
	const [dataTable, setDataTable] = useState<IRegional[]>([]);
	const [loadingActive, setLoadingActive] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(1);
	//#endregion

	useEffect(() => {
		OnClickViewList();
	}, []);

	function OnClickViewList() {
		setLoadingActive(true);
		setLoaders({ associations: true });

		Promise.all([
			api.GetAccountsList(filterObj),
			api.GetAllAssociations(),
		])
			.then((res) => {
				const accountList: IRegional[] = res[0].data;

				const associations: IAssociation[] = res[1].data.map(item => {
					return { value: item.id, label: item.nombre }
				});

				setDataTable(accountList);
				setAssociations(associations);
				setTotalCount(1);
			})
			.catch((err: any) => {
				MessageNotificaction(err.response.data.errores, "error");
			})
			.finally(() => {
				setLoadingActive(false);
				setLoaders({ associations: false });
			})
	}
	function ButtonNew() {
		return (
			<Button
				className='btn-new-filter'
				onClick={() => OpenModalSelected("open", "create", values)}
				icon
				labelPosition='left'
			>
				<Icon name='plus' />
				Nuevo
			</Button>
		)
	}
	function FilterComponent() {
		return (
			<Grid.Row className="content-filter-list">
				<TextField
					size='small'
					id='nombre'
					className="filter-input"
					label='Nombre'
					value={filterObj.accountName}
					onBlur={(e) => {
						setFilterObj({
							...filterObj,
							accountName: e.target.value,
						});
					}}
					variant='outlined'
				/>
				<ReactSelect
					className="filter-input"
					placeholder="Colegios..."
					options={associations}
					isLoading={loaders.associations}
					isClearable={true}
					value={filterObj.association}
					onChange={(e) => setFilterObj({ ...filterObj, association: e })}
				/>
			</Grid.Row>
		)
	}

	//#region UTILS
	function OpenModalSelected(action: typeOfActionForModal, modal: typeOfModal, data?: any) {
		const keys = Object.keys(openModal);
		const indexOfKey = Object.keys(openModal).findIndex(item => item === modal);
		const keyOfModalToOpen = keys[indexOfKey];

		setOpenModal({ ...openModal, [keyOfModalToOpen]: { show: action === "open" ? true : false, data } });

		if (action === "close") {
			setValues(_DEFAULT_VALUES._VALUES);
		} else {
			if (modal !== "create" && data) setValues(data);
		}
	}
	function PrevToAction(e: any, item) {
    const filteredItem = Object.fromEntries(Object.entries(item).filter(([_, value]) => value !== null && value !== undefined));
		OpenModalSelected("open", e.currentTarget.name, filteredItem);
	}
	function MessageNotificaction(message: string, type: string) {
		setDataToast({
			active: true,
			message: message ?? 'Ocurrió un error.',
			type: type,
		});
	}
	function handleChange(event: any, value: number) {
		setPage(value);
		OnClickViewList();
	}
	//#endregion

	return (
		<>
			<GeneralList
				titleOfSection="Regionales"
				isLoaderActive={loadingActive}
				dataToRender={dataTable}
				initialPage={page}
				numPagesToRender={totalCount}
				ButtonNew={ButtonNew}
				FilterComponent={FilterComponent}
				HandleSearch={OnClickViewList}
				onChangePage={handleChange}
			>
				<Table aria-label='spanning table'>
					<TableHead>
						<TableRow>
							<TableCell className='letter-title-table c-text-center p-cell'>
								<b>Nombre</b>
							</TableCell>
							<TableCell className='letter-title-table c-text-center p-cell'>
								<b>Colegio</b>
							</TableCell>
							<TableCell className='letter-title-table c-text-center p-cell'>
								<b>CUIT</b>
							</TableCell>
							<TableCell className='letter-title-table c-text-center p-cell'>
								<b>Correo</b>
							</TableCell>
							<TableCell className='letter-title-table c-text-center p-cell'>
								<b>Gestión</b>
							</TableCell>
							<TableCell className='letter-title-table c-text-center p-cell'>
								<b>Opciones</b>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{dataTable.map(cuenta => (
							<TableRow key={cuenta.id}>
								<TableCell className='letter-title-table c-text-center p-cell'>
									{cuenta.nombre}
								</TableCell>
								<TableCell className='letter-title-table c-text-center p-cell'>
									{cuenta.colegio.id}
								</TableCell>
								<TableCell className='letter-title-table c-text-center p-cell'>
									{cuenta.cuit}
								</TableCell>
								<TableCell className='letter-title-table c-text-center p-cell'>
									{cuenta.correo}
								</TableCell>
								<TableCell className='letter-title-table c-text-center p-cell'>
									<Checkbox checked={cuenta.requiereGestion} />
								</TableCell>
								<TableCell className='p-cell'>
									<Button.Group>
										<ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e) => PrevToAction(e, cuenta)} />
										<ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e) => PrevToAction(e, cuenta)} />
									</Button.Group>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</GeneralList>

			{(openModal.create.show || openModal.edit.show) &&
				<NewModalRegional
					values={values}
					modal={`${openModal.create.show ? "create" : "edit"}`}
					openModal={openModal.create.show || openModal.edit.show}
					associationList={associations}
					loaders={loaders}
					setLoadingActive={setLoadingActive}
					OpenModalSelected={OpenModalSelected}
					MessageNotificaction={MessageNotificaction}
					reloadData={OnClickViewList}
				/>}

			{openModal.delete.show &&
				<DeleteModalRegional
					modal={"delete"}
					openModal={openModal.delete.show}
					modalData={openModal.delete.data}
					OpenModalSelected={() => OpenModalSelected("close", "delete")}
					MessageNotificaction={MessageNotificaction}
					setIsLoaderActive={setLoadingActive}
					reloadData={OnClickViewList}
				/>}
		</>
	);
}

export default CrudRegional;
