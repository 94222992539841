import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FormatNumber } from '../../../utils/FormatInputs';
import GeneralSimpleList from '../../../utils/GeneralSimpleList';
import { ISummaryForSocialWorkResponse } from '../../../../api/Interfaces/managment/ILiquidation';

const SummaryForSocialWork = ({ dataToRender }) => {
    return (
        <GeneralSimpleList
            dataToRender={dataToRender}
            isLoaderActive={false}
            paginated={false}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha Factura</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Nº Factura</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Nº Recibo</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Obra Social</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Plan</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Periodo</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Refacturada</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Total Factura</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Total Débitos</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: ISummaryForSocialWorkResponse) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fechaFactura).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.numeroFactura}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.numeroRecibo}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.obraSocial.nombre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.planObraSocial.nombre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.periodo}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <Checkbox checked={item.refacturado} />
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.totalFactura })}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.TotalDebito })}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralSimpleList>
    );
}

export default SummaryForSocialWork;