import React from 'react';
import GeneralModalShow from '../../../../utils/GeneralModalShow';
import GeneralSimpleList from '../../../../utils/GeneralSimpleList';
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	isAmountDown: {color: "#ff8a80"}
});

const ViewHistorialAmount: React.FC<any> = ({
    openModal,
    HandleCloseModal,
    practiceAmountHistorialData,
})=>{
	const classStyle = useStyles();

    return(
        <GeneralModalShow
            title="Historial de montos"
            openModal={openModal}
            HandleClose={HandleCloseModal}
            >
                <GeneralSimpleList
                dataToRender={practiceAmountHistorialData}
                isLoaderActive={false}
                paginated={false}
                >
                    <Table aria-label='spanning table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Fecha</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Monto</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {practiceAmountHistorialData.map((data) => (
                                <TableRow key={data.id}>
                                    <TableCell className={`c-text-center ${data.dadoDeBaja && classStyle.isAmountDown}`}>
                                        {new Date(data.fecha).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell className={`c-text-center ${data.dadoDeBaja && classStyle.isAmountDown}`}>
                                        $ {data.monto ?? "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </GeneralSimpleList>
            </GeneralModalShow>
    )
}

export default ViewHistorialAmount;