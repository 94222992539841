export const toolbarConfig = [
    ['bold', 'italic', 'underline', "image"],
    ['blockquote'],       
    [{ 'list': 'bullet' }],     
    [{ 'indent': '-1'}, { 'indent': '+1' }],
  
    [{ 'size': ['small', false, 'large', 'huge'] }],
  
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ]