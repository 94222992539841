import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    liquidacion: {nombre: "", fecha: moment().startOf('day').toDate(), estado: null},
    parametros: {
        costoCuotaSocietaria: 0,
        costoMantenimientoMatricula: 0,
        porcentajeDebitoColegio: 0,
        porcentajeDebitoRegional: 0,
        motivoDebitoAutomatico: "Débito Automático",
        porcentajeGastoNoSocio: 0,
        costoSistemaDocumental: 0,
        edad0a18: 0,
        edad19a35: 0,
        edad36a45: 0,
        edad46a55: 0,
        edad56a65: 0,
        edadMas65: 0,
        recAntecEnfermedadesCatastrof: 0,
        edadMaximaCoberturaHijoAdulto: 0
    },
    facturas: {},
    descuentos: [],
    acreditaciones: [],
    acreditacionesSaldo: [],
    cuotas: []
};


const {actions, reducer} = createSlice({
    name: 'generateLiquidation',
    initialState: initialState,
    reducers: {
        setDataLiquidation(state, {payload}){
            state.liquidacion =  {
                nombre: payload.nombre,
                fecha: payload.fecha,
                estado: payload.estado
            }
        },
        setDataOfBillForLiquidation(state, {payload}){
            state.facturas = {
                ...state.facturas,
                ...payload
            }
        },
        setRestoreDataOfBillForliquidation(state, {payload}){
            const newStateForBill = {...state.facturas};
            delete newStateForBill[payload];
            state.facturas = newStateForBill;
        },
        setDataDescountForLiquidation(state, {payload}){
            state.descuentos = payload;
        },
        setDataAccreditationForLiquidation(state, {payload}){
            state.acreditaciones = payload;
        },
        setDataAccreditationBalanceForLiquidation(state, {payload}){
            state.acreditacionesSaldo = payload;
        },
        setDataInstallmentForLiquidation(state, {payload}){
            state.cuotas = payload;
        },
        setDataParamsRegionalForLiquidation(state, {payload}){
            state.parametros = {...payload}
        },
        setRestoreStateLiquidation(state){
            state.liquidacion = initialState.liquidacion;
            state.parametros = initialState.parametros;
            state.facturas = initialState.facturas;
            state.descuentos = initialState.descuentos;
            state.acreditaciones = initialState.acreditaciones;
            state.acreditacionesSaldo = initialState.acreditacionesSaldo;
            state.cuotas = initialState.cuotas;
        },
        setRestoreAmounts(state){
            state.parametros = initialState.parametros;
            state.facturas = initialState.facturas;
            state.descuentos = initialState.descuentos;
            state.acreditaciones = initialState.acreditaciones;
            state.acreditacionesSaldo = initialState.acreditacionesSaldo;
            state.cuotas = initialState.cuotas;
        }
    }
});

export const {
    setDataLiquidation, 
    setDataOfBillForLiquidation, 
    setRestoreDataOfBillForliquidation,
    setDataDescountForLiquidation,
    setDataAccreditationForLiquidation,
    setDataInstallmentForLiquidation,
    setDataParamsRegionalForLiquidation,
    setDataAccreditationBalanceForLiquidation,
    setRestoreStateLiquidation,
    setRestoreAmounts
} = actions;
export default reducer;