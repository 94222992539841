import React from 'react'
import { TypeRole } from '../../enums';
import { IGeneralModalDelete } from '../../services/interfaces/utils/IGeneralModalDelete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from 'semantic-ui-react';
import { red } from '@material-ui/core/colors';

const GeneralModalDelete: React.FC<IGeneralModalDelete> = ({
    contentText,
    openModal,
    HandleClose,
    HandleSubmit,
    schoolText = "",
    warningText = ""
}) => {
    const [RolType, setRolType] = React.useState<string>(JSON.parse(localStorage.getItem('TypeRole') ?? ""));
    return <Dialog open={openModal} onClose={HandleClose}>
        <DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
        <DialogContent>
            {(RolType === TypeRole.Colegio || RolType === TypeRole.Admin) && warningText !== "" &&
                <span style={{ color: red[700] }}>
                    {warningText}
                </span>}
            {RolType === TypeRole.Colegio && schoolText !== "" &&
                <span style={{ color: red[700] }}>
                    {schoolText}
                </span>}
            <DialogContentText>
                {contentText}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={HandleClose}>Cancelar</Button>
            <Button onClick={HandleSubmit} color='red'>
                Eliminar
            </Button>
        </DialogActions>
    </Dialog>
}

export default GeneralModalDelete;