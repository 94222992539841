import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IDescount, IDescountCreateOrEditParams, IDescountFilterParams, IPostResult } from "../../api/Interfaces/managment/ICrudDescount";
import errorHandler from "../errorHandler";

const GetDescounts = async (params: IDescountFilterParams): Promise<IApiResponse<IDescount[]>> => {
    try{
        const response = await api.GetDescounts(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateDescount = async (params: IDescountCreateOrEditParams): Promise<IApiResponse<IPostResult>> => {
    try{
        const response = await api.CreateDescount(params);
        
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditDescount = async (id: number, params: IDescountCreateOrEditParams): Promise<IApiResponse<void>> => {
    try{
        
        const response = await api.EditDescount(id, params);
        
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteDescount = async (id: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteDescount(id);
        
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
export {GetDescounts, CreateDescount, EditDescount, DeleteDescount}

