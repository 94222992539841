import { Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { IFamily } from '../../../../api/Interfaces/configurations/IFamily';
import { StatusCode } from '../../../../enums';
import { DeleteFamily, GetFamily } from '../../../../services/services/Family';
import ButtonBox from '../../../utils/ButtonBox';
import GeneralModalDelete from '../../../utils/GeneralModalDelete';
import GeneralSimpleList from '../../../utils/GeneralSimpleList';
import { _DEFAULT_VALUES } from '../defaultValues';
import ModalNewFamily from '../modals/ModalNewFamily';
import { IFamilyProfessional, IOpenModalsObj } from '../../../../services/interfaces/Configurations/IFamilyProfessional';
import { IErrorObjFamily } from '../../../../services/interfaces/Configurations/IModalNewFamily';

const FamilyProfessional: React.FC<IFamilyProfessional> = ({MessageNotification}) =>{
    const {idProf} = useParams<any>();
    const loadForPage = 20;
    const [openModal, setOpenModal] = React.useState<IOpenModalsObj>(_DEFAULT_VALUES._OPEN_MODALS);
    const [data, setData] = React.useState<Partial<IFamily>>(_DEFAULT_VALUES._DATA_FAMILY_PROFESIONAL);
    const [error, setError] = React.useState<IErrorObjFamily>(_DEFAULT_VALUES._ERROR_OBJ);
    const [familyList, setFamilyList] = React.useState<IFamily[]>([]);
    const [loaderActive, setLoaderActive] = React.useState(false);
    const [page, setPage] = React.useState(1);

    React.useEffect(()=>{
        GetFamilyList();
    },[]);

    async function GetFamilyList(){
        try{
            setLoaderActive(true);
            const params = {
                page: page,
                pageSize: loadForPage
            }
            const {data, status} = await GetFamily(+idProf, params);
            if (status === StatusCode.Ok){
                setFamilyList(data.results);
            }
        }catch(err: any){
			MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoaderActive(false);
        }
    }

    //#region ELIMINAR FAMILIARES
    async function DeleteExistFamily(){
        try{
            setLoaderActive(true);
            const {status} = await DeleteFamily(+idProf, data.id as number);
            if (status === StatusCode.Ok){
                MessageNotification("Familiar eliminado correctamente.", "success");
                HandleCloseModal();
                GetFamilyList();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoaderActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function HandleCloseModal(){
        setData(_DEFAULT_VALUES._DATA_FAMILY_PROFESIONAL);
        setOpenModal(_DEFAULT_VALUES._OPEN_MODALS);
        setError(_DEFAULT_VALUES._ERROR_OBJ);
    }
    function HandleData({data, isEdit}){
        if (isEdit){
            setOpenModal({...openModal, edit: true});
        }else{
            setOpenModal({...openModal, delete: true});
        }
        setData(data);
    }
    //#endregion
   
    return(
        <>
            <Grid>
                <Button
                    className='btn-new-filter'
                    onClick={()=>setOpenModal({...openModal, new: true})}
                    icon
                    labelPosition='left'
                    >
                    <Icon name='plus' />
                    Nuevo
                </Button>
                <GeneralSimpleList
                dataToRender={familyList}
                isLoaderActive={loaderActive}
                paginated={false}
                >
                    <Table aria-label='spanning table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Estado</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Nombre</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Relacion</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Fecha Nacimiento</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Estudiante Regular</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Siempre Cubierto</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <b>Opciones</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {familyList.map((family)=> (
                                <TableRow key={family.id}>
                                    <TableCell className='c-text-center p-cell'>
                                        <Checkbox checked={family.estado}/>
                                    </TableCell>
                                    <TableCell className='c-text-center p-cell'>
                                        {family.nombre}
                                    </TableCell>
                                    <TableCell className='c-text-center p-cell'>
                                        {family.relacion}
                                    </TableCell>
                                    <TableCell className='c-text-center p-cell'>
                                        { family.fechaNacimiento !== null ? new Date(family.fechaNacimiento).toLocaleDateString() : "--"}
                                    </TableCell>
                                    <TableCell className='c-text-center p-cell'>
                                        <Checkbox checked={family.estudianteRegular}/>
                                    </TableCell>
                                    <TableCell className='c-text-center p-cell'>
                                        <Checkbox checked={family.siempreCubierto}/>
                                    </TableCell>
                                    <TableCell className='c-text-center p-cell' >
                                        <Button.Group>
                                            <ButtonBox buttonToShow='edit' name='edit' HandleOnClik={()=>HandleData({data: family, isEdit: true})}/>
                                            <ButtonBox buttonToShow='delete' name='delete' HandleOnClik={()=>HandleData({data: family, isEdit: false})}/>
                                        </Button.Group>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </GeneralSimpleList>
            </Grid>

            {openModal.new &&
            <ModalNewFamily
            title="Nuevo"
            isCreate={openModal.new}
            error={error}
            openModal={openModal.new}
            values={data}
            setError={setError}
            ReloadData={GetFamilyList}
            HandleCloseModal={HandleCloseModal}
            MessageNotification={MessageNotification}
            />}

           {openModal.edit &&
           <ModalNewFamily
            title="Editar"
            error={error}
            openModal={openModal.edit}
            values={data}
            setError={setError}
            ReloadData={GetFamilyList}
            HandleCloseModal={HandleCloseModal}
            MessageNotification={MessageNotification}
            />}

            <GeneralModalDelete 
            openModal={openModal.delete}
            HandleClose={HandleCloseModal}
            HandleSubmit={DeleteExistFamily}
            contentText={`Esta seguro de eliminar a: ${data.nombre}?`}
            />
        </>
    )
}

export default FamilyProfessional;