import React, { useEffect } from 'react'
import ReactSelect from 'react-select'
import { Button, Grid, Icon, TextArea } from 'semantic-ui-react'
import { ICrudInstallmentModal } from "../../../../services/interfaces/Managment/ICrudInstallment"
import { GetProfesionals } from "../../../../services/services/Users"
import GeneralModalCrud from '../../../utils/GeneralModalCrud'
import PreviewViewInstallment from './PreviewViewInstallment'
import GeneralModalShow from '../../../utils/GeneralModalShow'
import { selectTypeFormat } from '../../../../services/interfaces/utils/IUtilDeclaration'

const EditModalInstallment: React.FC<ICrudInstallmentModal> = ({
    titleOfModal,
    modal,
    openModal, 
    values, 
    setValues,
    errorNotification,
    HandleChangeInput, 
    MessageNotification,
    OpenModalSelected,
    SendValues})=>{

        const [profesionalList, setProfesionalList] = React.useState<selectTypeFormat[]>([]);
        const [loaderSelect, setLoaderSelect] = React.useState({profesional: false});
        const [openPreview, setOpenPreview] = React.useState(false);

        useEffect(()=>{
            GetProfesionalList();
        },[])

    async function GetProfesionalList(){
        try{
            setLoaderSelect({profesional: true});
            const {status, data} = await GetProfesionals({});
            if (status === 200){
                const RSUserList = data?.results?.map(item=>{return {value: item.id, label: `${item.apellido}, ${item.nombre}`}}) ?? [];
                setProfesionalList(RSUserList);
            }
        }catch(err: any){
            MessageNotification(err.message, "error");
        }finally{
             setLoaderSelect({profesional: false});
        }
    }
        
    function HandleCloseModal(){
       OpenModalSelected("close", `${modal === "create" ? "create" : "edit"}`, values);
    }

    function HandleSubmit(){
        SendValues();
    }

    return(
        <>
        <GeneralModalCrud 
        titleOfModal={titleOfModal}
        openModal={openModal} 
        HandleCloseModal={HandleCloseModal} 
        HandleSubmit={HandleSubmit}
        styles="cont-default-modal">
            <Grid.Column style={{display:"flex", flexDirection: "column"}}>
                <ReactSelect
                     styles={{
                        control: (s)=>({...s, maxWidth: 300})
                    }}
                    placeholder={`${values.profesionalNombre ?? "Profesionales..."}`}
                    options={profesionalList}
                    isLoading={loaderSelect.profesional}
                    isClearable={true}
                    className={errorNotification.profesionalId ? "errorNotificationInput" : ""}
                    onChange={(e)=>setValues({...values, profesionalId: e === null ? null : e.value, profesionalNombre: e === null ? null : e.label})}
                />
                <TextArea
                    value={values.detalle} 
                    placeholder="Detalle..."
                    className={`commentReturn ${errorNotification.detalle ? "errorNotificationInput" : ""}`}
                    onChange={(e: any)=>setValues({
                        ...values,
                        detalle: e.target.value
                    })}
                    style={{resize: "vertical", margin: "15px 0"}}
                />
                <Button
                    className='btn-search-filter'
                    primary
                    disabled={Object.values(values).some(item => item === null || item === "")}
                    icon
                    labelPosition='left'
                    onClick={()=>setOpenPreview(true)}
                    type='submit'
                    >
                    <Icon name='search' />
                    Lista de Cuotas
                </Button>
            </Grid.Column>
        </GeneralModalCrud>
         <GeneralModalShow
         openModal={openPreview}
         HandleClose={()=>setOpenPreview(false)}
         title="Vista - Cuotas"
         >
            <PreviewViewInstallment isEdit={true} MessageNotification={MessageNotification} values={values}/>
         </GeneralModalShow>
        </>
    )
}

export default EditModalInstallment;