import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EditorText({
    handleOnChange, 
    text, 
    toolbarOptions = [], 
    isReadOnly = false,
    errorNotificationInput = false,
    placeholder
}){
    return (<ReactQuill 
                className={`editorText ${isReadOnly ? "onlyShowEditorText" : ""} ${errorNotificationInput ? "errorNotificationInput" : ""}`}
                theme='snow'
                value={text}
                onChange={handleOnChange}
                placeholder={placeholder}
                modules={{
                    toolbar: toolbarOptions
                }}
                readOnly={isReadOnly}
            />)
}