import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Pagination from "@material-ui/lab/Pagination";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import api from "../../../api";
import { StatusCode } from "../../../enums";
import { ReactivateUser } from "../../../services/services/Users";
import DialogDelete from "../../common/DialogDelete";
import { useSelector } from "react-redux";

export default function UsersList({
  dataTable,
  isEdit,
  isDelete,
  quantityData,
  setDataToast,
  page,
  setPage,
  viewDataPreDelete,
  setViewDataPreDelete,
  onClickViewList,
  alertDelete,
  setAlertDelete,
  isRemoved,
}) {
  const history = useHistory();
  const [openReactivate, setOpenReactivate] = useState(false);
  const [openModifyPass, setOpenModifyPass] = useState(false);
  const hasManagment = useSelector(({ managment }) => managment.hasManagment);
  const [dataToModify, setDataToModify] = useState({
    id: null,
    nombre: null,
    apellido: null,
  });

  const actionConfirmLowerUser = () => {
    api
      .lowerUser(viewDataPreDelete.id)
      .then((resp) => {
        setAlertDelete(false);
        onClickViewList();
        setDataToast({
          active: true,
          message: "Dado de baja con éxito",
          type: "success",
        });
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: "Acaba de ocurrir un error",
          type: "error",
        });
      });
  };
  const handleCloseOpenModifyPass = () => {
    setOpenModifyPass(false);
    setDataToModify({});
  };
  const OnClickUpdatePass = () => {
    api
      .updatePassUser(+dataToModify.id)
      .then((resp) => {
        if (resp.status === 200) {
          handleCloseOpenModifyPass();
          setDataToast({
            active: true,
            message: "Contraseña actualizada correctamente.",
            type: "success",
          });
        }
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores ?? "Ocurrió un error.",
          type: "error",
        });
      });
  };
  function HandleUpdateStatus(userSelected) {
    setDataToModify({
      apellido: userSelected.apellido,
      id: userSelected.id,
      matricula: userSelected.matricula,
      nombre: userSelected.nombre,
    });
    setOpenReactivate(true);
  }
  async function HandleReactivateUser() {
    try {
      const { status } = await ReactivateUser(dataToModify.id);
      if (status === StatusCode.Ok) {
        setDataToast({
          active: true,
          message: "Usuario reactivado correctamente.",
          type: "success",
        });
        setOpenReactivate(false);
        onClickViewList();
      }
    } catch (err) {
      setDataToast({
        active: true,
        message: err.response.data.errores ?? "Ocurrió un error.",
        type: "error",
      });
    }
  }
  const HandleModifyPass = (id, nombre, apellido) => {
    setOpenModifyPass(true);
    setDataToModify({
      id,
      nombre,
      apellido,
    });
  };

  const TableRowData = ({ item }) => {
    return (
      <TableRow colSpan={6} key={item.id}>
        <TableCell className="p-cell c-text-center c-wp-250">
          {item.apellido} {item.nombre}
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {item.email}
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {item.cuit}
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {item.cuenta.nombre ?? "--"}
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {item.matricula} 
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {item.rol.nombre ?? "--"}
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {item.fechaUltimaConexion
            ? new Date(item.fechaUltimaConexion).toLocaleDateString("es-ES")
            : "--"}
        </TableCell>
        <TableCell className="p-cell c-text-center c-wp-150">
          {isRemoved ? (
            <IconButton
              className="btn-circle-green"
              onClick={() => HandleUpdateStatus(item)}
            >
              <Tooltip placement="top" title="Reactivar">
                <CachedIcon />
              </Tooltip>
            </IconButton>
          ) : (
            <Button.Group>
              {isEdit == true && (
                <IconButton
                  className="btn-circle-yellow"
                  aria-label="edit"
                  onClick={() =>
                    history.push(`/configuraciones/usuarios/${item.id}`)
                  }
                >
                  <Tooltip placement="top" title="Editar">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              )}
              {hasManagment && (
                <IconButton
                  className="btn-circle-blue"
                  aria-label="search"
                  onClick={() =>
                    history.push(
                      `/configuraciones/usuarios/${item.id}/resumen-de-cuenta`
                    )
                  }
                >
                  <Tooltip placement="top" title="Resumen de cuenta">
                    <SearchIcon />
                  </Tooltip>
                </IconButton>
              )}
              {isDelete == true && (
                <IconButton
                  className="btn-circle-red"
                  aria-label="delete"
                  onClick={() => {
                    setAlertDelete(true);
                    setViewDataPreDelete({
                      id: item.id,
                      title: "Baja de registro",
                      content:
                        "¿Está seguro de dar de baja el registro de " +
                        item.nombre +
                        " " +
                        item.apellido +
                        "?",
                    });
                  }}
                >
                  <Tooltip placement="top" title="Eliminar">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              )}

              <IconButton
                className="btn-circle-yellow"
                onClick={() =>
                  HandleModifyPass(
                    item.id,
                    item.matricula,
                    item.nombre,
                    item.apellido
                  )
                }
              >
                <Tooltip placement="top" title="Blanquear contraseña">
                  <VpnKeyIcon />
                </Tooltip>
              </IconButton>
            </Button.Group>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      {dataTable && dataTable.length > 0 ? (
        <Grid.Row>
          <TableContainer component={Paper} id="color-letter-table-tasks">
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow colSpan={5} />
              </TableHead>
              <TableHead className="color-letter-inTable">
                <TableRow>
                  <TableCell className="letter-title-table c-text-center">
                    <b>Nombre Profesional</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>Email Profesional</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>CUIT</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>Regional</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>N° Matrícula</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>Tipo Rol</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>Última Conexion</b>
                  </TableCell>
                  <TableCell className="letter-title-table c-text-center">
                    <b>Opciones</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dataTable.map((item) => (
                  <TableRowData key={item.id} item={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid.Row>
      ) : (
        <Grid.Row style={{ textAlign: "-webkit-center" }}>
          <TableContainer component={Paper} id="color-letter-table-tasks">
            <TableCell>
              <h5>No se encontraron resultados</h5>
            </TableCell>
          </TableContainer>
        </Grid.Row>
      )}
      <Grid.Row centered>
        {dataTable && dataTable.length > 0 && (
          <Pagination
            className="pagination"
            count={quantityData}
            page={page}
            onChange={handleChange}
          />
        )}
      </Grid.Row>

      {/* ELIMINAR */}
      <DialogDelete
        open={alertDelete}
        setOpen={setAlertDelete}
        title={viewDataPreDelete.title}
        content={viewDataPreDelete.content}
        actionConfirm={actionConfirmLowerUser}
      />

      {/* REACTIVAR */}
      <Dialog open={openReactivate} onClose={() => setOpenReactivate(false)}>
        <DialogTitle id="form-dialog-title">Reactivar Registro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de reactivar el siguiente usuario:{" "}
            {`${dataToModify.apellido}, ${dataToModify.nombre}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReactivate(false)}>Cancelar</Button>
          <Button onClick={HandleReactivateUser} color="green">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* BLANQUEAR PASS */}
      <Dialog open={openModifyPass} onClose={handleCloseOpenModifyPass}>
        <DialogTitle id="form-dialog-title">Blanquear contraseñas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esta función actualiza la contraseña del profesional con el número
            de matrícula.
          </DialogContentText>
          <DialogContentText>
            ¿Está seguro de actualizar la contraseña de:{" "}
            {dataToModify.nombre + " " + dataToModify.apellido}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenModifyPass}>Cancelar</Button>
          <Button onClick={OnClickUpdatePass} color="green">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
