export const _DEFAULT_NEWNEWSMODAL_VALUES = {
    _VALUES: {
        title: null,
        body: null,
        urlVideo: ""
    },
    _ERROR_NOTIFICATION:{
        title: false,
        text: false,
        urlVideo: false,
        body: false
    },
    _DETAIL_NOTIFICATION_LIST:{
        show: false,
        data: null
    }
}

export const _DEFAULT_NEWS_VALUES = {
    _FILTER_OBJ: {
        title: null,
        page: 1,
        pageSize: 100,
        deleted: false
    },
    _MODALS: {
        new: {show: false, data: null},
        edit: {show: false, data: null},
        delete: {show: false, data: null},
        reactivate: {show: false, data: null}
    }
}