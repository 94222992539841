import DateFnsUtils from "@date-io/date-fns";
import { InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';
import ReactSelect from 'react-select';
import { Button, Icon } from "semantic-ui-react";
import api from "../../../../api";
import { StatusCode } from "../../../../enums";
import { INewBillErrorNotification, INewModalBill } from "../../../../services/interfaces/Managment/INewModalBill";
import { selectTypeFormat } from "../../../../services/interfaces/utils/IUtilDeclaration";
import GeneralModalCrud from "../../../utils/GeneralModalCrud";
import ViewDetailProfesionalList from "./ViewDetailProfesionalList";
import { GetBillById } from "../../../../services/services/CrudBill";

const _DEFAULT_VALUES = {
    errorNotification: {
        obraSocialId: false,
        planObraSocialId: false,
        fecha: false,
        periodo: false,
        monto: false
    }
}
const NewModalBill: React.FC<INewModalBill> = ({
    titleOfModal,
    openModal, 
    values,
    socialWorkList,
    isEdit,
    setOpenModal,
    setValues,
    MessageNotification,
    HandleCloseModal,
    SendValues
})=>{
    const [loadingSelect, setLoadingSelect] = React.useState(false);
    const [planSocialWork, setPlanSocialWork] = React.useState<selectTypeFormat[]>([]);
    const [errorNotification, setErrorNotificacion] = React.useState<INewBillErrorNotification>(_DEFAULT_VALUES.errorNotification);
    const [planSocialWorkSelected, setPlanSocialWorkSelected] = React.useState<selectTypeFormat | null>({value: null, label: null});
    const [detailtOfBilling, setDetailOfBilling] = React.useState<any>([]);

    React.useEffect(()=>{
        (isEdit && HandleGetBillById())
    },[]);

     React.useEffect(()=>{
        HandleModalProfesionalList();
        HandleSetDefaultValues();
    },[values.obraSocialId || values.planObraSocialId]);

    async function GetSocialWorkAndPlan(socialWorkId: number){
        try{
            setLoadingSelect(true);
            const {status, data} = await api.getSocialWorksPlan(socialWorkId);
            if(status === StatusCode.Ok){
                setPlanSocialWork(data.map(plan => {
                    return {value: plan.id, label: plan.nombre}
                }));
                setValues((prevState)=>({...prevState, obraSocialId: socialWorkId}));
            }
        }catch(err: any){
            MessageNotification(err, "error");
        }finally{
            setLoadingSelect(false);
        }
    }

    async function HandleGetBillById(){
        try{
            setLoadingSelect(true);
            const {data, status} = await GetBillById(openModal.edit.data.id);
            if (status === StatusCode.Ok){
                const profesionalSelectedForDefault = data.results.usuarioFactura.map(prof => ({
                    id: prof.UsuarioId,
                    nombre: prof.nombre,
                    matricula: prof.matricula,
                    apellido: prof.apellido,
                    marcado: true,
                    monto: prof.monto
                }));
                const totalAmount: number = profesionalSelectedForDefault.reduce((acc, actualValue)=>{
                    acc = acc + actualValue.monto;
                    return acc;
                },0);
                setValues((prevState) =>({
                    ...prevState, 
                    profesionales: data.results.usuarioFactura, 
                    planObraSocialId: data.results.plan.id,
                    monto: totalAmount
                }));
                setPlanSocialWorkSelected({value: data.results.plan.id, label: data.results.plan.nombre});
                setDetailOfBilling(profesionalSelectedForDefault);
                GetSocialWorkAndPlan(data.results.obraSocial.id);
            }
        }catch(err: any){
            MessageNotification(err.message, "error");
        }finally{
            setLoadingSelect(false);
        }
    }

    function ValidateInputs(){
        setErrorNotificacion(_DEFAULT_VALUES.errorNotification);
        const objError: any = {};
        
        Object.entries(values).forEach(item =>{
            if (item[1] === null) objError[item[0]] = true
        });

        if (Object.keys(objError).length !== 0){
            MessageNotification("Todos los campos son obligatorios.","error");
            setErrorNotificacion(objError);
            return;
        }
        if (detailtOfBilling.length === 0){
            MessageNotification("Debe seleccionar profesionales para refacturar.","error");
            return;
        }

        SendValues();
    }

    function HandleModalProfesionalList(toShow = false, data = null){
        setOpenModal({
            ...openModal,
            profesionalList: {show: toShow, data: data}
        })
    }

    function HandleSetDefaultValues(){
        setDetailOfBilling([]);
        setPlanSocialWorkSelected(null);
        setValues((prevState)=>({
            ...prevState, 
            monto: 0,
            planObraSocialId: null, 
            profesionales: []
        }));
    }

    return(
        <>
            <GeneralModalCrud
            titleOfModal={titleOfModal}
            openModal={isEdit ? openModal.edit.show : openModal.create.show}
            HandleCloseModal={HandleCloseModal}
            HandleSubmit={ValidateInputs}
            styles={`cont-default-modal cont-modalBill-rebill ${detailtOfBilling.length > 0 ? "cont-default-modal--grid cont-default-modal--expansion" : ""}`}
            >
                <section className="cont-modalBill-rebill-info">
                    <ReactSelect
                    placeholder="Obras sociales..."
                    options={socialWorkList}
                    isLoading={loadingSelect}
                    className={errorNotification.obraSocialId ? "errorNotificationInput" : ""}
                    value={socialWorkList.find(os => os.value === values.obraSocialId) ?? null}
                    onChange={(e)=>GetSocialWorkAndPlan(e.value)}
                />
                <ReactSelect
                    placeholder="Planes..."
                    options={planSocialWork}
                    value={planSocialWorkSelected}
                    isLoading={loadingSelect}
                    className={errorNotification.planObraSocialId ? "errorNotificationInput" : ""}
                    onChange={(e)=>{
                        setPlanSocialWorkSelected(e);
                        setValues((prevState)=>({...prevState, planObraSocialId: e.value}));
                    }}
                />
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter"
                    clearable
                    style={{margin: 0}}
                    error={errorNotification.fecha}
                    value={values.fecha}
                    placeholder="Fecha"
                    onChange={(date)=>setValues((prevState)=>({...prevState, fecha: date}))}
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter"
                    clearable
                    style={{margin: 0}}
                    error={errorNotification.periodo}
                    value={values.periodo}
                    placeholder="Período"
                    openTo='month'
                    onChange={(date)=>setValues((prevState)=>({...prevState, periodo: date}))}
                    views={['month', 'year']}
                    margin="normal"
                    inputVariant="outlined"
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    value={`${Intl.NumberFormat().format(values.monto)}`}
                    label='Monto'
                    disabled={true}
                    style={{zIndex: "-1"}}
                    size="small"
                    error={errorNotification.monto}
                    type='text'
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <AttachMoneyOutlinedIcon/>
                        </InputAdornment>
                        ),
                    }}
                    />
                    <Button
                    className='btn-search-filter'
                    primary
                    disabled={Object.values(values).some(input => input === null)}
                    icon
                    labelPosition='left'
                    onClick={()=>HandleModalProfesionalList(true, detailtOfBilling)}
                    type='submit'
                    >
                        <Icon name='search' />
                        Profesionales
                    </Button>
                </section>

               {detailtOfBilling.length > 0 && 
               <section className="cont-modalBill-rebill-detail">
                    <Table aria-label='spanning table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Matrícula</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Profesional</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Monto</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detailtOfBilling.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell className='letter-title-table c-text-center'>
                                        {item.matricula}
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                        {item.apellido}, {item.nombre}
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                        $ {Intl.NumberFormat().format(item.monto)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
               </section>}

            </GeneralModalCrud>

            {openModal.profesionalList.show &&
                <ViewDetailProfesionalList
                infoOfBill={values}
                openModal={openModal}
                setValues={setValues}
                MessageNotification={MessageNotification}
                setOpenModal={setOpenModal}
                setDataOfBilling={setDetailOfBilling}
                />
            }
        </>
    )
}

export default NewModalBill