import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IInstallPreview, IInstallPreviewParams, IInstallment, IInstallmentGetParams, IInstallmentRequest } from "../../api/Interfaces/managment/ICrudInstallment";
import errorHandler from "../errorHandler";
import { postResponse } from "../interfaces/utils/IUtilDeclaration";

const GetInstallments = async (params: IInstallmentGetParams): Promise<IApiResponse<IInstallment[]>> => {
    try{
        const response = await api.GetInstallments(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateInstallment = async (params: IInstallmentRequest): Promise<IApiResponse<postResponse>> => {
    try{
        const response = await api.CreateInstallment(params);
        return {data: {results: response.data}, status: response.status}
    }catch(err){
       return errorHandler(err);
    }
}

const EditInstallment = async (params: IInstallmentRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.EditInstallment(params);
        return {data: {results: undefined}, status: response.status}
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteInstallment = async (params: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteInstallment(params);
        return { data: {results: undefined}, status: response.status }
    }catch(err){
       return errorHandler(err);
    }
}

const GetPreviewInstallment = async (params: IInstallPreviewParams): Promise<IApiResponse<IInstallPreview[]>> => {
    try{
        const response = await api.GetPreviewInstallment(params);
        return { 
            data: {results: response.data}, 
            status: response.status 
        }
    }catch(err){
       return errorHandler(err);
    }
}

const UpdateInstallmentSelected = async (installmentId: number, params: number[]): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateInstallmentSelected(installmentId, params);
        return {data: {results: undefined}, status: response.status }
    }catch(err){
       return errorHandler(err);
    }
} 

export {GetInstallments,CreateInstallment, EditInstallment, DeleteInstallment,GetPreviewInstallment, UpdateInstallmentSelected};