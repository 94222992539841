import { IconButton, Tooltip } from "@material-ui/core";
import React from 'react'
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import AddIcon from "@material-ui/icons/AddCircle";
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { SHOW_BUTTON_BOX } from "../../enums";
import { IButtonBox } from '../../services/interfaces/utils/IButtonBox';
;

//TO DO: Mediante se vaya reutilizando el componente, debemos de agregar todos los otros botones faltantes.
const ButtonBox: React.FC<IButtonBox> = ({buttonToShow, name, isDisabled, HandleOnClik}) =>{
    return(
    <>
        {buttonToShow === SHOW_BUTTON_BOX.view && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Ver" placement='top' arrow>
                <SearchOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.edit && (
         <IconButton className={`btn-circle-orange ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Editar" placement='top' arrow>
                <EditOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.delete && (
         <IconButton className={`btn-circle-red ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Eliminar" placement='top' arrow>
                <DeleteOutlineOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.debitManually && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Saldar Manual" placement='top' arrow>
                <AttachMoneyOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.showModalDiagnostic && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Lista de Diagnósticos" placement='top' arrow>
                <PageviewOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.viewPhotos && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Ver Imagen" placement='top' arrow>
                <ImageSearchOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.practice && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Prácticas" placement='top'>
                <DescriptionOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.reactivate && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Reactivar" placement='top' arrow>
                <CachedOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.amount && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Montos" placement='top' arrow>
                <AttachMoneyOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.instructive && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Instructivo" placement='top' arrow>
                <ImportContactsOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.massive && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Configurar Obra social Colegio" placement='top' arrow>
                <LayersOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
        {buttonToShow === SHOW_BUTTON_BOX.viewAmountHistorial && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Historial" placement='top' arrow>
                <FindInPageOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.viewPresentedSocialWorks && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Obras sociales presentadas" placement='top' arrow>
                <InfoOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.viewStateOfDocuments && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Estado de documentos" placement='top' arrow>
                <InfoOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.print && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Imprimir" placement='top' arrow>
                <PrintOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.printExcel && (
         <IconButton className={`btn-circle-blue ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Descargar" placement='top' arrow>
                <DescriptionOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.addSession && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Agregar Sesión" placement='top' arrow>
                <AddIcon />
            </Tooltip>
        </IconButton>
       )}
       {buttonToShow === SHOW_BUTTON_BOX.manualDebit && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Agregar Débito" placement='top' arrow>
                <AddIcon />
            </Tooltip>
        </IconButton>
       )}
        {buttonToShow === SHOW_BUTTON_BOX.generateTxtSW && (
         <IconButton className={`btn-circle-green ${isDisabled ? "btn-circle-disabled--color" : ""}`} onClick={HandleOnClik} name={name} disabled={isDisabled}>
            <Tooltip title="Generar Reporte" placement='top' arrow>
                <DescriptionOutlinedIcon />
            </Tooltip>
        </IconButton>
       )}
    </>)
}

export default ButtonBox;