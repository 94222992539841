import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { Table, Icon, Grid, Button, Card } from 'semantic-ui-react';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import CreateIcon from '@material-ui/icons/Create';
import { Tooltip, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { Dimmer, Loader } from 'semantic-ui-react';
import { setFiltroFecha } from '../../../redux/socialWork';
export default function CrudPeriod({ setDataToast }) {
	//#region Estados del componente
	const [page, setPage] = useState(1);
	const [loadingActive, setLoadingActive] = useState();
	const loadForPage = 20;
	const handleChange = (event, value) => {
		setPage(value);
		onClickViewList(value);
	};
	const [totalCount, setTotalCount] = useState();
	const [viewDate, setViewDate] = useState();
	const [dataTable, setDataTable] = useState();
	const [error, setError] = useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [dateFilter, setDateFilter] = useState();

	const [defaultValues, setDefaultValues] = useState({
		id: null,
		fechaCierre: null,
	});

	// //#endregion

	useEffect(() => {
		setDateFilter(null);
	}, []);

	//#region Metodos para editar elemento
	function EditPeriod() {
		setLoadingActive(true);
		if (defaultValues.fechaCierre) {
			let body = {
				fechaCierre: moment(defaultValues.fechaCierre).format(),
			};

			api
				.patchPeriod(defaultValues.id, body)
				.then((res) => {
					setDataToast({
						active: true,
						message: 'Fecha editada correctamente.',
						type: 'success',
					});
					onClickViewList(1);
					setLoadingActive(false);
				})

				.catch((err) => {
					setDataToast({
						active: true,
						message: err.response.data.errores ?? 'Ocurrió un error.',
						type: 'error',
					});
					setLoadingActive(false);
				});

			setError(false);
			handleCloseEdit();
		} else {
			setError(true);
		}
	}
	const handleCloseEdit = () => {
		setOpenEdit(false);
	};
	//#endregion

	function shortError(message) {
		setDataToast({
			active: true,
			message: message,
			type: 'error',
		});
	}

	function onClickViewList(nroPage = 1) {
		let fechaFiltro = dateFilter;
		if (fechaFiltro == null) {
			shortError('Debe ingresar Mes y Año');
			return;
		}
		let mes = fechaFiltro.getMonth() + 1;
		let anio = fechaFiltro.getFullYear();
		let body = {
			month: mes,
			year: anio,
			pagina: nroPage,
     		cantidad: loadForPage
		};
		setLoadingActive(true);
		api
			.getPeriodsForCrud(body)
			.then((res) => {
				let data = res.data.resultados;
				let count = res.data.cantidadPaginas;
				setTotalCount(count);

				let list = [];
				data.forEach((item) => {
					if (item.id !== 0) {
						list.push({
							id: item.id,
							numeroMes: item.numeroMes,
							anio: item.anio,
							fechaFin: item.fechaFin,
							fechaCierre: item.fechaCierre,
						});
					}
				});
				setDataTable(null);
				setDataTable(list);
				setLoadingActive(false);
			})

			.catch((err) => {
				setDataToast({
					active: true,
					type: 'error',
					message: 'Ocurrió un error.',
				});
				setLoadingActive(false);
			});
	}

	function getPeriodById(id) {
		api
			.getPeriodById(id)
			.then((res) => {
				let data = res.data;
				setDefaultValues({
					id: data.id,
					fechaCierre: data.fechaCierre,
				});

				setOpenEdit(true);
				setViewDate(true);
			})
			.catch((err) => {
				setViewDate(false);
				shortError('Ocurrió un error.');
			});
	}

	
	const useStyles = makeStyles({
		colorBtn: {backgroundColor: "#e0e1e2", color:'rgba(0,0,0,.6)'}
	});

	const classStyle = useStyles();

	return (
		<>
			<Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className="header-section">
								<div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>Períodos</span>
								</div>
							</Grid.Row>
							<Grid.Row className="content-filter-list">
								<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
										<KeyboardDatePicker
											style={{ marginTop: 5 }}
											autoOk
											className='size-date-picke-filter filter-input'
											inputVariant='outlined'
											InputAdornmentProps={{ position: 'end' }}
											variant='inline'
											name='month'
											clearable
											openTo='month'
											views={['month', 'year']}
											placeholder='Mes - Año.'
											value={dateFilter}
											onChange={(date) => {
												setDateFilter(date);
											}}
										/>
								</MuiPickersUtilsProvider>
							</Grid.Row>
							<Button
								className='btn-search-filter'
								primary
								icon
								labelPosition='left'
								onClick={() => onClickViewList()}
								type='submit'
							>
								<Icon name='search' />
								Buscar
							</Button>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={6} className='color-letter-inTable'>
										<TableRow>
											<>
												<TableCell className='letter-title-table c-text-center'>
													<b>Mes</b>
												</TableCell>
												<TableCell className='letter-title-table c-text-center'>
													<b>Año</b>
												</TableCell>
												<TableCell className='letter-title-table c-text-center'>
													<b>Fecha Cierre</b>
												</TableCell>
												<TableCell className='letter-title-table c-text-center'>
													<b>Fecha Fin</b>
												</TableCell>
											</>
											<TableCell className='letter-title-table c-text-center'>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map((periodo) => (
											<TableRow key={periodo.id}>
												<>
													<TableCell className='letter-title-table c-text-center'>
														{periodo.numeroMes}
													</TableCell>
													<TableCell className='letter-title-table c-text-center'>
														{periodo.anio}
													</TableCell>
													<TableCell className='letter-title-table c-text-center'>
														{moment(periodo.fechaCierre).format('DD/MM/YYYY')}
													</TableCell>
													<TableCell className='letter-title-table c-text-center'>
														{moment(periodo.fechaFin).format('DD/MM/YYYY')}
													</TableCell>
												</>

												<TableCell className='c-text-center'>
													<IconButton
														className='btn-circle-orange'
														onClick={() => getPeriodById(periodo.id)}
													>
														<Tooltip placement='top' title="Editar">
															<CreateIcon />
														</Tooltip>
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
				{dataTable && (
					<Grid.Row centered>
						<Pagination
							className='pagination'
							count={
								totalCount / loadForPage > parseInt(totalCount / loadForPage)
									? parseInt(totalCount / loadForPage) + 1
									: parseInt(totalCount / loadForPage)
							}
							page={page}
							onChange={handleChange}
						/>
					</Grid.Row>
				)}
			</Grid>

			{viewDate && (
				<Dialog
					open={openEdit}
					onClose={handleCloseEdit}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'>
						Editar Fecha de Cierre
					</DialogTitle>
					<DialogContent>
						<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
							<KeyboardDatePicker
								autoOk
								className='size-date-picke-filter'
								clearable
								style={{ margin: 5 }}
								placeholder='Fecha'
								onChange={(date) =>
									setDefaultValues({
										...defaultValues,
										fechaCierre: date,
									})
								}
								format='dd/MM/yyyy'
								margin='normal'
								inputVariant='outlined'
								value={defaultValues.fechaCierre}
								error={error}
							/>
						</MuiPickersUtilsProvider>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={() => handleCloseEdit()} className={classStyle.colorBtn}>
							Cancelar
						</Button>

						<Button onClick={() => EditPeriod()} color='blue'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}
