import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import ReactSelect from 'react-select';
import { GetSocialWorkListByAssociation, GetSocialWorkSelect } from '../../../../services/services/ConfigurationSocialWork';
import { StatusCode, TypeRole } from '../../../../enums';
import GeneralList from '../../../utils/GeneralList';
import { ISocialWork, ISocialWorkFilterSearch, ISocialWorkSelectListRequest } from '../../../../api/Interfaces/configurations/ISocialWork';
import { selectTypeFormat } from '../../../../services/interfaces/utils/IUtilDeclaration';
import { _DEFAULT_FILTROS_VALUES, _DEFAULT_LOADER_SELECT } from '../defaultValues';
import api from '../../../../api';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

const ConfirmOsForAssociation: React.FC<any> = ({setDataToast}) => {
    const loadForPage = 20;
    const history = useHistory();
    const [colegioId, setColegioId] = React.useState<any>(localStorage.getItem('colegioId') ?? null);
	const [RolType, setRolType] = React.useState<string>(JSON.parse(localStorage.getItem('TypeRole') ?? ""));
    const [filtrosBusqueda, setFiltrosBusqueda] = React.useState<ISocialWorkFilterSearch>(_DEFAULT_FILTROS_VALUES);
    const [loaderSelect, setLoaderSelect] = React.useState(_DEFAULT_LOADER_SELECT);
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [stateOfCheckbox, setStateCheckBox] = React.useState<any>([{}]);
    const [dataTable, setDataTable] = React.useState<ISocialWork[]>([]);
	const [regionalList, setRegionalList] = React.useState<selectTypeFormat[]>([]);
    const [listaOs, setListaOs] = React.useState<selectTypeFormat[]>([]);

    React.useEffect(()=>{
        OnClickViewList();
    },[]);

    async function OnClickViewList(nroPage = 1){
        try{
            setLoadingActive(true);
            const params = {
                obraSocialId: filtrosBusqueda.obraSocial.value,
                cuentaId: filtrosBusqueda.cuenta.value,
                conPaginacion: false,
                confirmadas: filtrosBusqueda.confirmadas,
                pagina: nroPage,
                cantidad: loadForPage
            }
            const {data, status} = await GetSocialWorkListByAssociation(+colegioId, params);
            if (status === StatusCode.Ok){
                const dataToRender = data.results.map(item => {
                    return {
                        id: item.id, 
                        nombre: item.nombre, 
                        alias: item.alias,
                        confirmaObraSocial: item.confirmaObraSocial,
                        regional: {
                            id: item.regional.id,
                            nombre: item.regional.nombre
                        }
                    }
                });
                const osObjState =  data.results.map(os =>{
                    return {id: `${os.id}_${os.regional.id}`, confirm: os.confirmaObraSocial}
                });
                setStateCheckBox(osObjState);
                setDataTable(dataToRender);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            FilterList();
            setLoadingActive(false);
        }
    }

    //#region Componentes adicionales
    const FilterComponents = ()=> (
        <div className="content-filter-list">
            <ReactSelect
                className="filter-input"
                options={listaOs}
                placeholder="Obras sociales..."
                isLoading={loaderSelect.socialWork}
                isClearable={true}
                value={filtrosBusqueda.obraSocial?.value === null ? null : filtrosBusqueda.obraSocial}
                onChange={(e)=> setFiltrosBusqueda({...filtrosBusqueda, obraSocial: e === null ? _DEFAULT_FILTROS_VALUES.obraSocial : e})}
                />
                <ReactSelect
                className="filter-input"
                options={regionalList}
                placeholder="Regionales..."
                isLoading={loaderSelect.account}
                isClearable={true}
                value={filtrosBusqueda.cuenta?.value === null ? null : filtrosBusqueda.cuenta}
                onChange={(e)=> setFiltrosBusqueda({...filtrosBusqueda, cuenta: e === null ? _DEFAULT_FILTROS_VALUES.cuenta : e})}
                />
                <FormControlLabel
                control={
                    <Checkbox
                        checked={filtrosBusqueda.confirmadas}
                        onChange={(e)=>setFiltrosBusqueda({...filtrosBusqueda, confirmadas: e.target.checked})}
                    />
                }
                label='Confirmadas'
            />
        </div>
    )
    const ButtonNew = () => (
        <div>
            <Button onClick={()=>{history.goBack()}}>Volver</Button>
            <Button onClick={HandleSubmitAllOs} color='green'>Aceptar</Button>
        </div>
    )
    //#endregion

    //#region Filtros de obra social y cuentas
	async function FilterList(isDeleted = false){
		try{
            setLoaderSelect({socialWork: true, account: true});
			let params: ISocialWorkSelectListRequest = {
				dadasDeBaja: isDeleted
			}
			const [socialWorks, accounts] = await Promise.all([GetSocialWorkSelect(params),api.GetAccountsList({association: {value: +colegioId}})]);
			
			const socialWorkList = socialWorks.data.results.map(item =>{
				return{value: item.id, label: item.nombre}
			});
			const accountList = accounts.data.map(item =>{
				return{value: item.id, label: item.nombre}
			});

			setListaOs(socialWorkList);
			setRegionalList(accountList);
		}catch(err: any){
            MessageNotification(err.response.data.errores, "error");
		}finally{
            setLoaderSelect(_DEFAULT_LOADER_SELECT);
        }
	}
	//#endregion

    //#region UTILS
    async function HandleSubmitAllOs(){
        try{
            setLoadingActive(true);
            //divido la key del objeto state para obtener por separado el id de la obra social y el id de la regional
            //se necesita un array de objetos donde tenga el id de la obra social, regional y si esta confirm o no.
            let configOs = stateOfCheckbox.map(os=>{
                let [idSocialWork, idAccountSocialWork] = os.id.toString().split("_");
                return {
                    obraSocialId: +idSocialWork,
                    cuentaId: +idAccountSocialWork,
                    confirmada: os.confirm
                }
            });
            const {status} = await api.ConfirmOsByAssociation(+colegioId, configOs);
            if (status === StatusCode.Ok){
                MessageNotification("Obras sociales confirmadas correctamente.", "success");
                setStateCheckBox([]);
                OnClickViewList();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    function HandleAllChangeState(e: any){
       let newObjState = stateOfCheckbox.map(os => {
        return {...os, confirm: e.target.checked}
       });
       setStateCheckBox(newObjState);
    }
    function HandleIndividualChangeState(e: any, os: ISocialWork){
       const newObjState = stateOfCheckbox.map(osOriginal => {
        if (osOriginal.id === `${os.id}_${os.regional.id}`) return {...osOriginal, confirm: e.target.checked}
        else return osOriginal;
       });
       setStateCheckBox(newObjState);
    }

    function MessageNotification(message: string, type: string){
        setDataToast({
                active: true,
                message: message || "Acaba de ocurrir un error",
                type: type,
            });
	}
    //#endregion

    return (
        <GeneralList
        titleOfSection='Confirmar Obras Sociales'
        dataToRender={dataTable}
        isLoaderActive={loadingActive}
        ButtonNew={ButtonNew}
        HandleSearch={OnClickViewList}
        FilterComponent={FilterComponents}
        >
        <Table aria-label='spanning table'>
            <TableHead>
                <TableRow>
                    <TableCell className='letter-title-table c-text-center'>
                         <Checkbox
                            className="no-padding-checkbox p-cell"
                            onClick={(e)=>HandleAllChangeState(e)}
                            />
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Nombre</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Alias</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Regional</b>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dataTable.map((socialWork: any, index) => (
                    <TableRow key={index}>
                        <TableCell className='letter-title-table c-text-center'>
                            <Checkbox
                            className="no-padding-checkbox p-cell"
                            checked={stateOfCheckbox.find(os => os.id === `${socialWork.id}_${socialWork.regional.id}`)?.confirm ?? false}
                            onClick={(e: any)=>HandleIndividualChangeState(e, socialWork)}
                            />
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            {socialWork.nombre}
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            {socialWork.alias ?? "--"}
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            {socialWork.regional.nombre}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </GeneralList>
    )
}

export default ConfirmOsForAssociation;