import React from 'react';
import ReactSelect from 'react-select';
import { ICustomReactSelect } from '../../services/interfaces/utils/ICustomReactSelect';

const CustomReactSelect: React.FC<ICustomReactSelect> = ({
  options,
  setValue,
  value,
  label,
  name,
  customStyles,
  placeholder,
  defaultValue,
  isLoading,
  isDisabled
}) => {
  const [selectedOption, setSelectedOption] = React.useState(()=>{
    if (value !== null && (value.value !== null || value.label !== null)) return value
    else return null
});

React.useEffect(()=>{
  setSelectedOption(()=>{
      if (value !== null && (value.value !== null || value.label !== null)) return value
      else return null
  });
},[value])

function HandleChange(e){
  if (e !== null){
    setSelectedOption({value: e.value, label: e.label});
    setValue({value: e.value, label: e.label}, {name});
  }else {
    setSelectedOption(null); 
    setValue({value: e.value, label: e.label}, {name});
  }
}

  return (
    <div style={{position: "relative"}}>
      <label htmlFor="my-select" className="customLabelSelect">{label}:</label>
      <ReactSelect
        isDisabled={isDisabled}
        isLoading={isLoading}
        placeholder={placeholder}
        name={name}
        className={customStyles}
        value={selectedOption}
        defaultValue={defaultValue}
        onChange={HandleChange}
        options={options}
      />
    </div>
  );
}

export default CustomReactSelect;