import Swal from 'sweetalert2/dist/sweetalert2.js';

export function FirePopUpSuccess(title, text){
    Swal.fire({
        title: title,
        text: text,
        icon: 'success',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: "#209520b9",
    });
}

export function FirePopUpSuccessWithHTMLFull(affiliateName, socialWorkPlanName) {
    let html = `<div>
                    ${affiliateName ? `<p>Afiliado: ${affiliateName}</p>` : ''}
                    ${socialWorkPlanName ? `<p>Plan: ${socialWorkPlanName}</p>` : ''}
                </div>`;
    Swal.fire({
        title: "Afiliado validado correctamente.",
        html: html,
        icon: 'success',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: "#209520b9",
    });
}
export function FirePopUpError(title, text){
    Swal.fire({
        title: title,
        text: text,
        icon: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: "#cacbcd",
    });
}