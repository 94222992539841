import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from "react-select";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import api from '../../api';
import { setModalExportLiquidation } from "../../redux/modal";
import { StatusCode } from "../../enums";
import { GetExportLiquidation } from "../../services/services/Reports";
import Toast from "../common/Toast";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { es } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { saveAs } from "file-saver";

const defaultValues = {
    banco: { id: 0, nombre: "Todos los Bancos" },
    anio: null,
    liquidation: { id: null, nombre: "" }
}

export default function ExportLiquidationModal() {
    const [dataToast, setDataToast] = React.useState({
        active: false,
        message: '',
        type: '',
    });
    const dispatch = useDispatch();
    const modalWithholdings = useSelector((state) => state.modal.exportLiquidationModal);
    const [values, setValues] = React.useState(defaultValues);
    const [bankList, setBankList] = React.useState([]);
    const [liquidationList, setLiquidationList] = React.useState([]);
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [closed, setClosed] = React.useState(true);

    React.useEffect(() => {
        api.GetBankSelectList()
            .then(resp => {
                // Añadimos "Todos los Bancos" al comienzo de la lista
                const banksWithAllOption = [defaultValues.banco, ...resp.data];
                setBankList(banksWithAllOption);

                // Hacemos que "Todos los Bancos" sea el seleccionado por defecto
                setValues((prevValues) => ({
                    ...prevValues,
                    banco: defaultValues.banco,
                }));
            })
            .catch(err => {
                MessageNotification(err.response.data.errores, "error");
            })
    }, []);

    function OnClickGenerate() {
        if (values.anio === null) { MessageNotification('El campo "Año" es obligatorio.', "error"); return; }
        if (values.liquidation.id === null) { MessageNotification('El campo "Liquidación" es obligatorio.', "error"); return; }

        const params = {
            liquidationId: values.liquidation.id,
            bancoId: values.banco.id === 0 ? null : values.banco.id
        }
        HandleGenerate(params);
    }

    const handleChangeYear = (date) => {
        if (date === null) {
            MessageNotification("Debe tipear o seleccionar un año.", "error");
        }
        if (date && date.getFullYear().toString().length === 4) {
            setValues({ ...values, anio: date });
            GetLiquidationSelectList(date.getFullYear());
        }
        else {
            setClosed(true);
            setValues((prevValues) => ({
                ...prevValues,
                liquidation: defaultValues.liquidation,
                anio: defaultValues.anio
            }));
        }
    };

    //#region Calls
    async function HandleGenerate(params) {
        try {
            setLoadingActive(true);
            const { data, status } = await GetExportLiquidation(params);
            if (status === StatusCode.Ok) {
                UtilityFNExport("txt", `Lista Banco ${values.liquidation.nombre}`, data.results);
            }
        } catch (err) {
            const errorMessage = err.message || 'No se pudo obtener el PDF.';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    async function GetLiquidationSelectList(anio) {
        try {
            const resp = await api.GetLiquidationByAnio(anio);

            // Verifico si resp.data es un array vacío
            if (resp.data.length === 0) {
                setClosed(true);
                setValues((prevValues) => ({ ...prevValues, liquidation: defaultValues.liquidation, }));
                MessageNotification("No se encontraron liquidaciones para el año seleccionado.", "error");
            } else {
                setClosed(false);
                setLiquidationList(resp.data);

                // Selecciono la Primer liquidación por defecto
                setValues((prevValues) => ({
                    ...prevValues,
                    liquidation: { id: resp.data[0].id, nombre: resp.data[0].nombre },
                }));
            }
        } catch (err) {
            const errorMessage = err.message || 'No se pudo obtener el PDF.';
            MessageNotification(errorMessage, "error");
        }
    }
    //#endregion

    //#region  UTILS
    function UtilityFNExport(extension, nameOfFile, responseApi) {
        const fileName = `${nameOfFile}.${extension}`;
        const blob = new Blob([responseApi], {
            type: "text/plain",
        });
        saveAs(blob, fileName);
        MessageNotification("Archivo exportado con éxito.", "success");
    }
    function MessageNotification(message, type) {
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error.",
            type: type,
        });
    }
    function HandleCloseModal() {
        setClosed(true);
        dispatch(setModalExportLiquidation(false));
        setValues(defaultValues);
    }
    //#endregion
    return (<>

        <Dialog
            open={modalWithholdings}
            onClose={HandleCloseModal}
            aria-labelledby='form-dialog-title'
        >
            {loadingActive &&
                <Dimmer className='loader-fixed' active inverted>
                    <Loader size='big'>Cargando...</Loader>
                </Dimmer>
            }

            <DialogTitle id='form-dialog-title'>
                Exportar Liquidación
            </DialogTitle>
            <DialogContent style={{ overflowY: "visible" }} className="cont-default-modal">
                <ReactSelect
                    placeholder='Bancos...'
                    options={bankList}
                    value={values.banco}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => setValues({ ...values, banco: { id: e.id, nombre: e.nombre } })}
                    styles={{ control: (s) => ({ ...s, maxWidth: 300 }) }}
                />
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <>
                        <KeyboardDatePicker
                            style={{ maxWidth: 300 }}
                            autoOk
                            className='size-date-picke-filter'
                            inputVariant='outlined'
                            InputAdornmentProps={{ position: 'end' }}
                            variant='inline'
                            name='year'
                            openTo='year'
                            views={['year']}
                            placeholder='Año'
                            value={values.anio}
                            onChange={handleChangeYear}
                        />
                    </>
                </MuiPickersUtilsProvider>

                <Collapse in={!closed} timeout={300}>
                    <ReactSelect
                        placeholder='Liquidaciones...'
                        options={liquidationList}
                        value={values.liquidation}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => setValues({ ...values, liquidation: { id: e.id, nombre: e.nombre } })}
                        styles={{
                            control: (s) => ({ ...s, maxWidth: 300 })
                        }}
                        maxMenuHeight={200}
                    />
                </Collapse>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button onClick={HandleCloseModal}>
                    Cancelar
                </Button>
                <Button onClick={() => OnClickGenerate()} color='blue'>
                    Generar
                </Button>
            </DialogActions>
            {dataToast.active && (
                <Toast dataToast={dataToast} setDataToast={setDataToast} />
            )}
        </Dialog>
    </>);
}