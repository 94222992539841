import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IAmountPractice, IAmountPracticeRequest, IPracticeAmount, PracticeAmountRowRequest } from "../../api/Interfaces/configurations/IAmounts";
import errorHandler from "../errorHandler";
import { postResponse } from "../interfaces/utils/IUtilDeclaration";

const GetAmountForPractices = async (obraSocialId: number | null): Promise<IApiResponse<IPracticeAmount[]>> => {
    try{
        const response = await api.GetAmountForPractices(obraSocialId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetHistorialAmount = async (obraSocialId: number | null, practicaObraSocialId: number): Promise<IApiResponse<IAmountPractice[]>> => {
    try{
        const response = await api.GetHistorialAmount(obraSocialId, practicaObraSocialId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const AssingAmountForPractices = async (obraSocialId: number, params: PracticeAmountRowRequest[]): Promise<IApiResponse<postResponse>> => {
    try{
        const response = await api.AssingAmountForPractices(obraSocialId, params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteAmountForPractices = async (params: any): Promise<IApiResponse<null>> => {
    try{
        const response = await api.DeleteAmountForPractices(params);
        return {
            data: {results: null},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {
    GetAmountForPractices,
    GetHistorialAmount,
    AssingAmountForPractices,
    DeleteAmountForPractices
}