import React from 'react';
import Notes from './Notes';
import { makeStyles } from '@material-ui/core/styles';
import AccordionVersion from './utils-components/AccordionVersion';
import { Typography } from '@material-ui/core';
import {informationOfVersions} from './consts/consts.d';

const useStyles = makeStyles((theme) => ({
        heading: {
            fontSize: theme.typography.pxToRem(18),
        },
        titleOfChanges: {
            padding: 8,
            fontSize: "1.4em ",
        },
        textChange: {
            padding: 8,
            fontSize: "1em", 
            overflowX:"auto",
            fontWeight: "200 !important"
        }
    }));

export default function VersionOfProduct(){
    const classes = useStyles();
    return(
        <>
            {informationOfVersions.map((section,i)=>{
                return (
                    <Notes key={i} titleOfSection={section[0].titleOfSection}>
                        {section[0].accordionContent.sort((a,b)=>new Date(b.dateOfChange) - new Date(a.dateOfChange)).map((content,i)=>{
                            return (<AccordionVersion key={i} title={content.titleOfChange}>
                                    {content.paragraphs.map(({text,title},i)=>
                                        <>
                                            {title ? (
                                            <Typography key={i} className={classes.titleOfChanges}>
                                                {title}
                                            </Typography>
                                            ) :
                                            <Typography key={i} className={classes.textChange}>
                                                - {text}
                                            </Typography>
                                            }
                                        </>
                                    )}
                                </AccordionVersion>)
                        
                        })}
                    </Notes>
                )
            })}
        </>
    )
}