import { Tooltip } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactSelect from 'react-select';
import TextField from '@material-ui/core/TextField';
import CachedIcon from '@material-ui/icons/Cached';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../../api';
import { Options, StatusCode } from '../../../../enums';
import { UpdateAssignedPractice } from '../../../../services/services/ConfigurationSocialWork';

export default function AssignPracticeForOs({setDataToast}){
	const {idOs} = useParams();
	const redirect = useHistory();
    //#region VARIABLES DE ESTADO
    const [loadingActive, setLoadingActive] = useState(false);
    const [assignPracticeList, setAssignPracticeList] = useState([{}]);
    const [notAssignPracticeList, setNotassignPracticeList] = useState([{}]);
    const [openNew, setOpenNew] = useState(false);
    const [isCreate, setCreate] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
	const [quantityForPage, setQuantityForPage] = useState();
	const loadForPage = 20;
	const [page, setPage] = useState(1);
	const [reactivate, setReactivate] = useState({
		iconReactivate: false,
		modalReactivate: false
	});
	const [socialWork, setSocialWork] = useState({
		nombre: "",
		alias: "",
		esObraSocialColegio: false
	});
	const [objFilterData, setObjFilterData] = useState({
		dadasDeBaja: false,
		nombre: null,
		dadasDeBajaChecked: false,
	});
	const [openDelete, setOpenDelete] = useState({
		mostrar: false,
		id: null,
		nombre: null,
		alias: null,
		codigo: null,
		aliasReporte: null
	});
    const [newValue, setNewValue] = useState({
		id: null,
        nombre: null,
		alias: null,
		aliasReporte: null
    });
    const [editValues, setEditValues] = useState({
        id: null,
        alias: null,
		nombre: null,
		aliasReporte: null
    })
    //#endregion
    
    useEffect(()=>{
		setLoadingActive(true);
        let optionsCrud = JSON.parse(localStorage.getItem('optionCruds'));
		optionsCrud.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
		LoadData();
		GetSocialWorkById();
    },[]);
	
    const LoadData = async (pageSelected = 1,assign = true)=>{
		try{
			
			setLoadingActive(true);
			if (assign){
				
                    let {data} = await api.getPractices(+idOs,assign, objFilterData.dadasDeBaja, true, pageSelected, loadForPage);
                    let list = data.resultados.map(item=>{
                        return {
                            id: item.id,
                            codigo: item.codigo,
                            nombre: item.nombre,
							alias: item.alias,
							aliasReporte: item.aliasReporte,
                            obraSocial: {
                                id: item.obraSocial.id,
                                nombre: item.obraSocial.nombre
                            }
                        }
                    });
					setQuantityForPage(data.cantidadPaginas);
                    setAssignPracticeList(list);
					setReactivate({
						modalReactivate: false,
						iconReactivate: false
					});
					if (objFilterData.dadasDeBaja) {
						setReactivate({
						modalReactivate: false,
						iconReactivate: true
					});
					}else{
						setObjFilterData({...objFilterData, dadasDeBaja: false})
					}
            }else{
				
                let {data} = await api.getPractices(+idOs,assign);
                let list = data.resultados.map(item=>{
                    return {
                        value: item.id,
                        label: item["nombre"],
                    }
                });
                setNotassignPracticeList(list);
            }
		}
		catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setLoadingActive(false);
		}
    }
    const OnClickAssign = async () => {
		setOpenNew(false);
		setLoadingActive(true);
		try{
			
			let isValidated = checkInputs(newValue.id, newValue.alias, newValue.aliasReporte);

				if (isValidated){
					let bodyParams = {
						practicaId: newValue.id,
						practicaAlias: newValue.alias,
						practicaAliasReporte: newValue.aliasReporte
					}
					let query = await api.AssignPracticeForOs(+idOs,bodyParams);
					if(query.status === 200 && query.data != null){
						HandleErrorOrSuccess('success', "Práctica Asociada correctamente.");
					}
				}
			}catch(err){
				HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
			}
			finally{
				setNewValue({
					id: null,
					nombre: null,
					alias: null,
					aliasReporte: null
				});
				setLoadingActive(false);
				LoadData();
			}
    }
     const OnClickEdit = async ()=>{
		 setOpenEdit(false);
		 try{
			 let isValidated = checkInputs(editValues.id, editValues.alias, editValues.aliasReporte);
			 if (isValidated){
				setLoadingActive(true);
				let bodyParams = {practicaAlias: editValues.alias, practicaAliasReporte: editValues.aliasReporte};
				let query = await api.UpdatePracticeForOs(+idOs,editValues.id, bodyParams);
				if(query.status === 200){
					HandleErrorOrSuccess('success',"Práctica editada correctamente.");
				}
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setLoadingActive(false);
			setEditValues({
				id: null,
				alias: null,
				nombre: null,
				aliasReporte: null
			});
			LoadData();
		}
    }
	const OnClickDelete = async ()=>{
		setLoadingActive(true);
		setOpenDelete({...openDelete, mostrar: false});
		try{
			let isValidated = checkInputs(openDelete.id, openDelete.alias, openDelete.aliasReporte, true);
			if (isValidated){
				let query = await api.DeleteAssignedPractice(+idOs, openDelete.id);
				if(query.status === 200){
					HandleErrorOrSuccess('success',"Práctica dada de baja correctamente.");
				}
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setOpenDelete({
				mostrar: false,
				id: null,
				nombreOAlias: null,
				codigo: null
			});
			setLoadingActive(false);
			LoadData();
		}
	}
    //#region UTILIDADES
	const GetSocialWorkById = async() =>{
		try{
			setLoadingActive(true);
			let {data} = await api.getSocialWorkById(+idOs);
			setSocialWork({
				nombre: data.nombre,
				alias: data.alias,
				esObraSocialColegio: data.esObraSocialColegio
			});
		}catch(err){
			HandleErrorOrSuccess("error",err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			LoadData();
		}
	}
	const checkInputs = (practiceId, alias, aliasReport, isDeleted = false)=>{
		
		//Comment: este metodo valida:

		/*
		1-No se puede asignar / editar una practica que solamente tenga un campo alias. O estan los dos campos completos o ninguno.
		2-Se puede editar / asignar una practica sin ningun alias.
		3-No se permite editar un registro si el Id de la practica es nulo.
		4-El alias de las practicas para los PDF que se exportan deben de ser menores a cuatro caracteres.
		*/

		let isValidated = true;
		if ((alias !== null || aliasReport !== null)){

			if ((aliasReport === null || alias === null)){
				 const errorMessage = isDeleted
					? "Deben completarse ambos campos de alias para eliminar una práctica."
					: "Deben completarse ambos campos para asignar un alias a la práctica.";

				HandleErrorOrSuccess('error', errorMessage);
   			 	isValidated = false;
			}

			if (aliasReport !== null && aliasReport.length > 4){
				HandleErrorOrSuccess('error',"El Alias del reporte no debe de ser mayor a 4 caracteres.");
				isValidated = false
			}

		}

		if (practiceId === null){
			HandleErrorOrSuccess('error',"El campo 'Practica' no puede estar vacío.");
			isValidated = false
		}
		return isValidated;
	}
	const HandleErrorOrSuccess = (type,message)=>{
		setDataToast({
			active: true,
			type: type,
			message: message
		});
	}
	const PreDelete = (idPractice, name,code, alias, aliasReporte)=>{
		setOpenDelete({
			id: idPractice,
			nombre: name,
			alias: alias,
			aliasReporte: aliasReporte,
			mostrar: true,
			codigo: code
		});
    }
    const HandleOpenEditOrReactivate = (idPractice, alias, name, aliasReporte, reactivatePractice = false)=>{
		setEditValues({
			id: idPractice,
			alias: alias,
			nombre: name,
			aliasReporte: aliasReporte
		});

		if (reactivatePractice){
			setReactivate({
				...reactivate,
				modalReactivate: true
			});
		}else{
			setOpenEdit(true);
		}
    }
	async function HandleReactivatePractice(){
		try{
			setLoadingActive(true);
			const {status} = await UpdateAssignedPractice(+idOs, +editValues.id);
			if (status === StatusCode.Ok){
				setReactivate({
					...reactivate,
					modalReactivate: false
				});
				HandleErrorOrSuccess("success","Practica reactivada correctamente.");
				LoadData();
			}
		}catch(err){
			HandleErrorOrSuccess('error',err?.response?.data?.errores ?? "Ocurrió un error.");
		}finally{
			setLoadingActive(false);
		}
	}
	const handleChange = (event, value) => {
		setPage(value);
		LoadData(value);
	};
    const HandleCloseModal = ()=>{
        setOpenEdit(false);
		setReactivate({
			...reactivate,
			modalReactivate: false
		});
    }
    const HandleClickOpen = ()=>{
		LoadData(page,false)
		.finally(()=>{
			setOpenNew(true)
		});
    }
    const HandleCloseNew = ()=>{
        setOpenNew(false);
    }
	const HandleCloseDelete = ()=>{
		setOpenDelete({...openDelete, mostrar: false});
	}
    //#endregion

    return(<>
        <Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className="header-section">
								<div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>{socialWork.alias !== null ? `${socialWork.alias} - ` : `${socialWork.nombre} - `} Prácticas</span>
								</div>
								<div className="contBtnHeaderSection">
									<Button
										className='btn-back-filter'
										onClick={() => redirect.push('/configuraciones/obras-sociales/asignar')}
									>
										Volver
									</Button>
									{isCreate && 
									<Button
										className='btn-new-filter'
										disabled={socialWork.esObraSocialColegio}
										onClick={() => HandleClickOpen()}
										icon
										labelPosition='left'
									>
										<Icon name='plus' />
										Asignar
									</Button>}	
								</div>
							</Grid.Row>
							<Grid.Row className="content-filter-list">
								<FormControlLabel
								control={
									<Checkbox
										checked={objFilterData.dadasDeBajaChecked}
										onChange={(e) => {
											setObjFilterData({
												dadasDeBaja: e.target.checked,
												dadasDeBajaChecked: e.target.checked,
											});
										}}
									/>
								}
								label='Dadas de baja'
								/>
							</Grid.Row>
							<Button
								className='btn-search-filter'
								primary
								icon
								labelPosition='left'
								onClick={() => LoadData()}
								type='submit'
							>
								<Icon name='search' />
								Buscar
							</Button>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					assignPracticeList &&
					assignPracticeList.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={6} className='color-letter-inTable'>
										<TableRow>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Nombre</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Alias</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Alias Reporte</b>
											</TableCell>
                                            <TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Codigo</b>
											</TableCell>
                                            <TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Obra Social</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{assignPracticeList.map(practice => (
											<TableRow key={practice.id}>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
                                                    {practice.nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
                                                    {practice.alias ?? "-"}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
                                                    {practice.aliasReporte ?? "-"}
												</TableCell>
                                                <TableCell className='c-text-center' style={{textAlign:"center"}}>
                                                    <p style={{padding: 10}}>{practice.codigo ?? "No Asignado"}</p>
												</TableCell>
                                                <TableCell className='c-text-center' style={{textAlign:"center"}}>
                                                    <p style={{padding: 10}}>{practice.obraSocial?.nombre}</p>
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{reactivate.iconReactivate ? 
													<>
													<IconButton
															className='btn-circle-green'
															onClick={() => HandleOpenEditOrReactivate(practice.id, practice.alias, practice.nombre, practice.aliasReporte, true)}
														>
															<Tooltip placement='top' title="Reactivar">
																<CachedIcon />
															</Tooltip>
														</IconButton>
													</> 
													: 
													<>
													{isEdit && socialWork.esObraSocialColegio === false && (
														<IconButton
															className='btn-circle-orange'
															onClick={() => HandleOpenEditOrReactivate(practice.id, practice.alias, practice.nombre, practice.aliasReporte, false)}
														>
															<Tooltip placement='top' title="Editar">
																<CreateIcon />
															</Tooltip>
														</IconButton>
													)}
													{isDelete && (
														<IconButton
															className='btn-circle-red'
															onClick={() =>
																PreDelete(practice.id, practice.nombre, practice.codigo, practice.alias, practice.aliasReporte)
															}
														>
															<Tooltip placement='top' title="Eliminar">
																<DeleteIcon />
															</Tooltip>
														</IconButton>
													)}
													</>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{assignPracticeList && assignPracticeList.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
				{assignPracticeList && (
				<Grid.Row centered>
					<Pagination
					color="primary"
					className="pagination"
					count={quantityForPage}
					page={page}
					onChange={handleChange}
					/>
				</Grid.Row>
				)}
			</Grid>

			<Dialog
			open={openNew}
			onClose={HandleCloseNew}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>Asignar</DialogTitle>
			<DialogContent className='cont-default-modal contModalAmount'>
				<ReactSelect
				placeholder="Prácticas"
				options={notAssignPracticeList}
				onChange={(e)=>setNewValue({
					...newValue,
					id: e !== null ? e.value : null,
					nombre: e !== null ? e.label : null,
				})}
				styles={{
					control: (s)=>({...s, maxWidth: 300})
				}}
				/>
				<TextField
				size='small'
				id='alias'
				label='Alias Reporte'
				style={{position: "relative"}}
				value={newValue.aliasReporte}
				onChange={(e) => {
					setNewValue({
						...newValue,
						aliasReporte: e.target.value === "" ? null : e.target.value
					});
				}}
				/>
				<TextField
					size='small'
					id='alias'
					label='Alias'
					value={newValue.alias}
						onChange={(e) => {
						setNewValue({
							...newValue,
							alias: e.target.value === "" ? null : e.target.value
						});
						}}
				/>
			</DialogContent>

			<DialogActions style={{textAlign: 'center',justifyContent: 'center'}}>
				<Button onClick={HandleCloseNew}>
					Cancelar
				</Button>
				<Button onClick={OnClickAssign} color='green'>
					Aceptar
				</Button>
			</DialogActions>
			</Dialog>

			{openEdit && (
				<Dialog
					open={openEdit}
					onClose={HandleCloseModal}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
					<DialogContent>
							<TextField
								style={{ margin: "10px 0" }}
								size='small'
								id='nombre'
								label='Alias'
								value={editValues.alias}
								onChange={(e) => {
									setEditValues({
										...editValues,
										alias: e.target.value === "" ? null : e.target.value,
									});
								}}
								
							/>
							<TextField
								style={{ margin: "10px 5px" }}
								size='small'
								id='nombre'
								label='Alias Reporte'
								value={editValues.aliasReporte}
								onChange={(e) => {
									setEditValues({
										...editValues,
										aliasReporte: e.target.value === "" ? null : e.target.value,
									});
								}}
								
							/>
					</DialogContent>
					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={() => HandleCloseModal()}>
							Cancelar
						</Button>

						<Button onClick={OnClickEdit} color='green'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openDelete.mostrar &&
			<Dialog open={openDelete.mostrar} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
				<DialogContent>
					<Grid.Row>
						<DialogContentText className='c-text-center'>
						¿Está seguro de eliminar la siguiente Práctica: {openDelete.nombre}? 
					</DialogContentText>
					</Grid.Row>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseDelete()}>Cancelar</Button>
					<Button
						onClick={() => OnClickDelete()}
						color='red'
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
			}

			{reactivate.modalReactivate &&
			<Dialog open={reactivate.modalReactivate} onClose={HandleCloseModal}>
				<DialogTitle id='form-dialog-title'>Reactivar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de reactivar la siguiente Práctica: {editValues.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseModal()}>Cancelar</Button>
					<Button
						onClick={HandleReactivatePractice}
						color='green'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
			}
    </>)
}