import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import document from "./document";
import dashboard from "./dashboard";
import socialWork from "./socialWork";
import modal from "./modal";
import managment from "./managment";
import amount from "./amount";
import generateLiquidation from "./generateLiquidation";

// Configuración de persistencia para el reducer generateLiquidation
const generateLiquidationPersistConfig = {
  key: 'generateLiquidation',
  storage,
};

const persistedGenerateLiquidationReducer = persistReducer(generateLiquidationPersistConfig, generateLiquidation);

const store = configureStore({
  reducer: {
    document,
    dashboard,
    socialWork,
    modal,
    managment,
    amount,
    generateLiquidation: persistedGenerateLiquidationReducer,
  },
});

export const persistor = persistStore(store);

export default store;
