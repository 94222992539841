import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { INewRequest } from '../../../../api/Interfaces/configurations/News';
import { StatusCode } from '../../../../enums';
import { IErrorNotification, INewNewsModal, INewNewsModalValues } from '../../../../services/interfaces/Configurations/NewNewsModal';
import { CreateNew, GetNewById, UpdateNew } from '../../../../services/services/News';
import EditorText from '../../../QuillEditoText/EditorText';
import { toolbarConfig } from '../../../QuillEditoText/configs/toolbarConfig';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { _DEFAULT_NEWNEWSMODAL_VALUES } from '../defaultValues';
import ValidateValues from '../utilityFunctions/ValidateValues';
import { Dimmer, Loader } from 'semantic-ui-react';

const NewNewsModal: React.FC<INewNewsModal> = ({
    titleOfModal,
    ReloadData,
    isEdit,
    openModal,
    aditionalData,
    HandleCloseAllModals,
    MessageNotification,
}) => {
    const [values, setValues] = React.useState<INewNewsModalValues>(_DEFAULT_NEWNEWSMODAL_VALUES._VALUES);
    const [errorNotification, setErrorNotification] = React.useState<IErrorNotification>(_DEFAULT_NEWNEWSMODAL_VALUES._ERROR_NOTIFICATION);
    const [loadingActive, setLoadingActive] = React.useState(false);
  
    React.useEffect(()=>{
       (isEdit && HandleGetNewById()) 
    },[]);

    //#region GETBYID / CREATE / UPDATE
    async function HandleGetNewById(){
        try{
            setLoadingActive(true);
            const {data, status} = await GetNewById(aditionalData?.id as number);
            if (status === StatusCode.Ok){
                const news: INewNewsModalValues = {
                    body: data.results.cuerpo,
                    title: data.results.titulo,
                    urlVideo: data.results.urlYoutube
                }
                setValues(news);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function HandleCreateNew(){
        try{
            setLoadingActive(true);
            if (HandleValidateValues(values)){
                const params: INewRequest = {
                    titulo: values.title as string,
                    urlYoutube: values.urlVideo as string,
                    cuerpo: values.body as string
                }
                const {data, status} = await CreateNew(params);
                if (status === StatusCode.Ok){
                    HandleCloseAllModals();
                    MessageNotification("Novedad creada correctamente.", "success");
                    ReloadData();
                }
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function HandleUpdateNew(){
        try{
            setLoadingActive(true);
            if (HandleValidateValues(values)){
                const params: INewRequest = {
                    titulo: values.title as string,
                    urlYoutube: values.urlVideo as string,
                    cuerpo: values.body as string
                }
                const {data, status} = await UpdateNew(aditionalData?.id as number,params);
                if (status === StatusCode.Ok){
                    HandleCloseAllModals();
                    MessageNotification("Novedad editada correctamente.", "success");
                    ReloadData();
                }
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function HandleValidateValues(values){
        const {validateObj, objError} = ValidateValues(values);
        if (!validateObj.validate){
            setErrorNotification(objError);
            MessageNotification(validateObj.message, "error");
        }
        return validateObj.validate;
    }
    function HandleChangeTextNew(text: string){
        setValues({
            ...values,
            body: text
        })
    }
    function HandleChangeInput(e: any){
        setValues({
            ...values,
            [e.target.name]: e.target.value === "" ? null : e.target.value
        })
    }
    //#endregion
    
    return (
        <>
        {loadingActive ? 
        <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando...</Loader>
        </Dimmer>
        :
        <GeneralModalCrud
        titleOfModal={titleOfModal}
        openModal={openModal}
        HandleCloseModal={HandleCloseAllModals}
        HandleSubmit={isEdit ? HandleUpdateNew : HandleCreateNew}
        >
        <section style={{
            display: "flex",
            flexDirection: "column",
            gap: 25
        }}>
        <TextField
        value={values.title}
        label='Título'
        error={errorNotification.title}
        size="small"
        name="title"
        type='text'
        variant="outlined"
        onChange={HandleChangeInput}
        InputLabelProps={{shrink: true}}
        />
        <TextField
        value={values.urlVideo}
        label='URL Video'
        error={errorNotification.urlVideo}
        size="small"
        name='urlVideo'
        type='text'
        variant="outlined"
        onChange={HandleChangeInput}
        InputLabelProps={{shrink: true}}
        />
        </section>
        <section>
        <EditorText 
        placeholder="Escriba el cuerpo de la novedad."
        errorNotificationInput={errorNotification.body}
        handleOnChange={HandleChangeTextNew} 
        text={values.body} 
        toolbarOptions={toolbarConfig} 
        isReadOnly={false}/>
        </section>
        </GeneralModalCrud>
        }
        </>
    )
}
export default NewNewsModal;