import { IApiResponse } from "../api/Interfaces/IApiResponse";

export default function errorHandler(err: any): IApiResponse<any> {
  let response = "";

  if (err && err.response.status !== 200) {
    response = err?.response?.data?.errores || "Acaba de ocurrir un error.";
  }

  throw new Error(response);
};

export async function errorBlobHandler(err: any): Promise<IApiResponse<any>> {
  let response = "";

  if (err && err.response) {
    //Blob lo convierto a texto y luego a JSON
    if (err.response.data instanceof Blob) {
      const blob = err.response.data;
      try {
        const text = await blob.text();
        const json = JSON.parse(text);
        response = json.errores?.join(', ') || "Acaba de ocurrir un error.";
      } catch (parseError) {
        const errorParseResponse = "Error desconocido ocurrió.";
        throw new Error(errorParseResponse);
      }
    } else {
      response = err.response.data?.errores?.join(', ') || "Acaba de ocurrir un error.";
    }
  }
  throw new Error(response);
}