import { Grid, Paper, TableContainer } from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import React from 'react';
import { Button, Card, CardContent, Dimmer, Icon, Loader } from 'semantic-ui-react';
import { IGeneralList } from '../../services/interfaces/utils/IGeneralList';

export const GeneralList: React.FC<IGeneralList> = ({
    titleOfSection, 
    FilterComponent,
    isLoaderActive,
    dataToRender,
    children,
    HeaderInfo,
    initialPage,
    numPagesToRender,
    ButtonNew,
    TestFilterComponents,
    onChangePage,
    HandleSearch})=>{

    return(
    <Grid>
        <Grid item style={{marginBottom: 30}}>
            <Card className='color-border-dash'>
                <CardContent className='center-responsive-filters'>
                    <Grid className='header-section'>
                        <div className="titleAndVideo">
                            <span style={{ fontSize: 27, textAlign: "left", lineHeight: "1.03" }}>{titleOfSection}</span>
                        </div>
                        <div className="contBtnHeaderSection">
                            {ButtonNew ? <ButtonNew /> : <></>}
                        </div>
                    </Grid>
                    {HeaderInfo ? <HeaderInfo /> : <></>}
                    <Grid>
                        <Grid className="row-filterList content-filter-list">
                            {TestFilterComponents ? TestFilterComponents.map((fcItem, index)=> (fcItem(index))) : <></>}
                        </Grid>
                        {FilterComponent ? <FilterComponent /> : <></>}
                        {(TestFilterComponents || FilterComponent )&& 
                        <Button
                            className='btn-search-filter'
                            primary
                            icon
                            labelPosition='left'
                            onClick={()=>HandleSearch()}
                            type='submit'
                            >
                            <Icon name='search' />
                            Buscar
                        </Button>}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>

        {isLoaderActive ? 
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
        :
            <Grid container>
                <TableContainer component={Paper} id='color-letter-table-tasks'>
                    {children}
                </TableContainer>
            </Grid>
        }

        {dataToRender && dataToRender.length === 0 &&
            <Grid>
                <TableContainer component={Paper} id='color-letter-table-tasks' style={{padding: 14}}>
                        <h5 style={{textAlign: "center"}}>No se encontraron resultados.</h5>
                </TableContainer>
            </Grid>
        }

        {dataToRender && 
            <Grid container justifyContent='center' style={{marginTop: 10}}>
                <Pagination
                    className="pagination"
                    count={numPagesToRender}
                    page={initialPage}
                    onChange={onChangePage}
                />
            </Grid>
        }
    </Grid>
    )
}

export default GeneralList;