import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import { Button, Icon } from "semantic-ui-react";
import { IAccreditation, IAccreditationFilterParams, IAccreditationValues } from '../../api/Interfaces/managment/ICrudAccreditation';
import { typeOfError } from "../../api/Interfaces/managment/ICrudInstallment";
import { ICrudAccreditation } from '../../services/interfaces/Managment/ICrudAccreditation';
import { selectTypeFormat, typeOfActionForModal, typeOfModal } from "../../services/interfaces/utils/IUtilDeclaration";
import { CreateAccreditation, DeleteAccreditation, EditAccreditation, GetAccreditations } from '../../services/services/CrudAccreditation';
import ButtonBox from '../utils/ButtonBox';
import GeneralList from '../utils/GeneralList';
import GeneralModalDelete from "../utils/GeneralModalDelete";
import GeneralModalShow from '../utils/GeneralModalShow';
import NewModalAccreditation from './modals/CrudAccreditation/NewModalAccreditation';
import ReactSelect from 'react-select';
import { GetProfesionals } from '../../services/services/Users';
import { _DEFAULT_VALUES_ACCREDITATION } from './defaultValues';
import { useSelector } from 'react-redux';

const CrudAccreditation: React.FC<ICrudAccreditation> = ({setDataToast})=>{
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);
    const [isLoaderActive, setIsLoaderActive] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pagesToRender, setPagesToRender] = React.useState(1);
    const [dataToRender, setDataToRender] = React.useState<IAccreditation[]>([]);
    const [loaderSelect, setLoaderSelect] = React.useState({profesional: false});
    const [values, setValues] = React.useState<IAccreditationValues>(_DEFAULT_VALUES_ACCREDITATION.values);
    const [errorNotification, setErrorNotificacion] = React.useState<typeOfError>(_DEFAULT_VALUES_ACCREDITATION.errorNotification);
    const [filterObj, setFilterObj] = React.useState<IAccreditationFilterParams>(_DEFAULT_VALUES_ACCREDITATION.filterObj);
    const [openModal, setOpenModal] = React.useState<any>(_DEFAULT_VALUES_ACCREDITATION.modals);
    const [profesionalList, setProfesionalList] = React.useState<selectTypeFormat[]>([]);

     useEffect(()=>{
        (hasManagment && GetAllPromises());
    },[]);

    function GetAllPromises(){
        try{
            LoadInfoForTable();
            GetProfesionalList();
        }
        catch(err: any){
            MessageNotification(err.message, "error");
        }
    }

    const GetProfesionalList = React.useCallback(async () => {
        try {
        setLoaderSelect({ profesional: true });
        const { status, data } = await GetProfesionals({});
        if (status === 200) {
            const RSUserList = data?.results?.map((item) => ({
            value: item.id,
            label: `${item.apellido}, ${item.nombre}`,
            })) ?? [];
            setProfesionalList(RSUserList);
        }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        }finally{setLoaderSelect({profesional: false})}
    }, []);

    const FilterComponents = () =>(
        <Grid container direction="row" className="content-filter-list">
        <div className="filter-input">
           <ReactSelect
               className="filter-input"
               placeholder="Profesionales..."
               options={profesionalList}
               isLoading={loaderSelect.profesional}
               isClearable={true}
               value={filterObj.profesional?.value === null ? null : filterObj.profesional}
               onChange={(e)=> setFilterObj({...filterObj, profesional: e === null ? _DEFAULT_VALUES_ACCREDITATION.filterObj.profesional : e})}
           />
        </div>
   </Grid>);
    const ButtonNew = () =>(
        <Button
            className='btn-new-filter'
            onClick={()=>OpenModalSelected("open","create")}
            icon
            labelPosition='left'
            >
            <Icon name='plus' />
            Nuevo
        </Button>
    );
    
    //#region LISTAR - EDITAR - ELIMINAR - CREAR
        async function LoadInfoForTable(pageSelected: number = 1){
            try{
                setIsLoaderActive(true);
                const params: IAccreditationFilterParams  = {profesional: filterObj.profesional, pagina: pageSelected}
                const {status, data} = await GetAccreditations(params);
                if (status === 200){
                    setDataToRender(data?.results ?? []);
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
        async function OnCreateInstallment(){
            try{
                const {response, objError} = ValidateInputs(values);
                if (response.isValidated){
                    setIsLoaderActive(true);
                    OpenModalSelected("close", "create");
                    const {status} = await CreateAccreditation(values);
                    if (status === 200){
                        MessageNotification("La acreditación ha sido creada correctamente.", "success");
                        LoadInfoForTable();
                    }
                }else{
                    MessageNotification(response.message, "error");
                    setErrorNotificacion(objError);
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
        async function OnEditInstallment(){
            try{
                if (openModal.edit.data.fechaAcreditacion === null){
                    const {response, objError} = ValidateInputs(values);
                    if (response.isValidated){
                        setIsLoaderActive(true);
                        OpenModalSelected("close", "edit");
                        const {status} = await EditAccreditation(values);
                        if (status === 200) {
                            MessageNotification("La acreditación ha sido creada correctamente.", "success");
                            LoadInfoForTable();
                        }
                    }else{
                        MessageNotification(response.message, "error");
                        setErrorNotificacion(objError);
                    }
                }else{
                    MessageNotification("No es posible editar el siguiente registro porque se encuentra acreditado.", "error");
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
        async function OnDeleteInstallment(){
            try{
                if (openModal.delete.data.fechaAcreditacion === null){
                    setIsLoaderActive(true);
                    OpenModalSelected("close", "delete");
                    const {id} = openModal.delete.data;
                    const {status} = await DeleteAccreditation(id);
                    if (status === 200){
                        MessageNotification("La acreditación ha sido eliminada correctamente.", "success");
                        LoadInfoForTable();
                    }
                }else{
                    MessageNotification("No es posible eliminar el siguiente registro porque se encuentra acreditado.", "error");
                }
            }catch(err: any){
                MessageNotification(err.message, "error");
            }finally{
                setIsLoaderActive(false);
            }
        }
    //#endregion

    //#region UTILS
        function PrevToAction(e: any,item){
            OpenModalSelected("open",e.currentTarget.name,item);
        }
        function HandleChangeInputModal(e: any, nameOfProperty: string){
            setValues({...values, [nameOfProperty]: e instanceof Date ? e : (!isNaN(e.target.value) && e.target.value !== "" ? +e.target.value : e.target.value)});
        }
        function HandleSearch(){
            LoadInfoForTable();
        }
        function ValidateInputs(dataOfInputs: IAccreditationValues){
            let response = {isValidated: true, message: ""};
            let objError: typeOfError = {fecha: false, profesionalId: false, importe: false, detalle: false, cantidadCuotas: false};
            const valuesOfObj = Object.values(dataOfInputs);
            const keysOfObj = Object.keys(dataOfInputs);

            const notNullValues = valuesOfObj.findIndex(item => item === null || item === "");
            //validas si alguna propiedad esta vacia.
            if (notNullValues !== -1){
                response = {isValidated: false, message: "Todos los campos son obligatorios."};
                valuesOfObj.forEach((item,i)=>{
                    if(item === null || item === ""){
                        objError = {...objError, [keysOfObj[i]]: true};
                    }
                })
            }else{
                const notNegativeNumbers = valuesOfObj.findIndex(item => typeof item === "number" && +item < 1);
                //validas que no haya numeros en negativo.
                if (notNegativeNumbers !== -1){
                    response = {isValidated: false, message: "Los campos numéricos deben ser mayores o iguales a uno."}
                    valuesOfObj.forEach((item,i)=>{
                    if(typeof item === "number" && +item < 1){
                            objError = {...objError, [keysOfObj[i]]: true};
                        }
                    })
                }
            }
            return {response, objError};
        }
        function OpenModalSelected(action: typeOfActionForModal, modal: typeOfModal, item?: any){ 
            const keys = Object.keys(openModal);
            const indexOfKey = Object.keys(openModal).findIndex(item => item === modal);
            const keyOfModalToOpen = keys[indexOfKey];
            
            setOpenModal({...openModal, [keyOfModalToOpen]: {show: action === "open" ? true : false, data: item}});

            if (action === "close"){
                setErrorNotificacion(_DEFAULT_VALUES_ACCREDITATION.errorNotification);
                setValues(_DEFAULT_VALUES_ACCREDITATION.values);
            }else{
                if (modal !== "create" && item) {
                    const data = {
                        id: item.id,
                        fecha: item.fecha,
                        profesionalId: item.profesional.id,
                        profesionalNombre: item.profesional.nombre,
                        monto: item.monto,
                        descripcion: item.descripcion
                    }
                    setValues(data)
                };
            }
        }
        function HandleChangePage(event: any, pageSelected: number){
            LoadInfoForTable(pageSelected);
        }
        function MessageNotification(message: string, type: string){
            setDataToast({
                active: true,
                message,
                type
            });
        }
    //#endregion

    return(
    <>
        <GeneralList
        titleOfSection="Acreditaciones"
        isLoaderActive={isLoaderActive}
        dataToRender={dataToRender}
        initialPage={page}
        numPagesToRender={pagesToRender}
        FilterComponent={FilterComponents}
        ButtonNew={ButtonNew}
        onChangePage={HandleChangePage}
        HandleSearch={HandleSearch}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Matrícula</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Profesional</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Monto</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Saldado</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Descripción</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: any) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.profesional.matricula}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                               {`${item.profesional.apellido}, ${item.profesional.nombre}`}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {item.monto}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <Checkbox checked={Boolean(item.fechaAcreditacion) || item.liquidacionId}/>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.descripcion.length > 6 ? item.descripcion.slice(0, 10) + '...' : item.descripcion}
                                {item.descripcion.length > 6 &&
                                 <IconButton
                                    style={{marginLeft: 5}}
                                    className='btn-circle-blue'
                                    aria-label='search'
                                    onClick={()=>OpenModalSelected("open", "showComment", item)}
                                    >
                                    <Tooltip placement="top" title="Ver Descripción">
                                        <SearchIcon />
                                    </Tooltip>
                                    </IconButton>
                                }
                            </TableCell>
                            <TableCell className='p-cell'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e)=>PrevToAction(e,item)}/>
                                    <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e)=>PrevToAction(e,item)}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>
        
        {/* MODALS */}
        {openModal.create.show && 
            <NewModalAccreditation 
            titleOfModal="Nuevo"
            modal="create"
            openModal={openModal.create.show} 
            values={values}
            errorNotification={errorNotification}
            HandleChangeInput={HandleChangeInputModal}
            setValues={setValues}
            MessageNotification={MessageNotification}
            OpenModalSelected={OpenModalSelected}
            SendValues={OnCreateInstallment}/>
        }
       {openModal.edit.show && 
            <NewModalAccreditation 
            titleOfModal="Editar"
            modal="edit"
            openModal={openModal.edit.show} 
            values={values}
            errorNotification={errorNotification}
            HandleChangeInput={HandleChangeInputModal}
            setValues={setValues}
            MessageNotification={MessageNotification}
            OpenModalSelected={OpenModalSelected}
            SendValues={OnEditInstallment}/>
        }
        {openModal.delete.show &&
            <GeneralModalDelete
            contentText={`¿Esta seguro de eliminar la siguiente acreditación para el profesional: ${openModal.delete.data.profesional.nombre}?`}
            openModal={openModal.delete.show}
            HandleClose={()=>OpenModalSelected("close", "delete")}
            HandleSubmit={OnDeleteInstallment}
            />
        }
        {openModal.showComment.show && 
            <GeneralModalShow 
            openModal={openModal.showComment.show}
            title='Detalle de la acreditación'
            content={openModal.showComment.data.descripcion}
            HandleClose={()=>OpenModalSelected("close", "showComment")}/>
        }
    </>
    )
}

export default CrudAccreditation;