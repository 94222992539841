import { Tooltip } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../api';
import { ApiCallStatus } from '../../enums';

export default function IntegrationList({ setDataToast }) {
	const [loadingActive, setLoadingActive] = useState();
	const [dataTable, setDataTable] = useState([]);
	const [deleteDialog, setDeleteDialog] = useState({
		open: false,
		id: null,
        dateSessionId: null,
        authorizationNumber: null,
        documentId: null,
        date: null,
		dateUp: null
	});

    useEffect(() => {
		GetIntegrationPendings();
	}, []);

	//#region UTILS
	async function GetIntegrationPendings(){
		try{
			setLoadingActive(true);
			const paramsIntegration = {socialWorkId: null, isConfirm: false}
			const {data} = await api.GetPendingIntegrations(paramsIntegration);
			
			data.forEach(item=>{
				if(item.status === ApiCallStatus.REJECTED){
					throw new Error(item.reason);
				}
			});

			setDataTable(data);
		}catch(err){
			MessageNotification(err.response.data.errores, 'error');
		}finally{setLoadingActive(false)}
	}
	function OnClickOpenDelete(id, dateSessionId, documentId, date, dateUp,) {
		setDeleteDialog({ open: true, id, dateSessionId, documentId, date, dateUp});
	}
	function OnClickCloseDelete() {
		setDeleteDialog({ open: false, id: null, dateSessionId: null, authorizationNumber: null, documentId: null});
	}
	function MessageNotification(message, type) {
		setDataToast({
			active: true,
			message: message ?? 'Ocurrió un error.',
			type: type,
		});
	}
	//#endregion

	//#region METODOS LISTAR - ELIMINAR
	async function DeleteDateSession() {
        try{
            setLoadingActive(true);
            const {status} = await api.RemovePendingIntegrations({integrationId: deleteDialog.id});
            if (status === 200) MessageNotification('Prestación dada de baja correctamente.','success');
        }catch(err){
            MessageNotification(err.response.data.errores, 'error');
        }
        finally{setLoadingActive(false)}
	}
	//#endregion
	
	return (
		<>
			<Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className='header-section'>
								<div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>Prestaciones</span>
								</div>
							</Grid.Row>
						</CardContent>
					</Card>
				</Grid.Row>
				
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={2} className='color-letter-inTable'>
										<TableRow>
										<TableCell className='letter-title-table c-text-center'>
												<b>Fecha</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>Fecha Alta</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>Nro. Autorización</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map(({id, fecha, fechaAlta, numeroAutorizacion, fechaSesionId, documentoId}) => (
											<TableRow key={id}>
												<TableCell className='c-text-center'>
													{new Date(fecha).toLocaleDateString()}
												</TableCell>
												<TableCell className='c-text-center'>
													{new Date(fechaAlta).toLocaleDateString()}
												</TableCell>
												<TableCell className='c-text-center'>
													{numeroAutorizacion}
												</TableCell>
												<TableCell className='c-text-center'>
														<IconButton
															className='btn-circle-red'
															onClick={() =>OnClickOpenDelete(id,fechaSesionId, documentoId, fecha, fechaAlta)}
														>
															<Tooltip placement='top' title="Eliminar">
																<DeleteIcon />
															</Tooltip>
														</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
			</Grid>

			{deleteDialog && (
				<Dialog open={deleteDialog.open} onClose={OnClickCloseDelete}>
					<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
					<DialogContent>
						<DialogContentText>
							¿Está seguro de eliminar la siguiente prestación - Fecha: {new Date(deleteDialog.date).toLocaleDateString()}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={OnClickCloseDelete}>Cancelar</Button>
						<Button onClick={() => DeleteDateSession(deleteDialog.id)} color='red'>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}
