import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IBillLiquidation, ILiquidationResponse, IProfessionalBullDetailsResponse, ISummaryForLiquidationDetails, ISummaryForProfessionalResponse, ISummaryForSocialWorkResponse } from "../../api/Interfaces/managment/ILiquidation";
import errorHandler, { errorBlobHandler } from "../errorHandler";

const GetNameForLiquidationByDate = async (dateSelected: Date): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetNameForLiquidationByDate(dateSelected);
        return {
            data: {results: response.data.nombre},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const GetBillForLiquidation = async (liquidationId: number, pendingBills: boolean): Promise<IApiResponse<IBillLiquidation[]>> => {
    try{
        const response = await api.GetBillForLiquidation(liquidationId, pendingBills);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const GetLiquidationById = async (liquidationId: number): Promise<IApiResponse<ILiquidationResponse>> => {
    try{
        const response = await api.GetLiquidationById(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const CreateLiquidation = async (liquidationResponse: any): Promise<IApiResponse<any>> => {
    try{
        const response = await api.CreateLiquidation(liquidationResponse);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const FinalizeLiquidation = async (liquidationId: number): Promise<IApiResponse<any>> => {
    try{
        const response = await api.FinalizeLiquidation(liquidationId);
        return {
            data: {results: response.data.nombre},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const GetLiquidationSummaryForProfessional = async (liquidationId: number): Promise<IApiResponse<ISummaryForProfessionalResponse[]>> => {
    try{
        const response = await api.GetLiquidationSummaryForProfessional(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const GetLiquidationDetailsForProfessional = async (liquidationId: number, profesionalId: number): Promise<IApiResponse<IProfessionalBullDetailsResponse>> => {
    try{
        const response = await api.GetLiquidationDetailsForProfessional(liquidationId, profesionalId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const GetLiquidationSummaryForSocialWork = async (liquidationId: number): Promise<IApiResponse<ISummaryForSocialWorkResponse[]>> => {
    try{
        const response = await api.GetLiquidationSummaryForSocialWork(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const GetLiquidationDetails = async (liquidationId: number): Promise<IApiResponse<ISummaryForLiquidationDetails>> => {
    try{
        const response = await api.GetLiquidationDetails(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const ExportSummaryBalanceReport = async (liquidationId: number): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetSummaryBalanceExport(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const ExportSummaryLiquidationReport = async (liquidationId: number): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetSummaryLiquidationExport(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const ExportSummaryProfessionalReport = async (liquidationId: number): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetSummaryProfesionales(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const ExportSummarySocialWorksReport = async (liquidationId: number): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetSummaryObraSociales(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const ExportReportSocialWorkPeriod = async (liquidationId: number): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetSummarySocialWorkPeriod(liquidationId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const ExportReportProfitRetention = async (liquidationId: number, profesionalId: number): Promise<IApiResponse<string>> => {
    try{
        const response = await api.GetSummaryProfitRetention(liquidationId, profesionalId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorBlobHandler(err);
    }
}
export {GetNameForLiquidationByDate, 
        GetBillForLiquidation, 
        GetLiquidationById, 
        CreateLiquidation,
        FinalizeLiquidation,
        GetLiquidationSummaryForProfessional,
        GetLiquidationSummaryForSocialWork,
        GetLiquidationDetails,
        ExportSummaryProfessionalReport,
        ExportSummarySocialWorksReport,
        ExportSummaryLiquidationReport,
        ExportSummaryBalanceReport,
        ExportReportSocialWorkPeriod,
        ExportReportProfitRetention,
        GetLiquidationDetailsForProfessional}