import { Checkbox, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import React from 'react';
import { IUserList, IUserParams } from '../../../../api/Interfaces/configurations/IUsers';
import { StatusCode, TypeRoleNum } from '../../../../enums';
import { ICheckedProfesional, IErrorNotificationInput, IViewDetailProfesionalList } from '../../../../services/interfaces/Managment/ViewDetailProfesionalList';
import { GetProfesionals } from '../../../../services/services/Users';
import GeneralList from '../../../utils/GeneralList';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';

const ViewDetailProfesionalList: React.FC<IViewDetailProfesionalList> = ({
    openModal, 
    infoOfBill,
    setOpenModal,
    MessageNotification,
    setValues,
    setDataOfBilling
    }) =>{
        const [checkedProfesional, setCheckedProfesional] = React.useState<ICheckedProfesional>({});
        const [loadingActive, setLoadingActive] = React.useState(false);
        const [profesionalList, setProfesionalList] = React.useState<IUserList[]>([]);
        const [errorNotificationInput, setErrorNotificationInput] = React.useState<IErrorNotificationInput>({});
        const [filterObj, setFilterObj] = React.useState<any>({});
        const profesionalLista = React.useRef<any>();

        React.useEffect(()=>{
            LoadDataForTable();
        },[]);

        async function LoadDataForTable(){
            try{
                setLoadingActive(true);
                const filterParams: IUserParams = {
                    profesional: filterObj.enrollmentOrProfesional,
                    rolId: TypeRoleNum.Professional,
                    conPaginacion: false
                }
                const {data, status} = await GetProfesionals(filterParams);
                if (status === StatusCode.Ok){
                    //el array de profesionales seleccionados, se vuelve a transformar en un objeto.
                    const previusProfesionalSelected = Object.values(openModal.profesionalList.data).length > 0 && Object.entries(openModal.profesionalList.data).reduce((acc, actualValue: any)=>{
                        return {
                            ...acc, 
                            [actualValue[1].id]: {
                                id: actualValue[1].id,
                                nombre: actualValue[1].nombre,
                                apellido: actualValue[1].apellido,
                                monto: actualValue[1].monto,
                                marcado: actualValue[1].marcado
                            }
                        }
                    },{});

                    if (!filterObj.enrollmentOrProfesional){
                        profesionalLista.current = data.results;
                    }

                    //Carga el listado de checkbox
                    let objProfesionalCheck: ICheckedProfesional | {} = {}
                   profesionalLista.current.forEach(prof => {
                        objProfesionalCheck = {
                            ...objProfesionalCheck,
                            [prof.id]: {
                                id: prof.id,
                                matricula: prof.matricula,
                                apellido: prof.apellido,
                                nombre: prof.nombre,
                                marcado: SelectPreviusCheckForProfesional(previusProfesionalSelected, prof.id),
                                monto: SelectPreviusAmountForProfesional(previusProfesionalSelected, prof.id)
                            }
                        }
                    });
                    setCheckedProfesional(objProfesionalCheck);
                    setProfesionalList(data.results);
                }
            }catch(err: any){
                MessageNotification(err, "error");
            }finally{
                setLoadingActive(false);
            }
        }

        function HandleSubmit(){
            const profesionalListDetail = Object.values(checkedProfesional).filter((prof: any) => prof.marcado === true);
            
            if (ValidateInputs()){
                HandleClose();
                const profesionalListToSend = profesionalListDetail.map((prof: any) => {
                    return {
                        id: prof.id,
                        nombre: prof.nombre,
                        monto: +prof.monto
                    }
                });
                const montoTotal = profesionalListDetail.reduce((previusValue: any, currentValue: any)=>previusValue + (+currentValue.monto),0);
    
                setValues((prev) => {
                    return {
                        ...prev,
                        monto: +montoTotal,
                        profesionales: profesionalListToSend
                    }
                });
                setDataOfBilling(profesionalListDetail);
            }
        }

        const FilterComponents = [
            (key)=>(
            <TextField
            key={key}
            className='filter-input'
            style={{margin: "15px 0"}}
            value={filterObj.enrollmentOrProfesional}
            label='Profesional'
            type='text'
            variant='outlined'
            size='small'
            InputLabelProps={{shrink: true}}
            InputProps={{
                inputProps: {max: 9999999, min: 0}
            }}
            onChange={(e)=>setFilterObj({...filterObj, enrollmentOrProfesional: e.target.value === "" ? null : e.target.value})}
            />),
        ];

        //#region UTILS FUNCTIONS
        function SelectPreviusCheckForProfesional(previusProfesionalSelected, profesionalId: number){
            if (previusProfesionalSelected !== null && previusProfesionalSelected[profesionalId] !== undefined){
                return previusProfesionalSelected[profesionalId].marcado;
            }else{
                return false;
            }
        }
        function SelectPreviusAmountForProfesional(previusProfesionalSelected, profesionalId: number){
            if (previusProfesionalSelected !== null && previusProfesionalSelected[profesionalId] !== undefined){
                return previusProfesionalSelected[profesionalId].monto;
            }else{
                return 0;
            }
        }
        function HandleClose(){
            setOpenModal({
                ...openModal,
                profesionalList: {show: false, data: null}
            })
        }
        function HandleAllCheckProfesionals(e){
          let objAllProfChecked = {}
          let profesionalSelectList = Object.values(checkedProfesional);

          profesionalSelectList.forEach(input => {
            objAllProfChecked = {
                ...objAllProfChecked,
                [input.id]: {
                    id: input.id,
                    matricula: input.matricula,
                    apellido: input.apellido,
                    nombre: input.nombre,
                    marcado: e.target.checked,
                    monto: e.target.checked ? input.monto : 0
                }
            }
          });

          setCheckedProfesional(objAllProfChecked);
        }
        function HandleCheckProfesional(profesional: IUserList, e: any){
            setCheckedProfesional({
                ...checkedProfesional,
                [profesional.id]: {
                    id: profesional.id,
                    matricula: profesional.matricula,
                    apellido: profesional.apellido,
                    nombre: profesional.nombre,
                    marcado: e.target.checked,
                    monto: e.target.checked ? checkedProfesional[profesional.id].monto : 0
                }
            });
            setOpenModal({
                ...openModal,
                profesionalList: {show: true, data: {
                    ...openModal.profesionalList.data,
                    [profesional.id]: {
                        id: profesional.id,
                        nombre: profesional.nombre,
                        apellido: profesional.apellido,
                        monto: profesional.monto,
                        marcado: e.target.checked
                    }
                }}
            })
        }
        function HandleChangeAmountOfProfesional(profesional, e){
            setCheckedProfesional({
                ...checkedProfesional,
                [profesional.id]: {
                    ...checkedProfesional[profesional.id],
                    monto: e.target.value
                }
            });
            setOpenModal({
                ...openModal,
                profesionalList: {show: true, data: {
                    ...openModal.profesionalList.data,
                    [profesional.id]: {
                        id: profesional.id,
                        nombre: profesional.nombre,
                        apellido: profesional.apellido,
                        monto: e.target.value,
                        marcado: true
                    }
                }}
            })
        }
        function ValidateInputs(){

            if (Object.values(checkedProfesional).every(prof => prof.marcado === false)){
                MessageNotification("Debe seleccionar por lo menos un profesional para continuar.", "error");
                return;
            }

            const values = Object.entries(checkedProfesional).filter((prof: any) => prof[1].marcado === true);
            let isValidated = true;
            let objError = {};

            if (values.some(value => value[1].monto <= 0 || value[1].monto === null || value[1].monto.toString().length > 7)){
                isValidated = false;
                values.forEach(value => {
                    if (value[1].monto <= 0 || value[1].monto === null || value[1].monto.toString().length > 7) {
                        objError = {...objError, [value[0]]: true}
                    }
                });
                MessageNotification("Los montos deben ser números positivos y menor o igual a 7 (siete) dígitos.", "error");
            }
            setErrorNotificationInput(objError);
            return isValidated;
        }
        //#endregion
        
    return(
        <GeneralModalCrud
        titleOfModal="Seleccionar"
        openModal={openModal.profesionalList.show}
        HandleCloseModal={HandleClose}
        HandleSubmit={HandleSubmit}
        >
            <GeneralList
            titleOfSection='Profesionales'
            isLoaderActive={loadingActive}
            dataToRender={profesionalList}
            HandleSearch={LoadDataForTable}
            TestFilterComponents={FilterComponents}

            >
                <section className='cont-modalBill-rebill-selectProfesionalList'>
                    <Table aria-label='spanning table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='letter-title-table c-text-center'>
                                    <Checkbox 
                                    checked={Object.values(checkedProfesional).every(input => input.marcado === true)} 
                                    onChange={(e)=>HandleAllCheckProfesionals(e)}/>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Matrícula</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Profesional</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Monto</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {profesionalList.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell className='letter-title-table c-text-center'>
                                        <Checkbox 
                                            checked={checkedProfesional[item.id]?.marcado} 
                                            onChange={(e)=>HandleCheckProfesional(item, e)}/>
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                        {item.matricula}
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                        {item.apellido}, {item.nombre}
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                    <TextField
                                        className='cont-modalBill-rebill-selectProfesionalList__amountInput'
                                        value={checkedProfesional[item.id]?.monto}
                                        type='number'
                                        disabled={checkedProfesional[item.id]?.marcado === false}
                                        name={item.id}
                                        error={errorNotificationInput[item.id] ?? false}
                                        InputLabelProps={{shrink: true}}
                                        onChange={(e) => HandleChangeAmountOfProfesional(item, e)}
                                        InputProps={{
                                            inputProps: {max: 9999999, min: 0},
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoneyOutlinedIcon/>
                                            </InputAdornment>
                                            ),
                                        }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </section>
            </GeneralList>
        </GeneralModalCrud>
    )
}

export default ViewDetailProfesionalList;