import React from 'react';
import Inicio from './Inicio';
import Beneficios from './Beneficios';
import Contacto from './Contacto';

const LandingOctus: React.FC<any> = () => {
    return (
    <main className='cont-main-landing'>
        {/* INICIO */}
        <Inicio/>

        {/* BENEFICIOS */}
        <Beneficios/>

        {/* CONTACTO */}
        <Contacto/>
    </main>
    )
}

export default LandingOctus