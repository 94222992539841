import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { INewFilterRequest, INewRequest, INewsResponse } from "../../api/Interfaces/configurations/News";
import errorHandler from "../errorHandler";

const GetNewList = async (params: INewFilterRequest): Promise<IApiResponse<INewsResponse[]>> => {
    try{
        const response = await api.GetNewList(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetNewsForUsers = async (view?: boolean): Promise<IApiResponse<INewsResponse[]>> => {
    try{
        const response = await api.GetNewsForUsers(view);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CheckAllNewsForUser = async (): Promise<IApiResponse<void>> => {
    try{
        const response = await api.CheckAllNewsForUser();
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetNewById = async (newsId: number): Promise<IApiResponse<INewsResponse>> => {
    try{
        const response = await api.GetNewById(newsId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateNew = async (params: INewRequest): Promise<IApiResponse<number>> => {
    try{
        const response = await api.CreateNew(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const UpdateNew = async (newId: number, params: INewRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateNew(newId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivateNew = async (newId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivateNew(newId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteNew = async (newId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteNew(newId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {GetNewList, GetNewById, CreateNew, ReactivateNew, UpdateNew, DeleteNew, GetNewsForUsers, CheckAllNewsForUser}