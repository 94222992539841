import {Link} from "react-router-dom"
import React from 'react'
import { Icon } from "semantic-ui-react"
import "./popUpStyles.css"

export default function PopUpNotification({title, redirect, show, quantity}){
    return(
        <div className={`modalTextReturn ${show && "mostrarModalReturn"}`}>
            <Link
                style={{
                    color: "#721c24",
                    textAlign: "center",
                    width: "max-content",
                }}
                primary
                to={`${redirect}`}
                icon
                labelPosition='left'
            >
                <span style={{marginRight: 15}}>{title}</span>
                <span className="searchReturn" totalReturns={quantity}>
                    <Icon name='search'/>
                </span>
            </Link>
        </div>
    )
}