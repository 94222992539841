import { Checkbox, DialogContentText, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import React from 'react';
import { Button, Icon } from "semantic-ui-react";
import ButtonBox from '../../utils/ButtonBox';
import GeneralList from '../../utils/GeneralList';
import { INewFilterRequest, INewsResponse } from '../../../api/Interfaces/configurations/News';
import { DeleteNew, GetNewList, ReactivateNew } from '../../../services/services/News';
import { StatusCode } from '../../../enums';
import GeneralModalDelete from '../../utils/GeneralModalDelete';
import GeneralModalCrud from '../../utils/GeneralModalCrud';
import NewNewsModal from './modals/NewNewsModal';
import { INewsFilterObj, INewsModals } from '../../../services/interfaces/Configurations/INews';
import { _DEFAULT_NEWS_VALUES } from './defaultValues';

const _DEFAULT_LOAD_FOR_PAGE = 20;

const News: React.FC<any> = ({setDataToast}) => {
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [dataToRender, setDataToRender] = React.useState<INewsResponse[]>([]);
    const [page, setPage] = React.useState(1);
    const [loadForPage, setLoadForPage] = React.useState(1);
    const [reactivateNew, setReactivateNew] = React.useState(false);
    const [filterObj, setFilterObj] = React.useState<INewsFilterObj>(_DEFAULT_NEWS_VALUES._FILTER_OBJ);
    const [modals, setModals] = React.useState<INewsModals>(_DEFAULT_NEWS_VALUES._MODALS);

    React.useEffect(()=>{
        LoadDataForTable();
    },[]);

    //#region COMPONENTS
    const ButtonNew = ()=>(
        <Button
        className='btn-new-filter'
        onClick={(e)=>HandleOpenModal(e)}
        icon
        name="new"
        labelPosition='left'
        >
        <Icon name='plus' />
        Nuevo
        </Button>
    );
    const FilterComponents = [
        (key)=> (
        <TextField
        key={key}
        value={filterObj.title}
        label='Titulo'
        disabled={false}
        size="small"
        type='text'
        variant="outlined"
        onChange={(e)=> setFilterObj({
            ...filterObj,
            title: e.target.value === "" ? null : e.target.value
        })}
        InputLabelProps={{shrink: true}}
        />),
        (key)=> (
        <FormControlLabel style={{marginLeft: 2}}
        control={
            <Checkbox
            key={key}
            checked={filterObj.deleted}
            onChange={(e)=> setFilterObj({
                ...filterObj,
                deleted: e.target.checked
            })}
            />
        }
        label='Dadas de Baja'
        />),
    ];
    //#endregion

    //#region GET / DELETE / REACTIVATE
    async function LoadDataForTable(pageSelected: number = 1){
        try{
            setLoadingActive(true);
            const params: INewFilterRequest = {
                cantidad: _DEFAULT_LOAD_FOR_PAGE,
                pagina: pageSelected,
                dadasDeBaja: filterObj.deleted,
                titulo: filterObj.title
            }
            const {data, status} = await GetNewList(params);
            if (status === StatusCode.Ok){
                setDataToRender(data.results);
                setLoadForPage(data.quantity ?? 1);

                if (filterObj.deleted) setReactivateNew(true);
                else setReactivateNew(false);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function DeleteNewSelected(){
        try{
            setLoadingActive(true);
            const {data, status} = await DeleteNew(modals.delete.data?.id as number);
            if (status === StatusCode.Ok){
                HandleCloseAllModals();
                MessageNotification("Novedad dada de baja correctamente.", "success");
                LoadDataForTable();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function ReactivateNewSelected(){
        try{
            setLoadingActive(true);
            const {data, status} = await ReactivateNew(modals.reactivate.data?.id as number);
            if (status === StatusCode.Ok){
                MessageNotification("Novedad reactivada correctamente.", "success");
                HandleCloseAllModals();
                LoadDataForTable();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function HandleCloseAllModals(){
        setModals(_DEFAULT_NEWS_VALUES._MODALS);
    }
    function HandleChangePage(event: any, pageSelected: number){
        setPage(pageSelected);
        LoadDataForTable(pageSelected);
    }
    function HandleOpenModal(event, data: INewsResponse | null = null){
        setModals({
            ...modals,
            [event.currentTarget.name]: {show: true, data: data}
        })
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }
    //#endregion

    return (
        <>
        <GeneralList
        titleOfSection='Novedades'
        isLoaderActive={loadingActive}
        dataToRender={dataToRender}
        initialPage={page}
        numPagesToRender={loadForPage}
        TestFilterComponents={FilterComponents}
        HandleSearch={LoadDataForTable}
        ButtonNew={ButtonNew}
        onChangePage={HandleChangePage} 
        >
        <Table aria-label='spanning table'>
            <TableHead>
                <TableRow>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Título</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Video</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Fecha Creada</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Opciones</b>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dataToRender.map((item: any) => (
                    <TableRow key={item.id}>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            {item.titulo.length > 30 ? `${item.titulo.slice(0,20)}...` : item.titulo}
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <Tooltip placement='top' arrow title="Ver Video">
                                {(item.urlYoutube === "" || item.urlYoutube === null ) ?
                                <p>No Asignado</p>
                                :
                                <a href={item.urlYoutube} target='_blank'>
                                    <OndemandVideoOutlinedIcon />
                                </a>}
                            </Tooltip>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            {new Date(item.fechaAlta).toLocaleDateString()}
                        </TableCell>
                        <TableCell className='p-cell'>
                           {reactivateNew ?
                            <ButtonBox buttonToShow='reactivate' name='reactivate' HandleOnClik={(e)=>HandleOpenModal(e, item)}/>
                            :
                            <Button.Group>
                                <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e)=>HandleOpenModal(e, item)}/>
                                <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e)=>HandleOpenModal(e, item)}/>
                            </Button.Group>
                            }
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </GeneralList>

        {modals.new.show &&
        <NewNewsModal
        titleOfModal='Nuevo'
        aditionalData={modals.new.data}
        isEdit={false}
        openModal={modals.new.show}
        HandleCloseAllModals={HandleCloseAllModals}
        MessageNotification={MessageNotification}
        ReloadData={LoadDataForTable}
        />}

        {modals.edit.show &&
        <NewNewsModal
        titleOfModal='Editar'
        aditionalData={modals.edit.data}
        isEdit={true}
        openModal={modals.edit.show}
        HandleCloseAllModals={HandleCloseAllModals}
        MessageNotification={MessageNotification}
        ReloadData={LoadDataForTable}
        />}

        {modals.reactivate.show &&
        <GeneralModalCrud
        titleOfModal='Reactivar'
        openModal={modals.reactivate.show}
        HandleCloseModal={HandleCloseAllModals}
        HandleSubmit={ReactivateNewSelected}
        >
            <DialogContentText>
                {`¿Está seguro de reactivar la novedad: ${modals.reactivate.data?.titulo}?`}
            </DialogContentText>
        </GeneralModalCrud>}
        
        {modals.delete.show &&
        <GeneralModalDelete 
        contentText={`¿Está seguro de dar de baja la novedad: ${modals.delete.data?.titulo}?`}
        openModal={modals.delete.show}
        HandleClose={HandleCloseAllModals}
        HandleSubmit={DeleteNewSelected}
        />}
        </>
    );
}

export default News;