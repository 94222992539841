import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import React from 'react';
import { Button } from "semantic-ui-react";
import { StatusCode } from '../../../enums';
import { GetNewsForUsers } from '../../../services/services/News';
import DetailNotification from '../../common/AuthenticatedHeader/modals/DetailNotification';
import ButtonBox from '../../utils/ButtonBox';
import GeneralList from '../../utils/GeneralList';
import { INewsResponse } from '../../../api/Interfaces/configurations/News';


export const ViewNewsList: React.FC<any> = ({setDataToast}) =>{
    const [dataToRender, setDataToRender] = React.useState<INewsResponse[]>([]);
    const [loaderActive, setLoaderActive] = React.useState(false);
    const [openModals, setOpenModals] = React.useState<any>({detailNotification: {show: false, data: null}});

    React.useEffect(()=>{
        LoadDataForTable();
    },[]);

    async function LoadDataForTable(){
        try{
            setLoaderActive(true);
            const {data, status} = await GetNewsForUsers(true);
            if (status === StatusCode.Ok){
				setDataToRender(data.results);
            }
        }catch(err: any){
			MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoaderActive(false);
        }
    }

    function HandleDetailtNews(item){
        setOpenModals({
            ...openModals,
            detailNotification: {show: true, data: item}
        })
    }
    function HandleCloseDetailNotification(){
        setOpenModals({
            detailNotification: {show: false, data: null}
        })
    }

    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }

    return(
        <>
        <GeneralList
        titleOfSection='Novedades'
        dataToRender={dataToRender}
        HandleSearch={()=>{}}
        isLoaderActive={loaderActive}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Título</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Video</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha Creada</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: any) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.titulo.length > 30 ? `${item.titulo.slice(0,20)}...` : item.titulo}                               
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <Tooltip placement='top' arrow title="Ver Video">
                                    {(item.urlYoutube === "" || item.urlYoutube === null ) ?
                                    <p>No Asignado</p>
                                    :
                                    <a href={item.urlYoutube} target='_blank'>
                                        <OndemandVideoOutlinedIcon />
                                    </a>}
                                </Tooltip>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fechaAlta).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='p-cell'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='view' name="view" HandleOnClik={(e)=>HandleDetailtNews(item)}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>

        {openModals.detailNotification.show &&
        <DetailNotification 
        titleOfModal={`${openModals.detailNotification.data?.titulo}`}
        newsSelected={openModals.detailNotification.data}
        openModal={openModals.detailNotification.show}
        HandleCloseDetailNotification={HandleCloseDetailNotification}
        />}
        </>
    );
}