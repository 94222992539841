import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es, } from 'date-fns/locale';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Dimmer, Loader } from "semantic-ui-react";
import { IFamily } from '../../../../api/Interfaces/configurations/IFamily';
import { StatusCode } from '../../../../enums';
import { IErrorObjFamily, IModalNewFamily } from '../../../../services/interfaces/Configurations/IModalNewFamily';
import { CreateFamily, UpdateFamily } from '../../../../services/services/Family';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { _DEFAULT_VALUES } from '../defaultValues';

const ModalNewFamily: React.FC<IModalNewFamily> = ({
    openModal, 
    title, 
    values,
    error,
    isCreate,
    setError,
    ReloadData,
    HandleCloseModal,
    MessageNotification
    })=>{
    const {idProf} = useParams<any>();
    const [data, setData] = React.useState<Partial<IFamily>>(values);
    const [loaderActive, setLoaderActive] = React.useState(false);

    function HandleChangeInputs(e){
        setData({...data, [e.target.name]: e.target.value});
    }
    function HandleChangeCheckbox(e){
        setData({...data, [e.target.name]: e.target.checked});
    }
    function ValidateValues(values){
        let isValidated = true;
        let errorInput: {} = {}
        const valuesNulls = Object.keys(values).filter(key => values[key] === null || values[key] === "");

        if (valuesNulls.length !== 0){
            MessageNotification("Todos los campos son obligatorios.", "error");
            valuesNulls.forEach(key => {
                errorInput[key] = true
            });
            setError(errorInput as IErrorObjFamily);
            isValidated = false;
        }
        return isValidated;
    }
    async function CreateNewFamily(){
        try{
            setLoaderActive(true);
            const {status} = await CreateFamily(+idProf, data);
            if (status === StatusCode.Ok){
                MessageNotification("Familiar creado correctamente.", "success");
                HandleCloseModal();
                ReloadData();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoaderActive(false);
        }
    }
    async function EditExistsFamily(){
        try{
            setLoaderActive(true);
            const {status} = await UpdateFamily(+idProf, data.id as number, data);
            if (status === StatusCode.Ok){
                MessageNotification("Familiar editado correctamente.", "success");
                HandleCloseModal();
                ReloadData();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoaderActive(false);
        }
    }
    function HandleSubmit(){
        if(ValidateValues(data)){
            if (isCreate){
                CreateNewFamily();
            }else{
                EditExistsFamily();
            }
        }
    }

    return (
        <>
        {loaderActive &&
            <Dimmer className='loader-fixed' active inverted>
                <Loader size='big'>Cargando..</Loader>
            </Dimmer>
        }
        <GeneralModalCrud
        titleOfModal={title}
        openModal={openModal}
        HandleCloseModal={HandleCloseModal}
        HandleSubmit={HandleSubmit}
        >
            <TextField
            size='small'
            type="text"
            name="nombre"
            placeholder="Nombre..."
            onChange={(e)=>HandleChangeInputs(e)}
            error={error.nombre}
            value={data.nombre}
            variant='outlined'
            />
            <TextField
            size='small'
            type="text"
            name="relacion"
            placeholder="Relacion del familiar..."
            onChange={(e)=>HandleChangeInputs(e)}
            value={data.relacion}
            InputLabelProps={{shrink: true}}
            variant='outlined'
            error={error.relacion}
            />
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    style={{ marginTop: 5 }}
                    autoOk
                    className='size-date-picke-filter'
                    inputVariant='outlined'
                    InputAdornmentProps={{ position: 'end' }}
                    variant='inline'
                    name='fechaNacimiento'
                    error={error.fechaNacimiento}
                    format="dd/MM/yyyy"
                    placeholder='Fecha Nacimiento'
                    value={data.fechaNacimiento}
                    onChange={(date: any)=> setData({...data, fechaNacimiento: date})}
                />
            </MuiPickersUtilsProvider>
            <FormControlLabel
                control={
                    <Checkbox
                    name='estudianteRegular'
                    checked={data.estudianteRegular}
                    onChange={(e)=>HandleChangeCheckbox(e)}
                    />
                }
                label='Estudiante Regular'
            />
            <FormControlLabel
                control={
                    <Checkbox
                    name='siempreCubierto'
                    checked={data.siempreCubierto}
                    onChange={(e)=>HandleChangeCheckbox(e)}
                    />
                }
                label='Siempre Cubierto'
            />
        </GeneralModalCrud>
        </>)
}

export default ModalNewFamily;