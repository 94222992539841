import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jwt-decode";
import ConfigurationPage from "../utils/ConfigurationPage";
import { GestionUrl, TypeRole } from "../../enums";
import api from "../../api";
import { useDispatch } from "react-redux";
import { setManagment } from "../../redux/managment";

const PrivateRoute = ({ children, component: Component, ...rest }) => {
  const disparador = useDispatch();
  const expirationTokenLog = window.localStorage.getItem("tokenExp");
  const tokenLog = window.localStorage.getItem("tokenLog");
  const [datJWT, setDataJWT] = React.useState(null);

  React.useEffect(()=>{
    HandleGetDataJWT();
  },[expirationTokenLog]);
  
  async function HandleGetDataJWT(){
    try{
      const {data}  = await api.getJwtData();
      setDataJWT(data);
    }catch(err){
      console.log(err);
    }
  }

  const validationToken = () => {
    if (tokenLog){
      if (new Date(expirationTokenLog * 1000) < new Date()) return false;
      return true;
    }else {
      return false;
    }
  };

  function Components(app){
    const actualUrl = window.location.pathname;
    const managment = (datJWT === null ? false : !(datJWT?.gestion.toLowerCase() === "false"));
    disparador(setManagment(managment));

    if(GestionUrl.includes(actualUrl)){
      if (managment === false && datJWT?.rol !== TypeRole.SuperAdmin) {
        return <ConfigurationPage />
      }
      else return app;
    }else return app;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        validationToken() ? Components(children) : <Redirect to="/login"/>
      }
    />
  );
};

export default PrivateRoute;
