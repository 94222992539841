import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { default as DownloadImage, default as GetAppIcon } from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { Button, Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import "../../App.scss";
import api from "../../api";
import "./Document.css";

export default function DocumentZipList({ setDataToast }) {
	const [isLoading, setIsLoading] = useState(true);
	const [dataTable, setDataTable] = useState([]);
	const [processZip, setProcessZip] = useState(false);
	
	const CreateZipManually = async() => {
		try{
			setIsLoading(true);
			const today = new Date().getMonth();
			let query = await api.createZipManually(today);
			if (query.status === 200){
				NotificationMessage("success","Archivos procesados correctamente.")
				LoadData();
			}
		}catch(err){
			NotificationMessage("error","Error al procesar los archivos.")
		}
		finally{
			setIsLoading(false);
		}
	}

	const NotificationMessage = (type, message)=>{
		setDataToast({
          active: true,
          type: type,
          message: message,
        })
	}

	const LoadData = ()=>{
		setIsLoading(true);
		api
			.getZipPeriodList()
			.then((res) => {
				ShowOrNot(res.data);
				if (res.data.length > 0){
					setDataTable(res.data);
				}
			})
			.catch((err) => NotificationMessage("error","Ocurrio un error."))
			.finally(()=>{setIsLoading(false)})
	}

	const ShowOrNot = (data)=>{
		const today = [new Date().getMonth(), new Date().getFullYear()];
		const prevPeriod = data.some(item => +item.mes === today[0] && +item.anio === today[1]);
		if (!prevPeriod){
			setProcessZip(true);
		}else{setProcessZip(false)}
	}

	const TableRowData = ({ item }) => {
		return (
			<TableRow colSpan={4} key={item.archiveName}>
				<TableCell className='p-cell c-text-center'>{item.anio}</TableCell>
				<TableCell className='p-cell c-text-center'>{item.mes}</TableCell>
				<TableCell className='p-cell c-text-center'>
					<a href={`${process.env.REACT_APP_API_URL}documentos/zip?ArchiveName=${item.archiveName}`}
					 className='btn-circle-yellow' target="_blank" rel="noreferrer"
					 style={{padding: 10, display: "inline-block", borderRadius: "50%"}}>
						<DownloadImage />
					</a>
				</TableCell>
			</TableRow>
		);
	};

	useEffect(() => {
		LoadData();
	}, []);

	return (
		<>
			{isLoading && (
				<Dimmer className='loader-fixed' active inverted>
					<Loader size='big'>Cargando archivos...</Loader>
				</Dimmer>
			)}
			<Header as='h2'>
				<Grid.Row>
				<Header.Content>Período</Header.Content>
					{processZip && 
					<Button
					style={{display:"flex", justifyContent: "center", alignItems: "center"}}
					primary
					className='btn-search-filter'
					onClick={() => CreateZipManually()}
					>
						<GetAppIcon style={{marginRight: 10, fontSize: ".96em"}}/>
						<Typography style={{fontSize: ".9em"}}>Procesar archivo zip</Typography>
					</Button>}
				</Grid.Row>
			</Header>
			<Grid.Row>
				<TableContainer component={Paper} id='color-letter-table-tasks'>
					<Table aria-label='spanning table'>
						<TableHead className='color-letter-inTable'>
							<TableRow colSpan={4}>
								<TableCell className='letter-title-table not-padding-table-task c-text-center'>
									<b>Año</b>
								</TableCell>
								<TableCell className='letter-title-table c-text-center'>
									<b>Mes</b>
								</TableCell>
								<TableCell className='letter-title-table c-text-center'>
									<b>Opciones</b>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{dataTable &&
								dataTable.map((item) => (
									<TableRowData item={item} key={item.archiveName} />
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid.Row>
		</>
	);
}