import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { StatusCode } from '../../../../enums';
import { setDataOfBillForLiquidation } from '../../../../redux/generateLiquidation';
import { GetBillById } from '../../../../services/services/CrudBill';
import ButtonBox from '../../../utils/ButtonBox';
import { FormatNumber, FormatToString } from '../../../utils/FormatInputs';
import GeneralList from '../../../utils/GeneralList';
import EditManualDebitByBill from '../../modals/Liquidation/EditManualDebitByBill';
import { _DEFAULT_VALUES_MANUALDEBIT } from '../../defaultValues';
import { IDataOfBill, IManualDebitByBill, IModalManualDebit } from '../../../../services/interfaces/Managment/IManualDebitByBill';


const ManualDebitByBill: React.FC<IManualDebitByBill> = ({setDataToast}) => {
    const {id} = useParams<any>();
    const history = useHistory();
    const {facturas}: any = useSelector<any>(({generateLiquidation})=> generateLiquidation);
    const disparador = useDispatch();
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [modals, setModals] = React.useState<IModalManualDebit>(_DEFAULT_VALUES_MANUALDEBIT._MODALS);
    const [dataOfBill, setDataOfBill] = React.useState<IDataOfBill>(_DEFAULT_VALUES_MANUALDEBIT._DATA_OF_BILL);

    React.useEffect(()=>{
       if (Object.values(facturas).length > 0 && id !== undefined) HandleGetBillById();
       else {
        MessageNotification("Debe seleccionar primero una factura.", "error");
        history.goBack();
       }
    },[]);

    async function HandleGetBillById(){
        try{
            setLoadingActive(true);
            const {data, status} = await GetBillById(id, true);
            if (status === StatusCode.Ok){
                const dataOfBillToLiquidation: any = {
                    ...data.results,
                    usuarioFactura: data.results.usuarioFactura.map(userBill => {
                        return {
                            ...userBill,
                            montoDebitoManual: facturas[id]?.usuarioFactura.find(ub => ub.UsuarioId === userBill.UsuarioId)?.montoDebitoManual ?? 0, 
                            motivoDebitoManual: facturas[id]?.usuarioFactura.find(ub => ub.UsuarioId === userBill.UsuarioId)?.motivoDebitoManual ?? ""
                        }
                    })
                };
                setDataOfBill(dataOfBillToLiquidation);
            }
        }catch(err: any){
            MessageNotification(err.message, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    function ValidateValues(){
        let isValidated = true;
        const areManualDebitCharge = dataOfBill.usuarioFactura.every((userBill)=> (userBill.montoDebitoManual === 0 && userBill.motivoDebitoManual === ""));

        if (areManualDebitCharge){
            MessageNotification("Deberá cargar valores a los campos 'Monto' y 'Motivo' de algún profesional para guardar.", "error");
            isValidated = false;
        }
        return isValidated;
    }
    function HandleSendValues(){
        if (ValidateValues()){
            const manualDebit: number = dataOfBill.usuarioFactura.reduce((acc, actualValue) =>{
                return acc + actualValue.montoDebitoManual
            },0);
            const manualDebitByProf = {
                [dataOfBill.id as number]: {
                    ...facturas[dataOfBill.id as number],
                    montoDebitoManual: manualDebit,
                    montoTotalDebito: facturas[dataOfBill.id as number].montoDebitoAutomatico + manualDebit,
                    usuarioFactura: dataOfBill.usuarioFactura
                }
            }
            disparador(setDataOfBillForLiquidation(manualDebitByProf));
            MessageNotification("Débito cargado correctamente.", "success");
            setTimeout(()=>{
                history.goBack();
            }, 500);
        }
    }
    const ButtonNew = () =>(
        <>
        <Button onClick={()=>{history.goBack()}}>Cancelar</Button>
        <Button onClick={HandleSendValues} color='green'>Aceptar</Button> 
        </>
    );
    const HeaderInfo = ()=>(
       <div>
        <Typography style={{margin: "6px 0"}}>Factura: N°: {dataOfBill.id}</Typography>
        <Typography style={{margin: "6px 0"}}>Obra Social: {dataOfBill.obraSocial.nombre} / Plan: {dataOfBill.plan.nombre}</Typography>
        <Typography style={{margin: "6px 0"}}>Fecha: {dataOfBill.fecha !== null ? FormatToString(dataOfBill?.fecha) : ""}</Typography>
        <Typography style={{margin: "6px 0"}}>Total: {dataOfBill.total !== null ? FormatNumber({style: "currency", currency: "USD", value: dataOfBill?.total}) : ""}</Typography>
       </div>
    );
    function HandleCloseAllModals(){
        setModals(_DEFAULT_VALUES_MANUALDEBIT._MODALS);
    }
    function HandleEditManualDebit(profesional){
        setModals({edit: {show: true, data: {profesional: {...profesional}, factura: {...dataOfBill}}}});
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }
    return (
    <>
    <GeneralList
    titleOfSection='Débito a Profesional'
    HeaderInfo={HeaderInfo}
    ButtonNew={ButtonNew}
    dataToRender={dataOfBill}
    isLoaderActive={loadingActive} 
    HandleSearch={()=>{}}
    >
         <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Matrícula</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Apellido</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Nombre</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Facturado</b>
                        </TableCell>
                         <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Monto</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Motivo</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataOfBill.usuarioFactura.map((item) => (
                        <TableRow key={item.UsuarioId}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.matricula}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.apellido}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.nombre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({style: 'currency', currency:'USD', value: dataOfBill.refacturada ? item.monto : dataOfBill.total})}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({style: 'currency', currency: 'USD', value: item.montoDebitoManual})}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.motivoDebitoManual}
                            </TableCell>
                            <TableCell className='c-text-center'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e)=>HandleEditManualDebit(item)}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    </GeneralList>

    {modals.edit.show && 
    <EditManualDebitByBill 
    openModal={modals.edit.show}
    dataModal={modals.edit.data}
    MessageNotification={MessageNotification}
    setDataOfBill={setDataOfBill}
    HandleCloseAllModals={HandleCloseAllModals}
    />}
    </>);
}

export default ManualDebitByBill;