export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      const unregisterPromises = registrations.map(registration => {
        return caches.keys()
          .then(keys => Promise.all(keys.map(key => caches.delete(key))))
          .then(() => registration.unregister())
          .catch(err => console.error('Failed to delete cache or unregister service worker:', err));
      });

      return Promise.all(unregisterPromises).then(() => {
        console.log('All Service Workers unregistered and caches cleared.');
      });
    }).catch(error => {
      console.error('Failed to get Service Worker registrations:', error);
    });
  }
}