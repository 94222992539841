import React from 'react';
import api from '../../../api';
import ButtonBox from '../ButtonBox';
import PhotoView from './PhotoView';

export default function PopupImage({
	title,
	data,
	dataToast,
	document,
	dontDelete,
	professionalId,
	MessageNotification,
	reloadData,
}) {
	let tipoUsuario = JSON.parse(localStorage.getItem('TypeRole'));
	const dataUrl = [];
	const [abrirModal, setAbrirModal] = React.useState(false);
	const [photo, setPhoto] = React.useState({ imagenes: [] });

	const OpenPreviewImage = () => {
		PreviewImg(data, document);
		setAbrirModal(true);
	};
	const PreviewImg = (imagesId, document) => {
		let arrayFunctions = [];
		arrayFunctions = downloadImagesByDocument(imagesId, document);
		downloadImageFromDB(arrayFunctions);
	};

	const downloadImageFromDB =(arrayFunctions)=>{
		Promise.all(arrayFunctions)
		.then((res) => {
			res.forEach((item) => {
				const imageUrl = URL.createObjectURL(item.data);
				dataUrl.push({
					src: imageUrl,
					title,
				});
			});
			setPhoto({
				imagenes: dataUrl,
			});
		})
		 .catch((err) => {
			MessageNotification(err.response.data.errores, "error");
		 })
	}

	const downloadImagesByDocument = (imagesId, document)=>{
		let returnInfo = [];
		if (imagesId.length > 1) {
			imagesId.forEach((item) => {
				let image = {
					documentoId: document,
					imagenId: item.id,
				};
				returnInfo.push(api.imagesDownload(image));
			});
		} else {
			let item = imagesId[0];
			let image = {
				documentoId: document,
				imagenId: item.id,
			};
			returnInfo.push(api.imagesDownload(image));
		}
		return returnInfo;
	}

	return (
		<>
			{data.length > 0 && <ButtonBox name='viewPhotos' buttonToShow='viewPhotos' HandleOnClik={OpenPreviewImage}/>}
				<PhotoView
					professionalId={professionalId}
					tipoUsuario={tipoUsuario}
					imagenes={photo.imagenes}
					data={data}
					document={document}
					dontDelete={dontDelete}
					dataToast={dataToast}
					MessageNotification={MessageNotification}
					reloadData={reloadData}
					abrirModal={abrirModal}
					setAbrirModal={setAbrirModal}
				/>
		</>
	);
}
