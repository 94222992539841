import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IFamily } from "../../api/Interfaces/configurations/IFamily";
import errorHandler from "../errorHandler";

const GetFamily = async (userId: number, params: any): Promise<IApiResponse<IFamily[]>> => {
    try{
        const response = await api.GetFamily(userId, params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateFamily = async (userId: number, params: Partial<IFamily>): Promise<IApiResponse<number>> => {
    try{
        const response = await api.CreateFamily(userId, params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const UpdateFamily = async (userId: number, familyId: number, params: Partial<IFamily>): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateFamily(userId, familyId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteFamily = async (userId: number, familyId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteFamily(userId, familyId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {
    CreateFamily, DeleteFamily, GetFamily, UpdateFamily
};
