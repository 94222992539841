import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { StatusCode, TypeRole } from '../../../enums';
import { IAccountSummary } from '../../../services/interfaces/Configurations/IAccountSummary';
import { GetAccountSummaryByUserId } from '../../../services/services/Users';
import GeneralList from '../../utils/GeneralList';

const AccountSummary: React.FC<IAccountSummary> = ({setDataToast}) => {
    const {id} = useParams<any>();
    const [dataToRender, setDataToRender] = React.useState<any>([]);
    const [loadingActive, setLoadingActive] = React.useState(false);

    React.useEffect(()=>{
        LoadDataForTable();
    },[]);

    async function LoadDataForTable(){
        try{
            setLoadingActive(true);
            const {data, status} = await GetAccountSummaryByUserId(id);
            if (status === StatusCode.Ok){
                const accountSummaryById = data.results.map(item => {
                    return {
                        fecha: item.fecha,
                        montoEntrada: item.monto >= 0 ? item.monto : 0,
                        montoSalida: item.monto <= 0 ? (item.monto * -1) : 0,
                        saldo: item.saldo,
                        descripcion: item.descripcion
                    }
                });
                setDataToRender(accountSummaryById);
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }

    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }

    return(
        <GeneralList
        titleOfSection='Resumen de Cuenta'
        dataToRender={dataToRender}
        isLoaderActive={loadingActive}
        HandleSearch={LoadDataForTable}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Fecha</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Entrada</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Salida</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Saldo Regional</b>
                        </TableCell>
                         <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Descripción</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: any, index: number) => (
                        <TableRow key={index}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {Intl.NumberFormat().format(item.montoEntrada)}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {Intl.NumberFormat().format(item.montoSalida)}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {Intl.NumberFormat().format(item.saldo)}
                            </TableCell>
                             <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.descripcion}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>
    )
}

export default AccountSummary;