import * as MComponents from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../api';
import { ApiCallStatus, StatusOfDocument, TypeRole } from '../../../enums';
import NewLoader from '../../utils/newLoader/Loader';
import RateReviewIcon from '@material-ui/icons/RateReview';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: "#e1e1e1",
    flexShrink: 0,
    justifyContent: "space-between",
    transition: "transform .5s ease",
    borderBottom: "2px solid transparent",
    "&:hover":{
      borderBottom: "2px solid #ef4f5b",
      transform: "scale(.9)",
      transition: "transform .5s ease"
    },
    marginBottom: 10
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    height: 120,
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
   width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems:"center"
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  imageContent:{
    width: 70,
    height: 70,
    borderRadius: 6,
  },
  indicatorIcon: {fontSize: "3em", color: "#ef4f5b"}
}));

const defaultValues = {
    returns: 0,
    patientCount: 0,
    sessionSocialWorkCount: 0,
    socialWorkAttendedCount: 0,
    nextClosingDate: "-",
    pendingPatients: 0,
    pendingSessions: 0,
    patientsVerified: 0,
    pendingIntegration: 0,
  }

export default function Indicator({
  setDataToast,
  periodSelected,
  setData
}) {

    const classes = useStyles();
    const [dataIndicator, setDataIndicator] = useState(defaultValues);
    const [loaderIndicators, setLoaderIndicators] = useState(true);
    const [roleLogged, setRoleLogged] = useState(()=>JSON.parse(localStorage.getItem('TypeRole')));
    
    const dataToExport = [
    {
        forRol: [TypeRole.Professional].includes(roleLogged),
        title: 'Pacientes Presentados en el Período',
        info: `${dataIndicator.patientCount}`,
        icon: <ContactMailIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Admin].includes(roleLogged),
        title: 'Documentación Pendiente de Validar',
        info: `${dataIndicator.patientsVerified}`,
        icon: <ContactMailIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Professional].includes(roleLogged),
        title: 'Pacientes Pendientes',
        info: `${dataIndicator.pendingPatients}`,
        icon: <CloudUploadIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Professional, TypeRole.Admin].includes(roleLogged),
        title: 'Devoluciones Pendientes',
        info: `${dataIndicator.returns}`,
        icon: <RestorePageIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Professional, TypeRole.Admin].includes(roleLogged),
        title: 'Próxima Fecha de Cierre',
        info: dataIndicator.nextClosingDate,
        icon: <ScheduleIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Professional].includes(roleLogged),
        title: 'Obras Sociales Atendidas en el Período',
        info: `${dataIndicator.socialWorkAttendedCount}`,
        icon: <HowToRegIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Professional].includes(roleLogged),
        title: 'Cantidad de Sesiones en el Período',
        info:  `${dataIndicator.sessionSocialWorkCount}`,
        icon: <FormatListNumberedRtlIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
    {
        forRol: [TypeRole.Professional].includes(roleLogged),
        title: 'Cantidad de Sesiones pendientes',
        info: `${dataIndicator.pendingSessions}`,
        icon: <FormatListNumberedRtlIcon className={classes.indicatorIcon}/>,
        toRedirect: "/Inicio",
    },
  //   {
  //     forRol: [TypeRole.Professional].includes(roleLogged),
  //     title: 'Prestaciones pendientes',
  //     info: `${dataIndicator.pendingIntegration}`,
  //     icon: <RateReviewIcon className={classes.indicatorIcon}/>,
  //     toRedirect: "/integraciones/lista",
  // },
      
    ];
    
  useEffect(()=>{
    setLoaderIndicators(true);
    GetAllThePromises();
  },[periodSelected]);

  const GetAllThePromises = ()=>{
    if(roleLogged === TypeRole.Professional){
      GetDataForProfessionalRol();
    } 
    if(roleLogged === TypeRole.Admin){
      GetDataForAdminRol();
    }
  }

  const GetDataForProfessionalRol = ()=>{
    let today = new Date();
    let periodSelected = {
          mes: today.getMonth() + 1,
          anio: today.getFullYear(),
        }
    Promise.allSettled([
      api.GetReturnsCount(),
      api.GetDataOfPeriod(periodSelected, StatusOfDocument.NotVerified),
      api.GetPatientInfo(),
      api.GetTotalSessions(),
      api.GetNexClosingDate(periodSelected),
      //lo vamos a usar para los Administrativos a futuro
      // api.GetPendingIntegrations({socialWorkId: null, isConfirm: false})
  ])
  .then((results) =>{
      const dataReturns = results[0].status === ApiCallStatus.FULFILLED ? results[0].value.data : defaultValues.returns;

      const dataOfPeriod = results[1].status === ApiCallStatus.FULFILLED && results[1].value.data.periodos.length > 0 ? 
        results[1].value.data.periodos[0] 
      : 
        {
          cantidadDocumentos: defaultValues.patientCount,
          cantidadObrasSociales: defaultValues.socialWorkAttendedCount,
        };

      const patientInfo = results[2].status === ApiCallStatus.FULFILLED ? 
        results[2].value.data 
      : 
        {
          pacientesPendientes: defaultValues.pendingPatients,
          sesionesPendientes: defaultValues.pendingSessions
        };

      const totalSessions = results[3].status === ApiCallStatus.FULFILLED ? results[3].value.data : defaultValues.sessionSocialWorkCount

      const nexClosingDate = results[4].status === ApiCallStatus.FULFILLED ? new Date(results[4].value.data).toLocaleDateString() : defaultValues.nextClosingDate;
      
      // const pendingIntegrations = results[5].status === ApiCallStatus.FULFILLED ? results[5].value.data.length : defaultValues.pendingIntegration;

      setDataIndicator({
        returns: dataReturns,
        patientCount: dataOfPeriod.cantidadDocumentos,
        sessionSocialWorkCount: totalSessions,
        socialWorkAttendedCount: dataOfPeriod.cantidadObrasSociales,
        nextClosingDate: nexClosingDate,
        pendingPatients: patientInfo.pacientesPendientes,
        pendingSessions: patientInfo.sesionesPendientes,
        // pendingIntegration: pendingIntegrations,
      });
      // setData(pendingIntegrations);
    })
    .catch(err=>{
       setDataToast({
          active: true,
          message: err.response.data.errores ?? "Ocurrió un error.",
          type: "error",
        });
    })
    .finally(()=>setLoaderIndicators(false));
  }
  const GetDataForAdminRol = ()=>{
    Promise.allSettled([
      api.GetReturnsCountAdmin(),
      api.GetDataOfPeriod(periodSelected, StatusOfDocument.NotVerified),
      api.GetNexClosingDate(periodSelected)
  ])
  .then((results) =>{
      let dataReturns = results[0].status === ApiCallStatus.FULFILLED ? results[0].value.data : defaultValues.returns;

      let dataOfPeriod = results[1].status === ApiCallStatus.FULFILLED && results[1].value.data.periodos.length > 0 ? 
        results[1].value.data.periodos[0] 
      : 
        {
          cantidadDocumentos: defaultValues.patientCount,
          cantidadObrasSociales: defaultValues.socialWorkAttendedCount,
          verificado: defaultValues.patientsVerified,
        };

      let nexClosingDate = results[2].status === ApiCallStatus.FULFILLED ? new Date(results[2].value.data).toLocaleDateString() : defaultValues.nextClosingDate;

      setDataIndicator({
        returns: dataReturns,
        patientCount: dataOfPeriod.cantidadDocumentos,
        socialWorkAttendedCount: dataOfPeriod.cantidadObrasSociales,
        patientsVerified: results[1].value.data.cantidadRegistros,
        nextClosingDate: nexClosingDate,
      });
    })
    .catch(err=>{
       setDataToast({
          active: true,
          message: err.response.data.errores ?? "Ocurrió un error.",
          type: "error",
        });
    })
    .finally(()=>setLoaderIndicators(false));
  }

  return (
   <React.Fragment>
    {dataToExport.map(({forRol,title, info, icon, toRedirect})=>(
      forRol && (
        <Link to={`${toRedirect}`}>
          <MComponents.Card className={classes.root}>
          <div className={classes.details}>
            <MComponents.CardContent className={classes.content}>
              <MComponents.Typography component="h5" variant="h5" style={{fontSize: "1em"}}>
                {title}
              </MComponents.Typography>
              <MComponents.Typography variant="subtitle1" color="textSecondary" style={{fontSize: "1.8em", margin: "2px 0", display: "flex"}}>
                {loaderIndicators ? <NewLoader/> : info }
              </MComponents.Typography>
            </MComponents.CardContent>
          </div>
          <div className={classes.cover}>
            <section className={classes.imageContent}>
              {icon}
            </section>
          </div>
        </MComponents.Card>
      </Link>
      )
    ))}
   </React.Fragment>
  );
}