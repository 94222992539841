import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { IReceiptRequest, ISuggestionsResponse } from '../../../../api/Interfaces/managment/IReceipt';
import { StatusCode } from '../../../../enums';
import { IErrorObj, INewModalReceipt, IReceiptForFree, IReceiptForProfessional, IReceiptForSocialWork } from "../../../../services/interfaces/Managment/INewModalReceipt";
import { CreateReceipt, GetSuggestions } from '../../../../services/services/Receipts';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import GeneralModalTabs from "../../../utils/GeneralModalTabs";
import { _DEFAULT_VALUES_RECEIPTS } from '../../defaultValues';
import ReceiptForFree from "../../panelSection/ReceiptForFree";
import ReceiptForProfesional from "../../panelSection/ReceiptForProfesional";
import ReceiptForSocialWork from "../../panelSection/ReceiptForSocialWork";
import ValidateReceiptValues from "./utilityFunctions/ValidateReceiptValues";

const panelSection = ["Profesional", "Obra Social", "Libre"];

const suggestionsDefault: ISuggestionsResponse = {
    numeroSucursal: 0,
    numero: 1,
    numeroSucursalOS: 0
}

const NewModalReceipts: React.FC<INewModalReceipt> = ({
    dataToRender,
    modalSelected,
    titleOfModal,
    isView,
    ReloadData,
    MessageNotification,
    HandleCloseModals
}) => {
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState<IErrorObj>(_DEFAULT_VALUES_RECEIPTS._ERROROBJ);
    const [receiptProfessional, setReceiptProfessional] = React.useState<IReceiptForProfessional>(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._PROFESSIONAL);
    const [receiptSocialWork, setReceiptSocialWork] = React.useState<IReceiptForSocialWork>(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._SOCIALWORK);
    const [receiptFree, setReceiptFree] = React.useState<IReceiptForFree>(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._FREE);
    const [sectionSelected, setSectionSelected] = React.useState(_DEFAULT_VALUES_RECEIPTS._TABS_PANELS._PROFESSIONAL);
    const [suggestions, setSuggestions] = React.useState<ISuggestionsResponse>(suggestionsDefault);

    React.useEffect(() => {
        HandleGetSuggestions();
    }, []);

    //#region CREAR
    async function HandleSubmitReceipt(){
        try{
            if (sectionSelected === _DEFAULT_VALUES_RECEIPTS._TABS_PANELS._PROFESSIONAL){
                if (ValidateInputs(receiptProfessional)){
                    CreateReceipts(receiptProfessional);
                }
            }
            if (sectionSelected === _DEFAULT_VALUES_RECEIPTS._TABS_PANELS._SOCIALWORK){
                if (ValidateInputs(receiptSocialWork)){
                    CreateReceipts(receiptSocialWork);
                }
            }
            if (sectionSelected === _DEFAULT_VALUES_RECEIPTS._TABS_PANELS._FREE){
                if (ValidateInputs(receiptFree)){
                    CreateReceipts(receiptFree);
                }
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{

        }
    }
    //#endregion

    //#region CREAR RECIBOS PROFESIONAL / OBRA SOCIAL / LIBRE
     async function CreateReceipts(values: any){
        try{
            setLoadingActive(true);
            const params: IReceiptRequest = {
                numero: values.numero,
                monto: values.monto,
                profesionalId: values.profesionalId,
                fecha: values.fecha,
                interes: values.interes,
                numeroSucursal: values.sucursal,
                obraSocialId: values.obraSocialId,
                nombreLibre: values.nombre,
                afectaSaldo: values.afectaSaldo,
                concepto: values.enConceptoDe,
                facturaId: values.facturaId,
                impreso: false,
                esDeposito: values.deposito
            }
            const {data, status} = await CreateReceipt(params);
            if (status === StatusCode.Ok){
                MessageNotification("Recibo creado correctamente.","success");
                HandleSetDefaultValues();
                HandleCloseModals();
                ReloadData();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores,"error");
        }finally{
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region SUGERENCIAS
    async function HandleGetSuggestions() {
        try {
            setLoadingActive(true);
            const { data, status } = await GetSuggestions();
            if (status === StatusCode.Ok) {
                SetSuggestionsValues(data.results);
                setSuggestions(data.results);
            }
        } catch (err: any) {
            MessageNotification(err.response.data.errores, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function ValidateInputs(values: IReceiptForProfessional | IReceiptForSocialWork | IReceiptForFree){
        const {objValidate, objError} = ValidateReceiptValues(values, dataToRender);
        if (!objValidate.validate){
            MessageNotification(objValidate.message, "error");
            setErrorObj(objError);
        }
        return objValidate.validate;
    }
    function HandleSetDefaultValues(){
        setReceiptFree(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._FREE);
        setReceiptProfessional(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._PROFESSIONAL);
        setReceiptSocialWork(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._SOCIALWORK);
        setErrorObj(_DEFAULT_VALUES_RECEIPTS._ERROROBJ);
        SetSuggestionsValues(null);
    }
    function SetSuggestionsValues(data: ISuggestionsResponse | null) {
        setReceiptProfessional((prevValues) => ({
            ...prevValues,
            numero: data?.numero ?? suggestions.numero,
            sucursal: data?.numeroSucursal ?? suggestions.numeroSucursal,
        }));
        setReceiptSocialWork((prevValues) => ({
            ...prevValues,
            sucursal: data?.numeroSucursalOS ?? suggestions.numeroSucursalOS,
        }));
        setReceiptFree((prevValues) => ({
            ...prevValues,
            numero: data?.numero ?? suggestions.numero,
            sucursal: data?.numeroSucursal ?? suggestions.numeroSucursal,
        }));
    }
    //#endregion
    
    return (
        <>
        {loadingActive ?
        <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando...</Loader>
        </Dimmer>
            :
        <GeneralModalCrud 
        titleOfModal={titleOfModal}
        openModal={modalSelected.show}
        HandleCloseModal={HandleCloseModals}
        HandleSubmit={HandleSubmitReceipt}
        styles="cont-default-modal"
        >
            <GeneralModalTabs
                isView={isView}
                setSectionSelected={setSectionSelected}
                setDefaultValues={HandleSetDefaultValues}
                viewPanelDefault={0}
                arrPanels={[
                    <ReceiptForProfesional 
                    receiptProfessional={receiptProfessional}
                    errorObj={errorObj}
                    isView={isView}
                    setReceiptProfessional={setReceiptProfessional}
                    MessageNotification={MessageNotification}
                    />,
                    <ReceiptForSocialWork 
                    receiptSocialWork={receiptSocialWork}
                    errorObj={errorObj}
                    isView={isView}
                    setReceiptSocialWork={setReceiptSocialWork}
                    MessageNotification={MessageNotification}
                    />,
                    <ReceiptForFree 
                    receiptFree={receiptFree}
                    errorObj={errorObj}
                    isView={isView}
                    setReceiptFree={setReceiptFree}
                    />
                ]}
                arrTittleOfPanels={panelSection}
            />
        </GeneralModalCrud>
        }
        </>
    )
}

export default NewModalReceipts;