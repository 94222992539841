import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SideBarItem from "../sideBar/SideBarItem";
import PermMediaIcon from '@material-ui/icons/PermMedia';

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={5}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
));

const useStyles = makeStyles(() => ({
    sideBarIconStyles: {
        fontSize: "2em",
        marginRight: 5,
        transition: 'margin-left 0.3s ease !important',
    },
    sideBarTextItemStyles: {
        fontSize: "1em",
        color: "#363534",
        transition: 'opacity 0.3s ease !important',
    }
}));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default function AuditHandler() {
    const classStyle = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();

    function HandlePeriods() {
        history.push('/auditorias/periodos');
        setAnchorEl(null);
    }

    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    return (
        <>
            <SideBarItem urlToRedirect="#" titleTooltip="Auditoria" dispatchAction={handleClick}>
                <>
                    <PageviewOutlinedIcon className={classStyle.sideBarIconStyles} />
                    <Typography className={classStyle.sideBarTextItemStyles}>Auditorias</Typography>
                </>
            </SideBarItem>

            <StyledMenu
                id='customized-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem onClick={() => HandlePeriods()}>
                    <ListItemIcon>
                        <PermMediaIcon />
                    </ListItemIcon>
                    <ListItemText primary='Períodos' />
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
}
