import React from "react";
import { Grid } from "semantic-ui-react";
import "./SelectForm.css";
import { Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-select";
import GridMaterial from "@material-ui/core/Grid";

export default function SelectForm({
  label,
  name,
  menuItemList,
  control,
  actionChange,
  disabled,
  loading,
  error,
  placeholder = "",
  width,
  padding,
  children,
  as = Select,
  onInputChange,
}) {
  return (
    <Grid.Row style={{ padding: padding ?? 8, width: `${width}` }}>
      <FormControl variant="outlined" size="small">
        <GridMaterial container spacing={1} alignItems="center">
          <GridMaterial item style={{ width: 140 }}>
            <span>{label}</span>
          </GridMaterial>
          <GridMaterial item className="filter-input">
            <Controller
              isDisabled={disabled}
              onChange={actionChange}
              className={`width-select-form filter-input ${
                error[name] ? " class-error-select " : ""
              }`}
              as={as}
              placeholder={placeholder}
              noOptionsMessage={() => "No hay registros"}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isLoading={loading}
              isClearable={true}
              isSearchable={true}
              options={menuItemList}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#6a5ef3",
                },
              })}
              onInputChange={onInputChange}
              control={control}
              name={name}
              id={name}
            />
             {children}
          </GridMaterial>
        </GridMaterial>
      </FormControl>
    </Grid.Row>
  );
}
