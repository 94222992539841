import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import FormGroup from "@material-ui/core/FormGroup";
import { Table, Icon, Grid, Button, Card } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../../api";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import CardContent from "@material-ui/core/CardContent";
import { Dimmer, Loader } from "semantic-ui-react";
import SettingExcelEdit from "./SettingExcelEdit";
import "../../../App.scss";

export default function SettingExcel({ setDataToast, dataToast }) {
	const [loadingActive, setLoadingActive] = useState(false);
	const history = useHistory();
	const itemsPerPage = 20;
	const [socialWorks, setSocialWorks] = useState([]);
	const [filtersLoading, setFilterLoading] = useState(false);
	const [idSocialWork, setIdSocialWork] = useState();
	const [loadingSelect, setLoadingSelect] = useState({
		socialWork: true,
	});
	const [paginationData, setPaginationData] = useState({
		page: 1,
		quantity: 0
	});
	const [viewOptions, setViewOptions] = useState(false);
	const [dataSelected, setData] = useState({
		nombre: '',
		modulo: '',
	});
	const [dataTable, setDataTable] = useState();
	const [options, setOptions] = useState({
		Id: '',
		Nombre: '',
		MostrarMatdelProf: false,
		MostrarLic: false,
		MostrarSesiones: false,
		MostrarNumAut: false,
		MostrarNumAfiliado: false,
		MostrarNombrePac: false,
		MostrarDNIPac: false,
		MostrarFechaPraSes: false,
		MostrarFechaPresc: false,
		MostrarMatPresc: false,
		MostrarMedico: false,
		MostrarOS: false,
		MostrarPlanOS: false,
		MostrarFechadeTodaslasSes: false,
		MostrarNumSin: false,
		MostrarDiagnostico: false,
		MostrarCoseguro: false,
		MostrarMonto: false,
	});
	const { register, control, getValues, errors, setValue } = useForm({});
	useEffect(() => {
		reloadData(1, 1, itemsPerPage, true);
	}, []);

	const handleChange = (event) => {
		setOptions({ ...options, [event.target.name]: event.target.checked });
	};
	const shortError = (message) => {
		setDataToast({
			active: true,
			message: message,
			type: 'error',
		});
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	const changePage = (event, value) => {
		reloadData(value, true);
		scrollToTop();
	};

	const getOptionsBySocialWork = (idSocialWork) => {
		setLoadingActive(true);
		api
			.getOptionsBySocialWorkId(idSocialWork)
			.then((res) => {
				let item = res.data;
				let list = [];
				
				list.push({
					idSocialWork: item.id !== 0 ? item.id : idSocialWork,
					enrollmentProfessional: item.mostrarMatriculadelProfesional,
					graduate: item.mostrarLicenciado,
					viewSessions: item.mostrarSesiones,
					authorizationNumber: item.mostrarNumeroAutorizacion,
					afiliateNumber: item.mostrarNumeroAfiliado,
					patientName: item.mostrarNombrePaciente,
					dniPatient: item.mostrarDNIPaciente,
					firstDateSessions: item.mostrarFechaPrimeraSesion,
					datePrescriber: item.mostrarFechaPrescriptor,
					enrollmentPrescriber: item.mostrarMatriculaPrescriptor,
					doctor: item.mostrarMedico,
					socialWork: item.mostrarObraSocial,
					planSocialWork: item.mostrarPlanObraSocial,
					dateAllSesions: item.mostrarFechaTotalSesiones,
					siniesterNumber: item.mostrarNumeroSiniestro,
					diagnostic: item.mostrarDiagnostico,
					coinsecure: item.mostrarCoseguro,
					amount: item.mostrarMonto,
				});

				viewCheckSelected(list);
				scrollToTop();
			})
			.catch((err) => {
				setLoadingActive(false);
				shortError(err.response.data.errores || 'Acaba de ocurrir un error');
			});
	};

	const viewCheckSelected = (list) => {
		let objSave = {};
		let data = [];
		list.forEach((item) => {
			objSave['Id'] = item.idSocialWork;
			objSave['Nombre'] = item.nameSocialWork;
			objSave['MostrarMatdelProf'] = item.enrollmentProfessional;
			objSave['MostrarLic'] = item.graduate;
			objSave['MostrarSesiones'] = item.viewSessions;
			objSave['MostrarNumAut'] = item.authorizationNumber;
			objSave['MostrarNumAfiliado'] = item.afiliateNumber;
			objSave['MostrarNombrePac'] = item.patientName;
			objSave['MostrarDNIPac'] = item.dniPatient;
			objSave['MostrarFechaPraSes'] = item.firstDateSessions;
			objSave['MostrarFechaPresc'] = item.datePrescriber;
			objSave['MostrarMatPresc'] = item.enrollmentPrescriber;
			objSave['MostrarMedico'] = item.doctor;
			objSave['MostrarOS'] = item.socialWork;
			objSave['MostrarPlanOS'] = item.planSocialWork;
			objSave['MostrarFechadeTodaslasSes'] = item.dateAllSesions;
			objSave['MostrarNumSin'] = item.siniesterNumber;
			objSave['MostrarDiagnostico'] = item.diagnostic;
			objSave['MostrarCoseguro'] = item.coinsecure;
			objSave['MostrarMonto'] = item.amount;
		});
		data.push(objSave);
		setOptions(objSave);
		setLoadingActive(false);
		setViewOptions(true);
	};

	const reloadData = (page = 1) => {
		setLoadingActive(true);
		let params = {
			obraSocialId: null,
			asignadas: true,
			dadasDeBaja: false,
			pagina: page,
			cantidad: 20,
			conPaginacion: true
		}

		api
			.getSocialWorks(params)
			.then((res) => {
				setPaginationData({
					page: page,
					quantity: res.data.cantidadPaginas,
				});

				let list = res.data.resultados.map((item) => {
					return{
						id: item.id,
						nameSocialWork: item.alias === null ? item.nombre : item.alias,
					};
				});

				setDataTable(list);
				setLoadingActive(false);
			})
			.catch((err) => {
				shortError('Acaba de ocurrir un error');
				setLoadingActive(false);
			});
	};

	return (
		<>
			{loadingActive && (
				<Dimmer className='loader-fixed' active inverted>
					<Loader size='big'>Cargando..</Loader>
				</Dimmer>
			)}
			{!viewOptions ? (
				<Grid>
					<Grid.Row>
						<Card className='color-border-dash'>
							<CardContent className='center-responsive-filters'>
								<Grid.Row className="header-section">
									<div className="titleAndVideo">
										<span style={{ fontSize: 27 }}>Exportar Excel</span>
									</div>
									<div className="contBtnHeaderSection">
										<Button
											className='btn-new-filter btn-filter-position'
											disabled={!viewOptions}
											icon
											labelPosition='left'
										>
											<Icon name='save' />
											Guardar
										</Button>
									</div>
								</Grid.Row>
							</CardContent>
						</Card>
					</Grid.Row>

					{dataTable && dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={2} className='color-letter-inTable'>
										<TableRow>
											<TableCell
												style={{
													textAlign: 'center',
													width: 200,
												}}
												className='letter-title-table tex '
											>
												<b>Obra Social</b>
											</TableCell>
											<TableCell
												style={{
													textAlign: 'center',
													width: 300,
												}}
												className='letter-title-table'
											>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map(({ id, nameSocialWork }) => (
											<TableRow key={id}>
												<TableCell
													style={{
														textAlign: 'center',
													}}
												>
													{nameSocialWork}
												</TableCell>
												<TableCell
													style={{
														textAlign: 'center',
													}}
												>
													<IconButton
														className='btn-circle-yellow'
														aria-label='edit'
														onClick={() => getOptionsBySocialWork(id)}
													>
														<Tooltip placement="top" title="Editar">
															<EditIcon />
														</Tooltip>
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)}

					{dataTable && dataTable.length === 0 && (
						<Grid.Row style={{ textAlign: '-webkit-center' }}>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<TableCell>
									<h5>No se encontraron resultados</h5>
								</TableCell>
							</TableContainer>
						</Grid.Row>
					)}
					<Grid.Row centered>
						<Pagination
							className='pagination'
							onChange={changePage}
							page={paginationData.page}
							count={paginationData.quantity}
						/>
					</Grid.Row>
				</Grid>
			) : (
				<SettingExcelEdit
					options={options}
					handleChange={handleChange}
					setDataToast={setDataToast}
					setViewOptions={setViewOptions}
					viewOptions={viewOptions}
					dataToast={dataToast}
					setLoadingActive={setLoadingActive}
					loadingActive={loadingActive}
					shortError={shortError}
				></SettingExcelEdit>
			)}
		</>
	);
}
