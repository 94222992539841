export const _DEFAULT_VALUES = {
    _LOADING_FOR_SELECT: {
        socialWorkList: false,
        accountList: false,
        rolList: false,
        associationList: false,
        bankList: false,
        conditionIVA: false,
        seguros: false
    },
    _SELECT_INFO_SELECTED: {
        sexo: {value: null, label: null},
        rol: {value: null, label: null},
        colegio: {value: null, label: null},
        cuenta: {value: null, label: null},
        obraSocial: {value: null, label: null},
        banco: {value: null, label: null},
        seguro: {value: null, label: null},
    },
    _INFO_FOR_SELECT: {
        socialWorkList: [{value: null, label: "Sin Obra Social"}],
        associationList: [{value: null, label: null}],
        accountList: [{value: null, label: null}],
        bankList: [{value: null, label: "No Asignado"}],
        rolList: [{value: null, label: null}],
        conditionIVAList: [{value: null, label: null}],
        seguroList: [{value: null, label: "Sin Seguro"}],
    },
    _DATA_PROFESIONAL: {
        id: 0,
        email: null,
        nombre: null,
        apellido: null,
        rolId: null,
        matricula: null,
        obraSocialId: null,
        clave: null,
        cuit: null,
        sexo: null,
        cuentaId: null,
        condicionIVAId: null,
        colegioId: null,
        bancoId: null,
        administradoPorRegional: false,
        trabajaConObraSocial: false,
        noSocio: false,
        fechaNacimiento: null,
        cbu: null,
        monto: null,
        seguroId: null
    },
    _DATA_FAMILY_PROFESIONAL: {
        id: 0,
        fechaNacimiento: null,
        nombre: null,
        relacion: null,
        estudianteRegular: false,
        estado: true,
        siempreCubierto: false
    },
    _OPEN_MODALS: {
        new: false,
        edit: false,
        delete: false
    },
    _ERROR_OBJ: {
        nombre: false,
        fechaNacimiento: false,
        relacion: false,
        estudianteRegular: false,
    },
    _DESCOUNT_AMOUNT: {
        edad0a18: [{
            nombre: null,
            monto: 0,
            titular: false,
            relacion: null
        }],
        edad19a35: [{
            nombre: null,
            monto: 0,
            titular: false,
            relacion: null
        }],
        edad36a45: [{
            nombre: null,
            monto: 0,
            titular: false,
            relacion: null
        }],
        edad46a55: [{
            nombre: null,
            monto: 0,
            titular: false,
            relacion: null
        }],
        edad56a65: [{
            nombre: null,
            monto: 0,
            titular: false,
            relacion: null
        }],
        edadMas65: [{
            nombre: null,
            monto: 0,
            titular: false,
            relacion: null
        }],
        recAntecEnfermedadesCatastrof: 0,
        total: 0
    },
    _OPEN_MODAL_INFO_DESCOUNT: {
        modal: false,
        viewInfo: false
    }
}