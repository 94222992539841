import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React from 'react'
export default function SwitchComponent({onChange, label, stateOfChecked,name, disabled, abbr}){
    return(
        <FormControlLabel
        disabled={disabled}
        control={
          <Switch
            checked={stateOfChecked}
            onChange={onChange}
            name={name}
            color="primary"
          />
        }
        label={abbr ? <abbr title={label}>{abbr}</abbr> : label}
      />
    )
}