import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IRegional, IRegionalDeleteParams, IRegionalFilterParams, IRegionalPostParams, IRegionalPutParams } from "../../api/Interfaces/managment/ICrudRegional";
import errorHandler from "../errorHandler";

const GetAccounts = async (params: IRegionalFilterParams): Promise<IApiResponse<IRegional[]>> => {
    try{
        const response = await api.GetAccountsList(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const PostAccount = async (params: IRegionalPostParams): Promise<IApiResponse<void>> => {
    try{
        const response = await api.PostAccount(params);
        return { data: {results: undefined}, status: response.status }
    }catch(err){
       return errorHandler(err);
    }
}
const PutAccount = async (id: number, params: IRegionalPutParams): Promise<IApiResponse<void>> => {
    try{
        const response = await api.PutAccount(id, params);
        return {data: {results: undefined}, status: response.status }
    }catch(err){
       return errorHandler(err);
    }
}
const DeleteAccount = async (params: IRegionalDeleteParams): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteAccount(params.id);
        return {data: {results: undefined}, status: response.status }
    }catch(err){
       return errorHandler(err);
    }
}

export {GetAccounts, PostAccount, PutAccount, DeleteAccount}

