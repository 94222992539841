import { Checkbox, DialogContent, DialogContentText, FormControlLabel, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import ReactSelect from 'react-select';
import { Button, Icon } from 'semantic-ui-react';
import { IBank, IBankParam } from '../../api/Interfaces/managment/ICrudBank';
import { StatusCode } from '../../enums';
import { IBankSelect, ICrudBank, ICrudBankFilterParam, ICrudBankModal } from '../../services/interfaces/Managment/ICrudBank';
import { selectTypeFormat } from '../../services/interfaces/utils/IUtilDeclaration';
import { DeleteBank, GetBankSelectList, GetBanks, ReactivateBank } from '../../services/services/Bank';
import ButtonBox from '../utils/ButtonBox';
import GeneralList from '../utils/GeneralList';
import GeneralModalDelete from '../utils/GeneralModalDelete';
import { _DEFAULT_VALUES_BANK, _DEFAULT_VALUES_BANK_FILTER_PARAM, _DEFAULT_VALUES_BANK_MODAL } from './defaultValues';
import NewModalBank from './modals/CrudBank/NewModalBank';
import GeneralModalCrud from '../utils/GeneralModalCrud';
import { useSelector } from 'react-redux';

const CrudBank: React.FC<ICrudBank> = ({setDataToast}) => {
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);
    const _LOADPAGES_DEFAULT = 20;
    const [loadPages, setLoadPages] = React.useState(20);
    const [page, setPage] = React.useState(1);
    const [bankList, setBankList] = React.useState<IBank[]>([]);
    const [bankSelectList, setBankSelectList] = React.useState<selectTypeFormat[]>([]);
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [loaderSelect, setLoaderSelect] = React.useState({banks: false});
    const [filterObj, setFilterObj] = React.useState<ICrudBankFilterParam>(_DEFAULT_VALUES_BANK_FILTER_PARAM);
    const [modals, setModals] = React.useState<ICrudBankModal>(_DEFAULT_VALUES_BANK_MODAL);
    const [bank, setBank] = React.useState<IBankSelect>(_DEFAULT_VALUES_BANK);
    const [reactivateBank, setReactivateBank] = React.useState(false);

    React.useEffect(()=>{
        (hasManagment && LoadDataForTable());
    },[]);

    //#region COMPONENTS UTILS
    const FilterComponents = () => (
        <Grid container direction="row" className="content-filter-list">
            <div className="filter-input">
                <ReactSelect
                    className="filter-input"
                    placeholder="Bancos..."
                    options={bankSelectList}
                    isLoading={loaderSelect.banks}
                    isClearable={true}
                    value={filterObj === null ? null : filterObj.bank}
                    onChange={(e)=>HandleChangeFilterObj(e)}
                />
            </div>
            <FormControlLabel style={{marginLeft: 2}}
					control={
						<Checkbox
							checked={filterObj.dadosDeBaja}
							onChange={(e) => {
                                setFilterObj({
                                    ...filterObj,
                                    dadosDeBaja: e.target.checked
                                })
                            }}
						/>
					}
					label='Dados de Baja'
				/>
        </Grid>
    );
    const ButtonNew = () =>(
        <Button
            className='btn-new-filter'
            onClick={()=>setModals({...modals, new: true})}
            icon
            labelPosition='left'
            >
            <Icon name='plus' />
            Nuevo
        </Button>
    );
    //#endregion

    //#region GET / GETSELECTLIST / DELETE
    async function LoadDataForTable(pageSelected = 1){
        try{
            setLoadingActive(true);
            const params: IBankParam = {
                idBanco: filterObj?.bank?.value ?? null,
                dadosDeBaja: filterObj.dadosDeBaja,
                page: pageSelected,
                pageSize: _LOADPAGES_DEFAULT
            }
            const {data, status} = await GetBanks(params);
            if (status === StatusCode.Ok){
                setBankList(data.results);
                setPage(pageSelected);
                setLoadPages(data.quantity ?? 20);
                setReactivateBank(filterObj.dadosDeBaja);
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            LoadDataForSelect();
            setLoadingActive(false);
        }
    }
    async function LoadDataForSelect(){
        try{
            setLoaderSelect({banks: true});
            const {data, status} = await GetBankSelectList();
            if (status === StatusCode.Ok){
                const bankList: selectTypeFormat[] = data.results.map(bank => {
                    return {value: bank.id, label: bank.nombre}
                })
                setBankSelectList(bankList);
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoaderSelect({banks: false});
        }
    }
    async function HandleReactivateBank(){
        try{
            setLoadingActive(true);
            const {data, status} = await ReactivateBank(bank.id);
            if (status === StatusCode.Ok){
                HandleCloseModal();
                MessageNotification("Banco reactivado correctamente", "success");
                LoadDataForTable();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function HandleDeleteBank(){
        try{
            setLoadingActive(true);
            const {data, status} = await DeleteBank(bank.id);
            if (status === StatusCode.Ok){
                HandleCloseModal();
                MessageNotification("Banco eliminado correctamente", "success");
                LoadDataForTable();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function HandleChangePage(e, pageSelected: number){
        setPage(pageSelected);
        LoadDataForTable(pageSelected);
    }
    function HandleChangeFilterObj(bankSelected: selectTypeFormat){
        setFilterObj({
            ...filterObj,
            bank: bankSelected === null ? null : bankSelected
        });
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error",
            type
        });
    }
    function HandleSetValuesToModal(item: IBank, isDelete: boolean = false, isReactivate: boolean = false){
        setBank({
            id: item.id,
            nombre: item.nombre
        });
        if (isDelete){
            setModals({...modals, delete: true});
        }else if (isReactivate){
            setModals({...modals, reactivate: true});
        }else{
            setModals({...modals, edit: true});
        }
    }
    function HandleCloseModal(){
        setModals(_DEFAULT_VALUES_BANK_MODAL);
        setBank(_DEFAULT_VALUES_BANK);
    }
    //#endregion

    return (
        <>
        <GeneralList
        titleOfSection='Bancos'
        isLoaderActive={loadingActive}
        dataToRender={bankList}
        initialPage={page}
        numPagesToRender={loadPages}
        HandleSearch={LoadDataForTable}
        ButtonNew={ButtonNew}
        FilterComponent={FilterComponents}
        onChangePage={HandleChangePage}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Nombre</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bankList.map((item: any) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.nombre}
                            </TableCell>
                            <TableCell className='p-cell'>
                                <Button.Group>
                                    {
                                    reactivateBank ?
                                        <ButtonBox buttonToShow='reactivate' name="reactivate" HandleOnClik={(e)=>HandleSetValuesToModal(item, false, true)}/>
                                    :
                                    <>
                                        <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e)=>HandleSetValuesToModal(item)}/>
                                        <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e)=>HandleSetValuesToModal(item, true)}/>
                                    </>
                                    }
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>

        {modals.new && 
        <NewModalBank 
        titleOfModal="Nuevo"
        bank={bank}
        openModal={modals.new}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        HandleCloseModal={HandleCloseModal}
        MessageNotification={MessageNotification}
        ReloadData={LoadDataForTable}
        />}

        {modals.edit && 
        <NewModalBank 
        titleOfModal="Editar"
        bank={bank}
        openModal={modals.edit}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        HandleCloseModal={HandleCloseModal}
        MessageNotification={MessageNotification}
        ReloadData={LoadDataForTable}
        />}

        {modals.delete &&
        <GeneralModalDelete
        contentText={`¿Está seguro de eliminar a ${bank.nombre}?`}
        openModal={modals.delete}
        HandleClose={HandleCloseModal}
        HandleSubmit={HandleDeleteBank}
        />}

         {modals.reactivate &&
        <GeneralModalCrud
        titleOfModal='Reactivar'
        openModal={modals.reactivate}
        HandleCloseModal={HandleCloseModal}
        HandleSubmit={HandleReactivateBank}
        >
            <DialogContentText>
                {`¿Está seguro de reactivar a ${bank.nombre}?`}
            </DialogContentText>
        </GeneralModalCrud>}

        </>
    )
}

export default CrudBank;