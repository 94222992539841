import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
  name: "document",
  initialState: {
    dataTableDash: [],
    filtersDash: {},
    dataOfPatient: {}
  },
  reducers: {
    setDataOfPatient(state, {payload}){
      state.dataOfPatient =  payload;
    },
    setDataTable(state, { payload }) {
      state.dataTableDash = payload.dataTable;
    },
    setFilters(state, { payload }) {
      state.filtersDash = payload;
    },
  },
});

export const { setDataTable, setDataOfPatient } = actions;

export default reducer;
