import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IConditionIVA } from "../../api/Interfaces/configurations/IConditionIVA";
import { IAccountSummary, IUser, IUserList, IUserParams } from "../../api/Interfaces/configurations/IUsers";
import errorHandler from "../errorHandler";

const GetProfesionals = async (params: IUserParams): Promise<IApiResponse<IUserList[]>> => {
    try{
        const response = await api.getUsersList(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetProfesionalById = async (profesionalId: number): Promise<IApiResponse<IUser>> => {
    try{
        const response = await api.GetUserById(profesionalId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateProfesional = async (params: any): Promise<IApiResponse<number>> => {
    try{
        const response = await api.PostUser(params);
        return {
            data: {results: response.data.id},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const UpdateProfesional = async (profesionalId: number, params: any): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateUser(profesionalId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivateUser = async (userId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivateUser(userId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetAccountSummaryByUserId = async (userId: number): Promise<IApiResponse<IAccountSummary[]>> => {
    try{
        const response = await api.GetAccountSummaryByUserId(userId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetConditionIVA = async (): Promise<IApiResponse<IConditionIVA[]>> => {
    try{
        const response = await api.GetConditionIVA();
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {GetProfesionals, 
        CreateProfesional, 
        UpdateProfesional, 
        GetProfesionalById,
        ReactivateUser,
        GetAccountSummaryByUserId,
        GetConditionIVA}