import React from 'react';

const CardInfo: React.FC<any> = ({
    title,
    info,
    aditionalText,
    icon
}) => {
    return (
    <div className='cont-card'>
        <section className='cont-card-prop-info'>
            <article className='cont-card-prop-info--flex'>
                <figure className='cont-card-prop-logo'>
                    <img src={icon} alt="Beneficio de Octus en logo" className='cont-card-prop-logo__img'/>
                </figure>
            <h4 className='cont-card-prop-info__title'>{title}</h4>
            </article>
            <article className='cont-card-prop-info-details'>
                <p className='cont-card-prop-info-details__text'>{info}</p>
                <b className='cont-card-prop-info-details__text--bColor'>{aditionalText}</b>
            </article>
        </section>
    </div>
    )
}

export default CardInfo