import React from 'react';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { InputAdornment, TextField } from '@material-ui/core';
import { IErrorNotification, INewProfitWithHoldings, IProfitWithHoldingsRequest } from '../../../../services/interfaces/Managment/IProfitWithHoldings';
import { CreateProfit, EditProfit } from '../../../../services/services/ProfitWithHoldings';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { StatusCode } from '../../../../enums';
import { _DEFAULT_ERROR_NOTIFICATION, _DEFAULT_VALUES_PROFIT } from '../../defaultValues';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';

const NewProfitWithHoldings: React.FC<INewProfitWithHoldings> = ({
    titleOfModal,
    openModal,
    isCreate,
    values,
    HandleCloseModal,
    MessageNotification,
    ReloadData
})=>{
    const [params, setParams] = React.useState<IProfitWithHoldingsRequest>(()=>{
        return {
            aPesos: values.aPesos,
            masElPorcentaje: values.masElPorcentaje,
            masDePesos: values.masDePesos,
            pesos: values.pesos,
            sobreExcluido: values.sobreExcluido
        }
    });
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [errorNotification, setErrorNotification] = React.useState<IErrorNotification>(_DEFAULT_ERROR_NOTIFICATION);

    async function HandleSubmit(){
        let isValidated = ValidateValues();
        if (isValidated){
            (isCreate ? Create() : Edit())
        }
    }   
    function ValidateValues(){
        let isValidated = true;
        const allValues = Object.entries(params);
        let objError: any = {}

        if (allValues.some(input => input[1] === null)){
            isValidated = false;
            MessageNotification("Todos los campos deben tener valor.", "error");
            allValues.forEach(input => {
                if (+input[1] < 0) objError = {...objError, [input[0]]: true}
            });
        }else{
            if (allValues.some(input => input[1] < 0)){
                isValidated = false;
                MessageNotification("Los valores deben ser números positivos.", "error");
                allValues.forEach(input => {
                    if (+input[1] < 0) objError = {...objError, [input[0]]: true}
                });
            }
        }
        
        //validaciones para rangos
        if (params.aPesos < params.masDePesos){
            MessageNotification("El campo 'A Pesos' debe ser mayor al campo 'Más de Pesos'.", "error");
            isValidated = false;
            objError = {"aPesos": true}
        }
        
        if (params.pesos > params.masDePesos || params.pesos > params.sobreExcluido){
            MessageNotification("El campo 'Pesos' no puede ser mayor al campo 'Más de Pesos' ni al campo 'Sobre Excluido'.", "error");
            isValidated = false;
            objError = {"pesos": true}
        }
        
        if (params.sobreExcluido !== params.masDePesos){
            MessageNotification("El campo 'Sobre Excluido' debe ser igual al campo 'Más de Pesos'.", "error");
            isValidated = false;
            objError = {"sobreExcluido": true}
        }

        if (Object.values(objError).length > 0){
            setErrorNotification(objError);
        }

        return isValidated;
    }
    async function Create(){
         try{
            setLoadingActive(true);
            const {data, status} = await CreateProfit(params);
            if (status === StatusCode.Ok){
                MessageNotification("Valores asignados correctamente", "success");
                HandleCloseModal();
                ReloadData();
            }
         }catch(err: any){
            MessageNotification(err.response.data.errores ?? "Ocurrio un error.", "error");
         }finally{
            setLoadingActive(false);
         }
    }
    async function Edit(){
        try{
            setLoadingActive(true);
            const {status} = await EditProfit(values.id, params);
            if (status === StatusCode.Ok){
                MessageNotification("Valores editados correctamente", "success");
                HandleCloseModal();
                ReloadData();
            }
         }catch(err: any){
            MessageNotification(err.response.data.errores ?? "Ocurrio un error.", "error");
         }finally{
            setLoadingActive(false);
         }
    }
 
    function HadleChangeInput(e:any){
        setParams({
            ...params,
            [e.target.name]: +e.target.value
        });
    }
    
    return(
        <>
        {loadingActive ? 
        <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando...</Loader>
        </Dimmer>
        :
        <GeneralModalCrud
        titleOfModal={titleOfModal}
        openModal={openModal}
        HandleCloseModal={HandleCloseModal}
        HandleSubmit={HandleSubmit}
        styles="cont-default-modal"
        >
            <TextField
            value={params.masDePesos}
            label='Más de Pesos'
            name="masDePesos"
            type='number'
            error={errorNotification.masDePesos}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon/>
                </InputAdornment>
                ),
            }}
            onChange={(e)=>HadleChangeInput(e)}
             />
             <TextField
            value={params.aPesos}
            label='A Pesos'
            name="aPesos"
            type='number'
            error={errorNotification.aPesos}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon/>
                </InputAdornment>
                ),
            }}
            onChange={(e)=>HadleChangeInput(e)}
             />
             <TextField
            value={params.pesos}
            label='Pesos'
            name="pesos"
            type='number'
            error={errorNotification.pesos}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon/>
                </InputAdornment>
                ),
            }}
            onChange={(e)=>HadleChangeInput(e)}
             />
             <TextField
            value={params.masElPorcentaje}
            label='Más el porcentaje'
            name="masElPorcentaje"
            type='number'
            error={errorNotification.masElPorcentaje}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <Icon name="percent"/>
                </InputAdornment>
                ),
            }}
            onChange={(e)=>HadleChangeInput(e)}
             />
             <TextField
            value={params.sobreExcluido}
            label='Sobre el Excedente'
            name="sobreExcluido"
            type='number'
            error={errorNotification.sobreExcluido}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon/>
                </InputAdornment>
                ),
            }}
            onChange={(e)=>HadleChangeInput(e)}
             />
        </GeneralModalCrud>
        }
        </>
    )
}

export default NewProfitWithHoldings;