import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import ReactSelect from 'react-select';
import { Button, Grid } from 'semantic-ui-react';
import { IAmountPractice, IPracticeAmount } from '../../../../api/Interfaces/configurations/IAmounts';
import { ISocialWorkSelectListRequest } from '../../../../api/Interfaces/configurations/ISocialWork';
import { StatusCode } from '../../../../enums';
import { selectTypeFormat } from '../../../../services/interfaces/utils/IUtilDeclaration';
import { GetAmountForPractices, GetHistorialAmount } from '../../../../services/services/Amounts';
import { GetSocialWorkSelect } from '../../../../services/services/ConfigurationSocialWork';
import ButtonBox from '../../../utils/ButtonBox';
import GeneralList from '../../../utils/GeneralList';
import ViewHistorialAmount from './modals/ViewHistorialAmount';

const _DEFAULT_VALUES = {
    _FILTER_OBJ: {
        socialWork: {value: null, label: null},
        actualAmount: false
    },
    _LOADER_SELECT: {
        socialWork: false
    },
    _MODALS: {
        viewHistorial: {show: false, data: null}
    }
}

const AmountHistorial: React.FC<any> = ({setDataToast}) => {
    const [isLoaderActive, setIsLoaderActive] = React.useState(false);
    const [practiceAmountData, setPracticeAmountData] = React.useState<IPracticeAmount[]>([]);
    const [practiceAmountHistorialData, setPracticeAmountHistorialData] = React.useState<IAmountPractice[]>([]);
    const [openModal, setOpenModal] = React.useState(_DEFAULT_VALUES._MODALS);
    const [socialWorkList, setSocialWorkList] = React.useState<selectTypeFormat[]>([]);
    const [loaderSelect, setLoaderSelect] = React.useState(_DEFAULT_VALUES._LOADER_SELECT);
    const [filterObj, setFilterObj] = React.useState(_DEFAULT_VALUES._FILTER_OBJ);

    React.useEffect(()=>{
        FiltroListaOs();
    },[]);

    async function FiltroListaOs(isDeleted = false){
		setLoaderSelect({...loaderSelect, socialWork: true});
		try{
			let params: ISocialWorkSelectListRequest = {
				dadasDeBaja: isDeleted
			}
			const {status, data} = await GetSocialWorkSelect(params);
			const listOs = data.results.map(item =>{
				return{value: item.id, label: item.nombre}
			});
			if (status === StatusCode.Ok){
				setSocialWorkList(listOs);
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores,"error");
		}
		finally{
			setLoaderSelect({...loaderSelect, socialWork: false});
		}
	}
    async function GetAmountList(){
        try{
            setIsLoaderActive(true);
            const {data, status} = await GetAmountForPractices(filterObj.socialWork.value);
            if (status === StatusCode.Ok){
                setPracticeAmountData(data.results);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{   
            setIsLoaderActive(false);
        }
    }
    async function GetAmountHistorialList(practicaObraSocialCuentaId: number){
         try{
            setIsLoaderActive(true);
            const {data, status} = await GetHistorialAmount(filterObj.socialWork.value, practicaObraSocialCuentaId);
            if (status === StatusCode.Ok){
                setPracticeAmountHistorialData(data.results);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{   
            setIsLoaderActive(false);
        }
    }
    function HandleSearch(){
        if (filterObj.socialWork.value === null) {
            MessageNotification("Debe seleccionar una Obra Social.", "error");
            return
        }
        GetAmountList();
    }
    function HandleSearchHistorialAmount(practicaObraSocialCuentaId: number){
        if (filterObj.socialWork.value === null) {
            MessageNotification("Debe seleccionar una Obra Social.", "error");
            return
        }
        GetAmountHistorialList(practicaObraSocialCuentaId);
        setOpenModal({...openModal, viewHistorial: {show: true, data: null}});
    }
    function HandleCloseModals(){
        setOpenModal(_DEFAULT_VALUES._MODALS);
    }
    function FilterComponents(){
        return(
            <Grid.Row className="content-filter-list">
                <ReactSelect
                className="filter-input"
                options={socialWorkList}
                placeholder="Obras sociales..."
                isLoading={loaderSelect.socialWork}
                isClearable={true}
                value={filterObj.socialWork.value === null ? null : filterObj.socialWork}
                onChange={(e: any)=> setFilterObj({
                    ...filterObj,
                    socialWork: e !== null ? e : _DEFAULT_VALUES._FILTER_OBJ.socialWork
                })}
                />
            </Grid.Row>
        );
    }
    function MessageNotification(message: string, type: string){
		setDataToast({
				active: true,
				message: message || "Acaba de ocurrir un error",
				type: type,
			});
	}

    return(
        <>
            <GeneralList
            titleOfSection='Montos de Obras sociales'
            dataToRender={practiceAmountData}
            isLoaderActive={isLoaderActive}
            HandleSearch={HandleSearch}
            FilterComponent={FilterComponents}
            >
                <Table aria-label='spanning table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Fecha</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Práctica</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Monto</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Opciones</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {practiceAmountData.map(amount => (
                        <TableRow key={amount.id}>
                            <TableCell className='c-text-center p-cell'>
                                {new Date(amount.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='c-text-center p-cell'>
                                {amount.practica.alias ?? amount.practica.nombre}
                            </TableCell>
                            <TableCell className='c-text-center p-cell'>
                                {amount.monto === 0 || amount.monto === null ? "No asignado a la fecha" : `$ ${amount.monto}`}
                            </TableCell>
                            <TableCell className='c-text-center p-cell' >
                                <Button.Group>
                                    <ButtonBox buttonToShow='viewAmountHistorial' name='viewAmountHistorial' HandleOnClik={()=>{HandleSearchHistorialAmount(amount.practica.practicaObraSocialCuentaId)}}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </GeneralList>

            <ViewHistorialAmount
            openModal={openModal.viewHistorial.show}
            HandleCloseModal={HandleCloseModals}
            practiceAmountHistorialData={practiceAmountHistorialData}
            />
        </>
    )
}

export default AmountHistorial;