import React from "react";
import { Button } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DialogDelete({
  open,
  setOpen,
  title,
  content,
  ButtonOpen,
  actionConfirm,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
		<div>
			{ButtonOpen && <ButtonOpen />}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancelar</Button>
					<Button onClick={actionConfirm} color='red' autoFocus>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
