import { Tooltip, makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../api';
import { Options, ValidationObjRegex } from '../../../enums';

export default function CrudAssociation({ setDataToast }) {
	const [page, setPage] = useState(1);
	const [loadingActive, setLoadingActive] = useState();
	const [totalCount, setTotalCount] = useState();
	const [viewDate, setViewDate] = useState();
	const [dataTable, setDataTable] = useState([]);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [openNew, setOpenNew] = React.useState(false);
	const [provinceList, setProvinceList] = useState([]);
	const [isCreate, setCreate] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [newValue, setNewValue] = useState({
		nombre: null, 
		cuit: null, 
		correo: null,
		provinciaId: null
	});
	const [associationFilter, setAssociationFilter] = useState({
		nombre: null,
		provinciaId: null
	});
	const [editValues, setEditValues] = useState({
		id: null,
		correo: null,
		nombre: null,
		cuit: null,
		provincia: {
			id: null,
			nombre: null
		}

	});
	const [deleteDialog, setDeleteDialog] = useState({
		open: false,
		id: null,
	});

	//#region UTILS
	function ValidationValues(nombre, cuit, provinciaId, correo){
		let isValidated = true;

		if (!ValidationObjRegex.emailValidation.test(correo)){
			MessageNotification("Formato de correo inválido.", "error");
			isValidated = false;
		}
		
		if (cuit !== null) {
			if (isNaN(cuit)){
				MessageNotification("El campo 'CUIT' deben de ser digitos numéricos.", "error");
				isValidated = false;
			}
			if (cuit.length >= 12 || cuit.length < 11){
				MessageNotification('El campo "CUIT" debe tener 11 digitos.', 'error');
				isValidated = false;
			}
		} 
		if (nombre === null || cuit === null || provinciaId === null){
			MessageNotification('Todos los campos son obligatorios', 'error');
			isValidated = false;
		}
		return isValidated;
	}
	const handleChange = (event, value) => {
		setPage(value);
		OnClickView(value);
	};
	const handleCloseEdit = () => {
		setOpenEdit(false);
	};
	const handleCloseNew = () => {
		setOpenNew(false);
		setNewValue({nombre: null, cuit: null, provinciaId: null});
	};
	const handleClickOpen = () => {
		setOpenNew(true);
	};
	function preDelete(id, nombre) {
		setDeleteDialog({ open: true, id: id, nombre: nombre });
	}
	function closePreDelete() {
		setDeleteDialog({ ...deleteDialog, open: false });
	}
	function MessageNotification(message, type) {
		setDataToast({
			active: true,
			message: message ?? 'Ocurrió un error.',
			type: type,
		});
	}
	function GetProvinces(){
		api.getProvinces(associationFilter.provinciaId)
		.then(resp=>{
			const provinceList = resp.data.map(item=> {
				return{id: item.id, label: item.nombre, value: item.nombre}
			})
			setProvinceList(provinceList);
		})
		.catch(err=> MessageNotification(err.response.data.errores,'error'))
	}
	//#endregion

	//#region METODOS LISTAR - EDITAR - ELIMINAR
	function OnClickView(nroPage = 1) {
		setLoadingActive(true);
		api
		.GetAllAssociations(associationFilter)
		.then((res) => {
			const dataTable = res.data.map((item) => {
				return{
					id: item.id,
					nombre: item.nombre,
					cuit: item.cuit,
					correo: item.correo,
					provincia: {
						id: item.provincia.id,
						nombre: item.provincia.nombre
					}
				}
				
			});
			
			//TO DO: si se quiere paginado hay que paginar el metodo desde la API
			setTotalCount(1);
			setDataTable(dataTable);
		})
		.catch((err) => {
			MessageNotification(err.response.data.errores, 'error');
			})
			.finally(()=>{
				setLoadingActive(false);
			})
	}	
	function GetAssociationId(id) {
		setLoadingActive(true);
		api
		.getAssociationById(id)
		.then((res) => {
			const data = res.data;
			setEditValues({
				id: data.id,
				nombre: data.nombre,
				cuit: data.cuit,
				correo: data.correo,
				provincia: {
					id: data.provincia.id,
					nombre: data.provincia.nombre
				}
			});
			setOpenEdit(true);
			setViewDate(true);
		})
		.catch((err) => {
			setViewDate(false);
			MessageNotification(err.response.data.errores, 'error');
		})
		.finally(()=>{setLoadingActive(false);})
	}	
	function Edit() {
		
		let isValidated = ValidationValues(editValues.nombre, editValues.cuit, editValues.provincia.id, editValues.correo);

		if (isValidated){
			setOpenEdit(false);
			setLoadingActive(true);
			const body = {
				nombre: editValues.nombre,
				cuit: +editValues.cuit,
				provinciaId: editValues.provincia.id,
				correo: editValues.correo
			};
			api
			.putAssociation(editValues.id, body)
			.then(() => {
				OnClickView();
				MessageNotification('Colegio editado correctamente.', 'success');
			})
			.catch((err) => {
				MessageNotification(err.response.data.errores, 'error');
			})
			.finally(()=>{
				setLoadingActive(false);
			})
		}
	}	
	function OnClickCreate(){
		
		let isValidated = ValidationValues(newValue.nombre, newValue.cuit, newValue.provinciaId, newValue.correo);

		if (isValidated){
			setOpenNew(false);
			setLoadingActive(true);
			const body = {
			nombre: newValue.nombre,
			cuit: +newValue.cuit,
			provinciaId: newValue.provinciaId,
			correo: newValue.correo
			};

			api
			.postAssociations(body)
			.then(() => {
				MessageNotification('Colegio creado correctamente.','success');
				OnClickView();
			})
			.catch((err) => {
				MessageNotification(err.response.data.errores, 'error');
			})
			.finally(()=>{
				setNewValue({nombre: null, cuit: null, provinciaId: null});
				setLoadingActive(false);
			})
		}	
	}	
	function DeleteAssociation(id) {
		closePreDelete();
		setLoadingActive(true);
		api
		.deleteAssociation(id)
		.then(() => {
			OnClickView();
			MessageNotification('Colegio eliminado correctamente.','success');
		})
		.catch((err) => {
			MessageNotification(err.response.data.errores, 'error');
		})
		.finally(()=>{
			setLoadingActive(false);
		})
	}
	//#endregion

	useEffect(() => {
		let options = JSON.parse(localStorage.getItem('optionCruds'));
		options.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
		GetProvinces();
		OnClickView(page);
	}, []);
	
	return (
		<>
			<Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className='header-section'>
								 <div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>Colegios</span>
								</div>
								 <div className="contBtnHeaderSection">
									{isCreate == true && 
										<Button
											className='btn-new-filter'
											onClick={() => handleClickOpen()}
											icon
											labelPosition='left'
										>
											<Icon name='plus' />
											Nuevo
										</Button>}
								 </div>
							</Grid.Row>
							<Grid.Row className="content-filter-list">
								<TextField
									className='filter-input'
									size='small'
									id='nombre'
									label='Nombre'
									value={associationFilter.nombre}
									onChange={(e) => {
										setAssociationFilter({
											...associationFilter,
											nombre: e.target.value,
										});
									}}
									variant='outlined'
								/>
								<Select
									className='filter-input'
									placeholder='Provincias...'
									isClearable={true}
									options={provinceList}
									onChange={(e)=>setAssociationFilter({...associationFilter, provinciaId: e === null ? null : e.id})}
								/>
							</Grid.Row>
							<Button
							className='btn-search-filter'
							primary
							icon
							labelPosition='left'
							onClick={OnClickView}
							type='submit'
							>
								<Icon name='search' />
								Buscar
							</Button>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={2} className='color-letter-inTable'>
										<TableRow>
											<TableCell className='letter-title-table c-text-center'>
												<b>Nombre</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>CUIT</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>Provincia</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>Correo</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center'>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map((colegio) => (
											<TableRow key={colegio.id}>
												<TableCell className='c-text-center'>
													{colegio.nombre}
												</TableCell>
												<TableCell className='c-text-center'>
													{colegio.cuit ?? "--"}
												</TableCell>
												<TableCell className='c-text-center'>
													{colegio.provincia.nombre ?? "--"}
												</TableCell>
												<TableCell className='c-text-center'>
													{colegio.correo ?? "--"}
												</TableCell>
												<TableCell className='c-text-center'>
													{isEdit == true && (
														<IconButton
															className='btn-circle-orange'
															onClick={() => GetAssociationId(colegio.id)}
														>
															<Tooltip placement='top' title="Editar">
																<CreateIcon />
															</Tooltip>
														</IconButton>
													)}
													{isDelete == true && (
														<IconButton
															className='btn-circle-red'
															onClick={() =>
																preDelete(colegio.id, colegio.nombre)
															}
														>
															<Tooltip placement='top' title="Eliminar">
																<DeleteIcon />
															</Tooltip>
														</IconButton>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
				{dataTable && (
					<Grid.Row centered>
						<Pagination
							className='pagination'
							count={totalCount}
							page={page}
							onChange={handleChange}
						/>
					</Grid.Row>
				)}
			</Grid>

			<Dialog
			open={openNew}
			onClose={handleCloseNew}
			aria-labelledby='form-dialog-title'
			>
			<DialogTitle id='form-dialog-title'>Nuevo</DialogTitle>
			<DialogContent style={{display: "grid", gap: 20, overflow: "visible"}}>
				<TextField
					style={{ marginRight: 5 }}
					size='small'
					id='nombre'
					label='Nombre'
					value={newValue.nombre}
					onChange={(e) => {setNewValue({...newValue, nombre: e.target.value.trim() === "" ? null : e.target.value})}}
					variant='outlined'
				/>
				<TextField
					style={{ marginRight: 5 }}
					size='small'
					id='cuit'
					label='CUIT'
					value={newValue.cuit}
					onChange={(e) => {setNewValue({...newValue, cuit: e.target.value.trim() === "" ? null : e.target.value})}}
					variant='outlined'
				/>
				<TextField
					style={{ marginRight: 5 }}
					size='small'
					id='correo'
					label='Correo'
					value={newValue.correo}
					onChange={(e) => {setNewValue({...newValue, correo: e.target.value.trim() === "" ? null : e.target.value})}}
					variant='outlined'
				/>
				<Select
					placeholder='Provincias...'
					options={provinceList}
					isClearable={true}
					onChange={(e)=>setNewValue({...newValue, provinciaId: e === null ? null : e.id})}
				/>
			</DialogContent>

			<DialogActions
				style={{
					textAlign: 'center',
					justifyContent: 'center',
				}}
			>
				<Button onClick={handleCloseNew}>
					Cancelar
				</Button>
				<Button onClick={OnClickCreate} color='blue'>
					Aceptar
				</Button>
			</DialogActions>
			</Dialog>				

			{viewDate && (
				<Dialog
					open={openEdit}
					onClose={handleCloseEdit}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
					<DialogContent style={{display: "grid", gap: 20, overflow: "visible"}}>
						<TextField
							style={{ marginRight: 5 }}
							size='small'
							id='nombre'
							label='Nombre'
							value={editValues.nombre}
							onChange={(e) => {
								setEditValues({
									...editValues,
									nombre: e.target.value.trim() === "" ? null : e.target.value,
								});
							}}
							variant='outlined'
						/>
						<TextField
							style={{ marginRight: 5 }}
							size='small'
							id='cuit'
							label='CUIT'
							value={editValues.cuit}
							onChange={(e) => {
								setEditValues({
									...editValues,
									cuit: e.target.value.trim() === "" ? null : e.target.value,
								});
							}}
							variant='outlined'
						/>
						<TextField
							style={{ marginRight: 5 }}
							size='small'
							id='correo'
							label='Correo'
							value={editValues.correo}
							onChange={(e) => {
								setEditValues({
									...editValues,
									correo: e.target.value.trim() === "" ? null : e.target.value,
								});
							}}
							variant='outlined'
						/>
						<Select
							placeholder={`${editValues.provincia.nombre === null ? "Provincias..." : editValues.provincia.nombre}`}
							options={provinceList}
							isClearable={true}
							onChange={(e)=>setEditValues({...editValues, provincia: {id: e === null ? null : e.id, nombre: e === null ? null : e.nombre}})}
						/>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={handleCloseEdit}>
							Cancelar
						</Button>

						<Button onClick={Edit} color='blue'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{deleteDialog && (
				<Dialog open={deleteDialog.open} onClose={closePreDelete}>
					<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
					<DialogContent>
						<DialogContentText>
							¿Está seguro de eliminar el colegio {deleteDialog.nombre}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={closePreDelete}>Cancelar</Button>
						<Button
							onClick={() => DeleteAssociation(deleteDialog.id)}
							color='red'
						>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}
