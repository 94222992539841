import { InputAdornment, TextField } from '@material-ui/core';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import React from 'react';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';

const EditManualDebitByBill: React.FC<any> = ({
    openModal,
    dataModal,
    MessageNotification,
    setDataOfBill,
    HandleCloseAllModals,
})=> {
    const [values, setValues] = React.useState<any>({monto: dataModal.profesional.montoDebitoManual ?? 0, motivo: dataModal.profesional.motivoDebitoManual ?? ""});
    const [errorNotification, setErrorNotification] = React.useState<any>({});

    function HandleSubmitValues(){
        if (ValidateValues()){
            setDataOfBill((prevState)=>({
              ...prevState,
              usuarioFactura: prevState.usuarioFactura.map(userBill => {
                  if (userBill.UsuarioId === dataModal.profesional.UsuarioId){
                      return {
                          ...userBill,
                          montoDebitoManual: +values.monto,
                          motivoDebitoManual: values.motivo
                      }
                  }else return userBill
              })
            }));
            HandleCloseAllModals();
        }
    }
    function ValidateValues(){
        let objError: any = {};
        let isValidated = true;
        if ((values.monto === 0 || values.monto.length > 7) && values.motivo === ""){
            MessageNotification("Debe ingresar valores válidos","error");
            isValidated = false;
            objError = {motivo: true, monto: true}
        }else{
            if (values.monto <= 0 || +values.monto > 9999999){
                MessageNotification("El campo 'Monto' debe ser mayor a cero y menor a 9.999.999.","error");
                isValidated = false;
                objError = {monto: true}
            }
            if (values.motivo === ""){
                MessageNotification("El campo 'Motivo' es obligatorio.","error");
                isValidated = false;
                objError = {motivo: true}
            }
        }
        setErrorNotification(objError);
        return isValidated;
    }
    function HandleChangeInput(e){
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }
    
    return(
    <GeneralModalCrud
    titleOfModal='Editar'
    HandleCloseModal={HandleCloseAllModals}
    HandleSubmit={HandleSubmitValues}
    openModal={openModal}
    >
        <TextField
        value={values.monto}
        label='Monto'
        name="monto"
        type='number'
        variant='outlined'
        size='small'
        error={errorNotification.monto}
        InputLabelProps={{shrink: true}}
        InputProps={{
            startAdornment: (
            <InputAdornment position="start">
                <AttachMoneyOutlinedIcon/>
            </InputAdornment>
            ),
            inputProps: {max: 9999999, min: 0}}
        }
        onChange={(e)=>HandleChangeInput(e)}
        />
        <TextField
        value={values.motivo}
        label='Motivo'
        name="motivo"
        type='text'
        variant='outlined'
        size='small'
        error={errorNotification.motivo}
        InputLabelProps={{shrink: true}}
        onChange={(e)=>HandleChangeInput(e)}
        />
    </GeneralModalCrud>);
}

export default EditManualDebitByBill;