import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
    name: 'modal',
    initialState: {
        integrationModal: false,
        socialWorkWithholdingsModal: false,
        unpaidBillsModal: false,
        billsPerPeriodModal: false,
        exportLiquidationModal: false,
    },
    reducers: {
        setModalIntegration(state, { payload }) {
            state.integrationModal = payload
        },
        setModalSocialWorkWithholdings(state, { payload }) {
            state.socialWorkWithholdingsModal = payload
        },
        setModalUnpaidBills(state, { payload }) {
            state.unpaidBillsModal = payload
        },
        setModalBillsPerPeriod(state, { payload }) {
            state.billsPerPeriodModal = payload
        },
        setModalExportLiquidation(state, { payload }) {
            state.exportLiquidationModal = payload
        },
    }
});

export const { setModalIntegration, setModalSocialWorkWithholdings, setModalUnpaidBills, setModalBillsPerPeriod, setModalExportLiquidation } = actions;
export default reducer;