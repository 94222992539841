import React from 'react'
import { IMenuButton } from '../../../services/interfaces/MenuButton/IMenuButton';
import "./menuButton.css";
const MenuButton: React.FC<IMenuButton> = ({openMenu, label, stateOfSideBar})=>{
    return(
        <div className='contentBtn'>
            <button className='btn' onClick={()=> openMenu(prev => !prev)}>
                <span className={`btn__item ${stateOfSideBar ? "activo" : ""} btn__part1`}></span>
                <span className={`btn__item ${stateOfSideBar ? "activo" : ""} btn__part2`}></span>
                <span className={`btn__item ${stateOfSideBar ? "activo" : ""} btn__part3`}></span>
            </button>
            <span className='btn__label'>{label}</span>
        </div>
    )
}

export default MenuButton;