import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import GeneralModalShow from '../../../utils/GeneralModalShow';
import { IModalNotificationDescountOs } from '../../../../services/interfaces/Configurations/IModalNotificationDescountOs';

const styles = makeStyles(()=>({
    detailParamText: {
        color: "#515151"
    },
    contRangeParams: {
        overflowY: "auto",
        maxHeight: "600px"
    },
    contDetailParam: {
        padding: "0 0 0 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        maxWidth: 300
    },
    contInfoParam:{
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        width: "100%"
    },
    infoParamTitle: {
        margin: 0,
        width: "80%",
        flexShrink: 0,
        color: "#515151"
    },
    detailParamTextAmount: {
        color: "#000000",
        fontSize: "1.1em",
        width: "20%"
    }
}))

const ModalNotificationDescountOs: React.FC<IModalNotificationDescountOs> = ({
    openModal,
    amountDetail,
    HandleClose,
})=>{
    const customStyles = styles();
    return(
    <GeneralModalShow
    title='Detalle del monto de Obra Social'
    openModal={openModal}
    HandleClose={HandleClose}
    >
        <section className={customStyles.contRangeParams}>
            {amountDetail.edad0a18.length > 0 && amountDetail.edad0a18.some(x => x.monto > 0) &&
                <Typography className={customStyles.detailParamText}>Rango 0 - 18 años: 
                <ul className={customStyles.contDetailParam}>
                    {amountDetail.edad0a18.map(person => (
                        <li className={customStyles.contInfoParam}>
                            <p className={customStyles.infoParamTitle}>- {person.nombre + `${person.titular ? " (Titular)" : ` (${person.relacion})`}`}</p>
                            <b className={customStyles.detailParamTextAmount}>${person.monto}</b>
                        </li>
                    ))}
                </ul>
                </Typography>
            }
        
            {amountDetail.edad19a35.length > 0 && amountDetail.edad19a35.some(x => x.monto > 0) &&
                <Typography className={customStyles.detailParamText}>Rango 19 - 35 años: 
                <ul className={customStyles.contDetailParam}>
                    {amountDetail.edad19a35.map(person => (
                        <li className={customStyles.contInfoParam}>
                            <p className={customStyles.infoParamTitle}>- {person.nombre + `${person.titular ? "(Titular)" : ` (${person.relacion})`}`}</p>
                            <b className={customStyles.detailParamTextAmount}>${person.monto}</b>
                        </li>
                    ))}
                </ul>
                </Typography>
            }

            {amountDetail.edad36a45.length > 0 && amountDetail.edad36a45.some(x => x.monto > 0) &&
                <Typography className={customStyles.detailParamText}>Rango 36 - 45 años: 
                <ul className={customStyles.contDetailParam}>
                    {amountDetail.edad36a45.map(person => (
                        <li className={customStyles.contInfoParam}>
                            <p className={customStyles.infoParamTitle}>- {person.nombre + `${person.titular ? "(Titular)" : ` (${person.relacion})`}`}</p>
                            <b className={customStyles.detailParamTextAmount}>${person.monto}</b>
                        </li>
                    ))}
                </ul>
                </Typography>
            }
            
            {amountDetail.edad46a55.length > 0 && amountDetail.edad46a55.some(x => x.monto > 0) &&
                <Typography className={customStyles.detailParamText}>Rango 46 - 55 años: 
                <ul className={customStyles.contDetailParam}>
                    {amountDetail.edad46a55.map(person => (
                        <li className={customStyles.contInfoParam}>
                            <p className={customStyles.infoParamTitle}>- {person.nombre + `${person.titular ? "(Titular)" : ` (${person.relacion})`}`}</p>
                            <b className={customStyles.detailParamTextAmount}>${person.monto}</b>
                        </li>
                    ))}
                </ul>
                </Typography>
            }

            {amountDetail.edad56a65.length > 0 && amountDetail.edad56a65.some(x => x.monto > 0) &&
                <Typography className={customStyles.detailParamText}>Rango 56 - 65 años: 
                <ul className={customStyles.contDetailParam}>
                    {amountDetail.edad56a65.map(person => (
                        <li className={customStyles.contInfoParam}>
                            <p className={customStyles.infoParamTitle}>- {person.nombre + `${person.titular ? "(Titular)" : ` (${person.relacion})`}`}</p>
                            <b className={customStyles.detailParamTextAmount}>${person.monto}</b>
                        </li>
                    ))}
                </ul>
                </Typography>
            }

            {amountDetail.edadMas65.length > 0 && amountDetail.edadMas65.some(x => x.monto > 0) &&
                <Typography className={customStyles.detailParamText}>Rango + 65 años: 
                <ul className={customStyles.contDetailParam}>
                    {amountDetail.edadMas65.map(person => (
                        <li className={customStyles.contInfoParam}>
                            <p className={customStyles.infoParamTitle}>- {person.nombre + `${person.titular ? "(Titular)" : ` (${person.relacion})`}`}</p>
                            <b className={customStyles.detailParamTextAmount}>${person.monto}</b>
                        </li>
                    ))}
                </ul>
                </Typography>
            }
        </section>

        {amountDetail.recAntecEnfermedadesCatastrof > 0 && <Typography className={customStyles.detailParamText}>Recargo por antec. por enf. catastr.: <b className={customStyles.detailParamTextAmount}>${amountDetail.recAntecEnfermedadesCatastrof}</b></Typography>}
        
        <Typography className={customStyles.detailParamText}><b className={customStyles.detailParamTextAmount}>Monto: ${amountDetail.total}</b></Typography>
    </GeneralModalShow>)
}

export default ModalNotificationDescountOs;