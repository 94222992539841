import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Tooltip, Typography } from "@material-ui/core";
import { Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Module, Options, TypeRole } from "../../enums/index";
import { useEffect, useState } from "react";
import SideBarItem from "../sideBar/SideBarItem";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const useStyles = makeStyles(()=>({
	sideBarIconStyles: {
		fontSize: "2em", 
		marginRight: 5,
		transition: 'margin-left 0.3s ease !important',
	},
	sideBarTextItemStyles: {
		fontSize: "1em", 
		color: "#363534",
		transition: 'opacity 0.3s ease !important',
	}
}));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default function CustomizedMenus({ configUser, cruds }) {
  const classStyle = useStyles();
  const [typeRolUser, setTypeRolUser] = useState(() => {
    return JSON.parse(localStorage.getItem("TypeRole"));
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SideBarItem
        urlToRedirect="#"
        titleTooltip="Configuraciones"
        dispatchAction={handleClick}
      >
        <>
          <SettingsOutlinedIcon className={classStyle.sideBarIconStyles} />
          <Typography className={classStyle.sideBarTextItemStyles}>
            Configuraciones
          </Typography>
        </>
      </SideBarItem>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {typeRolUser !== TypeRole.ObraSocial &&
          typeRolUser !== TypeRole.Professional && (
            <StyledMenuItem
              onClick={() => {
                history.replace("/configuraciones/usuarios");
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Usuarios" />
            </StyledMenuItem>
          )}
        {cruds && typeRolUser === TypeRole.SuperAdmin && (
          <StyledMenuItem
            onClick={() => {
              history.replace("/configuraciones/regionales/listado");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Regionales" />
          </StyledMenuItem>
        )}
        {cruds && typeRolUser === TypeRole.Admin && (
          <StyledMenuItem
            onClick={() => {
              history.replace("/configuraciones/períodos/listado");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Períodos" />
          </StyledMenuItem>
        )}
        {cruds && typeRolUser === TypeRole.SuperAdmin && (
          <StyledMenuItem
            onClick={() => {
              history.replace("/configuraciones/colegios/listado");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Colegios" />
          </StyledMenuItem>
        )}
        {cruds && typeRolUser === TypeRole.SuperAdmin && (
          <StyledMenuItem
            onClick={() => {
              history.replace("/configuraciones/obras-sociales/practicas");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Prácticas" />
          </StyledMenuItem>
        )}

        {typeRolUser === TypeRole.Admin && (
          <StyledMenuItem
            onClick={() => {
              history.push("/configuraciones/exportar-excel");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Exportar Excel" />
          </StyledMenuItem>
        )}

        {(typeRolUser === TypeRole.Admin ||
          typeRolUser === TypeRole.Colegio) && (
          <StyledMenuItem
            onClick={() => {
              history.push("/configuraciones/novedades");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Novedades" />
          </StyledMenuItem>
        )}

        {typeRolUser !== TypeRole.ObraSocial && (
          <StyledMenuItem
            onClick={() => {
              history.push("/configuraciones/obras-sociales/asignar");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Obras Sociales" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
}
