import DateFnsUtils from "@date-io/date-fns";
import { InputAdornment, TextField, makeStyles } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table,
} from "semantic-ui-react";
import api from '../../../../api';
import { TypeRole } from '../../../../enums';
import ButtonBox from "../../../utils/ButtonBox";
import GeneralModalCrud from "../../../utils/GeneralModalCrud";
import GeneralModalDelete from "../../../utils/GeneralModalDelete";
import AssignAmountToPractice from "./modals/AssignAmountToPractice";

const useStyles = makeStyles({
	contAssingAmount: {
		display:"flex", 
		flexDirection:"column", 
		width: "43%", 
		height: "100%", 
		justifyContent: "space-between",
		marginLeft: 10,
	},
	contAssignModal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: "center",
		height: "140px",
		overflowY: "visible"
	},
	isAmountDown: {color: "#ff8a80"}
})

export const Amount = ({setDataToast}) =>{
    const {idOs} = useParams();
	const aditionalUrlInfo = useLocation();
   const { control } = useForm();
	const classStyle = useStyles();
	const redirect = useHistory();

    //#region ESTADOS
    const [RoleLogged, setRoleLogged] = React.useState(()=>{return JSON.parse(localStorage.getItem("TypeRole"))});
    const [dataTable, setDataTable] = React.useState([]);
    const [practiceList, setPracticeList] = React.useState([]);
    const [socialWork, setSocialWork] = React.useState({
		nombre: "",
		alias: "",
		esObraSocialColegio: false
	});
    const [loadingActive, setLoadingActive] = React.useState(false);
	const [actionTodo, setActionTodo] = React.useState({
		assign: 'assign',
		edit: 'edit',
		delete: 'delete',
		view: 'view'
	})
    const [assignModal, setAssingModal] = React.useState({
        showModal: false,
        data: {
            practicaId: null,
            monto: null,
            fecha: null,
        }
    });
	 const [editModal, setEditModal] = React.useState({
        showModal: false,
        data: {
            practicaId: null,
            monto: null,
			montoId: null,
            fecha: null,
        }
    });
	const [deleteModal, setDeleteModal] = React.useState({
		showModal: false,
        data: {
            practicaId: null,
			nombrePractica: null,
            monto: null,
			montoId: null,
        }
	});
	const [tableHistorialAmount, setTableHistorialAmount] = React.useState({
		showModal: false,
		data: []
	});
	const [errors, setErrors] = React.useState({
        practiceList: false,
		amount: false,
		date: false
	});
    const [isLoading, setIsLoading] = React.useState({
        practiceList: false,
    });
    //#endregion

	React.useEffect(()=> {
		GetSocialWorkById();
	},[]);

    const LoadData = async(idSocialWork = +idOs)=>{
        try{
                setLoadingActive(true);
				const {cuentaId} = aditionalUrlInfo.state;
                let {data} = await api.GetAmountForPractices(idSocialWork, cuentaId);
				let response = data.map(item=>{
					return{
							id: item.id,
							fecha: item.fecha,
							monto: item.monto,
							practica: {
								nombre: item.practica.nombre,
								alias: item.practica.alias,
								practicaObraSocialCuentaId: item.practica.practicaObraSocialCuentaId
							}
						}
				});
                setDataTable(response);
            }
		catch(err){
			notificationMessage("error",err.response.data.errores);
		}
		finally{setLoadingActive(false)}
    }
	const LoadHistorialAmount = async(idSocialWork, practice) =>{
		try{
			setLoadingActive(true);
			const {cuentaId} = aditionalUrlInfo.state;
			let {data} = await api.GetHistorialAmount(idSocialWork, practice.practicaObraSocialCuentaId, cuentaId);
			let response = data.map(item=>{
				return{
					id: item.id,
					fecha: item.fecha,
					monto: item.monto,
					practicaObraSocialCuentaId: practice.practicaObraSocialCuentaId,
					practicaNombre: practice.nombre,
					dadoDeBaja: item.fechaBaja
				}
			});
			setTableHistorialAmount({showModal: true, data: response});
		}
		catch(err){
			notificationMessage("error",err.response.data.errores);
		}
		finally{setLoadingActive(false)}
	}
    //#region CRUD METHODS
	const HandleDeleteValues = async()=>{
        try {
			setLoadingActive(true);
			setDeleteModal({...deleteModal, showModal: false});
			let data = await api.DeleteAmountForPractices(+idOs, deleteModal.data.practicaId, deleteModal.data.montoId);
			if (data.status === 200){
				HandleClearAllModalData(actionTodo.view);
				notificationMessage("success", "Monto dado de baja correctamente");
				LoadData();
			}
        } catch (err) {
			notificationMessage("error",err.response.data.errores ?? err.message);
        }
        finally{setLoadingActive(false)}
    }
    //#endregion

    //#region UTILS
	const GetSocialWorkById = async() =>{
		try{
			setLoadingActive(true);
			const {cuentaId} = aditionalUrlInfo.state;
			let {data} = await api.getSocialWorkById(+idOs, cuentaId);
			setSocialWork({
				nombre: data.nombre,
				alias: data.alias,
				esObraSocialColegio: data.esObraSocialColegio
			});
		}catch(err){
			notificationMessage("error",err.response.data.errores);
		}
		finally{
			LoadData();
		}
	}
    const notificationMessage = (typeOfError, message) => {
        setDataToast({
            active: true,
            message: message,
            type: typeOfError,
        });
    }
	const HandleOpenModalData = (
		action,
		practicaSocialWorkAccountId = null,
		namePractice = null,
		amount = null,
		amountId = null,
		date = null,
	) => {
		if (action === actionTodo.assign){
			GetPracticesAssigned();
			setAssingModal({...assignModal, showModal: true});
		}

		if (action === actionTodo.delete){
			setDeleteModal({showModal: true, data: {practicaId: practicaSocialWorkAccountId, nombrePractica: namePractice, monto: +amount, montoId: amountId}});
		}
	}
	const HandleClearAllModalData = (action) => {
		if (action === actionTodo.assign){
			setAssingModal({showModal: false, data: {practicaId: null, monto: null, fecha: null}});
		}

		if (action === actionTodo.edit){
			setEditModal({showModal: false, data: {practicaId: null, monto: null, montoId: null, fecha: null}});
			setErrors({amount:false, date: false, practiceList: false});
		}

		if (action === actionTodo.delete){
			setDeleteModal({showModal: false, data: {practicaId: null, nombrePractica: null, monto: null, montoId: null}});
			setErrors({amount:false, date: false, practiceList: false});
		}

		if (action === actionTodo.view){
			setTableHistorialAmount({showModal: false, data: []});
		}
	}
	const CheckAllValues = (data) => {
		let isValidatedObj = {
			showError: false,
			message: ""
		};

		if (data !== null){
			if (+data.monto === null || +data.monto <= 0 || isNaN(data.monto)){
				isValidatedObj.message = "El 'monto' debe ser un número. No puede estar vacío o ser un número negativo.";
				isValidatedObj.showError = true;
				setErrors({amount: true});
			}
			if (data.practicaId === null){
				isValidatedObj.message = "La 'Practica' no puede estar vacía.";
				isValidatedObj.showError = true;
				setErrors({practiceList: true});
			}
			if (data.fecha === null){
				isValidatedObj.message = "La 'Fecha' no puede estar vacía.";
				isValidatedObj.showError = true;
				setErrors({date: true});
			}
		}
		return isValidatedObj;
	}
	const GetPracticesAssigned = async(assign = true)=>{
		try{
			let {data} = await api.getPractices(+idOs,assign,false, true);
			let response = data.resultados.map(item=>{
				return {
					value: item.practicaObraSocialCuentaId,
					label: item.alias === null ? item['nombre'] : item.alias
				}
			});
			setPracticeList(response);
		}catch(err){
			 notificationMessage("error",err.response.data.errores);
		}
		finally{setIsLoading({practiceList: false})}
	}
    //#endregion

    return(<>
        <Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className="header-section">
								<div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>{socialWork.alias !== null ? `${socialWork.alias} - ` : `${socialWork.nombre} - `} Montos</span>
								</div>
								<div className="contBtnHeaderSection">
									<Button
									className='btn-back-filter'
									onClick={() => redirect.push('/configuraciones/obras-sociales/asignar')}
									>
										Volver
									</Button>
									{TypeRole.Admin === RoleLogged &&
									<Button
										disabled={socialWork.esObraSocialColegio}
										className='btn-new-filter'
										onClick={()=>{HandleOpenModalData(actionTodo.assign)}}
										icon
										labelPosition='left'
									>
										<Icon name='plus' />
										Asignar
									</Button>}
								</div>
							</Grid.Row>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando...</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={4} className='color-letter-inTable'>
										<TableRow>
                                            <TableCell className='letter-title-table c-text-center p-cell'>
												<b>Fecha</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center p-cell'>
												<b>Práctica</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center p-cell'>
												<b>Monto</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center p-cell'>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.length > 0 && dataTable.map((data) => (
											<TableRow key={data.id}>
                                                <TableCell className='c-text-center p-cell'>
													{new Date(data.fecha).toLocaleDateString()}
												</TableCell>
												<TableCell className='c-text-center p-cell'>
													{data.practica.alias ?? data.practica.nombre}
												</TableCell>
												<TableCell className='c-text-center p-cell'>
													{data.monto === null ? "No asignado a la fecha" : `$ ${data.monto}`}
												</TableCell>
												<TableCell className='c-text-center p-cell' >
													<Button.Group>
														<ButtonBox buttonToShow='viewAmountHistorial' name='viewAmountHistorial' HandleOnClik={()=>{LoadHistorialAmount(+idOs,data.practica)}}/>
														
														{TypeRole.Colegio !== RoleLogged &&
														<ButtonBox buttonToShow='delete' name='delete' HandleOnClik={() => {HandleOpenModalData(
														actionTodo.delete,
														data.practica.practicaObraSocialCuentaId,
														data.practica.nombre,
														data.monto,
														data.id,
														data.fecha)}}/>}
													</Button.Group>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
        </Grid>

		{/* ASIGNAR */}
        <AssignAmountToPractice 
		socialWorkId={idOs}
		openModal={assignModal.showModal}
		modalOpened={actionTodo.assign}
		setLoadingActive={setLoadingActive}
		HandleClearAllModalData={() => HandleClearAllModalData(actionTodo.assign)}
		NotificationMessage={notificationMessage}
		ReloadData={()=>LoadData()}
		/>

		{/* ELIMINAR */}
		<GeneralModalDelete
		openModal={deleteModal.showModal}
		HandleClose={() => HandleClearAllModalData(actionTodo.delete)}
		HandleSubmit={HandleDeleteValues}
		warningText={`El Monto no se actualizará sobre un Documento con estado Facturado.`}
		contentText={`¿Está seguro de eliminar el monto ($${deleteModal.data.monto}) de la práctica: ${deleteModal.data.nombrePractica}?`}
		schoolText={'¡El monto se va a eliminar para todas las regionales confirmadas y que no tienen convenio directo!'}
		/>

		{/* HISTORIAL */}
		<Dialog open={tableHistorialAmount.showModal} onClose={() => HandleClearAllModalData(actionTodo.view)}>
			<DialogTitle id='form-dialog-title'>Historial de montos</DialogTitle>
			<DialogContent>
				<Grid.Row>
					<TableContainer component={Paper} id='color-letter-table-tasks'>
						<Table aria-label='spanning table'>
							<TableHead colSpan={2} className='color-letter-inTable'>
								<TableRow>
									<TableCell className='letter-title-table c-text-center'>
										<b>Fecha</b>
									</TableCell>
									<TableCell className='letter-title-table c-text-center'>
										<b>Monto</b>
									</TableCell>
									<TableCell className='letter-title-table c-text-center'>
										<b>Opciones</b>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableHistorialAmount.data.map((data) => (
									<TableRow key={data.id}>
										<TableCell className={`c-text-center ${data.dadoDeBaja && classStyle.isAmountDown}`}>
											{new Date(data.fecha).toLocaleDateString()}
										</TableCell>
										<TableCell className={`c-text-center ${data.dadoDeBaja && classStyle.isAmountDown}`}>
											$ {data.monto === null ? "No Asignado a la fecha" : data.monto}
										</TableCell>
										<TableCell className='c-text-center p-cell'>
											<Button.Group>							
												<ButtonBox buttonToShow='delete' name='delete' HandleOnClik={() => {HandleOpenModalData(
														actionTodo.delete,
														data.practicaObraSocialCuentaId,
														data.practicaNombre,
														data.monto,
														data.id,
														data.fecha)}}/>
											</Button.Group>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid.Row>
			</DialogContent>
			<DialogActions style={{justifyContent: "center"}}>
				<Button onClick={() => HandleClearAllModalData(actionTodo.view)}>Cerrar</Button>
			</DialogActions>
		</Dialog>
    </>)
}

export default Amount;