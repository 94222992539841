import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Toast({ setDataToast, dataToast }) {
  const classes = useStyles();

  const handleClose = () => {
    setDataToast({ active: false, message: "", type: "" });
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={dataToast.active}
        // autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity={dataToast.type} onClose={handleClose}>
          {dataToast.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
