import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import api from '../../../../api';
import { StatusCode } from '../../../../enums';
import { IDataToAssign, ILoaderSelect, IModalAmountForOs } from "../../../../services/interfaces/Configurations/IModalAmoutForOs";
import { selectTypeFormat } from "../../../../services/interfaces/utils/IUtilDeclaration";
import { GetSocialWorkSelect } from '../../../../services/services/ConfigurationSocialWork';
import { GetPractices } from "../../../../services/services/Practices";
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { _DEFAULT_VALUES_ModalAmountForOs } from "../defaultValues";

const ModalPracticeForAssociation: React.FC<IModalAmountForOs> = ({
  associationId,
  openModal,
  titleOfModal,
  modal,
  setLoadingActive,
  CloseModal,
  MessageNotification }) => {
  interface ValueState {
    id: string | null;
    nombre: string | null;
    alias: string | null;
    aliasReporte: string | null;
  }
  const [socialWorkList, setSocialWorkList] = useState<selectTypeFormat[]>([]);
  const [loaderSelect, setLoaderSelect] = useState<ILoaderSelect>(_DEFAULT_VALUES_ModalAmountForOs._LOADER_SELECT);
  const [dataToAssign, setDataToAssing] = useState<IDataToAssign>(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);

  const [notAssignPracticeList, setNotassignPracticeList] = useState([{}]);
  const [newValue, setNewValue] = useState<ValueState>({
    id: null,
    nombre: null,
    alias: null,
    aliasReporte: null
  });

  React.useEffect(() => {
    SocialWorklist();
  }, []);

  async function SocialWorklist() {
    try {
      setLoaderSelect({ ...loaderSelect, socialWork: true });
      const params = {
        asignadas: true,
        confirmada: true
      }
      const { status, data } = await GetSocialWorkSelect(params);
      if (status === StatusCode.Ok) {
        const socialWorks: selectTypeFormat[] = data.results.map(item => {
          return { value: item.id, label: item.nombre }
        });
        setSocialWorkList(socialWorks);
      }
    } catch (err: any) {
      MessageNotification(err.response.data.errores, "error")
    } finally {
      setLoaderSelect({ ...loaderSelect, socialWork: false });
    }
  }
  async function PracticeList({ socialWorkId }) {
    try {
      setLoadingActive(true);
      const { status, data } = await GetPractices(socialWorkId, false, false);
      if (status === StatusCode.Ok) {
        let list = data.results.map(item => {
          return {
            value: item.id,
            label: item["nombre"],
          }
        });
        setNotassignPracticeList(list);
      }
    } catch (err: any) {
      MessageNotification(err.response.data.errores, "error")
    } finally {
      setLoadingActive(false);
    }
  }
  function GetPracticesBySocialWorkSelected(e) {
    if (e !== null) {
      setDataToAssing({
        ...dataToAssign,
        socialWorkId: +e.value
      });
      PracticeList({ socialWorkId: e.value });
    }
  }
  function ValidateInputs() {
    let isValidated = true;

    if (dataToAssign.socialWorkId === null) {
      MessageNotification("El campo 'Obra Social' es obligatorio.", "error");
      isValidated = false;
    }
    if ((newValue.alias !== null || newValue.aliasReporte !== null)){
      if ((newValue.aliasReporte === null || newValue.alias === null)) {
        MessageNotification("Deben completarse ambos campos para asignar un alias a la práctica.", 'error');
        isValidated = false;
      }
    }
    if (newValue.aliasReporte !== null && newValue.aliasReporte.length > 4) {
      MessageNotification("El Alias del reporte no debe de ser mayor a 4 caracteres.", 'error');
      isValidated = false
    }
    if (newValue.id === null) {
      MessageNotification("El campo 'Practica' no puede estar vacío.", 'error');
      isValidated = false
    }
    return isValidated
  }
  function HandleSubmit() {
    if (ValidateInputs()) {
      AssingPracticeSocialWorkAccount();
    }
  }
  async function AssingPracticeSocialWorkAccount() {
    try {
      setLoadingActive(true);
      let bodyParams = {
        practicaId: newValue.id,
        practicaAlias: newValue.alias,
        practicaAliasReporte: newValue.aliasReporte
      }
      const response = await api.AssignPracticeForOs(dataToAssign.socialWorkId, bodyParams);
      if (response.status === StatusCode.Ok) {
        CloseModal("close", "massiveEditAmount");
        setDataToAssing(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);
        MessageNotification("Práctica Asociadas correctamente.", "success");
      }
    } catch (err: any) {
			MessageNotification(err.response.data.errores, "error");
    } finally {
      setNewValue({
        id: null,
        nombre: null,
        alias: null,
        aliasReporte: null
      });
      setLoadingActive(false);
    }
  }
  return (
    <GeneralModalCrud
      openModal={openModal}
      HandleCloseModal={() => CloseModal("close", modal)}
      HandleSubmit={HandleSubmit}
      titleOfModal={titleOfModal}
      styles="cont-default-modal"
    >
      <>
        <ReactSelect
          placeholder="Obras Sociales..."
          options={socialWorkList}
          isLoading={loaderSelect.socialWork}
          onChange={(e) => GetPracticesBySocialWorkSelected(e)}
        />
        {dataToAssign.socialWorkId && <>
          <ReactSelect
            placeholder="Prácticas"
            options={notAssignPracticeList}
            onChange={(e) => setNewValue({
              ...newValue,
              id: e !== null ? e.value : null,
              nombre: e !== null ? e.label : null,
            })}
          />
          <TextField
            size='small'
            id='alias'
            label='Alias Reporte'
            style={{ position: "relative" }}
            value={newValue.aliasReporte}
            onChange={(e) => {
              setNewValue({
                ...newValue,
                aliasReporte: e.target.value === "" ? null : e.target.value
              });
            }}
          />
          <TextField
            size='small'
            id='alias'
            label='Alias'
            value={newValue.alias}
            onChange={(e) => {
              setNewValue({
                ...newValue,
                alias: e.target.value === "" ? null : e.target.value
              });
            }}
          />
        </>}
      </>
    </GeneralModalCrud>
  )
}

export default ModalPracticeForAssociation;