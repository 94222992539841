import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
	name: 'amount',
	initialState: {
		objFilterGrid: {
            obraSocial: {value: null, label: null},
            cuenta: {value: null, label: null},
            confirmadas: false
        }
	},
	reducers: {
		setObjFilterGrid(state, {payload}){
            state.objFilterGrid = payload;
        },
        setRestoreObjFilterGrid(state){
            state.objFilterGrid = {
                obraSocial: {value: null, label: null},
                cuenta: {value: null, label: null},
                confirmadas: false
            }
        }
	},
});

export const {
	setObjFilterGrid,
    setRestoreObjFilterGrid
} = actions;

export default reducer;
