import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IConfigParams, IConfigParamsToSend } from "../../api/Interfaces/managment/IConfigurationParamsRegional";
import { IDescountParamsByOs } from "../../api/Interfaces/configurations/IDescountOs";
import errorHandler from "../errorHandler";

const GetConfigParams = async (): Promise<IApiResponse<IConfigParams>> => {
    try{
        const response = await api.GetConfigParams();
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const AddConfigParams = async (params: IConfigParamsToSend): Promise<IApiResponse<number[]>> => {
    try{
        const response = await api.AddConfigParams(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditConfigParams = async (params: IConfigParamsToSend): Promise<IApiResponse<void>> => {
    try{
        const response = await api.EditConfigParams(params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetDescountParam = async (usuarioId: number): Promise<IApiResponse<IDescountParamsByOs>> => {
    try{
        const response = await api.GetDescountParam(usuarioId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}



export {GetConfigParams, EditConfigParams, AddConfigParams, GetDescountParam}