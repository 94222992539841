import { createSlice } from "@reduxjs/toolkit";

const {actions, reducer} = createSlice({
    name: 'managment',
    initialState: {
        hasManagment: false
    },
    reducers: {
        setManagment(state, {payload}){
            state.hasManagment = payload
        }
    }
});

export const {setManagment} = actions;
export default reducer;