import React from 'react'
import api from '../../api';
import errorHandler from '../errorHandler';
import { IApiResponse } from '../../api/Interfaces/IApiResponse';
import { IAccreditation, IAccreditationFilterParams, IAccreditationValues } from '../../api/Interfaces/managment/ICrudAccreditation';
import { postResponse } from '../interfaces/utils/IUtilDeclaration';

const GetAccreditations = async (params: IAccreditationFilterParams): Promise<IApiResponse<IAccreditation[]>> => {
    try{
        const response = await api.GetAccreditations(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateAccreditation = async (params: IAccreditationValues): Promise<IApiResponse<postResponse>> => {
    try{
        const response = await api.CreateAccreditation(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditAccreditation = async (params: IAccreditationValues): Promise<IApiResponse<void>> => {
    try{
        const response = await api.EditAccreditation(params);
        return {data: {results: undefined}, status: response.status}
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteAccreditation = async (params: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteAccreditation(params);
        return {data: {results: undefined}, status: response.status}
    }catch(err){
       return errorHandler(err);
    }
}

export {GetAccreditations, CreateAccreditation, EditAccreditation, DeleteAccreditation}