import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { es } from 'date-fns/locale';
import React from 'react';
import { StatusCode, TypeRoleNum } from "../../../enums";
import { IDataForSelect, ILoaderForSelect } from "../../../services/interfaces/Managment/IReceipts";
import { selectTypeFormat } from "../../../services/interfaces/utils/IUtilDeclaration";
import { GetProfesionals } from "../../../services/services/Users";
import CustomReactSelect from "../../utils/CustomReactSelect";
import { _DEFAULT_VALUES_RECEIPTS } from "../defaultValues";
// import PaymentDiscountDetails from '../modals/Receipts/PaymentDiscountDetails';
import { IReceiptProfesional } from "../../../services/interfaces/Managment/IReceiptForProfesional";
import { Dimmer, Loader } from "semantic-ui-react";

 const ReceiptForProfesional: React.FC<IReceiptProfesional> = ({
    receiptProfessional,
    errorObj,
    isView,
    setReceiptProfessional,
    MessageNotification
 }) =>{
    const [loaderForSelect, setLoaderForSelect] = React.useState<ILoaderForSelect>(_DEFAULT_VALUES_RECEIPTS._LOADER_FOR_SELECT);
    const [dataForSelect, setDataForSelect] = React.useState<IDataForSelect>(_DEFAULT_VALUES_RECEIPTS._DATA_FOR_SELECT);
    const [loadingActive, setLoadingActive] = React.useState(false);

    React.useEffect(()=>{
        GetProfessionalForSelect();
    },[]);

    async function GetProfessionalForSelect(){
        try{
            (isView && setLoadingActive(true))
            setLoaderForSelect({
                ...loaderForSelect,
                profesionals: true
             });
            const {data, status} = await GetProfesionals({conPaginacion: false, rolId: TypeRoleNum.Professional});
            if (status === StatusCode.Ok){
                const profesionalList = data.results.map(prof => ({value: prof.id, label: `${prof.apellido}, ${prof.nombre}`}));
                setDataForSelect({
                    ...dataForSelect,
                    profesionalList: profesionalList
                });
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{
            (isView && setLoadingActive(false));
            setLoaderForSelect({
                ...loaderForSelect,
                profesionals: false
             });
        }
    }

    //#region UTILS
    function HandleChangeDates(date: MaterialUiPickersDate, name: string){
        setReceiptProfessional({
            ...receiptProfessional,
            [name]: date
        });
    }
    function HandleChangeCheckboxProfessional(selectedCheckbox: any){
        setReceiptProfessional({
            ...receiptProfessional,
            [selectedCheckbox.target.name]: selectedCheckbox.target.checked
        });
    }
    function HandleChangeSelectReceiptProfessional(optionSelect: selectTypeFormat | null, aditionalData:any){
        setReceiptProfessional({
            ...receiptProfessional,
            [aditionalData.name]: optionSelect !== null ? optionSelect.value : null
        });
    }
    function HandleChangeNumberInputProfessionalReceipt(input){
        setReceiptProfessional({
            ...receiptProfessional,
            [input.target.name]: input.target.value === "" ? null : +input.target.value
        });
    }
    function HandleChangeInputProfessionalReceipt(input){
        setReceiptProfessional({
            ...receiptProfessional,
            [input.target.name]: input.target.value === "" ? null : input.target.value
        });
    }
    //#endregion

     return (
        <>
        
        {loadingActive ? 
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
            :
            <Grid className='cont-receipt'>
                <Grid className='cont-receipt cont-receipt--grid'>
                <TextField
                value={receiptProfessional.sucursal}
                label='Sucursal'
                type='number'
                error={errorObj?.sucursal}
                id="sucursal"
                variant="outlined"
                disabled={isView}
                size="small"
                name='sucursal'
                InputLabelProps={{shrink: true}}
                InputProps={{ inputProps: {max: 9999999, min: 0}}}
                onChange={(e)=>HandleChangeNumberInputProfessionalReceipt(e)}
                />
                <TextField
                value={receiptProfessional.numero}
                label='Número'
                type='number'
                name='numero'
                disabled={isView}
                id="numero"
                error={errorObj?.numero}
                InputProps={{ inputProps: {max: 9999999, min: 0}}}
                variant="outlined"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeNumberInputProfessionalReceipt(e)}
                />
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    style={{margin: 0}}
                    autoOk
                    className="size-date-picke-filter"
                    clearable
                    value={receiptProfessional.fecha}
                    error={errorObj?.fecha}
                    label="Fecha"
                    disabled={true}
                    id="fecha"
                    name='fecha'
                    placeholder="Fecha"
                    onChange={(e)=>HandleChangeDates(e, "fecha")}
                    format="dd/MM/yyyy"
                    margin="normal"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputVariant="outlined"
                    />
                </MuiPickersUtilsProvider>
                </Grid>
                <Grid className='cont-receipt cont-receipt--grid'>
                    <CustomReactSelect
                    placeholder="Profesionales..."
                    label="Profesional"
                    name="profesionalId"
                    isDisabled={isView}
                    customStyles={errorObj?.profesionalId && "errorNotificationInput"}
                    isLoading={loaderForSelect.profesionals}
                    options={dataForSelect.profesionalList}
                    value={dataForSelect.profesionalList.find(prof => prof.value === receiptProfessional.profesionalId) ?? null}
                    setValue={(e, opts)=>HandleChangeSelectReceiptProfessional(e, opts)}
                    />
                </Grid>
                <Grid className='cont-receipt cont-receipt--grid'>
                    <TextField
                    value={receiptProfessional.monto}
                    label='Monto'
                    disabled={isView}
                    id="monto"
                    type='number'
                    InputProps={{ inputProps: {max: 9999999, min: 0}}}
                    name='monto'
                    error={errorObj?.monto}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: true}}
                    onChange={(e)=>HandleChangeNumberInputProfessionalReceipt(e)}
                    />
                    <TextField
                    value={receiptProfessional.interes}
                    label='Interés'
                    type='number'
                    disabled={isView}
                    id="interes"
                    InputProps={{ inputProps: {max: 9999999, min: 0}}}
                    error={errorObj?.interes}
                    name='interes'
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: true}}
                    onChange={(e)=>HandleChangeNumberInputProfessionalReceipt(e)}
                    />
                    <Grid>
                        <FormControlLabel
                        control={
                            <Checkbox
                                disabled={isView}
                                checked={receiptProfessional.deposito}
                                name="deposito"
                                onChange={(e)=>HandleChangeCheckboxProfessional(e)}
                            />
                        }
                        label='Deposito'
                        />
                        <FormControlLabel
                        control={
                            <Checkbox
                                disabled={isView}
                                checked={receiptProfessional.afectaSaldo}
                                name="afectaSaldo"
                                onChange={(e)=>HandleChangeCheckboxProfessional(e)}
                            />
                        }
                        label='Afecta Saldo'
                        />
                    </Grid>
                </Grid>
                <Grid className='cont-receipt cont-receipt--grid'>
                    <TextField
                    value={receiptProfessional.enConceptoDe}
                    label='En concepto de:'
                    type='text'
                    id="enConceptoDe"
                    disabled={isView}
                    variant="outlined"
                    size="small"
                    error={errorObj?.enConceptoDe}
                    name='enConceptoDe'
                    InputLabelProps={{shrink: true}}
                    onChange={(e)=>HandleChangeInputProfessionalReceipt(e)}
                    />
                </Grid>
                {/* TO DO: Detalle de descuentos para pagos asociados, no existe dato de BE */}
                {/* <Grid className='cont-receipt cont-receipt--grid'>
                    <PaymentDiscountDetails />
                </Grid> */}
            </Grid>}
        </>
     )
 }
 export default ReceiptForProfesional;