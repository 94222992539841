import DateFnsUtils from '@date-io/date-fns';
import {
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Paper, TableBody, TableCell, TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip
} from '@material-ui/core';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from "@material-ui/icons/AddCircle";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from "@material-ui/icons/CheckSharp";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Grid, Icon, Table } from "semantic-ui-react";
import api from "../../api";
import DatePickerForm from '../common/DatePickerForm';
import InputFormLabel from "../common/InputFormLabel";
import SwitchComponent from '../common/SwitchComponent';
import { useParams } from 'react-router-dom';
import { red } from '@material-ui/core/colors';
import { startOfDay } from 'date-fns';

export default function DocumentPractice({
	infoSocialWork,
	state,
	setState,
	errors,
	datesSessions,
	setDatesSessions,
	sessionQuantity,
	setSessionQuantity,
	numAutorization,
	isView,
	isEdit,
	practices,
	idSocialWork,
	setDataToast,
	dateMedicalOrder,
	listSessiones,
	calculateDateSessions,
	stateOfCheckbox,
	setStateOfCheckbox,
	coinsuranceAmout,
	setLoadingActive,
	LoadDataToEdit
}) {
	const {id} = useParams();
	const [viewListSession, setViewListSession] = useState();
	const [listSessions, setListSessions] = useState([]);
	const [addSession, setAddSession] = useState({show: false, data: {
		fecha: null,
		numeroAutorizacion: null,
		coseguro: null
	}});
	const [deleteDialog, setDeleteDialog] = useState({
		id: undefined,
		num: undefined,
		document: undefined,
		open: false,
		loadManual: true
	});
	const [minDateFirstSession, setMinDateFirstSession] = useState(
		dateMedicalOrder ? dateMedicalOrder : new Date()
	);
	const [alertEditEvent, setAlertEditEvent] = useState(false);
	const [dataEditEvent, setDataEditEvent] = useState({
		id: '',
		fecha: '',
		numeroAutorizacion: undefined,
		coseguro: undefined,
		document: undefined,
		num: undefined,
		cargaManual: false
	});
	const [error, setError] = useState(false);
	const [errorDate, setErrorDate] = useState(false);
	const [tokenModal, setTokenModal] = useState({show: false, data: null});

	useEffect(() => {
		let viewListSession = JSON.parse(localStorage.getItem('viewListSession'));
		setViewListSession(viewListSession);
		setListSessions(listSessiones);
	}, [idSocialWork.id]);

	useEffect(()=>{
		setListSessions(listSessiones);
	},[listSessiones]);

	useEffect(() => {
		if (minDateFirstSession) {
			setMinDateFirstSession(minDateFirstSession);
		}
	}, [minDateFirstSession]);

	const changeDate = (value, name) => {
		if (!isNaN(value)){
			if (name === 'session1') {
				calculateDateSessions(sessionQuantity, new Date(value));
			}

			setDatesSessions({
				...datesSessions,
				[name]: value,
			});

			if (name !== 'session1') {
				const changeDateSession = {
					changeDate: true,
					nameSession: name,
					sessionValue: value,
				};
				let countSessions = 0;
				let sessionSelected = 0;
				Object.keys(datesSessions).forEach((key, index) => {
					countSessions = index + 1;

					if (key == changeDateSession.nameSession) {
						sessionSelected = countSessions;
					}
				});
				let quantitySessionCalculate = countSessions - sessionSelected;

				recalculateDateSessions(
					sessionSelected,
					changeDateSession.sessionValue,
					quantitySessionCalculate
				);
			}
		}
	}
	const recalculateDateSessions = (
		numberSession,
		session,
		quantitySessions
	) => {
		let sessionDateChange = session;
		let objSave = {
			['session' + numberSession]: sessionDateChange,
		};
		quantitySessions++;
		let body = {
			quantity: quantitySessions,
			dateFirstSession: moment(sessionDateChange).format('YYYY-MM-DD'),
		};
		api
			.getDatePractice(body)
			.then((resp) => {
				let responseDays = resp.data;
				for (let index = 1; index < responseDays.length; index++) {
					numberSession = numberSession + 1;
					objSave['session' + numberSession] = responseDays[index];
				}

				Object.keys(objSave).forEach((key) => {
					let sessionName = key;
					let newObj = {};
					Object.keys(datesSessions).forEach((key, index) => {
						index = index + 1;
						if (key == sessionName) {
							var obj = Object.assign(newObj, objSave);
							setDatesSessions(obj);
						} else {
							newObj['session' + index] = datesSessions[key];
						}
					});
				});
			})
			.catch((err) => {
				MessageNotificacion(err.response.data.errores,"error");
			});
	}
	function preDelete(num, id, documento, cargaManual) {
		setDeleteDialog({ open: true, id: id, num: num, document: documento, loadManual: cargaManual });
	}
	const closePreDelete = () => {
		setDeleteDialog({ ...deleteDialog, open: false });
	}
	function removeSession(id, document) {
		closePreDelete();
		if (listSessions.length === 1){
			MessageNotificacion("No es posible dar de baja la última sesión vigente.","error");
		}else{
			setLoadingActive(true);
			api
			.deleteSession(document, id)
			.then((res) => {
				let newDataTable = [];
				let index = listSessions.length;
				let fecha;
				listSessions.forEach((session) => {
					if (session.id !== id) {
						newDataTable.push(session);
						index = listSessions.length - 1;
						fecha = session.fechaSesion;
					}
				});

				if (index <= 1) {
					setDatesSessions({
						...datesSessions,
						sessionDate: new Date(fecha),
					});
				}
				setListSessions(newDataTable);
				setSessionQuantity(newDataTable.length)
				MessageNotificacion("Sesión eliminada correctamente.","success");
			})
			.catch((err) => {
				MessageNotificacion(err?.response?.data?.errores, "error");
			})
			.finally(()=>{
				setLoadingActive(false);
			})
		}
	}
	const DatePirckersSession = () => {
		let response = [];
		let quantitySession = sessionQuantity > 30 ? 30 : sessionQuantity;
		for (let index = 0; index < quantitySession; index++) {
			let item = index + 1;
			var minDate = new Date(datesSessions['session' + (item - 1)]);
			response.push(
				<DatePickerForm
					key={'session' + item}
					disabled={isView}
					label={'Fecha sesión Nº ' + item}
					name={'session' + item}
					dateValue={datesSessions['session' + item]}
					handleDateChange={changeDate}
					minDate={minDate.setDate(minDate.getDate() + 1)}
					minDateMessage={'Debe ser mayor a la fecha anterior'}
				/>
			);
		}

		return response.map((item) => {
			return item;
		});
	}
	const handleChange = (event, infoPractice) => {
		setState({
			...state,
			[event.target.id]: {checked: event.target.checked, amount: infoPractice.monto, codigo: infoPractice.codigo},
		});
	}
	function OpenEditSession(id, num, fecha, numAutorizacion, coseguro, documento, cargaManual) {
		setAlertEditEvent(true);
		setDataEditEvent({
			...dataEditEvent,
			id: id,
			num: num,
			fecha: fecha,
			numeroAutorizacion: numAutorizacion,
			coseguro: coseguro,
			document: documento,
			cargaManual
		});
	}
	function ValidateInputs(values){
		let isValidated = true;
		let fechaPedidoMedic = new Date(dateMedicalOrder)

		if (values.fecha === null) {
			setErrorDate(true);
			MessageNotificacion('Debe ingresar Fecha.','error');
			isValidated = false;
		}else{
			if (values.fecha < fechaPedidoMedic) {
				setErrorDate(true);
				MessageNotificacion('La fecha de sesión debe ser mayor o igual a la fecha del pedido médico.','error');
				isValidated = false;
			}
		}
		if (stateOfCheckbox.hasIntegration){
			if (tokenModal.show && (tokenModal.data === null || tokenModal.data === "")){
				setError(true);
				MessageNotificacion('El Token es obligatorio para la operación que desea realizar.','error');
				isValidated = false;
			}
			if (stateOfCheckbox.manualCharge){
				if (values.numeroAutorizacion === null || values.numeroAutorizacion === "") {
					setError(true);
					MessageNotificacion('Debe ingresar N° Autorización','error');
					isValidated = false;
				}else if (+values.numeroAutorizacion <= 0){
					setError(true);
					MessageNotificacion('No es posible asignar valores menores o iguales a cero.','error');
					isValidated = false;
				}
				if (values.coseguro === null || values.coseguro === "") {
					setError(true);
					MessageNotificacion('Debe ingresar Coseguro','error');
					isValidated = false;
				}else if (+values.coseguro < 0){
					setError(true);
					MessageNotificacion('No es posible asignar valores menores a cero.','error');
					isValidated = false;
				}
			}
		}else {
			if (values.numeroAutorizacion === null || values.numeroAutorizacion === "") {
				setError(true);
				MessageNotificacion('Debe ingresar N° Autorización','error');
				isValidated = false;
			}else if (+values.numeroAutorizacion <= 0){
				setError(true);
				MessageNotificacion('No es posible asignar valores menores o iguales a cero.','error');
				isValidated = false;
			}

			if (coinsuranceAmout){
				if (values.coseguro === null || values.coseguro === "") {
					setError(true);
					MessageNotificacion('Debe ingresar Coseguro','error');
					isValidated = false;
				}else if (+values.coseguro < 0){
					setError(true);
					MessageNotificacion('No es posible asignar valores menores a cero.','error');
					isValidated = false;
				}
			}
		}
		return {isValidated};
	}
	const OnClickCreate = () => {
		setLoadingActive(true);
		const body = {
			fechaSesion: moment(addSession.data.fecha).format(),
			numeroAutorizacion: +addSession.data.numeroAutorizacion,
			coseguro: +addSession.data.coseguro,
			tokenIntegracion: tokenModal.data
		};
			
		((stateOfCheckbox.hasIntegration && stateOfCheckbox.manualCharge) ? 
			api.postManualAuthorizationDocument(id, body) : api.postAuthorizationsDocument(id, body))
			.then((res) => {
				if(res.status === 200){
					setTokenModal({show: false, data: null});
					setAddSession({show: false, data: {
						fecha: null,
						numeroAutorizacion: null,
						coseguro: null
					}});
					MessageNotificacion("Sesión creada correctamente","success");
					LoadDataToEdit();
				}
			})
			.catch((err) => MessageNotificacion(err.response.data.errores,"error"))
			.finally(()=>{
				setAlertEditEvent(false);
				setLoadingActive(false);
				setError(false);
			})
	}
	function HandleClickSubmit(){
		let {isValidated} = ValidateInputs(addSession.data);
		if (isValidated){
			if(tokenModal.data === null && stateOfCheckbox.hasIntegration && infoSocialWork.tokenRequired && !stateOfCheckbox.manualCharge){
				setTokenModal({...tokenModal, show: true});
			}else{
				OnClickCreate();
			}
		}
	}
	const handleChangeAutorizacion = (e) => {
		setDataEditEvent({
			...dataEditEvent,
			numeroAutorizacion: e.target.value !== "" ? e.target.value : null 
		});
		setError(false);
	};
	const handleChangeCoseguro = (e) => {
		setDataEditEvent({
			...dataEditEvent,
			coseguro: e.target.value !== "" ? e.target.value : null
		});
		setError(false);
	};

	const OnClickUpdate = () => {
		let {isValidated} = ValidateInputs(dataEditEvent);
		if (isValidated){
			setLoadingActive(true);
			let body = {
				fechaSesion: new Date(dataEditEvent.fecha),
				numeroAutorizacion: +dataEditEvent.numeroAutorizacion,
				coseguro: +dataEditEvent.coseguro,
			};
			api
			.updateSession(dataEditEvent.document, dataEditEvent.id, body)
			.then((resp) => {
				setDataToast({
					active: true,
					message: 'Sesión Actualizada Exitosamente',
					type: 'success',
				});
				setError(false);
				setErrorDate(false);
				setAlertEditEvent(false);
				let copiaSesiones = listSessions;
				copiaSesiones.map((item, i) => {
					if (item.id === dataEditEvent.id) {
						item.fechaSesion =
							item.fechaSesion === dataEditEvent.fecha
								? item.fechaSesion
								: dataEditEvent.fecha;
						item.documentoId =
							item.documentoId === dataEditEvent.document
								? item.documentoId
								: dataEditEvent.document;
						item.numeroAutorizacion =
							item.numeroAutorizacion === dataEditEvent.numeroAutorizacion
								? item.numeroAutorizacion
								: dataEditEvent.numeroAutorizacion;
						item.coseguro =
							item.coseguro === dataEditEvent.coseguro
								? item.coseguro
								: dataEditEvent.coseguro;		

						return [
							{
								...copiaSesiones,
								id: dataEditEvent.id,
								num: dataEditEvent.num,
							},
						];
					} else return item;
				});

				setListSessions(copiaSesiones);
				if (dataEditEvent.num === 1) {
					setDatesSessions({
						...datesSessions,
						['session1']: copiaSesiones[0].fechaSesion,
					});
				}
			})
			.catch((err) => {
				setDataToast({
					active: true,
					message: err.response.data.errores || 'Acaba de ocurrir un error',
					type: 'error',
				});
			})
			.finally(()=>{
				setLoadingActive(false);
			})
		}
	};

	const handleChangeInputQuantity = (event) => {
		if (event.target.value !== ""){
			setSessionQuantity(event.target.value);
			calculateDateSessions(event.target.value);
		}else{setSessionQuantity(0)}
	}
	function HandleChangeManualCharge(e){
		if (e.target.checked){
			setStateOfCheckbox({...stateOfCheckbox, manualCharge: e.target.checked});
		}else{
			setStateOfCheckbox({...stateOfCheckbox, manualCharge: e.target.checked});
			setAddSession({...addSession, data: {
				...addSession.data,
				numeroAutorizacion: "",
				coseguro: ""
			}});
		}
	}
	const ListOfSessions = ()=>{	
		let typeOfPractices = practices.map(item=>{
				return(
					<FormControlLabel
						key={item.id}
						control={
							<Checkbox
								disabled={isView}
								id={item.practica.practicaObraSocialCuentaId}
								checked={state[item.practica.practicaObraSocialCuentaId] === undefined ? false : state[item.practica.practicaObraSocialCuentaId].checked}
								onChange={(e)=>handleChange(e, {monto: item.monto, codigo: item.practica.codigo})}
							/>
						}
					label={(item.practica.alias === null ? `${item.practica.nombre}` : `${item.practica.alias}`) + " " +`${item.monto === 0 ? "" : ` - $ ${item.monto}`}`}/>
				)
		})
		return typeOfPractices;
	}
	function HandleCloseModalEdittSession(){
		setError(false);
		setErrorDate(false);
		setAlertEditEvent(false);
	}
	function HandleCloseModalToken(){
		setError(false);
		setTokenModal({show: false, data: null});
	}
	function HandleCloseModalAddSession(){
		setError(false);
		setErrorDate(false);
		setAddSession({show: false, data: {
			fecha: null,
			numeroAutorizacion: null,
			coseguro: null
		}});
	}
	function HandleChangeInputAddSession(e){
		setAddSession({ ...addSession, data: {
			...addSession.data,
			[e.target.name]: e.target.value === "" ? null : e.target.value
		}});
	}
	function MessageNotificacion(message, type){
		setDataToast({
			active: true,
			message: message || 'Ocurrió un error.',
			type: type,
		});
	}
	return (
		<>
			<Grid.Row columns={2} className="contPracticeColumns">
				<Grid.Column id='column-practice-info'>
					<div>
						<div style={{ marginBottom: 25, display: "flex", alignItems: "center"}}>
							<DatePickerForm
								disabled={isView || viewListSession == false}
								label={'Fecha sesión Nº 1'}
								name={'session1'}
								dateValue={datesSessions['session1']}
								handleDateChange={changeDate}
								minDateMessage={'Debe ser mayor a la del pedido médico'}
							/>	
						</div>
						<div className='quantityAndSessions'>
							<InputFormLabel
							disabled={(isView || infoSocialWork.maximumSessions > 0)}
							classInput='width-input-form-doc'
							isRequired={true}
							type={'number'}
							label={'Cantidad'}
							name={'quantity'}
							isCreate={true}
							error={errors}
							value={sessionQuantity || 0}
							textChange={handleChangeInputQuantity}
							/>
							{practices.length > 0 && (
								<FormControl component='fieldset' className='listTypeOfSessions'>
									<section style={{display: "flex", alignItems: "center"}}>
										<FormLabel
											component='legend'
											className={errors.checkPractice ? 'text-error' : ''}
										>
											Tipo de sesiones
										</FormLabel>
									</section>
									<FormGroup>
										{practices.length > 0 && <ListOfSessions/>}
									</FormGroup>
								</FormControl>
							)}
						</div>
					</div>
				</Grid.Column>
				{infoSocialWork.maximumSessions !== null && (isEdit || isView) && listSessions.length > 0 && (
					
					<Paper
						elevation={0}
						style={{
							overflow: 'auto',
							minHeight: '64vh',
							maxHeight: '64vh',
						}}
						className='IntegrationSessionList'
					>
						{isEdit && infoSocialWork.maximumSessions > 0 &&
						<Button
						className='btn-new-filter'
						onClick={(e)=>{
							e.preventDefault();
							setAddSession({...addSession, show: true});
						}}
						icon
						labelPosition='left'
						>
							<Icon name='plus' />
							Agregar Sesión
						</Button>}	
						<Grid.Row style={{marginTop: 10}}>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={5} className='color-letter-inTable'>
										<TableRow>
											<TableCell  className='letter-title-table c-text-center'>
												<b>N° Sesión</b>
											</TableCell>
											<TableCell  className='letter-title-table c-text-center'>
												<b>Fecha Sesión</b>
											</TableCell>
											<TableCell  className='letter-title-table c-text-center'>
												<b>N° Autorización</b>
											</TableCell>
											<TableCell  className='letter-title-table c-text-center'>
												<b>Carga Manual</b>
											</TableCell>
											<TableCell  className='letter-title-table c-text-center'>
												<b>Coseguro</b>
											</TableCell>
											{listSessions.length > 1 && !isView &&
											<TableCell  className='letter-title-table c-text-center'>
												<b>Opciones</b>
											</TableCell>}
										</TableRow>
									</TableHead>
									<TableBody>
										{listSessions.map(
											({ id, documentoId, fechaSesion, numeroAutorizacion, cargaManual, coseguro},i) => (
												<TableRow key={id}>
													<TableCell className='c-text-center'>
														{i + 1}
													</TableCell>
													<TableCell className='c-text-center'>
														{moment(new Date(fechaSesion)).format('DD/MM/YYYY')}
													</TableCell>
													<TableCell className='c-text-center'>
														{numeroAutorizacion ?? 0}
													</TableCell>
													<TableCell className='c-text-center'>
														{cargaManual && <CheckIcon className='c-icon-success' />}
													</TableCell>
													<TableCell className='c-text-center'>
														$ {Intl.NumberFormat().format(coseguro) ?? 0}
													</TableCell>
													{listSessions.length > 1 && !isView &&
													<TableCell className='c-text-center'>
														<Button.Group>
															{(!stateOfCheckbox.hasIntegration || cargaManual) &&
																i > 0 &&
																<IconButton
																	disabled={isView}
																	className='btn-circle-orange'
																	onClick={() =>
																		OpenEditSession(
																			id,
																			i + 1,
																			fechaSesion,
																			numeroAutorizacion,
																			coseguro,
																			documentoId,
																			cargaManual
																		)}>
																		<Tooltip placement='top' title="Editar">
																			<EditIcon />		
																		</Tooltip>
																</IconButton>}
																
																{(listSessions.length > 1 || stateOfCheckbox.hasIntegration) &&
																<IconButton
																	disabled={isView || (i === 0)}
																	className='btn-circle-red'
																	onClick={() =>preDelete(i + 1, id, documentoId, cargaManual)}>
																		<Tooltip  placement="top" title="Eliminar">
																		  	<DeleteIcon />
																		</Tooltip>
																</IconButton>}
														</Button.Group>
													</TableCell>}
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					</Paper>
				)}
				{infoSocialWork.maximumSessions === null && (
					<Grid.Column id='column-practice-date'>
						<Paper
							elevation={0}
							style={{
								overflow: 'auto',
								minHeight: '64vh',
								maxHeight: '64vh',
							}}
						>
							<DatePirckersSession />
						</Paper>
					</Grid.Column>
				)}
			</Grid.Row>

			{deleteDialog != undefined && (
				<Dialog open={deleteDialog.open} onClose={closePreDelete}>
					<DialogTitle id='form-dialog-title'>Borrar registro</DialogTitle>
					<DialogContent>
						{!deleteDialog.loadManual && 
							<span style={{ color: red[700] }}>
								El Número de Autorización no podrá volver a utilizarse.
							</span>
						}
						<DialogContentText>
							¿Está seguro de eliminar el registro de la Sesión N°{' '}
							{deleteDialog.num}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => closePreDelete()}>Cancelar</Button>
						<Button
							onClick={() =>removeSession(deleteDialog.id, deleteDialog.document)}
							color='red'
						>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{alertEditEvent == true && (!stateOfCheckbox.hasIntegration || dataEditEvent.cargaManual) &&
				<Dialog
					open={alertEditEvent}
					onClose={HandleCloseModalEdittSession}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'>Editar Sesión</DialogTitle>
					<DialogContent>
						<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
							<KeyboardDatePicker
								autoOk
								className='size-date-picke-filter'
								clearable
								style={{ margin: 5 }}
								value={dataEditEvent.fecha}
								placeholder='Ingrese Fecha'
								onChange={(value) =>
									setDataEditEvent({ ...dataEditEvent, fecha: value })
								}
								format='dd/MM/yyyy'
								margin='normal'
								inputVariant='outlined'
								error={errorDate}
							/>
						</MuiPickersUtilsProvider>
					</DialogContent>
					<DialogContent>
						<TextField
							autoFocus
							margin='dense'
							type='number'
							size='small'
							variant='outlined'
							InputProps={{ inputProps: {max: 9999999, min: 0}}}
							placeholder='Ingrese N° Autorización'
							name='nombre'
							value={dataEditEvent.numeroAutorizacion}
							onChange={handleChangeAutorizacion}
							fullWidth
							error={error}
						/>
						<TextField
							autoFocus
							margin='dense'
							type='number'
							placeholder='Ingrese Coseguro'
							variant='outlined'
							size='small'
							name='nombre'
							className={(!infoSocialWork.coinsuranceAmout && !stateOfCheckbox.hasIntegration) ? "disabled-input" : ""}
							disabled={(!infoSocialWork.coinsuranceAmout && !stateOfCheckbox.hasIntegration)}
							value={dataEditEvent.coseguro}
							onChange={handleChangeCoseguro}
							fullWidth
							error={error}
							InputProps={{
								inputProps: {max: 9999999, min: 0},
							startAdornment: (
								<InputAdornment position="start">
									<AttachMoneyOutlinedIcon/>
								</InputAdornment>
								)
							}}
						/>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={HandleCloseModalEdittSession}>
							Cancelar
						</Button>
						<Button onClick={OnClickUpdate} color='green'>
							Guardar
						</Button>
					</DialogActions>
				</Dialog>}
			
			{isEdit && addSession.show &&
			<>
			<Dialog
				open={addSession.show}
				onClose={() => HandleCloseModalAddSession()}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Nueva Sesión</DialogTitle>
				<DialogContent>
					<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
						<KeyboardDatePicker
							autoOk
							className='size-date-picke-filter'
							clearable
							name="fecha"
							value={addSession.data.fecha}
							placeholder='Ingrese Fecha'
							onChange={(date) => {
								const onlyDate = startOfDay(date);
								setAddSession({ ...addSession, data: {
									...addSession.data,
									fecha: onlyDate
								}});
							}}
							format='dd/MM/yyyy'
							inputVariant='outlined'
							error={errorDate}
						/>
					</MuiPickersUtilsProvider>
					<TextField
						autoFocus
						margin='dense'
						type='number'
						InputProps={{ inputProps: {max: 9999999, min: 0}}}
						className={stateOfCheckbox.hasIntegration && !stateOfCheckbox.manualCharge ? "disabled-input" : ""}
						variant="outlined"
						disabled={stateOfCheckbox.hasIntegration && !stateOfCheckbox.manualCharge}
						placeholder='N° Autorización'
						name='numeroAutorizacion'
						value={addSession.data.numeroAutorizacion}
						onChange={(e) => HandleChangeInputAddSession(e)}
						error={error}
					/>
					<TextField
						autoFocus
						className={(!infoSocialWork.coinsuranceAmout && !stateOfCheckbox.hasIntegration) || (!stateOfCheckbox.manualCharge && stateOfCheckbox.hasIntegration) ? "disabled-input" : ""}
						margin='dense'
						disabled={(!infoSocialWork.coinsuranceAmout && !stateOfCheckbox.hasIntegration) || (!stateOfCheckbox.manualCharge && stateOfCheckbox.hasIntegration)}
						type='number'
						variant="outlined"
						placeholder='Coseguro'
						name='coseguro'
						value={addSession.data.coseguro}
						InputProps={{
							inputProps: {max: 9999999, min: 0},
							startAdornment: (
								<InputAdornment position="start">
									<AttachMoneyOutlinedIcon/>
								</InputAdornment>
								)
						}}
						onChange={(e) => HandleChangeInputAddSession(e)}
						error={error}
					/>
					{stateOfCheckbox.hasIntegration &&
					<SwitchComponent 
						label="Carga Manual"
						name="manualCharge" 
						onChange={(e)=>HandleChangeManualCharge(e)} 
						stateOfChecked={stateOfCheckbox.manualCharge}/>}
				</DialogContent>
				<DialogActions
					style={{
						textAlign: 'center',
						justifyContent: 'center',
					}}
				>
					<Button onClick={HandleCloseModalAddSession}>
						Cancelar
					</Button>
					<Button onClick={()=>HandleClickSubmit()} color='green'>
						Agregar
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={tokenModal.show}
				onClose={HandleCloseModalToken}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Token</DialogTitle>
				<DialogContent style={{display: "grid", gap: 20}}>
					<TextField
						style={{ marginRight: 5 }}
						size='small'
						label='Token'
						name="token"
						error={error}
						id="token"
						value={tokenModal.data}
						onChange={(e) => setTokenModal({...tokenModal, data: e.target.value})}
						variant='outlined'
					/>
				</DialogContent>

				<DialogActions style={{justifyContent: 'center'}}>
					<Button onClick={HandleCloseModalToken}>
						Cancelar
					</Button>
					<Button onClick={()=>HandleClickSubmit()} color='green'>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
			</>}
		</>
	);
}
