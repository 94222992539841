import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { errorBlobHandler } from "../errorHandler";

export const GetPositiveOrNegativeBalanceReport = async (isPositive: boolean): Promise<IApiResponse<string>> => {
    try {
        const response = await api.GetPositiveOrNegativeBalanceReport(isPositive);
        return {
            data: { results: response.data },
            message: response.statusText,
            status: response.status
        }
    } catch (err) {
        return errorBlobHandler(err);
    }
}

export const GetSocialWorkWithholdings = async (params: any): Promise<IApiResponse<string>> => {
    try {
        const response = await api.GetSocialWorkWithholdingsReport(params);
        return {
            data: { results: response.data },
            message: response.statusText,
            status: response.status
        }
    } catch (err) {
        return errorBlobHandler(err);
    }
}

export const GetUnpaidBills = async (params: any): Promise<IApiResponse<string>> => {
    try {
        const response = await api.GetUnpaidBillsReport(params);
        return {
            data: { results: response.data },
            message: response.statusText,
            status: response.status
        }
    } catch (err) {
        return errorBlobHandler(err);
    }
}

export const GetBillsPerPeriod = async (params: any): Promise<IApiResponse<string>> => {
    try {
        const response = await api.GetBillsPerPeriodReport(params);
        return {
            data: { results: response.data },
            message: response.statusText,
            status: response.status
        }
    } catch (err) {
        return errorBlobHandler(err);
    }
}

export const GetExportLiquidation = async (params: any): Promise<IApiResponse<string>> => {
    try {
        const response = await api.GetExportLiquidation(params.liquidationId, params.bancoId);
        return {
            data: { results: response.data },
            message: response.statusText,
            status: response.status
        }
    } catch (err) {
        return errorBlobHandler(err);
    }
}