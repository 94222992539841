import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

    const useStyles = makeStyles((theme) => ({
        heading: {
            fontSize: theme.typography.pxToRem(18),
        },
        accordionContent: {
            borderRadius: 6,
            marginBottom: 25
        }
    }));
    
export default function AccordionVersion({
    title,
    children
}){
    const classes = useStyles();
    return(
        <Accordion className={classes.accordionContent}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection:"column"}}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}