import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { setModalBillsPerPeriod } from "../../redux/modal";
import { RadioDataSourceEnum, StatusCode } from "../../enums";
import { GetBillsPerPeriod } from "../../services/services/Reports";
import Toast from "../common/Toast";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { es } from "date-fns/locale";
import { format } from 'date-fns';
import DateFnsUtils from "@date-io/date-fns";

export default function BillsPerPeriodModal() {
    const [dataToast, setDataToast] = React.useState({
        active: false,
        message: '',
        type: '',
    });
    const dispatch = useDispatch();
    const modalWithholdings = useSelector((state) => state.modal.billsPerPeriodModal);
    const [period, setPeriod] = React.useState(null);
    const [loadingActive, setLoadingActive] = React.useState(false);

    function OnClickGenerate() {
        if (period === null){
            MessageNotification("El campo 'Mes - Año' es obligatorio.", "error");
            return;
        }
        const params = {
            mes: period.getMonth() + 1,
            anio: period.getFullYear(),
        }

        HandleBillsPerPeriod(params);
    }

    async function HandleBillsPerPeriod(params) {
        try {
            setLoadingActive(true);
            const { data, status } = await GetBillsPerPeriod(params);
            if (status === StatusCode.Ok) {
                const nombreDelMes = format(period, 'MMMM', { locale: es });
                let tittleReport = `Resumen Facturas Colegio (${nombreDelMes} ${params.anio})`;
                UtilityFNExport("pdf", tittleReport, data.results);
            }
        } catch (err) {
            const errorMessage = err.message || 'No se pudo obtener el PDF.';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    //#region  UTILS
    function UtilityFNExport(extension, nameOfFile, responseApi) {
        let fileName = `${nameOfFile}.${extension}`;
        let blob = new Blob([responseApi], {
            type: "application/pdf",
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    function MessageNotification(message, type) {
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error.",
            type: type,
        });
    }
    function HandleCloseModal() {
        dispatch(setModalBillsPerPeriod(false));
        setPeriod(null);
    }
    //#endregion
    return (
        <Dialog
            open={modalWithholdings}
            onClose={HandleCloseModal}
            aria-labelledby='form-dialog-title'
        >
            {loadingActive &&
                <Dimmer className='loader-fixed' active inverted>
                    <Loader size='big'>Cargando...</Loader>
                </Dimmer>}

            <DialogTitle id='form-dialog-title' style={{ textAlign: "center" }}>
                Resumen Facturas Colegio
            </DialogTitle>
            <DialogContent style={{ overflowY: "visible" }} className="cont-default-modal">
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <>
                        <KeyboardDatePicker
                            style={{ marginRight: 5, marginTop: 5 }}
                            autoOk
                            className='size-date-picke-filter'
                            inputVariant='outlined'
                            InputAdornmentProps={{ position: 'end' }}
                            variant='inline'
                            name='month'
                            openTo='month'
                            views={['month', 'year']}
                            placeholder='Mes - Año'
                            value={period}
                            onChange={(date) => setPeriod(date)}
                        />
                    </>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button onClick={HandleCloseModal}>
                    Cancelar
                </Button>
                <Button onClick={() => OnClickGenerate()} color='blue'>
                    Generar
                </Button>
            </DialogActions>
            {dataToast.active && (
                <Toast dataToast={dataToast} setDataToast={setDataToast} />
            )}
        </Dialog>
    );
}