import React from "react";
import "./InputForm.css";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

export default function InputFormLabel({
  label,
  name,
  control,
  error,
  type,
  isRequired,
  classInput,
  value,
  textChange,
  disabled,
  isCreate,
  placeholder,
  defaultValue,
  stylesCustom
}) {
  if (control) {
    return (
      <Controller
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        style={{ margin: 5 , zIndex: stylesCustom?.zIndex}}
        className={classInput}
        label={label}
        error={error ? error[name] : null}
        as={TextField}
        required={isRequired}
        type={type}
        inputProps={{
          autoComplete: "new_" + name,
        }}
        name={name}
        id={name}
        control={control}
        variant="outlined"
        size="small"
      />
    );
  } else {
    return (
      <TextField
        disabled={disabled}
        style={{ margin: 5 , zIndex: stylesCustom?.zIndex}}
        className={classInput}
        placeholder={placeholder}
        defaultValue={defaultValue}
        label={label}
        error={error ? error[name] : null}
        required={isRequired}
        type={type}
        inputProps={{
          autoComplete: "new_" + name,
        }}
        name={name}
        id={name}
        onChange={textChange}
        variant="outlined"
        size="small"
        value={isCreate? ((parseInt(value) > 30) ? value=30 : value) : value}
      />
    );
  }
}
