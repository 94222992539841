import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IBank, IBankRequest, IBankParam } from "../../api/Interfaces/managment/ICrudBank";
import errorHandler from "../errorHandler";
import { selectListResponse } from "../interfaces/utils/IUtilDeclaration";

const GetBanks = async (params: IBankParam): Promise<IApiResponse<IBank[]>> => {
    try{
        const response = await api.GetBankList(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetBankId = async (bankId: any): Promise<IApiResponse<IBank>> => {
    try{
        const response = await api.GetBankId(bankId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetBankSelectList = async (): Promise<IApiResponse<selectListResponse[]>> => {
    try{
        const response = await api.GetBankSelectList();
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateBank = async (params: IBankRequest): Promise<IApiResponse<number>> => {
    try{
        const response = await api.CreateBank(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditBank = async (bankId: number, params: IBankRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.EditBank(bankId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivateBank = async (bankId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivateBank(bankId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteBank = async (bankId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteBank(bankId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}


export {
    CreateBank, DeleteBank, EditBank, GetBankId,
    GetBankSelectList, GetBanks, ReactivateBank
};
