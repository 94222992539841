import React from 'react';
import octusLogoAndTitle from './images/octusLogoAndTitle.png';
import octusMenuLogo from './images/octusMenuLogo.png';
import personaMirandoOctus from './images/personaMirandoOctus.png';
import tildeBeneficioOctus from './images/tildeBeneficioOctus.png';
import asociadosAOctus from './images/asociadosAOctus.png';
import propiedadesOctus from './images/propiedadesOctus.png';
import octusPropiedad_1 from './images/octusPropiedad_1.png';
import octusPropiedad_2 from './images/octusPropiedad_2.png';
import octusPropiedad_3 from './images/octusPropiedad_3.png';
import octusPropiedad_4 from './images/octusPropiedad_4.png';
import CardInfo from './utils/CardInfo';
import VersionOfProduct from '../versionOfProduct/VersionOfProduct';
const Inicio: React.FC<any> = () => {
   const [stateMenu, setStateMenu] = React.useState(false);

    return (
    <section className='cont-inicio' id='inicio'>

        <section className='cont-menu'>
           <figure className={`cont-menu-logo ${stateMenu ? 'openMenu' : ''}`}>
                <a href="#inicio" className='cont-menu-logo__img'>
                    <img src={octusMenuLogo} alt="Octus Logo" className='cont-menu-logo__img'/>    
                </a>
           </figure>
           <section className='cont-menu-navbar'>
                <button className={`cont-menu-navbar__menu ${stateMenu ? 'openMenu' : ''}`}
                onClick={()=>setStateMenu((prevState)=> !prevState)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
           </section>
            <nav className={`cont-menu-navbar-box ${stateMenu ? 'openMenuNavBar' : ''}`}>
                <ul className='cont-menu-navbar-box-list'>
                    {!window.location.pathname.includes("/versiones") && 
                    <>
                    <li className='cont-menu-navbar-box-list-btn'>
                        <a href="#profesionales-de-la-salud" className='cont-menu-navbar-box-list-btn__link'>
                            Profesionales de la Salud
                        </a>
                    </li>
                    <li className='cont-menu-navbar-box-list-btn'>
                        <a href="#asociaciones-medicas" className='cont-menu-navbar-box-list-btn__link'>
                            Asociaciones Médicas
                        </a>
                    </li>
                    <li className='cont-menu-navbar-box-list-btn'>
                        <a href="#obras-sociales-y-seguros" className='cont-menu-navbar-box-list-btn__link'>
                            Obras Sociales, Prepagas y Seguros
                        </a>
                    </li>
                    <li className='cont-menu-navbar-box-list-btn'>
                        <a href="#contacto" className='cont-menu-navbar-box-list-btn__link'>
                            Contacto
                        </a>
                    </li>
                    </>}
                    <li className='cont-menu-navbar-box-list-btn loginBtn'>
                        <a href="login" className='cont-menu-navbar-box-list-btn__link'>
                        Iniciar Sesión</a>
                    </li>
                </ul>
            </nav>
        </section>

        <section className='cont-inicio-c2'>

            <section className='cont-inicio-c2-info'>
                <figure className='cont-inicio-c2-info-logoOctus'>
                    <img src={octusLogoAndTitle} alt="Logo de Octus" className='cont-inicio-c2-info-logoOctus__img'/>
                </figure>
                <article className='cont-inicio-c2-info-info'>
                    <h3 className='cont-inicio-c2-info-info__title'>El Software de Gestión y Archivo Digital en Salud</h3>
                    <p className='cont-inicio-c2-info-info__text'>
                        Sincroniza e integra todo el proceso de facturación y validación de prácticas médicas, simplificando
                        y agilizando el trabajo de Profesionales de la Salud, Asociaciones Médicas, Obras Sociales, Prepagas y Seguros.
                    </p>
                </article>
            </section>
            {!window.location.pathname.includes("/versiones") && <a href='#contacto' className='cont-inicio-c2__button'>Descubrí</a>} 

        </section>

        {window.location.pathname.includes("/versiones") ?
            <section className='cont-inicio-versiones'>
                <VersionOfProduct/>
            </section>
            :
            <>
            <section className='cont-inicio-c3'>

            <figure className='cont-inicio-c3-info-persona'>
                <img src={personaMirandoOctus} alt="persona mirando App para celular de Octus" className='cont-inicio-c3-info-persona__img'/>
            </figure>
            <article className='cont-inicio-c3-aditionalInfo'>
                <p className='cont-inicio-c3-aditionalInfo__title'>Octus es</p>
                <section className='cont-inicio-c3-cont-aditionalInfo'>
                    <div className='cont-inicio-c3-cont-aditionalInfo-box'>
                        <img src={tildeBeneficioOctus} alt="tilde blanca"  className='cont-inicio-c3-cont-aditionalInfo-box__img'/>
                        <p className='cont-inicio-c3-cont-aditionalInfo-box__benefict'>Seguro</p>
                    </div>
                    <div className='cont-inicio-c3-cont-aditionalInfo-box'>
                        <img src={tildeBeneficioOctus} alt="tilde blanca"  className='cont-inicio-c3-cont-aditionalInfo-box__img'/>
                        <p className='cont-inicio-c3-cont-aditionalInfo-box__benefict'>Confiable</p>
                    </div>
                    <div className='cont-inicio-c3-cont-aditionalInfo-box'>
                        <img src={tildeBeneficioOctus} alt="tilde blanca"  className='cont-inicio-c3-cont-aditionalInfo-box__img'/>
                        <p className='cont-inicio-c3-cont-aditionalInfo-box__benefict'>Fácil de utilizar</p>
                    </div>
                </section>
            </article>

            </section>

            <section className='cont-inicio-c4'>

            <figure className='cont-inicio-c4-associateToOctus'>
                <img src={asociadosAOctus} alt="" className='cont-inicio-c4-associateToOctus__img'/>
            </figure>
            <figcaption className='cont-inicio-c4-associateToOctus--flex'>
                <article className='cont-inicio-c4-associateToOctus-info'>
                    <p className='cont-inicio-c4-associateToOctus__text'>
                        Garantiza un proceso transparente y permite una trazabilidad completa de toda la información, donde
                    </p>
                    <p className='cont-inicio-c4-associateToOctus__text-redColor'>
                        todas las partes se benefician.
                    </p>
                </article>
            </figcaption>

            </section>

            <section className='cont-inicio-c5'>

            <section className='cont-inicio-c5-props-box'>
            <figure className='cont-inicio-c5-props'>
                    <img src={propiedadesOctus} alt="Personas en reunion con Octus"  className='cont-inicio-c5-props__img'/>
                </figure>

                <article className='cont-inicio-c5-info-prop-box'>
                    <div className='cont-inicio-c5-info-prop-box-buttons'>
                        <button className='cont-inicio-c5-info-prop-box-buttons__button'>Profesional</button>
                        <button className='cont-inicio-c5-info-prop-box-buttons__button'>Asociación Médica</button>
                        <button className='cont-inicio-c5-info-prop-box-buttons__button'>Obras Sociales</button>
                    </div>
                    <p className='cont-inicio-c5-info-prop-box-title'>Impacto positivo para todos</p>
                </article>
            </section>

            <section className='cont-inicio-c5-box-cards'>
                <article className='cont-inicio-c5-box-cards__box'>
                    <CardInfo 
                    title="Acceso en la nube 24/7"
                    info="Desde cualquier dispositivo con conexión a internet, sin necesidad de instalación de programas."
                    aditionalText="¡La información siempre al alcance!"
                    icon={octusPropiedad_1}/>
                </article>
                <article className='cont-inicio-c5-box-cards__box'>
                    <CardInfo 
                    title="Conversión de documentos en formato digital"
                    info="Garantiza un respaldo de todos los documentos físicos."
                    icon={octusPropiedad_2}/>
                </article>
                <article className='cont-inicio-c5-box-cards__box'>
                    <CardInfo 
                    title="Reportes instantáneos e integración con otras plataformas de gestión"
                    info="Descargá reportes en formato Excel en segundos, e importalos a cualquier otro sistema de gestión,
                    facilitando la interoperabilidad y la transferencia de datos con precisión y rapidez."
                    icon={octusPropiedad_4}/>
                </article>
                <article className='cont-inicio-c5-box-cards__box'>
                    <CardInfo 
                    title="Aceleración de facturación, auditoría y pagos:"
                    info="Reduce significativamente errores, débitos inncesarios, y el tiempo dedicado a re-facturación."
                    icon={octusPropiedad_3}/>
                </article>
            </section>
            </section>
            </>
        }

    </section>
    )
}

export default Inicio