import React from 'react';
import { StatusCode } from '../../../../enums';
import { DeleteAccount } from '../../../../services/services/CrudRegional';
import GeneralModalDelete from '../../../utils/GeneralModalDelete';
import { ICrudRegionalDeleteModal } from '../../../../services/interfaces/Managment/ICrudRegional';

const DeleteModalRegional: React.FC<ICrudRegionalDeleteModal> = ({
    openModal, 
    modal,
    modalData, 
    OpenModalSelected,
    MessageNotificaction,
    setIsLoaderActive, 
    reloadData})=>{

    async function DeleteAccounts() {
        try{
            setIsLoaderActive(true);
            const {status} = await DeleteAccount(modalData);
            if (status === StatusCode.Ok){
                MessageNotificaction("Regional eliminada correctamente", "success");
                OpenModalSelected("close",modal);
                reloadData();
            }
        }catch(err: any){
            MessageNotificaction(err.response.data.errores, "error");
        }finally{
            setIsLoaderActive(false);
        }
	}

    return(
        <GeneralModalDelete
        contentText={`¿Está seguro de eliminar la regional: ${modalData.nombre}?`}
        openModal={openModal}
        HandleClose={()=> OpenModalSelected("close", modal)}
        HandleSubmit={DeleteAccounts}
        />
    )
}

export default DeleteModalRegional;