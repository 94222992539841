import Dialog from '@material-ui/core/Dialog';
import PhotoViewer from 'photoviewer';
import 'photoviewer/dist/photoviewer.css';
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import api from '../../../api';
import { TypeRole } from '../../../enums';
import DialogDelete from '../../common/DialogDelete';
export default function PhotoView({
	imagenes,
	data,
	document,
	reloadData,
	professionalId,
	MessageNotification,
	abrirModal,
	setAbrirModal,
	tipoUsuario,
}) {
	useEffect(() => {
		const opciones = {
			footerToolbar: [
				'zoomIn',
				'zoomOut',
				'prev',
				'fullscreen',
				'next',
				'rotateRight',
				'deleteI',
			],
			customButtons: {
				deleteI: {
					text:
						'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/</svg>',
					title: 'Delete image',
					click: (e)=>eliminarImagen(e),
				},
			},
			callbacks: {
				closed: function (context) {
					setAbrirModal(false);
				},
			},
		};
		if (imagenes.length === 0) return;
		let ins = new PhotoViewer(imagenes, opciones);
		setInstancia(ins);
	}, [imagenes]);
	const [instancia, setInstancia] = React.useState();
	const [dataDelete, setDataDelete] = React.useState({});
	const [openDelete, setOpenDelete] = React.useState(false);

	const eliminarImagen = (e) => {
		if (tipoUsuario !== TypeRole.Professional) {
			Swal.fire('No posee los permisos para eliminar.');
			return;
		}
		setOpenDelete(true);
		const idImgSelected = imagenes.findIndex((item)=>item.src === e.img.currentSrc);
		setDataDelete({
			documentId: document,
			imageId: data[idImgSelected].id,
			title: 'Borrar imagen',
			content: '¿Está seguro de eliminar esta imagen?',
		});
	};
	const actionConfirmDeleteImage = () => {
		api
		.deleteImage(dataDelete.documentId, dataDelete.imageId)
		.then((resp) => {
			instancia.close();
			reloadData();
			MessageNotification("Imagen eliminada con éxito.", "success");
		})
		.catch((err) => {
			MessageNotification(err.response.data.errores, "error");
		});
		setAbrirModal(false);
		setOpenDelete(false);
	};

	return (
		<>
			<DialogDelete
				open={openDelete}
				setOpen={setOpenDelete}
				title={dataDelete.title}
				content={dataDelete.content}
				actionConfirm={actionConfirmDeleteImage}
			/>
			<Dialog open={abrirModal} style={{ zIndex: 333 }} />
		</>
	);
}