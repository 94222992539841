import { IReceiptResponse } from "../../../../../api/Interfaces/managment/IReceipt";
import { IErrorObj, IReceiptForFree, IReceiptForProfessional, IReceiptForSocialWork } from "../../../../../services/interfaces/Managment/INewModalReceipt";
import { _DEFAULT_VALUES_RECEIPTS } from "../../../defaultValues";

function ValidateReceiptValues(values: IReceiptForFree | IReceiptForSocialWork | IReceiptForProfessional, dataToRender: IReceiptResponse[]){
        let objValidate = {
            validate: true,
            message: ""
        };
        const ignoreKeys = [
            "interes",
            "facturaId"
        ];
        const allValues: any = Object.entries(values).filter(input => !ignoreKeys.includes(input[0]));
        const numberInputValues = allValues.filter(input => !isNaN(input[1]) && typeof input[1] === 'number');
        let objError: IErrorObj = _DEFAULT_VALUES_RECEIPTS._ERROROBJ;

        if (allValues.some(input => input[1] === null || input[1] === "")){
            
            objValidate.validate = false;
            objValidate.message = "Todos los campos deben ser obligatorios";

            allValues.forEach(input => {
                if (input[1] === null || input[1] === ""){
                    objError = {...objError, [input[0]]: true}
                }
            });

        }else{
            if (numberInputValues.some(input => input[1] < 0 || input[1] > 9999999)){
                
                objValidate.validate = false;
                objValidate.message = "Los campos númericos deben ser mayores a cero y menor a 7 (siete) dígitos.";

                numberInputValues.forEach(input => {
                    if (input[1] < 0 || input[1] > 9999999){
                        objError = {...objError, [input[0]]: true}
                    }
                });

            }
        }

        if (dataToRender.some(input => input.numeroSucursal === values.sucursal && input.numero === values.numero)){
            objValidate.validate = false;
            objValidate.message = "El número de recibo ya existe asignado a otro recibo actualmente.";
            objError = {...objError, ["numero"]: true}
        }

        return {objValidate, objError};
    }

export default ValidateReceiptValues;