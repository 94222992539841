import { Tooltip, makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactSelect from 'react-select';
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../api';
import { Options, StatusCode } from '../../../enums';
import SelectForm from '../../common/SelectForm';
import { ReactivatePractice } from '../../../services/services/ConfigurationSocialWork';

  const useStyles = makeStyles({
        colorBtn: {backgroundColor: "#e0e1e2", color:'rgba(0,0,0,.6)'}
    });
const loadForPage = 20;
export default function PracticesForOs({
	setDataToast
}){
    const classStyle = useStyles();
    const { control } = useForm({});

    //#region VARIABLES DE ESTADO
    const [loadingActive, setLoadingActive] = useState();
    const [openNew, setOpenNew] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isCreate, setCreate] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isDelete, setDelete] = useState(false);
	const [page, setPage] = useState(1);
    const [dataTable, setDataTable] = useState([]);
	const [socialWorkList, setSocialWorkList] = useState([{}]);
	const [quantityForPage, setQuantityForPage] = useState(1);
	const [filterObj, setFilterObj] = useState({idSocialWork: null, removed: false, selectIcon: false});
	const [reactivate, setReactivate] = useState({
		reactivateModal: false,
		showReactivateIcon: false
	});
	const [socialWorkSelected, setSocialWorkSelected] = useState({
		id: null,
		nombre: null
	});
	const [openDelete, setOpenDelete] = useState({
		preModalDelete: false,
		modalDelete: false
	});
	const [loadings, setLoadings] = useState({
		socialWork: true
	})
	const [deleteValues, setDeleteValues] = useState({
		id: null,
		nombre: null,
		obraSocialId: null,
		codigo: null,
	});
    const [errors, setErrors] = useState({
		association: '',
	});
    const [newValue, setNewValue] = useState({
        nombre: null,
        codigo: null,
    });
    const [editValues, setEditValues] = useState({
        id: null,
        nombre: null,
        codigo: null
    })
    //#endregion
    
    useEffect(()=>{
		setLoadingActive(true);
        let optionCruds = JSON.parse(localStorage.getItem('optionCruds'));
		optionCruds.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
		GetAllSocialWorks();
		LoadData();
    },[])

	const GetAllSocialWorks = async ()=>{
		try{
			let {data} = await api.GetSocialWorkForSelect();
			let OsList = data.map((item)=>{
				return{
						value: item.id,
						label: item['nombre']
					};
			})
			setSocialWorkList(OsList);
		}
		catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setLoadings({
				socialWork: false
			});
		}
	}

	const LoadData = async (pageSelected = 1)=>{
		try{
			setLoadingActive(true);
			let params = {
				obraSocialId: filterObj.idSocialWork,
				removed: filterObj.removed,
				page: pageSelected,
				quantity: loadForPage,
				withPaginate: true
			};
			let {data} = await api.GetAllPractices(params);
			let response = data.resultados.map(item=>{
				return {
					id: item.id,
					nombre: item.nombre,
					codigo: item.codigo,
					obraSocial: {
						id: item.obraSocial.id,
						nombre: item.obraSocial.nombre
					}
				}
			});
			if (filterObj.removed){
				setReactivate({
					reactivateModal: false,
					showReactivateIcon: true
				});
			}else{
				setFilterObj({...filterObj, selectIcon: false})
				setReactivate({
					reactivateModal: false,
					showReactivateIcon: false
				});
			}
			setQuantityForPage(data.quantity);
			setDataTable(response);
		}
		catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setLoadingActive(false);
		}
    }
     const OnClickCreate = async () => {
		setOpenNew(false);
		setLoadingActive(true);
		try{
				if(newValue.nombre && newValue.codigo && socialWorkSelected.id){
					let bodyParams = {
						nombre: newValue.nombre,
						codigo: newValue.codigo,
						obraSocialId: socialWorkSelected.id
					}
					let query = await api.CreatePracticeWithOs(bodyParams);
					if(query.status === 200 && query.data != null){
						HandleErrorOrSuccess('success', "Práctica creada correctamente.");
					}
				}else{
					HandleErrorOrSuccess('error',"Los campos no pueden estar vacíos.");
				}
			}catch(err){
				HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
			}
			finally{
				setSocialWorkSelected({
					id: null,
					nombre: null
				});
				setNewValue({
					nombre: null,
					codigo: null,
				});
				setLoadingActive(false);
				LoadData();
		}
    }
	async function ReactivatePracticeSelected(){
		try{
			setReactivate({
				reactivateModal: false,
				showReactivateIcon: true
			});
			setLoadingActive(true);
			const {status} = await ReactivatePractice(editValues.id);
			if (status === StatusCode.Ok){
				HandleErrorOrSuccess('success', "Práctica reactivada correctamente.");
				LoadData();
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}finally{
			setLoadingActive(false);
		}
	}
    const OnClickEdit = async ()=>{
		setOpenEdit(false);
		setLoadingActive(true);
		try{
				if(editValues.nombre && socialWorkSelected.id){
					let bodyParams = {
						nombre: editValues.nombre,
						codigo: editValues.codigo,
						obraSocialId: socialWorkSelected.id
					}
					let query = await api.UpdatePracticeWithOs(editValues.id,bodyParams);
					if(query.status === 200 && query.data != null){
						HandleErrorOrSuccess('success', "Práctica editada correctamente.");
						LoadData();
					}
				}else{
					HandleErrorOrSuccess('error',"Los campos no pueden estar vacíos.");
				}
			}catch(err){
				HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
			}
			finally{
				setEditValues({
					id: null,
					nombre: null,
					codigo: null,
				});
				setSocialWorkSelected({
					id: null,
					nombre: null
				});
				setLoadingActive(false);
		}
    }
	const OnClickDelete = async ()=>{
		setOpenDelete(false);
		setLoadingActive(true);
		try{
			let query = await api.DeletePractice(deleteValues.id);
			if(query.status === 200){
				HandleErrorOrSuccess('success',"Práctica dada de baja correctamente.");
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setDeleteValues({
				id: null,
				nombre: null,
				obraSocialId: null,
				codigo: null
			})
			setLoadingActive(false);
			LoadData();
		}
	}

    //#region UTILIDADES
	const PreDelete = (id, namePractice,socialWorkId, code)=>{
		setOpenDelete({
			...openDelete,
			preModalDelete: true
		});
		setDeleteValues({
			id,
			nombre: namePractice,
			obraSocialId: socialWorkId,
			codigo: code
		});
    }
	  const handleChange = (event, value) => {
		  LoadData(value);
		  setPage(value);
	};
	const PreEdit = (id, namePractice, codePractice, socialWorkId, socialWorkName, reactivatePractice = false)=>{
		setEditValues({
			id,
			nombre: namePractice,
			codigo: codePractice,
		});
		GetSelectedSocialWork({id: socialWorkId, nombre: socialWorkName});
		if (reactivatePractice){
			setReactivate({
				...reactivate,
				reactivateModal: true
			});
		}else{
			setOpenEdit(true);
		}
    }
    const HandleCloseEdit = ()=>{
        setOpenEdit(false);
    }
    const HandleClickOpen = ()=>{
        setOpenNew(true);
    }
    const HandleCloseNew = ()=>{
        setOpenNew(false);
    }
	const HandleCloseDelete = ()=>{
		setOpenDelete({
			preModalDelete: false,
			modalDelete: false
		});
	}
	const HandleErrorOrSuccess = (type,message)=>{
		setDataToast({
			active: true,
			type: type,
			message: message
		});
	}
	const GetSelectedSocialWork = ({id, nombre})=>{
		setSocialWorkSelected({
			id,
			nombre
		});
	}
    //#endregion

    return(<>
		<Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className='header-section'>
								<div className="titleAndVideo">
									<span style={{ fontSize: 27 }}>Prácticas</span>
								</div>
								<div>
									{isCreate && 
										<Button
											className='btn-new-filter'
											onClick={() => HandleClickOpen()}
											icon
											labelPosition='left'
										>
											<Icon name='plus' />
											Nuevo
										</Button>}
								</div>
							</Grid.Row>
							<Grid.Row className="content-filter-list">
								<ReactSelect
									className="filter-input"
									placeholder="Obras Sociales"
									options={socialWorkList}
									isClearable={true}
									onChange={(e)=>setFilterObj({...filterObj, idSocialWork: e !== null ? e.value : e})}
									/>
									<FormControlLabel
									control={
										<Checkbox
											checked={filterObj.selectIcon}
											onChange={(e) => {
												setFilterObj({
													...filterObj,
													removed: e.target.checked,
													selectIcon: e.target.checked
												});
											}}
										/>
									}
									label='Dados de baja'
								/>
							</Grid.Row>
							<Button
								className='btn-search-filter'
								primary
								icon
								labelPosition='left'
								onClick={() => LoadData()}
								type='submit'
							>
								<Icon name='search' />
								Buscar
							</Button>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={2} className='color-letter-inTable'>
										<TableRow>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Nombre</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Codigo</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Obra Social</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map(({id, codigo, nombre, obraSocial}) => (
											<TableRow key={id}>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{codigo ?? "No Asignado"}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{obraSocial.nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{reactivate.showReactivateIcon ?
													<>
													<IconButton
															className='btn-circle-green'
															onClick={() => PreEdit(id,nombre,codigo, obraSocial?.id, obraSocial?.nombre, true)}
														>
														<Tooltip placement='top' title="Reactivar">
															<CachedIcon />
														</Tooltip>
													</IconButton>
													</> 
													: 
													<>
													{isEdit && (
														<IconButton
															className='btn-circle-orange'
															onClick={() => PreEdit(id,nombre,codigo, obraSocial?.id, obraSocial?.nombre)}
														>
															<Tooltip placement='top' title="Editar">
																<CreateIcon />
															</Tooltip>
														</IconButton>
													)}
													{isDelete && (
														<IconButton
															className='btn-circle-red'
															 onClick={() =>
															 	PreDelete(id,nombre,obraSocial?.id, codigo)
															 }
														>
															<Tooltip placement='top' title="Eliminar">
																<DeleteIcon />
															</Tooltip>
														</IconButton>
													)}
													</>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
				{dataTable && (
				<Grid.Row centered>
					<Pagination
					color="primary"
					className="pagination"
					count={quantityForPage}
					page={page}
					onChange={handleChange}
					/>
				</Grid.Row>
				)}
		</Grid>
		
		{openNew && (
				<Dialog
					open={openNew}
					onClose={HandleCloseNew}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title' style={{width: 350}}>Nuevo</DialogTitle>
					<DialogContent style={{overflow: 'visible', display: "flex", flexDirection: "column"}}>
						<TextField
							size='small'
							id='nombre'
							label='Nombre'
							value={newValue.nombre}
							onChange={(e) => {
								setNewValue({
									...newValue,
									nombre: e.target.value,
								});
							}}
							variant='outlined'
						/>
						<TextField
							size='small'
							id='nombre'
							label='Codigo'
							value={newValue.codigo}
							onChange={(e) => {
								setNewValue({
									...newValue,
									codigo: e.target.value
								});
							}}
							variant='outlined'
						/>
						<ReactSelect
							placeholder="Obras Sociales"
							options={socialWorkList}
							onChange={(e)=>setSocialWorkSelected({
								id: e !== null ? e.value : e, 
								nombre: e !== null ? e.label : e
							})}
							/>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={HandleCloseNew} className={classStyle.colorBtn}>
							Cancelar
						</Button>

						<Button onClick={OnClickCreate} color='green'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openEdit && (
				<Dialog
					open={openEdit}
					onClose={HandleCloseEdit}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'style={{width: 350}}>Editar</DialogTitle>
					<DialogContent style={{overflow: 'visible', display: "flex", flexDirection: "column"}}>
						<TextField
							size='small'
							id='nombre'
							label='Nombre'
							value={editValues.nombre}
							onChange={(e) => {
								setEditValues({
									...editValues,
									nombre: e.target.value,
								});
							}}
							variant='outlined'
						/>
						<TextField
							size='small'
							id='nombre'
							label='Codigo'
							value={editValues.codigo}
							onChange={(e) => {
								setEditValues({
									...editValues,
									codigo: e.target.value
								});
							}}
							variant='outlined'
						/>
						<ReactSelect
							placeholder={`${socialWorkSelected.nombre ?? "Obras sociales..."}`}
							options={socialWorkList}
							onChange={(e)=>setSocialWorkSelected({
								id: e !== null ? e.value : e, 
								nombre: e !== null ? e.label : e
							})}
						/>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={HandleCloseEdit} className={classStyle.colorBtn}>
							Cancelar
						</Button>

						<Button onClick={OnClickEdit} color='green'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openDelete.preModalDelete &&
			(<Dialog open={openDelete.preModalDelete} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>AVISO PREVIO DE ELIMINACIÓN</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<DialogContentText style={{marginBottom: 5}}>Esta Práctica '{deleteValues.nombre}', puede estar asociada a una o mas Obras Sociales.</DialogContentText>
						¿Desea seguir operando sobre esta práctica?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseDelete()}>Cancelar</Button>
					<Button
						onClick={() => setOpenDelete({preModalDelete: false, modalDelete: true})}
						color='blue'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>)
			}

		{reactivate.reactivateModal &&
			(<Dialog open={reactivate.reactivateModal} onClose={()=>setReactivate({...reactivate, reactivateModal: false})}>
				<DialogTitle id='form-dialog-title'>Reactivar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de reactivar la siguiente Práctica: {editValues.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>setReactivate({...reactivate, reactivateModal: false})}>Cancelar</Button>
					<Button
						onClick={ReactivatePracticeSelected}
						color='green'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>)
			}

			{openDelete.modalDelete &&
			(<Dialog open={openDelete.modalDelete} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de eliminar la siguiente Práctica: {deleteValues.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseDelete()}>Cancelar</Button>
					<Button
						onClick={() => OnClickDelete(deleteValues.id)}
						color='red'
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>)
			}
    </>)
}