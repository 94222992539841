import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import FormGroup from "@material-ui/core/FormGroup";
import { Table, Icon, Grid, Button, Card } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../../api";
import { useHistory, useParams } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import { Dimmer, Loader } from "semantic-ui-react";

export default function SettingExcelEdit({
  setDataToast,
  dataToast,
  options,
  viewOptions,
  handleChange,
  loadingActive,
  setViewOptions,
  setLoadingActive,
  shortError,
}) {
  const history = useHistory();
  const TableRowData = ({ item }) => {
    return (
      <TableRow colSpan={6} key={item.Id}>
        <FormGroup row style={{ justifyContent: "center" }}>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarDNIPac}
                  onChange={handleChange}
                  name={"MostrarDNIPac"}
                  className={item.MostrarDNIPac ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar DNI Paciente"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarCoseguro}
                  onChange={handleChange}
                  name={"MostrarCoseguro"}
                  className={item.MostrarCoseguro ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Coseguro"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarMonto}
                  onChange={handleChange}
                  name={"MostrarMonto"}
                  className={item.MostrarMonto ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Montos"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarDiagnostico}
                  onChange={handleChange}
                  name={"MostrarDiagnostico"}
                  className={item.MostrarDiagnostico ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Diagnóstico"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarFechaPraSes}
                  onChange={handleChange}
                  name={"MostrarFechaPraSes"}
                  className={item.MostrarFechaPraSes ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Fecha Pra. Sesión"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarFechaPresc}
                  onChange={handleChange}
                  name={"MostrarFechaPresc"}
                  className={item.MostrarFechaPresc ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Fecha Prescriptor"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarFechadeTodaslasSes}
                  onChange={handleChange}
                  name={"MostrarFechadeTodaslasSes"}
                  className={
                    item.MostrarFechadeTodaslasSes ? "c-checkbox-cancel" : ""
                  }
                />
              }
              label={"Mostrar Fecha de Todas las Sesiones"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarLic}
                  onChange={handleChange}
                  name={"MostrarLic"}
                  className={item.MostrarLic ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Licenciado"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarMatPresc}
                  onChange={handleChange}
                  name={"MostrarMatPresc"}
                  className={item.MostrarMatPresc ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Matrícula del Prescriptor"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarMatdelProf}
                  onChange={handleChange}
                  name={"MostrarMatdelProf"}
                  className={item.MostrarMatdelProf ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Matrícula del Profesional"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarMedico}
                  onChange={handleChange}
                  name={"MostrarMedico"}
                  className={item.MostrarMedico ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Médico"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarNombrePac}
                  onChange={handleChange}
                  name={"MostrarNombrePac"}
                  className={item.MostrarNombrePac ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Nombre Paciente"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarNumAfiliado}
                  onChange={handleChange}
                  name={"MostrarNumAfiliado"}
                  className={item.MostrarNumAfiliado ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar N° Afiliado"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarNumAut}
                  onChange={handleChange}
                  name={"MostrarNumAut"}
                  className={item.MostrarNumAut ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar N° Autorización"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarNumSin}
                  onChange={handleChange}
                  name={"MostrarNumSin"}
                  className={item.MostrarNumSin ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar N° Siniestro"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarOS}
                  onChange={handleChange}
                  name={"MostrarOS"}
                  className={item.MostrarOS ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Obra Social"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarPlanOS}
                  onChange={handleChange}
                  name={"MostrarPlanOS"}
                  className={item.MostrarPlanOS ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Plan Obra Social"}
            />
          </TableCell>
          <TableCell className="swal-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.MostrarSesiones}
                  onChange={handleChange}
                  name={"MostrarSesiones"}
                  className={item.MostrarSesiones ? "c-checkbox-cancel" : ""}
                />
              }
              label={"Mostrar Sesiones"}
            />
          </TableCell>
        </FormGroup>
      </TableRow>
    );
  };
  const SaveChanges = (optionsChecked) => {
    let body = {
      id: optionsChecked.Id,
      mostrarMatriculadelProfesional: optionsChecked.MostrarMatdelProf,
      mostrarDNIPaciente: optionsChecked.MostrarDNIPac,
      mostrarDiagnostico: optionsChecked.MostrarDiagnostico,
      mostrarFechaPrimeraSesion: optionsChecked.MostrarFechaPraSes,
      mostrarFechaPrescriptor: optionsChecked.MostrarFechaPresc,
      mostrarFechaTotalSesiones: optionsChecked.MostrarFechadeTodaslasSes,
      mostrarLicenciado: optionsChecked.MostrarLic,
      mostrarMatriculaPrescriptor: optionsChecked.MostrarMatPresc,
      mostrarMedico: optionsChecked.MostrarMedico,
      mostrarNombrePaciente: optionsChecked.MostrarNombrePac,
      mostrarNumeroAfiliado: optionsChecked.MostrarNumAfiliado,
      mostrarNumeroAutorizacion: optionsChecked.MostrarNumAut,
      mostrarNumeroSiniestro: optionsChecked.MostrarNumSin,
      mostrarObraSocial: optionsChecked.MostrarOS,
      mostrarPlanObraSocial: optionsChecked.MostrarPlanOS,
      mostrarSesiones: optionsChecked.MostrarSesiones,
      mostrarMonto: optionsChecked.MostrarMonto,
      mostrarCoseguro: optionsChecked.MostrarCoseguro
    };
    setLoadingActive(true);
    api
      .saveTemplateExcelById(body)
      .then((res) => {
        setLoadingActive(false);
        setDataToast({
          active: true,
          message: "Registro actualizado correctamente.",
          type: "success",
        });
        setViewOptions(false);
      })
      .catch((err) => {
        shortError("Acaba de ocurrir un error");
        setLoadingActive(false);
      });
  };
  return (
    <>
      <Grid>
        <Grid.Row>
          <Card className="color-border-dash">
            <CardContent className="center-responsive-filters">
              <Grid.Row className='header-section'>
                <div className="titleAndVideo">
                  <span style={{ fontSize: 25, lineHeight: "1.03", textAlign: "left"}}>Exportar Excel</span>
                </div>
                <div>
                  <Button
                    className="btn-new-filter btn-filter-position"
                    onClick={() => SaveChanges(options)}
                    icon
                    disabled={!viewOptions}
                    labelPosition="left"
                  >
                    <Icon name="save" />
                    Guardar
                  </Button>
                   <Button
                  className="btn-back-filter"
                  onClick={() => setViewOptions(false)}
                  icon
                  labelPosition="left"
                >
                  <Icon name="reply" />
                  Volver
                </Button>
                </div>
              </Grid.Row>
            </CardContent>
          </Card>
        </Grid.Row>
        <Grid.Row>
          <TableContainer component={Paper} id="color-letter-table-tasks">
            <Table aria-label="spanning table">
              <TableBody>
                <TableRowData item={options} />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid.Row>
      </Grid>
    </>
  );
}
