import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeView from 'react-tree-checkbox';
import { setDataAccreditationBalanceForLiquidation, setDataAccreditationForLiquidation, setDataDescountForLiquidation, setDataInstallmentForLiquidation } from '../../../../redux/generateLiquidation';
import { IAccreditationArrToAssign, IAccreditationBalanceArrToAssign, IAccreditationBalanceProf, IAccreditationProf, IDescountArrToAssign, IDescountForLiquidation, IDescountNodesToRender, IDescountProf, IInstallmentArrToAssign, IInstallmentProf, IObjNodesSelected } from '../../../../services/interfaces/Managment/IDescountForLiquidation';
import { FormatToString, UniqueId } from '../../../utils/FormatInputs';
import { _DEFAULT_DESCOUNT_LIQUIDATION_VALUES } from '../../defaultValues';

const DescountForLiquidation: React.FC<IDescountForLiquidation> = ({
  aditionalInfo,
}) => {
    const {descuentos, cuotas, acreditaciones, acreditacionesSaldo}: any = useSelector<any>(({generateLiquidation}) => generateLiquidation);
    const [nodeToRender, setNodeToRender] = React.useState<IDescountNodesToRender[]>(_DEFAULT_DESCOUNT_LIQUIDATION_VALUES._NODES_CHECKBOXTREE);
    const [expanded, setExpanded] = React.useState<number[]>([_DEFAULT_DESCOUNT_LIQUIDATION_VALUES._DESCOUNTID,_DEFAULT_DESCOUNT_LIQUIDATION_VALUES._ACCREDITATIONID,_DEFAULT_DESCOUNT_LIQUIDATION_VALUES._INSTALLMENTID, _DEFAULT_DESCOUNT_LIQUIDATION_VALUES._ACCREDITATION_BALANCE]);
    const disparador = useDispatch();
    const treeRef = React.useRef<any>(null);

    React.useEffect(()=>{
      HandleSetNodeToRender();
    },[]);

    function HandleSetNodeToRender(){
      const arrNode: IDescountNodesToRender[] = [];
      //descuentos
      if (aditionalInfo.descountList.length > 0) arrNode.push(HandleSetDescountList(aditionalInfo.descountList));
      //cuotas
      if (aditionalInfo.installmentList.length > 0) arrNode.push(HandleSetInstallmentList(aditionalInfo.installmentList));
      //acreditaciones
      if (aditionalInfo.accreditationList.length > 0) arrNode.push(HandleSetAccreditationList(aditionalInfo.accreditationList));
      //acreditarSaldo
      if (aditionalInfo.accreditationBalanceList.length > 0) arrNode.push(HandleSetAccreditationBalanceList(aditionalInfo.accreditationBalanceList));

      //arbol de checkbox
      setNodeToRender(arrNode);
    }

    //#region SET VARIABLES CHECKBOX TREE
    function HandleSetDescountList(descountList: any){
      //agrupamos los descuentos por profesional
      let descountProf: IDescountProf[] = descountList.reduce((acc, actualValue) => {
        const {id} = actualValue.profesional;

        if (!acc[id]){
          acc[id] = [];
        }

        acc[id].push({
          id: actualValue.id,
          idProf: actualValue.profesional.id,
          monto: actualValue.monto,
          fecha: FormatToString(actualValue.fecha),
          descripcion: actualValue.descripcion,
          profesionalNombre: actualValue.profesional.nombre
        });

        return acc;
      },{});

      let descountByProfToCheckbox: any = Object.values(descountProf);

        return {
          id: _DEFAULT_DESCOUNT_LIQUIDATION_VALUES._DESCOUNTID,
          value: 'descountList',
          text: 'Descuentos',
          status: descuentos.length > 0,
          nodes: descountByProfToCheckbox.map((prof: any) => {
            return {
              value: prof[0].idProf,
              id: UniqueId(),
              status: descuentos.length > 0 ? (descuentos.find(descountMemorized => +descountMemorized.idProfesional === prof[0].idProf) !== undefined ? true : false) : false,
              text: `${prof[0].profesionalNombre}`,
              nodes: prof.map((descount) => (
                [{
                  value: descount.id,
                  id: UniqueId(),
                  text: `${descount.fecha} - ${descount.descripcion} - $ ${descount.monto}`,
                  status: descuentos.length > 0 ? (descuentos.find(descountMemorized => descountMemorized.descuentosId === descount.id) !== undefined ? true : false) : false,
                  nodes: []
                }]
              )).flat()
            }
          })
        }
      
    }
    function HandleSetAccreditationList(accreditationList: any){
      //agrupamos las acreditaciones por profesional
      let accreditationProf: IAccreditationProf[] = accreditationList.reduce((acc, actualValue) => {
        const {id} = actualValue.profesional;

        if (!acc[id]){
          acc[id] = [];
        }

        acc[id].push({
          id: actualValue.id,
          idProf: actualValue.profesional.id,
          monto: actualValue.monto,
          fecha: FormatToString(actualValue.fecha),
          descripcion: actualValue.descripcion,
          profesionalNombre: `${actualValue.profesional.matricula} - ${actualValue.profesional.apellido}, ${actualValue.profesional.nombre}`
        });

        return acc;
      },{});

      let accreditationByProfToCheckbox = Object.values(accreditationProf);

      return {
        id: _DEFAULT_DESCOUNT_LIQUIDATION_VALUES._ACCREDITATIONID,
        value: 'accreditationList',
        text: 'Acreditaciones',
        status: acreditaciones.length > 0,
        nodes: accreditationByProfToCheckbox.map((prof: any) => {
          return {
            value: prof[0].idProf,
            id: UniqueId(),
            status: acreditaciones.length > 0 ? (acreditaciones.find(accreditationMemorized => +accreditationMemorized.idProfesional === prof[0].idProf) !== undefined ? true : false) : false,
            text: `${prof[0].profesionalNombre}`,
            nodes: prof.map((accreditation) => (
              [{
                value: accreditation.id,
                id: UniqueId(),
                text: `${accreditation.fecha} - ${accreditation.descripcion} - $ ${accreditation.monto}`,
                status: acreditaciones.length > 0 ? (acreditaciones.find(accreditationMemorized => accreditationMemorized.acreditacionesId === accreditation.id) !== undefined ? true : false) : false,
                nodes: []
              }]
            )).flat()
          }
        })
      }
    }
    function HandleSetInstallmentList(installmentList: any){
      //agrupamos las cuotas por profesional
      let installmentProf: IInstallmentProf[] = installmentList.reduce((acc, actualValue) => {
        const {id} = actualValue.profesional;
        if (!acc[id]){
          acc[id] = [];
        }
        acc[id].push({
         id: actualValue.id,
         idProf: actualValue.profesional.id,
         monto: actualValue.monto,
         fecha: FormatToString(actualValue.fecha),
         numeroCuota: actualValue.numero,
         descripcion: actualValue.descripcion,
         profesionalNombre: actualValue.profesional.nombre
       });
       return acc;
     },{});
     let installmentByProfToCheckbox = Object.values(installmentProf);
     return {
       id: _DEFAULT_DESCOUNT_LIQUIDATION_VALUES._INSTALLMENTID,
       value: 'installmentList',
       text: 'Cuotas',
       status: cuotas.length > 0,
       nodes: installmentByProfToCheckbox.map((prof: any) => {
         return {
           value: prof[0].idProf,
           id: UniqueId(),
           status: cuotas.length > 0 ? (cuotas.find(installmentMemorized => +installmentMemorized.idProfesional === prof[0].idProf) !== undefined ? true : false) : false,
           text: `${prof[0].profesionalNombre}`,
           nodes: prof.map((installment)=> (
            [{
              value: installment.id,
              id: UniqueId(),
              text: `${installment.fecha} - Cuota N°: ${installment.numeroCuota} - $ ${installment.monto}`,
              status: cuotas.length > 0 ? (cuotas.find(installmentMemorized => installmentMemorized.cuotasId === installment.id) !== undefined ? true : false) : false,
              nodes: []
            }]
           )).flat()
         }
       })
     }
    }
    function HandleSetAccreditationBalanceList(accreditationBalanceList: any){
      //agrupamos las acreditaciones saldo por profesional
      let accreditationBalanceProf: IAccreditationBalanceProf[] = accreditationBalanceList.reduce((acc, actualValue) => {
        const {id} = actualValue.profesional;

        if (!acc[id]){
          acc[id] = [];
        }

        acc[id].push({
          idProf: actualValue.profesional.id,
          saldo: actualValue.saldo,
          profesionalNombre: `${actualValue.matriculaProfesional} - ${actualValue.profesional.nombre}`,
          seleccionado: actualValue.seleccionado
        });

        return acc;
      },{});

      let accreditationBalanceProfByProfToCheckbox = Object.values(accreditationBalanceProf);

      return {
        id: _DEFAULT_DESCOUNT_LIQUIDATION_VALUES._ACCREDITATION_BALANCE,
        value: 'accreditationBalanceList',
        text: 'Acreditaciones Saldo',
        status: acreditacionesSaldo.length > 0,
        nodes: accreditationBalanceProfByProfToCheckbox.map((prof: IAccreditationBalanceProf) => {
          return {
            value: prof[0].idProf,
            id: UniqueId(),
            status: acreditacionesSaldo.length > 0 ? (acreditacionesSaldo.find(accreditationBalanceMemorized => +accreditationBalanceMemorized.idProfesional === prof[0].idProf) !== undefined ? true : false) : false,
            text: `$ ${prof[0].saldo} - ${prof[0].profesionalNombre}`,
            nodes: []
          }
        }).flat()
      }
    }
    //#endregion

    //#region UTILS
    const handleExpand = (newArray) => {
      setExpanded([...newArray]);
    };
    const handleCheck = (treeNodes: IDescountNodesToRender[]) => {
      setNodeToRender([...treeNodes]);

      let objNodesSelected: IObjNodesSelected = {};

      treeNodes.forEach((tree)=>{
        if (tree.status){
            tree.nodes.forEach(childTree => {
              if (childTree.status){
                objNodesSelected = {
                  ...objNodesSelected,
                  [tree.text]: {
                    ...objNodesSelected[tree.text],
                    [childTree.value]: childTree.nodes.filter(item => item.status === true).map(({value}) => value)
                  }
                }
              }
            })
        }
      });
      
      //agregar en variable de estado en descuentos
      let descountArr: IDescountArrToAssign[] = [];
      if (objNodesSelected["Descuentos"] !== undefined){
          Object.entries(objNodesSelected["Descuentos"]).forEach((descount: any) => {
            descount[1].forEach((descountId: any)=> {
              descountArr.push({
                idProfesional: +descount[0],
                descuentosId: descountId
              })
            })
        });
        disparador(setDataDescountForLiquidation(descountArr));
      }else{
        disparador(setDataDescountForLiquidation(descountArr));
      }
      //agregar en variable de estado en acreditaciones
      let accreditationArr: IAccreditationArrToAssign[] = [];
      if (objNodesSelected["Acreditaciones"] !== undefined){
          Object.entries(objNodesSelected["Acreditaciones"]).forEach((accreditation: any) => {
          accreditation[1].forEach((accreditationId)=>{
            accreditationArr.push({
              idProfesional: +accreditation[0],
              acreditacionesId: accreditationId
            })
          })
        });
        disparador(setDataAccreditationForLiquidation(accreditationArr));
      }else{
        disparador(setDataAccreditationForLiquidation(accreditationArr));
      }
      //agregar en variable de estado en cuotas
      let installmentArr: IInstallmentArrToAssign[] = []
      if (objNodesSelected["Cuotas"] !== undefined){
          Object.entries(objNodesSelected["Cuotas"]).forEach((installment: any) => {
          installment[1].forEach((installmentId) => {
            installmentArr.push({
              idProfesional: +installment[0],
              cuotasId: installmentId
            })
          })
        });
        disparador(setDataInstallmentForLiquidation(installmentArr));
      }else{
        disparador(setDataInstallmentForLiquidation(installmentArr));
      }
      //agregar en variable de estado en acreditaciones de saldo
      let accreditationBalanceArr: IAccreditationBalanceArrToAssign[] = [];
      if (objNodesSelected["Acreditaciones Saldo"] !== undefined){
          accreditationBalanceArr = Object.entries(objNodesSelected["Acreditaciones Saldo"]).map((accreditation: any) => {
          return {
            idProfesional: +accreditation[0]
          }
        });
        disparador(setDataAccreditationBalanceForLiquidation(accreditationBalanceArr));
      }else{
        disparador(setDataAccreditationBalanceForLiquidation(accreditationBalanceArr));
      }
    };
    const handleNodeClick = (nodeobj) => {
      console.log(nodeobj);
    };
    //#endregion
  
    return (
      <div>
        <TreeView
          style={{margin: 1}}
          ref={treeRef}
          filternodes={nodeToRender}
          expanded={expanded}
          handleExpand={handleExpand}
          changeState={handleCheck}
          column={12}
          onNodeClick={handleNodeClick}
          onNodeClickOptions={{
            allowExpand: true, // pass false if you dont want to expand node on node click
            key: 'text', // can be any key of the node eg: id, value status
            delimiter: '/',
          }}
          customStyling={{
            fontSize: '16px',
            fontWeight: '400',
            color: 'black',
          }}
          horizontalSpacing={'12px'}
          verticalSpacing={'15px'}
          borderLeft={'1px dotted red'}
          // allowCheck={false}
          allowDelete={false}
          allowAdd={false}
          icons={{
            compressIcon: <ArrowRightOutlinedIcon/>,
            expandIcon: <ArrowDropDownOutlinedIcon/>,
            nodeCompressIcon: <></>,
            nodeExpandIcon: <></>,
            nonNodeIcon: <></>,
          }}
        />
      </div>
    );
}

export default DescountForLiquidation;