import React, { useState, useEffect } from "react";
import api from "../../api";
import {
  Paper,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TableCell,
  TextField,
  IconButton,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Table, Icon, Grid, Button, Card } from "semantic-ui-react";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";
import CardContent from "@material-ui/core/CardContent";
import { Options, TypeRole } from "../../enums";

export default function Account({ setDataToast }) {
  const history = useHistory();
  const [open, setOpenNew] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const [dataTable, setDataTable] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [viewData, setViewData] = useState();
  const [deleteDialog, setDeleteDialog] = useState({
    id: undefined,
    open: false,
  });
  const [defaultValues, setDefaultValues] = useState({
    id: undefined,
    name: "",
  });
  const [isView, setView] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [TypeRoles, setTypeRoles] = useState();

  const handleClickOpen = () => {
    setOpenNew(true);
  };
  const handleCloseNew = () => {
    setOpenNew(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleImputChange = (e) => {
    setName(e.target.value);
  };
  const handleImputChangeEdit = (e) => {
    setDefaultValues({ ...defaultValues, name: e.target.value });
  };
  const preDeleteAccount = (id) => {
    setDeleteDialog({ open: true, id: id });
  };
  const closePreDelete = () => {
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  useEffect(() => {
    let options = JSON.parse(localStorage.getItem("optionConfig_Account"));
    let TypeRolUser = JSON.parse(localStorage.getItem('TypeRole'));
    
    options.forEach((element) => {
      if (element == Options.isView || TypeRolUser == TypeRole.SuperAdmin) {
        setView(true);
      } else if (
        element == Options.isCreate ||
        TypeRolUser == TypeRole.SuperAdmin
      ) {
        setCreate(true);
      } else if (element == Options.isEdit || TypeRolUser == TypeRole.SuperAdmin) {
        setEdit(true);
      } else if (
        element == Options.isDelete ||
        TypeRolUser == TypeRole.SuperAdmin
      ) {
        setDelete(true);
      }
    });
  }, []);

  const onClickCreate = () => {
    if (name.trim()) {
      let body = {
        nombre: name.trim(),
        activo: 1,
        rol: TypeRole.Admin,
      };
      api
        .PostAccount(body) /////FALTA CONFIGURAR ENDPOINT!!!!!!!
        .then(() => {
          setDataToast({
            active: true,
            message: "Nombre creado correctamente.",
            type: "success",
          });
          onClickVerList();
        })

        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
        });

      setError(false);
      handleCloseNew();
    } else {
      setError(true);
      setDataToast({
        active: true,
        message: "Debe ingresar un nombre válido",
        type: "error",
      });
    }
  };

  const onClickVerList = () => {
    api
      .GetAccountsList() ///FALTA CONFIGURAR ENDPOINT
      .then((res) => {
        let data = res;
        let list = [];

        data.forEach((item) => {
          if (item.id !== 0 && item.activo == true) {
            list.push({
              id: item.id,
              nombre: item.nombre,
            });
          }
        });

        setDataTable(null);
        setDataTable(list);
      })
      .catch((err) => {
        setDataToast({
          active: true,
          type: "error",
          message: "Ocurrió un error.",
        });
      });
  };

  const getAccountEdit = (id) => {
    api
      .getAccount(id) ////FALTA CONFIGURAR ENDPOINT
      .then((res) => {
        let data = res;

        setDefaultValues({
          id: data[0].id,
          name: data[0].nombre,
        });

        setOpenEdit(true);
        setViewData(true);
      })
      .catch((err) => {
        setViewData(false);
        setDataToast({
          active: true,
          type: "error",
          message: "Ocurrió un error.",
        });
      });
  };

  const EditAccount = () => {
    if (defaultValues.name.trim()) {
      let body = {
        nombre: defaultValues.name.trim(),
        activo: 1,
      };
      api
        .PutAccount(defaultValues.id, body) /////FALTA CONFIGURAR ENDPOINT
        .then((res) => {
          setDataToast({
            active: true,
            message: "Nombre editado correctamente.",
            type: "success",
          });
          onClickVerList();
        })
        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
        });
      setError(false);
      setOpenEdit(false);
    } else {
      setError(true);
      setDataToast({
        active: true,
        message: "Debe ingresar un nombre válido",
        type: "error",
      });
    }
  };
  const actionConfirmDelete = (id) => {
    if (id) {
      let activo = 0;
      api
        .deleteAccount(id, activo) ////FALTA CONFIGURAR ENDPOINT
        .then((resp) => {
          let newDataTable = [];
          dataTable.forEach((account) => {
            if (account.id !== id && activo == 1) {
              newDataTable.push(account);
            }
          });

          setDataTable(null);
          setDataTable(newDataTable);
          setDataToast({
            active: true,
            message: "Dado de baja con éxito",
            type: "success",
          });
          closePreDelete();
          onClickVerList();
        })
        .catch((err) => {
          setDataToast({
            active: true,
            message: "Acaba de ocurrir un error",
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Card className="color-border-dash">
            <CardContent className="center-responsive-filters">
              <Grid.Row style={{ marginBottom: 30 }}>
                <span style={{ fontSize: 27 }}>Cuenta</span>
                {isCreate == true && (
                  <Button
                    className="btn-new-filter"
                    onClick={() => handleClickOpen()}
                    icon
                    labelPosition="left"
                  >
                    <Icon name="plus" />
                    Nuevo
                  </Button>
                )}
                <Dialog
                  open={open}
                  onClose={handleCloseNew}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Nueva Cuenta</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      type="text"
                      placeholder="Ingrese nombre"
                      name="nombre"
                      onChange={handleImputChange}
                      fullWidth
                      error={error}
                    />
                  </DialogContent>

                  <DialogActions
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={() => handleCloseNew()} color="blue">
                      Cancelar
                    </Button>
                    <Button onClick={() => onClickCreate()} color="blue">
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid.Row>
              <Grid.Row>
                <Button
                  className="btn-search-filter"
                  primary
                  icon
                  labelPosition="left"
                  onClick={() => onClickVerList()}
                  type="submit"
                >
                  <Icon name="search" />
                  Buscar
                </Button>
              </Grid.Row>
            </CardContent>
          </Card>
        </Grid.Row>
        {dataTable && dataTable.length > 0 && (
          <Grid.Row>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <Table aria-label="spanning table">
                <TableHead colSpan={2} className="color-letter-inTable">
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="letter-title-table tex "
                    >
                      <b>N° Cuenta</b>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="letter-title-table"
                    >
                      <b>Nombre</b>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="letter-title-table"
                    >
                      <b>Opciones</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map(({ id, nombre }) => (
                    <TableRow key={id}>
                      <TableCell
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {id}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {nombre}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {isEdit == true && (
                          <IconButton
                            className="btn-circle-orange"
                            onClick={() => getAccountEdit(id)}
                          >
                            <CreateIcon />
                          </IconButton>
                        )}
                        {isDelete == true && (
                          <IconButton
                            className="btn-circle-red"
                            onClick={() => preDeleteAccount(id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {isView == true && (
                          <IconButton
                            className="btn-circle-blue"
                            onClick={() => history.push("/cuentas/usuarios")}
                          >
                            <GroupIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid.Row>
        )}
        {dataTable && dataTable.length === 0 && (
          <Grid.Row style={{ textAlign: "-webkit-center" }}>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <TableCell>
                <h5>No se encontraron resultados</h5>
              </TableCell>
            </TableContainer>
          </Grid.Row>
        )}
      </Grid>

      {viewData && (
        <Dialog
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Editar Registro</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              name="nombre"
              placeholder="Ingrese nuevo nombre"
              onChange={handleImputChangeEdit}
              value={defaultValues.name}
              fullWidth
              error={error}
            />
          </DialogContent>
          <DialogActions
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => handleCloseEdit()} color="blue">
              Cancelar
            </Button>
            <Button onClick={() => EditAccount()} color="blue">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {deleteDialog != undefined && (
        <Dialog open={deleteDialog.open} onClose={closePreDelete}>
          <DialogTitle id="form-dialog-title">Borrar registro</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro de eliminar el registro de la cuenta N°{" "}
              {deleteDialog.id}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closePreDelete()}>Cancelar</Button>
            <Button
              onClick={() => actionConfirmDelete(deleteDialog.id)}
              color="red"
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
