import React from 'react'
import GeneralSimpleList from '../../../utils/GeneralSimpleList';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { IInstallmentList } from '../../../../api/Interfaces/managment/ICrudInstallment';
import { GetPreviewInstallment } from '../../../../services/services/CrudInstallment';
import Checkbox from '@material-ui/core/Checkbox';
import { IPreviewViewInstallment } from '../../../../services/interfaces/Managment/IPreviewVIewInstallment';

const PreviewViewInstallment: React.FC<IPreviewViewInstallment> = ({values, isEdit, MessageNotification})=>{
    const [installmentList, setInstallmentList] = React.useState<IInstallmentList[]>([]);

    React.useEffect(() => {
        if(isEdit){
            setInstallmentList(values.cuotas);
        }else{
            GetPreview();
        }
    }, []);

    async function GetPreview(){
        try{
            const params: any = {
                importe: values.importe,
                cantidadCuotas: values.cantidadCuotas,
                fecha: values.fecha
            }
            const {status, data} = await GetPreviewInstallment(params);
            if (status === 200){
                setInstallmentList(data?.results ?? []);
            }
        }catch(err: any){
            MessageNotification(err.message,"error");
        }
    }
    
    return(
        <GeneralSimpleList
        isLoaderActive={false}
        dataToRender={installmentList}
        paginated={false}>
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Número</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Importe</b>
                        </TableCell>
                        {isEdit &&
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Saldada</b>
                        </TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {installmentList.map((item,i) => (
                        <TableRow className={`${item.fechaDeBaja ? "rowDeleted" : ""}`}>
                            <TableCell className='letter-title-table c-text-center'>
                                {item.numero}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                {new Date(item.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                $ {item.importe}
                            </TableCell>
                            {isEdit && 
                            <TableCell className='letter-title-table c-text-center'>
                                <Checkbox color="secondary" checked={Boolean(item.fechaPago)}/>
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralSimpleList>
    )
}

export default PreviewViewInstallment;