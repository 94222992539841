import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { es } from 'date-fns/locale';
import React from 'react';
import { IReceiptFree } from "../../../services/interfaces/Managment/IReceiptForProfesional";

const ReceiptForFree: React.FC<IReceiptFree> = ({
receiptFree,
errorObj,
isView,
setReceiptFree
}) => {

    //#region UTILS
    function HandleChangeDates(date: MaterialUiPickersDate, name: string){
        setReceiptFree({
            ...receiptFree,
            [name]: date
        });
    }
    function HandleChangeCheckboxFree(selectedCheckbox: any){
        setReceiptFree({
            ...receiptFree,
            [selectedCheckbox.target.name]: selectedCheckbox.target.checked
        });
    }
    function HandleChangeInputFreeReceipt(input){
        setReceiptFree({
            ...receiptFree,
            [input.target.name]: input.target.value === "" ? null : input.target.value
        });
    }
    function HandleChangeNumberInputFreeReceipt(input){
        setReceiptFree({
            ...receiptFree,
            [input.target.name]: input.target.value === "" ? null : +input.target.value
        });
    }
    //#endregion
    
    return(
        <Grid className='cont-receipt'>
            <Grid className='cont-receipt cont-receipt--grid'>
            <TextField
            value={receiptFree.sucursal}
            label='Sucursal'
            type='number'
            error={errorObj?.sucursal}
            id="sucursal"
            variant="outlined"
            size="small"
            name='sucursal'
            disabled={isView}
            InputLabelProps={{shrink: true}}
            InputProps={{ inputProps: {max: 9999999, min: 0}}}
            onChange={(e)=>HandleChangeNumberInputFreeReceipt(e)}
            />
            <TextField
            value={receiptFree.numero}
            label='Número'
            type='number'
            name='numero'
            id="numero"
            error={errorObj?.numero}
            InputProps={{ inputProps: {max: 9999999, min: 0}}}
            variant="outlined"
            disabled={isView}
            size="small"
            InputLabelProps={{shrink: true}}
            onChange={(e)=>HandleChangeNumberInputFreeReceipt(e)}
            />
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                style={{margin: 0}}
                autoOk
                className="size-date-picke-filter"
                clearable
                value={receiptFree.fecha}
                label="Fecha"
                error={errorObj?.fecha}
                id="fecha"
                name='fecha'
                disabled={true}
                placeholder="Fecha"
                onChange={(e)=>HandleChangeDates(e, "fecha")}
                format="dd/MM/yyyy"
                margin="normal"
                InputLabelProps={{
                    shrink: true
                }}
                inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid className='cont-receipt cont-receipt--grid'>
                <TextField
                value={receiptFree.nombre}
                label='Nombre'
                type='text'
                disabled={isView}
                error={errorObj?.nombre}
                name='nombre'
                id="nombre"
                variant="outlined"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeInputFreeReceipt(e)}
                />
            </Grid>
            <Grid className='cont-receipt cont-receipt--grid'>
                <TextField
                value={receiptFree.monto}
                label='Monto'
                id="monto"
                disabled={isView}
                type='number'
                InputProps={{ inputProps: {max: 9999999, min: 0}}}
                name='monto'
                error={errorObj?.monto}
                variant="outlined"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeNumberInputFreeReceipt(e)}
                />
                <TextField
                value={receiptFree.interes}
                label='Interés'
                type='number'
                id="interes"
                disabled={isView}
                InputProps={{ inputProps: {max: 9999999, min: 0}}}
                error={errorObj?.interes}
                name='interes'
                variant="outlined"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeNumberInputFreeReceipt(e)}
                />
                <Grid>
                    <FormControlLabel
                    control={
                        <Checkbox
                            disabled={isView}
                            checked={receiptFree.deposito}
                            name="deposito"
                            onChange={(e)=>HandleChangeCheckboxFree(e)}
                        />
                    }
                    label='Deposito'
                    />
                </Grid>
            </Grid>
            <Grid className='cont-receipt cont-receipt--grid'>
                <TextField
                value={receiptFree.enConceptoDe}
                label='En concepto de:'
                error={errorObj?.enConceptoDe}
                type='text'
                disabled={isView}
                id="enConceptoDe"
                variant="outlined"
                size="small"
                name='enConceptoDe'
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeInputFreeReceipt(e)}
                />
            </Grid>
        </Grid>
    );
}

export default ReceiptForFree;