import React from 'react';
import octusBeneficioPersona_1 from './images/octusBeneficioPersona_1.png';
import octusBeneficioPersona_2 from './images/octusBeneficioPersona_2.png';
import octusBeneficioPersona_3 from './images/octusBeneficioPersona_3.png';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';

const Beneficios: React.FC<any> = () => {
    return (
        <>
        <section className='cont-benefict-c1' id="profesionales-de-la-salud">
            <article className='cont-benefict-c1-info'>
                <p className='cont-benefict-c1-info__title'>Octus es la llave hacia una práctica médica más simple y sin obstáculos</p>
            </article>
            <section className='cont-benefict-c1-box'>
                <figure className='cont-benefict-c1-box-logo'>
                    <img src={octusBeneficioPersona_1} alt="Hombre mirando la página de Octus" className='cont-benefict-c1-box-logo__img'/>
                </figure>
                <article className='cont-benefict-c1-info-text'>
                    <p className='cont-benefict-c1-info-text__title'>Beneficios</p>
                    <p className='cont-benefict-c1-info-text__subTitle'>Para los profesionales de la salud</p>
                    <button className='cont-benefict-c1-info-text__buttonDown'><ArrowDownwardOutlinedIcon className="cont-arrow-down--color"/></button>
                </article>
            </section>

            <section className='cont-benefict-c2'>
                <article className='cont-benefict-c2-box'>
                    <p className='cont-benefict-c2-box__title'>Biblioteca Digital de Documentos</p>
                    <article className='cont-benefict-c2-box-info'>
                        <p className='cont-benefict-c2-box-info_text'>Acceso rápido y sencillo a toda la <b>documentación original.</b></p>
                        <p className='cont-benefict-c2-box-info_text'><b>Respaldo completo</b>, permitiendo tener un historial sólido ante auditorías y reclamos.</p>
                    </article>
                </article>

                <article className='cont-benefict-c2-box'>
                    <p className='cont-benefict-c2-box__title'>Facturación Optimizada</p>
                    <article className='cont-benefict-c2-box-info'>
                        <p className='cont-benefict-c2-box-info_text'>Posibilita una <b>recepción de pagos más rápida y reduce errores</b> en los registros.</p>
                    </article>
                </article>

                <article className='cont-benefict-c2-box'>
                    <p className='cont-benefict-c2-box__title'>Comunicación Mejorada</p>
                    <article className='cont-benefict-c2-box-info'>
                        <p className='cont-benefict-c2-box-info_text'>Facilita la comunicación con intermediarios y obras sociales,
                        promovimiendo una colaboración más efectiva y una <b>resolución más rápida</b> de los problemas.</p>
                    </article>
                </article>

                <article className='cont-benefict-c2-box'>
                    <p className='cont-benefict-c2-box__title'>Integración con AFIP</p>
                    <article className='cont-benefict-c2-box-info'>
                        <p className='cont-benefict-c2-box-info_text'><b>Podés facturar</b> dentro del entorno de OCTUS, cumpliendo con los 
                        requisitos fiscales y legales, agilizando el proceso de envío de documentos a las entidades pertinentes.</p>
                    </article>
                </article>
            </section>
        </section>

        <section className='cont-benefict-c3' id="asociaciones-medicas">
            <article className='cont-benefict-c3-info'>
                <p className='cont-benefict-c3-info__title'>Octus fortalece el rol de las asociaciones como eslabones clave en la cadena
                de prestaciones médicas</p>
            </article>
            <section className='cont-benefict-c3-box'>
                <figure className='cont-benefict-c3-box-logo'>
                    <img src={octusBeneficioPersona_2} alt="Mujer mirando la página de Octus" className='cont-benefict-c3-box-logo__img'/>
                </figure>
                <article className='cont-benefict-c3-info-text'>
                    <p className='cont-benefict-c3-info-text__title'>Beneficios</p>
                    <p className='cont-benefict-c3-info-text__subTitle'>Para Asociaciones Médicas e Intermediarios.</p>
                    <p className='cont-benefict-c3-info-text__subText'>Regionales | Círculos | Colegios</p>
                    <button className='cont-benefict-c3-info-text__buttonDown'><ArrowDownwardOutlinedIcon className="cont-arrow-down--color"/></button>
                </article>
            </section>

            <section className='cont-benefict-c4'>
                <article className='cont-benefict-c4-box'>
                    <p className='cont-benefict-c4-box__title'>Agiliza procesos:</p>
                    <article className='cont-benefict-c4-box-info'>
                        <p className='cont-benefict-c4-box-info_text'>OCTUS permite auditar y revisar la documentación enviada por los profesionales
                        y <b>acelera el procesamiento de reclamos y pagos, reduciendo los tiempos de liquidación.</b></p>
                    </article>
                </article>

                <article className='cont-benefict-c4-box'>
                    <p className='cont-benefict-c4-box__title'>Informes Excel personalizados y organizados:</p>
                    <article className='cont-benefict-c4-box-info'>
                        <p className='cont-benefict-c4-box-info_text'>OCTUS genera <b>informes detallados y organizados</b> que desglosan todas las prestaciones,
                        clasificadas según las necesidades de cada Obra Social.</p>
                        <p className='cont-benefict-c4-box-info_text'>Esta personalización garantiza una administración precisa y un control minucioso de los datos.</p>
                    </article>
                </article>

                <article className='cont-benefict-c4-box'>
                    <p className='cont-benefict-c4-box__title'>Colaboración eficiente:</p>
                    <article className='cont-benefict-c4-box-info'>
                        <p className='cont-benefict-c4-box-info_text'>Promueve una mejor colaboración y <b>mejora la calidad de los servicios ofrecidos </b> 
                         a los miembros de las Regionales, Círculos y Colegios.</p>
                    </article>
                </article>

                <article className='cont-benefict-c4-box'>
                    <p className='cont-benefict-c4-box__title'>Simplifica, ordena y estructura procesos:</p>
                    <article className='cont-benefict-c4-box-info'>
                        <p className='cont-benefict-c4-box-info_text'>OCTUS permite que las Asociaciones agilicen el flujo de trabajo,
                        reduzcan la complejidad y <b>mejoren su capacidad de intermediación entre profesionales y obras sociales.</b></p>
                        <p className='cont-benefict-c4-box-info_text'>Esto resulta en una gestión más eficiente y menos caótica de las 
                        operaciones administrativas</p>
                    </article>
                </article>
            </section>
        </section>

        <section className='cont-benefict-c5' id="obras-sociales-y-seguros">
            <article className='cont-benefict-c5-info'>
                <p className='cont-benefict-c5-info__title'>Octus simplifica la administración e impulsa la eficiencia de Obras Sociales, Prepagas y Seguros.</p>
            </article>
            <section className='cont-benefict-c5-box'>
                <figure className='cont-benefict-c5-box-logo'>
                    <img src={octusBeneficioPersona_3} alt="Mujer mirando la página de Octus" className='cont-benefict-c5-box-logo__img'/>
                </figure>
                <article className='cont-benefict-c5-info-text'>
                    <p className='cont-benefict-c5-info-text__title'>Beneficios</p>
                    <p className='cont-benefict-c5-info-text__subTitle'>Para Obras Sociales, Prepagas y Seguros.</p>
                    <button className='cont-benefict-c5-info-text__buttonDown'><ArrowDownwardOutlinedIcon className="cont-arrow-down--color"/></button>
                </article>
            </section>

            <section className='cont-benefict-c6'>
                <article className='cont-benefict-c6-box'>
                    <p className='cont-benefict-c6-box__title'>Transparencia:</p>
                    <article className='cont-benefict-c6-box-info'>
                        <p className='cont-benefict-c6-box-info_text'>Con OCTUS tenés un <b>control absoluto</b> y la capacidad de auditar en cualquier momento.</p>
                    </article>
                </article>

                <article className='cont-benefict-c6-box'>
                    <p className='cont-benefict-c6-box__title'>Acceso Rápido a Documentos Digitalizados:</p>
                    <article className='cont-benefict-c6-box-info'>
                        <p className='cont-benefict-c6-box-info_text'><b>Organizados</b> por periodos, profesionales, y convenios, simplificando la gestión de registros garantizando que la <b>información esté al alcance de tus manos.</b></p>
                    </article>
                </article>

                <article className='cont-benefict-c6-box'>
                    <p className='cont-benefict-c6-box__title'>Gestión de Informes:</p>
                    <article className='cont-benefict-c6-box-info'>
                        <p className='cont-benefict-c6-box-info_text'>Facilita la <b>solicitud y el acceso a informes</b> específicos que tu institución pueda requerir, agilizando la toma de decisiones.</p>
                    </article>
                </article>

            </section>
        </section>
        </>

    
    )
}

export default Beneficios