import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IProfitWithHoldingsResponse } from "../../api/Interfaces/managment/IProfitWithHoldingsResponse";
import errorHandler from "../errorHandler";
import { IProfitWithHoldingsRequest } from "../interfaces/Managment/IProfitWithHoldings";

const GetProfitWithHoldings = async (): Promise<IApiResponse<IProfitWithHoldingsResponse[]>> => {
    try{
        const response = await api.GetProfitWithHoldings();
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateProfit = async (params: IProfitWithHoldingsRequest): Promise<IApiResponse<number>> => {
    try{
        const response = await api.CreateProfit(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditProfit = async (profitWithHoldingId: number, params: IProfitWithHoldingsRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.EditProfit(profitWithHoldingId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}



export {GetProfitWithHoldings, CreateProfit, EditProfit}