import React from 'react';
import accessDenied from '../../img/accessDeniedPage.png';
import { IConfigurationPage } from '../../services/interfaces/utils/IConfigurationPage';

const ConfigurationPage: React.FC<IConfigurationPage> = () => {
    return (
    <section className="notPermission">
        <article className="notPermission-contInfo">
            <p className="notPermission-contInfo__title">ACCESO DENEGADO!</p>
            <p className="notPermission-contInfo__text">No posee permisos para acceder a esta página.</p>
            <p className="notPermission-contInfo__text">Esta sección corresponde a la funcionalidad <b style={{textDecoration: "underline"}}>"Gestión"</b>.</p>
            <p className="notPermission-contInfo__text">Consultenos cómo habilitar estas secciones y más.</p>
            <p className="notPermission-contInfo__text">Email: octussoftware@gmail.com</p>
            <p className="notPermission-contInfo__text">Tel: +54 (3564) 23-5460</p>
        </article>
        <figure className="notPermission-contImg">
            <img className="notPermission-contImg__img" src={accessDenied} alt="Falta de permisos"/>
        </figure>
    </section>
    )
}

export default ConfigurationPage;