import _ from "lodash";
import { useState, useEffect } from "react";
import { Icon, Grid, Button } from "semantic-ui-react";
import "./SocialWork.css";
import api from "../../api";
import InputFormLabel from "../common/InputFormLabel";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";
import SocialWorkList from "./DocumentList";
import { useForm } from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { es } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { Card, Dimmer, Loader } from "semantic-ui-react";
import CardContent from "@material-ui/core/CardContent";
import Select from "react-select";
import { Controller } from "react-hook-form";
import moment, { min } from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { setFilter } from '../../redux/socialWork';
import { Options, TypeRole } from '../../enums';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ReactSelect from "react-select";

const SelectFilter = ({
	label,
	name,
	menuItemList,
	control,
	filtersLoading,
	selectDisabled,
	selectedRegional,
}) => (
	<Controller
		onChange={selectedRegional}
		styles={{
			// Fixes the overlapping problem of the component
			menu: (provided) => ({ ...provided, zIndex: 9999 }),
		}}
		as={Select}
		Label={label}
		placeholder={label}
		noOptionsMessage={() => 'No hay registros'}
		getOptionLabel={(option) => option.name}
		getOptionValue={(option) => option.id}
		isDisabled={selectDisabled}
		isLoading={filtersLoading}
		isClearable={true}
		isSearchable={true}
		options={menuItemList}
		theme={(theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary: '#6a5ef3',
			},
		})}
		control={control}
		name={name}
		id={name}
	/>
);
const useStyles = makeStyles({
	paperFullWidth: {
		overflowY: 'visible',
		maxWidth: 300,
	},
	dialogContentRoot: {
		overflowY: 'visible',
	},
});
export default function SocialWork({ dataToast, setDataToast }) {
	const [regionalSelected, setRegionalSelected] = useState(0);
	const dispatch = useDispatch();
	const socialWorkRedux = useSelector((state) => state.socialWork);
	const [dataTable, setDataTable] = useState([]);
	const [loadingActive, setLoadingActive] = useState();
	const [socialWorks, setSocialWorks] = useState([]);
	const [defaultSocialWork, setdefaultSocialWork] = useState({});
	const [defaultPlanSocialWork, setdefaultPlanSocialWork] = useState({});
	const [OpenExport, setOpenExport] = useState(false);
	const [regionals, setRegionals] = useState([]);
	const [dateToExport, setDateToExport] = useState(null);
	const [nameSocialWork, setNameSocialWork] = useState();
	const [idPlanSelect, setIdPlanSelected] = useState();
	const [nameSocialWorkPlans, setSocialWorksPlans] = useState([]);
	const [selectDisabled, setSelectDisabled] = useState({
		college: false,
		socialWork: false,
	});
	const [idSocialWork, setIdSocialWork] = useState();
	const [filtersLoading, setFilterLoading] = useState(false);
	const [bodyFilter, setBodyFilter] = useState();
	const [alertDelete, setAlertDelete] = useState(false);
	const [dataSweetAlert, setDataSweetAlert] = useState({});
	const loadForPage = 20;
	const { register, control, getValues, errors, setValue } = useForm({
		defaultValues: {
			patient: '',
			numberAuth: '',
		},
	});
	const classes = useStyles();
	const [page, setPage] = useState(1);
	const [dateFilter, setDateFilter] = useState({
		dateRegistrationFrom: null,
		dateRegistrationTo: null,
		dateRegistrationPeriod: null,
		withPeriod: true,
	});
	const history = useHistory();
	const [quantityData, setQuantityData] = useState(0);
	const applyDataFilters = () => {
		var dataFilters = {
			withPeriod: socialWorkRedux.filters.withPeriod || '',
			socialWork: socialWorkRedux.filters.socialWork || '',
			regional: socialWorkRedux.filters.regional || '',
			auth: socialWorkRedux.filters.auth || '',
			patient: socialWorkRedux.filters.patient || '',
			dateFrom: socialWorkRedux.filters.dateFrom || '',
			dateTo: socialWorkRedux.filters.dateTo || '',
		};
		setValue('socialWork', dataFilters.socialWork);
		setValue('regional', dataFilters.regional);
		setValue('numberAuth', dataFilters.auth);
		setValue('patient', dataFilters.patient);
		setDateFilter({
			dateRegistrationFrom: dataFilters.dateFrom || null,
			dateRegistrationTo: dataFilters.dateTo || null,
			dateRegistrationPeriod: null,
		});
	};
	
	const [isView, setView] = useState(false);
	const [isCreate, setCreate] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [isExport, setExport] = useState(false);
	const [isProfessional, setIsProfessional] = useState(false);
	const [isCollege, setIsCollege] = useState(false);
	const [isSocialWork, setIsSocialWork] = useState(false);
	useEffect(() => {
		let objFitlerSave = {
			socialWork: bodyFilter?.socialWork,
			regional: bodyFilter?.regional,
			auth: bodyFilter?.auth,
			patient: bodyFilter?.patient,
			dateFrom: bodyFilter?.dateFrom,
			dateTo: bodyFilter?.dateTo,
			month: bodyFilter?.month,
			year: bodyFilter?.year,
			page: page,
			quantity: loadForPage,
			pagination: true,
			orderby: '-RegistrationDate',
		};
		dispatch(setFilter(objFitlerSave));
		loadData(objFitlerSave);
	}, [page]);

	useEffect(() => {
		let options = JSON.parse(localStorage.getItem('optionDocuments'));
		options.forEach((element) => {
			if (element == Options.isView) {
				setView(true);
			} else if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			} else if (element == Options.isExport) {
				setExport(true);
			}
		});
		let TypeRolUser = JSON.parse(localStorage.getItem('TypeRole'));
		let socialWorkId = JSON.parse(localStorage.getItem('SocialWorkId'));
		setFilterLoading(true);
		applyDataFilters();
		TypeRolUser === TypeRole.Professional
			? setIsProfessional(true)
			: setIsProfessional(false);
		TypeRolUser === TypeRole.Colegio ? setIsCollege(true) : setIsCollege(false);
		TypeRolUser === TypeRole.ObraSocial
			? setIsSocialWork(true)
			: setIsSocialWork(false);

		RegionalesPorRol(TypeRolUser, socialWorkId);
	}, []);

	const RegionalesPorRol = async (TypeRolUser, socialWorkId) => {
		if (TypeRolUser == TypeRole.Colegio || TypeRolUser == TypeRole.ObraSocial) {
			let colegioId =
				TypeRolUser == TypeRole.Colegio
					? JSON.parse(localStorage.getItem('colegioId'))
					: null;
			let obraSocialId =
				TypeRolUser == TypeRole.ObraSocial
					? JSON.parse(localStorage.getItem('SocialWorkId'))
					: null;

			try {
				setFilterLoading(true);
				let resp = await api.getRegionals(colegioId, obraSocialId);
				let auditorRegionalsArray = resp.data;

				for (let i = 0; i < auditorRegionalsArray.length; i++) {
					auditorRegionalsArray[i].name = auditorRegionalsArray[i]['nombre'];
					delete auditorRegionalsArray[i].nombre;
				}
				setRegionals(auditorRegionalsArray);
				setFilterLoading(false);
			} catch (err) {
				setFilterLoading(false);
				setDataToast({
					active: true,
					message: 'Acaba de ocurrir un error',
					type: 'error',
				});
			}
		} else {
			try {
				let resp = await api.GetSocialWorkForSelect();
				let auditorSocialWorksArray = resp.data.map(item=>{
					return{
						id: item.id,
						name: item["nombre"]
					}
				});
				setSocialWorks(auditorSocialWorksArray);
				setFilterLoading(false);
			} catch (err) {
				setFilterLoading(false);
				setDataToast({
					active: true,
					message: 'Acaba de ocurrir un error',
					type: 'error',
				});
			}
		}
		if (TypeRolUser == TypeRole.Colegio && socialWorkId == '') {
			try {
				let resp = await api.GetSocialWorkForSelect();
				
				let auditorSocialWorksArray = resp.data.map(item=>{
					return{
						id: item.id,
						name: item["nombre"]
					}
				});

				const uniqueArray = auditorSocialWorksArray.reduce((accumulator, currentObj) => {
				
				const isDuplicate = accumulator.some(obj => obj.id === currentObj.id);

				if (!isDuplicate) {
					accumulator.push(currentObj);
				}

				return accumulator;
				}, []);

				setSocialWorks(uniqueArray);
				setFilterLoading(false);
			} catch (err) {
				setFilterLoading(false);
				setDataToast({
					active: true,
					message: 'Acaba de ocurrir un error',
					type: 'error',
				});
			}
		}
		if (TypeRolUser === TypeRole.ObraSocial) {
			setSelectDisabled({
				...selectDisabled,
				socialWork: true,
			});
			try {
				let resp = await api.GetSocialWorkForSelect();
				let socialWorksArray = resp.data.map(item=>{
					return{
						id: item.id,
						name: item["nombre"]
					}
				});
				setSocialWorks(socialWorksArray);
				socialWorksArray.some((item) => {
					if (parseInt(socialWorkId) == item.id) {
						setValue('socialWork', {
							id: item.id,
							name: item.name,
						});
						setSocialWorks([
							{
								id: item.id,
								name: item.name,
							},
						]);
						setdefaultSocialWork({
							name: item.name
						})
						return true; //sale del bucle cuando entra en el if
					}
					return false;
				});
				getSocialWorksPlan(TypeRolUser,{id: socialWorkId})
			} catch (err) {
				setFilterLoading(false);
				setDataToast({
					active: true,
					message: 'Acaba de ocurrir un error',
					type: 'error',
				});
			}
		}
	};
	const actionConfirmDelete = () => {
		api
			.deleteDocument(dataSweetAlert.id)
			.then((resp) => {
				setAlertDelete(false);
				reloadData();
				setDataToast({
					active: true,
					message: 'Dado de baja con éxito',
					type: 'success',
				});
			})
			.catch((err) => {
				setDataToast({
					active: true,
					message: 'Acaba de ocurrir un error',
					type: 'error',
				});
			});
	};
	const handleClickNew = () => {
		history.push('/paciente/crear');
	};
	const handleClickPeriod = () => {
		history.push('/periodo/lista');
	};
	const reloadData = () => {
		api
			.getDocuments(bodyFilter)
			.then((resp) => {
				let dataApi = resp.data.resultados;
				setDataTable(dataApi);
				setLoadingActive(false);
				setQuantityData(resp.data.cantidadPaginas);
				//dispatch(setDataTable({ dataApi }));
			})
			.catch((err) => {
				setLoadingActive(false);
				setDataToast({
					active: true,
					message: err.response.data.errores || 'Acaba de ocurrir un error',
					type: 'error',
				});
			});
	};
	const handleClickSearch = () => {
		let values = getValues();
		let month = null;
		let date = dateFilter;
		let year = null;
		let filters = {
			withPeriod: true,
		};
		if (values.socialWork) {
			filters.socialWork = values.socialWork;
		}
		if (isCollege && !values.regional) {
			filters.regional = regionalSelected;
		}
		if (!isCollege && values.regional) {
			filters.regional = values.regional;
		}
		if (values.numberAuth) {
			filters.auth = values.numberAuth;
		}
		if (values.professional) {
			filters.professional = values.professional;
		}
		if (values.patient) {
			filters.patient = values.patient;
		}
		if (date.dateRegistrationFrom) {
			filters.dateRegistrationFrom = moment(date.dateRegistrationFrom).format();
		}
		if (date.dateRegistrationTo) {
			filters.dateRegistrationTo = moment(date.dateRegistrationTo).format();
		}
		if (date.dateRegistrationPeriod) {
			month = parseInt(moment(dateFilter.dateRegistrationPeriod).format('MM'));
			year = parseInt(moment(dateFilter.dateRegistrationPeriod).format('YYYY'));
		}
		filters.month = month;
		filters.year = year;
		filters.page = page;
		filters.quantity = loadForPage;
		filters.pagination = true;
		filters.orderby = '-RegistrationDate';
		loadData(filters);
		setBodyFilter(filters);
		//dispatch({ type: "document/setFilters", payload: filters });
		dispatch(setFilter(filters));
	};
	const getByDocuments = (filtersLoad) => {
		api
			.getDocuments(filtersLoad)
			.then((resp) => {
				let dataApi = resp.data.resultados;
				setDataTable(dataApi);
				setQuantityData(resp.data.cantidadPaginas);
				setLoadingActive(false);
			})
			.catch((err) => {
				setLoadingActive(false);
				setDataToast({
					active: true,
					message: err.response.data.errores,
					type: 'error',
				});
			});
	};
	const loadData = (filters) => {
		setLoadingActive(true);
		let TypeRolUser = JSON.parse(localStorage.getItem('TypeRole'));
		let filtersLoad = filters || socialWorkRedux.filters;

		if (TypeRolUser == TypeRole.Colegio || TypeRolUser == TypeRole.ObraSocial) {
			if (TypeRolUser === TypeRole.Colegio || TypeRolUser === TypeRole.ObraSocial) {
				if (filtersLoad.month && filtersLoad.year && filtersLoad.regional) {
					getByDocuments(filtersLoad);
				} else {
					setDataToast({
						active: true,
						message: 'Debe ingresar Mes-Año y Regional',
						type: 'error',
					});
					setLoadingActive(false);
				}
			}
		} else {
			getByDocuments(filtersLoad);
		}
	};

	const RegionalSelected = (event) => {
		let TypeRolUser = JSON.parse(localStorage.getItem('TypeRole'));
		let socialWorkId = JSON.parse(localStorage.getItem('SocialWorkId'));

		if (event[0] === null) {
			setRegionalSelected(0);
			RegionalesPorRol(TypeRolUser, socialWorkId);
			return;
		}

		const _regionalId = event[0].id;
		setRegionalSelected(event[0]);
		setFilterLoading(true);
		if(isCollege){
			api
			.getSocialworkByAccount(_regionalId)
			.then((resp) => {
				
				let auditorSocialWorksArray = resp.data.map(item=>{
					return{
						id: item.id,
						name: item["nombre"]
					}
				});

				const uniqueArray = auditorSocialWorksArray.reduce((accumulator, currentObj) => {
				
				const isDuplicate = accumulator.some(obj => obj.id === currentObj.id);

				if (!isDuplicate) {
					accumulator.push(currentObj);
				}

				return accumulator;
				}, []);
				
				setSocialWorks(uniqueArray);
				setFilterLoading(false);
				setSelectDisabled({
					...selectDisabled,
					socialWork: false,
				});
			})
			.catch((err) => {
				setLoadingActive(false);
				setDataToast({
					active: true,
					message: err.response.data.errores || 'Acaba de ocurrir un error',
					type: 'error',
				});
			});
		}
		setFilterLoading(false);
	};
	const getSocialWorksPlan = (TypeRolUser,event) => {
		let idSocialWorkSelected = event.id;
		api.getSocialWorksPlan(idSocialWorkSelected).then((resp) => {
			let plans = resp.data;
			setSocialWorksPlans(plans);
			setdefaultPlanSocialWork({
				planSocialWork: plans[0].nombre
			})
		if(TypeRolUser === TypeRole.ObraSocial){
			setSocialWorkPlanId(plans[0]);
		}
		});

		setNameSocialWork(event.name);
		setIdSocialWork(idSocialWorkSelected);

	};
	const setSocialWorkPlanId = (event) => {
		let idPlanSelected = event.id;
		setIdPlanSelected(idPlanSelected);
	};
	//#region Export registro para colegio y obra social.
	 const OnClickExport = () => {
		if(!idSocialWork || !dateToExport || !regionalSelected || !idPlanSelect){
			setDataToast({
	 			active: true,
	 			message: 'Todos los campos son obligatorios.',
	 			type: 'error',
	 		});
		}else{
			let body = {
	 				mes: parseInt(moment(dateToExport).format('MM')),
	 				anio: parseInt(moment(dateToExport).format('YYYY')),
	 				obraSocialId: socialWorks[0].id,
	 				socialWorkPlanId: idPlanSelect,
	 				cuentaId: regionalSelected.id,
	 			};
	 			setLoadingActive(true);
	 			api
	 				.exportExcelForAssociation(body)
	 				.then((res) => {
						
	 					let extension = 'xlsx';
	 					let tempFileName = `Periodo ${body.mes} - ${body.anio}`;
	 					let fileName = `${tempFileName}.${extension}`;

	 					let blob = new Blob([res.data], {
	 						type: 'application/vnd.ms-excel',
	 					});
	 					saveAs(blob, fileName);

	 					setDataToast({
	 						active: true,
	 						message: 'Exportado correctamente',
	 						type: 'success',
	 					});
	 					setLoadingActive(false);
	 				})
	 				.catch((err) => {
						setLoadingActive(false);
						// Convertir el Blob a texto
						err.response.data.text().then((text) => {
							// Convertir el texto a JSON
							let errorData = JSON.parse(text);
							setDataToast({
									active: true,
									message: errorData.errores || 'Acaba de ocurrir un error',
									type: 'error',
							});
						}).catch(() => {
							setDataToast({
									active: true,
									message: 'Acaba de ocurrir un error en la conversión del Blob',
									type: 'error',
							});
						});
	 				});
				if(isCollege){
				setIdSocialWork(null);
				setSocialWorksPlans([]);
				}
	 			setDateToExport(null);
	 			setOpenExport(false);
				
		}
	 };

	const handleClickOpenExport = ()=>{
		setOpenExport(true);
	}
	//#endregion
	return (
		<>
			<Grid>
				<Card className='color-border-dash'>
					<CardContent className='center-responsive-filters'>
						<Grid.Row className="header-section">
							<div className="titleAndVideo">
								<span style={{ fontSize: 27, textAlign: "left"}}>Documentos</span>
							</div>
							<div className="contBtnHeaderSection">
								{isExport == true && (isCollege || isSocialWork) && 
									<Button
										className='btn-add-filter'
										primary
										onClick={() => handleClickOpenExport()}
										icon
										labelPosition='left'
									>
										<Icon name='share square outline' />
										Exportar
									</Button>
								}
							</div>
						</Grid.Row>
						<Grid.Row className="content-filter-list">
							<InputFormLabel
								classInput='filter-input'
								isRequired={false}
								type={'text'}
								label={'Paciente'}
								name={'patient'}
								control={control}
								error={errors}
							/>
							<InputFormLabel
								classInput='filter-input'
								register={register({ required: false })}
								isRequired={false}
								type={'number'}
								label={'Nro. Autorización'}
								name={'numberAuth'}
								control={control}
								error={errors}
							/>
							{!isProfessional && (
								<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
									<>
										<KeyboardDatePicker
											style={{ marginRight: 5, marginTop: 5 }}
											autoOk
											className='size-date-picke-filter filter-input'
											inputVariant='outlined'
											InputAdornmentProps={{ position: 'end' }}
											variant='inline'
											name='month'
											openTo='month'
											views={['month', 'year']}
											placeholder='Mes - Año'
											value={dateFilter.dateRegistrationPeriod}
											onChange={(date) =>
												setDateFilter({
													...dateFilter,
													dateRegistrationPeriod: date,
												})
											}
										/>
										{dateFilter.dateRegistrationPeriod && (
											<Icon
												className='icon-clear-date-filter'
												style={{ marginTop: 10 }}
												name='close'
												size='large'
												onClick={() =>
													setDateFilter({
														...dateFilter,
														dateRegistrationPeriod: null,
													})
												}
											/>
										)}
									</>
								</MuiPickersUtilsProvider>
							)}

							{isCollege === true || isSocialWork === true ? (
								<div className="filter-input">
									<SelectFilter
										selectedRegional={isCollege && RegionalSelected}
										label={`${isSocialWork ? 'Convenio' : 'Regional'}`}
										name={'regional'}
										control={control}
										menuItemList={regionals}
										filtersLoading={filtersLoading}
									/>
								</div>
							) : null}

							<div className="filter-input">
								<SelectFilter
									label={'Obra Social'}
									name={'socialWork'}
									control={control}
									selectDisabled={selectDisabled.socialWork}
									menuItemList={socialWorks}
									filtersLoading={filtersLoading}
								/>
							</div>

							<InputFormLabel
								classInput="filter-input"
								isRequired={false}
								type={'text'}
								label={'Profesional'}
								name={'professional'}
								control={control}
								error={errors}
							/>
						</Grid.Row>
						<Dialog
							open={OpenExport}
							onClose={() => {
								setOpenExport(false);
								setSocialWorksPlans([]);
							}}
							aria-labelledby='form-dialog-title'
							fullWidth={true}
							classes={{
								paperFullWidth: classes.paperFullWidth,
							}}
						>
							<DialogTitle id='form-dialog-title'>
								Exportar Registros
							</DialogTitle>
							<DialogContent
									classes={{
									root: classes.dialogContentRoot,
									}}
								style={{ maxWidth: 300 }}
							>
								<div>
								<ReactSelect
										placeholder='Regional...'
										loading={filtersLoading}
										options={regionals}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										onChange={(event)=>{
											RegionalSelected([event])
										}}
										styles={{
											control: (s)=>({...s, maxWidth: 350})
										}}
									/>
								</div>
								<div>
								<ReactSelect
										placeholder={`${isCollege ?'Obra social...' : defaultSocialWork.name}`}
										loading={filtersLoading}
										options={socialWorks}
										isDisabled={selectDisabled.socialWork}
										defaultValue={!isCollege && socialWorks}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										onChange={(event)=>{getSocialWorksPlan(isSocialWork, event)}}
										styles={{
											control: (s)=>({...s, maxWidth: 350})
										}}
									/>
								</div>
								<div >
								<ReactSelect
									placeholder={`${isCollege ? 'Plan...' : defaultPlanSocialWork.planSocialWork}`}
									loading={filtersLoading}
									options={nameSocialWorkPlans}
									getOptionLabel={(option) => option.nombre}
									getOptionValue={(option) => option.id}
									onChange={setSocialWorkPlanId}
									styles={{
										control: (s)=>({...s, maxWidth: 350})
									}}
								/>
								</div>
								<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
									<>
										<KeyboardDatePicker
											style={{ marginRight: 5, marginTop: 5 }}
											autoOk
											className='size-date-picke-filter'
											inputVariant='outlined'
											InputAdornmentProps={{ position: 'end' }}
											variant='inline'
											name='month'
											openTo='month'
											views={['month', 'year']}
											placeholder='Mes - Año'
												value={dateToExport}
												onChange={(date) => {
												setDateToExport(date);
												}}
										/>
										{dateToExport && (
											<Icon
												className='icon-clear-date-filter'
												style={{ marginTop: 10 }}
												name='close'
												size='large'
												onClick={() => setDateToExport(null)}
											/>
										)}
									</>
								</MuiPickersUtilsProvider>
							</DialogContent>
							<DialogActions style={{ justifyContent: 'center' }}>
								<Button
									onClick={() => {
										setOpenExport(false);
										setSocialWorksPlans([]);
									}}
								>
									Cancelar
								</Button>
								<Button onClick={OnClickExport} color='blue'>
									Continuar
								</Button>
							</DialogActions>
						</Dialog>
						<Button
							className='btn-search-filter'
							primary
							onClick={() => handleClickSearch()}
							icon
							labelPosition='left'
						>
							<Icon name='search' />
							Buscar
						</Button>
					</CardContent>
				</Card>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable && (
						<SocialWorkList
							setDataToast={setDataToast}
							dataSweetAlert={dataSweetAlert}
							setDataSweetAlert={setDataSweetAlert}
							alertDelete={alertDelete}
							setAlertDelete={setAlertDelete}
							actionConfirm={actionConfirmDelete}
							reloadData={reloadData}
							dataTable={dataTable}
							handleClickNew={handleClickNew}
							handleClickPeriod={handleClickPeriod}
							totalCount={quantityData}
							loadForPage={loadForPage}
							isView={isView}
							isEdit={isEdit}
							isCreate={isCreate}
							isDelete={isDelete}
							isExport={isExport}
							page={page}
							setPage={setPage}
							isProfessional={isProfessional}
							isCollege={isCollege}
							isSocialWork={isSocialWork}
							setLoadingActive={setLoadingActive}
						/>
					)
				)}
			</Grid>
		</>
	);
}
