import { Tooltip, makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CachedIcon from '@material-ui/icons/Cached';
import Pagination from "@material-ui/lab/Pagination";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../../api';
import { Options, StatusCode } from '../../../../enums';
import SelectForm from '../../../common/SelectForm';
import ReactSelect from 'react-select';
import { ReactivateSocialWork } from '../../../../services/services/ConfigurationSocialWork';

  const useStyles = makeStyles({
        colorBtn: {backgroundColor: "#e0e1e2", color:'rgba(0,0,0,.6)'}
    });
	const loadForPage = 20;

export default function CrudSocialWork({setDataToast}){
	const history = useHistory();
    const classStyle = useStyles();
    const { control } = useForm({});
    //#region VARIABLES DE ESTADO
    const [loadingActive, setLoadingActive] = useState(false);
    const [dataTable, setDataTable] = useState([{}]);
    const [openNew, setOpenNew] = useState(false);
    const [isCreate, setCreate] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
	const [page, setPage] = useState(1);
	const [quantityForPage, setQuantityForPage] = useState(0);

	const [socialWorkList, setSocialWorkList] = useState([{id: null, nombre: null}]);
	const [reactivate, setReactivate] = useState({
		reactivateModal: false,
		showReactivateIcon: false
	});
	const [socialWorkSelected, setSocialWorkSelected] = useState({
		id: null,
		nombre: null
	});
	const [loadings, setLoadings] = useState({
		socialWork: true
	});
	const [objFilterData, setObjFilterData] = useState({
		dadasDeBaja: false,
		nombre: null,
		dadasDeBajaChecked: false,
	})
	const [openDelete, setOpenDelete] = useState({
		mostrar: false,
		id: null,
		nombre: null
	});
    const [errors, setErrors] = useState({
		association: '',
	});
    const [newValue, setNewValue] = useState({
        nombre: null,
        plan: null,
		urlIntegracion: null
    });
    const [editValues, setEditValues] = useState({
        id: null,
        nombre: null,
		urlIntegracion: null
    })
    //#endregion
    
    useEffect(()=>{
		setLoadingActive(true);
        let optionCruds = JSON.parse(localStorage.getItem('optionCruds'));
		optionCruds.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
        LoadData();
    },[])

	//#region Metodos ABM
	const LoadData = async (pageSelected = 1)=>{
		setLoadingActive(true);
		try{
			let params = {
				obraSocialId: socialWorkSelected.id,
				dadasDeBaja: objFilterData.dadasDeBaja,
				pagina: pageSelected,
				cantidad: loadForPage,
			}
			let {data} = await api.getSocialWorks(params);
			setQuantityForPage(data.cantidadPaginas);
			setDataTable(data.resultados);
		}
		catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			if (objFilterData.dadasDeBaja){
				setReactivate({
					reactivateModal: false,
					showReactivateIcon: true
				});
				GetAllSocialWorks(true);
			}else{
				GetAllSocialWorks();
				setObjFilterData({...objFilterData, dadasDeBajaChecked: false })
				setReactivate({
					reactivateModal: false,
					showReactivateIcon: false
				});
			}
			setLoadingActive(false);
		}
    }
    const OnClickCreate = async ()=>{
			if(newValue.nombre === null || newValue.plan === null){
				HandleErrorOrSuccess('error', "Todos los campos son obligatorios.");
				return
			}else{
				try{
					setOpenNew(false);
					setLoadingActive(true);
					const bodyParams = {
						nombre: newValue.plan,
						obraSocial: {nombre: newValue.nombre, urlIntegracion: newValue.urlIntegracion}
					}
					const query = await api.CreateSocialWorkWithPlan(bodyParams);
					if(query.status === 200){
						HandleErrorOrSuccess('success', "Obra social creada correctamente.");
					}
				}catch(err){
					HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
				}finally{
					setNewValue({
						nombre: null,
						plan: null,
						urlIntegracion: null,
					});
					LoadData();
				}
			}
		
    }
	async function ReactiveSocialWork(){
		try{
			setLoadingActive(true);
			setReactivate({
				...reactivate,
				reactivateModal: false
			});
			const {status} = await ReactivateSocialWork(editValues.id);
			if (status === StatusCode.Ok){
				HandleErrorOrSuccess('success',"Obra social reactivada correctamente.");
				LoadData();
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}finally{
			setLoadingActive(false);
		}
	}
    const OnClickEdit = async ()=>{
		if(editValues.nombre === null){
			HandleErrorOrSuccess('error', "Todos los campos son obligatorios.");
			return
		}else{
			try{
				setOpenEdit(false);
				setLoadingActive(true);
				let bodyParams = {
				nombre: editValues.nombre,
				urlIntegracion: editValues.urlIntegracion
				}
				let query = await api.UpdateSocialWork(editValues.id, bodyParams);
				if(query.status === 200){
					HandleErrorOrSuccess('success',"Obra social editada correctamente.");
					LoadData();
				}
			}catch(err){
				HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
			}finally{
				setEditValues({
					id: null,
					nombre: null
				});
			}
		}
    }
	const OnClickDelete = async (idSocialWork)=>{
		setOpenDelete({...openDelete, mostrar: false});
		setLoadingActive(true);
		try{
			let query = await api.DeleteSocialWork(idSocialWork);
			if(query.status === 200){
				HandleErrorOrSuccess('success',"Obra social dada de baja correctamente.");
			}
		}catch(err){
			HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
		}
		finally{
			setOpenDelete({
				mostrar: false,
				id: null,
				nombre: null,
			});
			LoadData();
		}
	}
	//#endregion

    //#region UTILIDADES
	 const GetAllSocialWorks = async (deleted = false)=>{
	 	try{
	 		let params = {
	 			dadasDeBaja: deleted
	 		}
	 		let {data} = await api.GetSocialWorkForSelect(params);
	 		let OsList = data.map((item)=>{
	 			return{
	 					value: item.id,
	 					label: item['nombre'],
						urlIntegracion: item.urlIntegracion
	 				};
	 		})
	 		setSocialWorkList(OsList);
	 	}
	 	catch(err){
	 		HandleErrorOrSuccess('error',err.response.data.errores ?? "Ocurrió un error.");
	 	}
	 	finally{
	 		setLoadings({
	 			socialWork: false
	 		});
	 	}
	 }
	const HandleErrorOrSuccess = (type,message)=>{
		setDataToast({
			active: true,
			type: type,
			message: message
		});
	}
	const PreDelete = (idSocialWork, nameSocialWork)=>{
		setOpenDelete({
			id: idSocialWork,
			nombre: nameSocialWork,
			mostrar: true,
		})
    }
    const HandleOpenEdit = (id, nombre, urlIntegracion, reactivateOS = false)=>{
		setEditValues({
			id,
			nombre,
			urlIntegracion
		});
		if(reactivateOS){
			setReactivate({
				...reactivate,
				reactivateModal: true
			});
		}else{
			setOpenEdit(true);
		}
    }
    const HandleCloseEdit = ()=>{
        setOpenEdit(false);
    }
    const HandleClickOpen = ()=>{
        setOpenNew(true);
    }
    const HandleCloseNew = ()=>{
        setOpenNew(false);
    }
	const HandleCloseDelete = ()=>{
		setOpenDelete(false);
	}
	const handleChange = (event, value) => {
    setPage(value);
    LoadData(value);
  };
    //#endregion

    return(<>
        <Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className='header-section'>
								<div className="titleAndVideo">
									<span style={{fontSize: 27 }}>Obras Sociales</span>
								</div>
								<div>
									{isCreate &&
										<Button
											className='btn-new-filter'
											onClick={HandleClickOpen}
											icon
											labelPosition='left'
										>
											<Icon name='plus' />
											Nuevo
										</Button>}
								</div>
							</Grid.Row>
							<Grid.Row className="content-filter-list">
								<ReactSelect
								className="filter-input"
								placeholder="Obras Sociales"
								options={socialWorkList}
								isClearable={true}
								onChange={(e)=>setSocialWorkSelected({
									id: e !== null ? e.value : e, 
									nombre: e !== null ? e.label : e
								})}
								/>
                               <FormControlLabel
							   className='filter-input'
                                control={
                                    <Checkbox
                                        checked={objFilterData.dadasDeBajaChecked}
                                        onChange={(e) => {
                                            setObjFilterData({
                                                ...objFilterData,
                                                dadasDeBaja: e.target.checked,
												dadasDeBajaChecked: e.target.checked
                                            });
                                        }}
                                    />
                                }
                                label='Dados de baja'
                                />
							</Grid.Row>
							<Button
								className='btn-search-filter'
								primary
								icon
								labelPosition='left'
								onClick={()=>{LoadData()}}
								type='submit'
							>
								<Icon name='search' />
								Buscar
							</Button>
						</CardContent>
					</Card>
				</Grid.Row>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable &&
					dataTable.length > 0 && (
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={2} className='color-letter-inTable'>
										<TableRow>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Nombre</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{textAlign:"center"}}>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataTable.map(({id,nombre, alias, urlIntegracion}) => (
											<TableRow key={id}>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{textAlign:"center"}}>
													{reactivate.showReactivateIcon ? 
													<>
													<IconButton
														className='btn-circle-green'
														onClick={() => HandleOpenEdit(id,nombre,urlIntegracion,true)}
													>
														<Tooltip placement='top' title="Reactivar">
															<CachedIcon />
														</Tooltip>
													</IconButton>
													</>
													:
													<>
													<IconButton
													className='btn-circle-green'
													onClick={() => history.push(`/configuraciones/obras-sociales/${id}/planes`)}
													>
														<Tooltip placement="top" title="Planes">
															<DescriptionIcon />
														</Tooltip>
													</IconButton>
													{isEdit && (
														<IconButton
															className='btn-circle-orange'
															onClick={() => HandleOpenEdit(id, nombre, urlIntegracion)}
														>
															<Tooltip placement='top' title="Editar">
																<CreateIcon />
															</Tooltip>
														</IconButton>
													)}
													{isDelete && (
														<IconButton
															className='btn-circle-red'
															onClick={() =>
																PreDelete(id, nombre)
															}
														>
															<Tooltip placement='top' title="Eliminar">
																<DeleteIcon />
															</Tooltip>
														</IconButton>
													)}
													</>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
					)
				)}
				{dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
				{dataTable && (
				<Grid.Row centered>
					<Pagination
					color="primary"
					className="pagination"
					count={quantityForPage}
					page={page}
					onChange={handleChange}
					/>
				</Grid.Row>
				)}
		</Grid>

			<Dialog
				open={openNew}
				onClose={HandleCloseNew}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Nuevo</DialogTitle>
				<DialogContent className='form-pupOp'>
					<TextField
						style={{ margin: "5px 0"}}
						size='small'
						id='nombre'
						label='Nombre'
						value={newValue.nombre}
						onChange={(e) => {
							setNewValue({
								...newValue,
								nombre: e.target.value.trim() === "" ? null : e.target.value
							});
						}}
						variant='outlined'
					/>
					<TextField
						style={{ margin: "5px 0"}}
						size='small'
						id='nombre'
						label='Plan'
						value={newValue.plan}
						onChange={(e) => {
							setNewValue({
								...newValue,
								plan: e.target.value.trim() === "" ? null : e.target.value
							});
						}}
						variant='outlined'
					/>
					<TextField
						style={{ margin: "5px 0"}}
						size='small'
						id='integracion'
						label='URL INTEGRACION'
						value={newValue.urlIntegracion}
						onChange={(e) => {
							setNewValue({
								...newValue,
								urlIntegracion: e.target.value
							});
						}}
						variant='outlined'
					/>
				</DialogContent>

				<DialogActions
					style={{
						textAlign: 'center',
						justifyContent: 'center',
					}}
				>
					<Button onClick={HandleCloseNew}>
						Cancelar
					</Button>
					<Button onClick={OnClickCreate} color='blue'>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>

			{openEdit && (
				<Dialog
					open={openEdit}
					onClose={HandleCloseEdit}
					aria-labelledby='form-dialog-title'
				>
					<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
					<DialogContent className='form-pupOp'>
						<TextField
							style={{ margin: "5px 0"}}
							size='small'
							id='nombre'
							label='Nombre'
							value={editValues.nombre}
							onChange={(e) => {
								setEditValues({
									...editValues,
									nombre: e.target.value.trim() === "" ? null : e.target.value,
								});
							}}
							variant='outlined'
						/>
						<TextField
							style={{ margin: "5px 0"}}
							size='small'
							id='integracion'
							label='URL INTEGRACION'
							value={editValues.urlIntegracion}
							onChange={(e) => {
								setEditValues({
									...editValues,
									urlIntegracion: e.target.value
								});
							}}
							variant='outlined'
						/>
					</DialogContent>

					<DialogActions
						style={{
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<Button onClick={HandleCloseEdit}>
							Cancelar
						</Button>

						<Button onClick={OnClickEdit} color='blue'>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openDelete.mostrar &&
			<Dialog open={openDelete.mostrar} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de eliminar la siguiente Obra Social: {openDelete.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={HandleCloseDelete}>Cancelar</Button>
					<Button
						onClick={() => OnClickDelete(openDelete.id)}
						color='red'
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
			}

			
		{reactivate.reactivateModal &&
			(<Dialog open={reactivate.reactivateModal} onClose={()=>setReactivate({...reactivate, reactivateModal: false})}>
				<DialogTitle id='form-dialog-title'>Reactivar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de reactivar la siguiente Obra Social: {editValues.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>setReactivate({...reactivate, reactivateModal: false})}>Cancelar</Button>
					<Button
						onClick={ReactiveSocialWork}
						color='green'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>)
			}
    </>)
}