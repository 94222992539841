import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React from 'react';
import ReactSelect from "react-select";
import { IRegional, IRegionalPostParams, IRegionalPutParams } from '../../../../api/Interfaces/managment/ICrudRegional';
import { StatusCode, ValidationObjRegex } from '../../../../enums';
import { ICrudRegionalModal } from '../../../../services/interfaces/Managment/ICrudRegional';
import { PostAccount, PutAccount } from '../../../../services/services/CrudRegional';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';

const _DEFAULT_VALUES_NEW = {
	_STATE_OBJ: { requiresManagment: false }
}

const NewModalRegional: React.FC<ICrudRegionalModal> = ({
	values,
	modal,
	openModal,
	associationList,
	loaders,
	setLoadingActive,
	OpenModalSelected,
	MessageNotificaction,
	reloadData }) => {

	const [valuesToEdit, setValuesToEdit] = React.useState<IRegional>({ ...values });

	//#region UTILS
	function HandleChangeInput(e: any) {
		setValuesToEdit({ ...valuesToEdit, [e.target.name]: e.target.value });
	}

	function HandleSubmit() {
		let isValidate: boolean = ValidateValues(valuesToEdit);
		console.log(valuesToEdit);

		if (modal === "create") {
			OnClickCreate(isValidate);
		} else {
			EditRegional(isValidate);
		}
	}
	//#endregion

	//#region CREAR - EDITAR - VALIDAR
	function ValidateValues(values: IRegional) {
		let isValidated: boolean = true;

		if (!ValidationObjRegex.emailValidation.test(values.correo)) {
			MessageNotificaction("Formato de correo inválido.", "error");
			isValidated = false;
		}

		if (Object.values(values).some(item => item === null)) {
			MessageNotificaction("Todos los campos son obligatorios.", "error");
			isValidated = false;
		}

		if (values.cuit !== null) {
			if (isNaN(values.cuit)) {
				MessageNotificaction("El campo 'CUIT' deben ser dígitos numéricos.", "error");
				isValidated = false;
			}
			if (values.cuit.toString().length >= 12 || values.cuit.toString().length < 11) {
				MessageNotificaction("El campo 'CUIT' debe tener 11 dígitos.", "error");
				isValidated = false;
			}
		}
		return isValidated;
	}

	async function OnClickCreate(isValidate: boolean) {
		try {
			setLoadingActive(true);
			if (isValidate) {
				const params: IRegionalPostParams = {
					nombre: valuesToEdit.nombre,
					correo: valuesToEdit.correo,
					colegioId: valuesToEdit.colegio.id,
					cuit: +valuesToEdit.cuit,
					requiereGestion: valuesToEdit.requiereGestion
				};
				const { status } = await PostAccount(params);
				if (status === StatusCode.Ok) {
					MessageNotificaction("Regional creada correctamente.", "success");
					OpenModalSelected("close", modal);
					reloadData();
				}
			}
		} catch (err: any) {
			MessageNotificaction(err.response.data.errores, "error");
		} finally {
			setLoadingActive(false);
		}
	}

	async function EditRegional(isValidate: boolean) {
		try {
			setLoadingActive(true);
			if (isValidate) {
				const params: IRegionalPutParams = {
					nombre: valuesToEdit.nombre,
					correo: valuesToEdit.correo,
					colegioId: valuesToEdit.colegio.id,
					colegio: valuesToEdit.colegio.nombre,
					cuit: +valuesToEdit.cuit,
					requiereGestion: valuesToEdit.requiereGestion
				};
				const { status } = await PutAccount(valuesToEdit.id, params);
				if (status === StatusCode.Ok) {
					MessageNotificaction("Regional editada correctamente.", "success");
					OpenModalSelected("close", modal);
					reloadData();
				}
			}
		} catch (err: any) {
			MessageNotificaction(err.response.data.errores, "error");
		} finally {
			setLoadingActive(false);
		}
	}
	//#endregion

	function HandleCloseModal() {
		OpenModalSelected("close", modal);
	}

	return (
		<GeneralModalCrud
			titleOfModal={`${modal === "create" ? 'Nuevo' : 'Editar'}`}
			openModal={openModal}
			HandleCloseModal={HandleCloseModal}
			HandleSubmit={HandleSubmit}
		>
			<TextField
				size='small'
				name='nombre'
				label='Nombre'
				value={valuesToEdit.nombre}
				onChange={HandleChangeInput}
				variant='outlined'
			/>
			<TextField
				size='small'
				name='correo'
				label='Correo'
				value={valuesToEdit.correo}
				onChange={HandleChangeInput}
				variant='outlined'
			/>
			<TextField
				size='small'
				name='cuit'
				label='CUIT'
				value={valuesToEdit.cuit}
				onChange={HandleChangeInput}
				variant='outlined'
			/>
			<ReactSelect
				isDisabled={modal === "edit"} 
				styles={{
					control: (e) => ({
						...e,
						width: "95%"
					})
				}}
				placeholder={`${valuesToEdit.colegio?.nombre ?? "Colegios..."}`}
				options={associationList}
				isLoading={loaders.associations}
				onChange={(e) => setValuesToEdit({ ...valuesToEdit, colegio: { id: e.value, nombre: e.label } })}
			/>
			<FormControlLabel
				control={<Checkbox checked={valuesToEdit.requiereGestion} onChange={(e) => setValuesToEdit({ ...valuesToEdit, requiereGestion: e.target.checked })} />}
				label="Requiere Gestión"
			/>
		</GeneralModalCrud>);
}

export default NewModalRegional;

