import DateFnsUtils from "@date-io/date-fns";
import { Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, TextareaAutosize, Tooltip, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/AddCircle";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from '@material-ui/icons/Email';
import DownloadImage from "@material-ui/icons/GetApp";
import ImageIcon from "@material-ui/icons/Image";
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import api from "../../api";
import DialogDelete from "../common/DialogDelete";
import SwitchComponent from "../common/SwitchComponent";
import PopupImage from "../utils/popupImage";
import "./Dashboard.css";
import { startOfDay } from "date-fns";

export default function DashboardList({
	dataTable,
	actionConfirm,
	devolutionSection,
	alertDelete,
	setAlertDelete,
	setAlertEditEvent,
	alertEditEvent,
	setDataEditEvent,
	dataEditEvent,
	dataSweetAlert,
	setDataSweetAlert,
	totalCount,
	loadForPage,
	dataPagination,
	setDataPagination,
	dataToast,
	setPage,
	page,
	setDataToast,
	isEdit,
	isView,
	isDelete,
	isExport,
	reloadData,
	dateToday,
	isProfessional,
	isAdmin,
	isSuperAdmin,
	loadingActive,
	setLoadingActive,
	loadData
}) {
	const history = useHistory();
	const [openModalComment, setOpenModalComment] = useState({
		showModal: false,
		patient: null,
		comment: null
	});
	const [presentDocumentModal, setPresentDocumentModal] = useState({
		showModal: false,
		patient: null,
		documentId: null
	});
	const [tokenModal, setTokenModal] = useState({show: false, data: null});
	const [stateOfCheckbox, setStateOfCheckbox] = useState({manualCharge: false, hasIntegration: false, enableAuthNum: false});
	const [error, setError] = useState(false);
	const [errorDate, setErrorDate] = useState(false);

	const handleChange = (event, value) => {
		let newDataPagination = {
			offset: dataPagination.offset + loadForPage * value,
			limit: dataPagination.limit + loadForPage * value,
		};
		setDataPagination(newDataPagination);
		setPage(value);
	}
	const printDocument = (id) => {
		setLoadingActive(true);
		api
			.printDocumentSessions(id)
			.then((resp) => {
				let extension = 'pdf';
				let tempFileName = 'Reporte de Sesiones';
				let fileName = `${tempFileName}.${extension}`;
				let blob = new Blob([resp.data], {
					type: 'application/pdf',
				});
				let a = document.createElement('a');
				document.body.appendChild(a);
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => MessageNotificacion("Acaba de ocurrir un error.","error"))
			.finally(() => setLoadingActive(false));
	}
	function ValidateInputs(){
		let isValidated = true;
		let fechaPedidoMedico = new Date(dataEditEvent.fechaPedidoMedico);

		if (dataEditEvent.fecha === null) {
			setErrorDate(true);
			MessageNotificacion('Debe ingresar Fecha.','error');
			isValidated = false;
			return {isValidated}
		}else{
			if (dataEditEvent.fecha < fechaPedidoMedico) {
				setErrorDate(true);
				MessageNotificacion('La fecha de sesión debe ser mayor o igual a la fecha del pedido médico.','error');
				isValidated = false;
				return {isValidated}
			}
		}
		if (dataEditEvent.integracion){
			if (tokenModal.show && (tokenModal.data === null || tokenModal.data === "")){
				setError(true);
				MessageNotificacion('El Token es obligatorio para la operación que desea realizar.','error');
				isValidated = false;
				return {isValidated}
			}
			if (stateOfCheckbox.manualCharge){
				if (dataEditEvent.numeroAutorizacion === null || dataEditEvent.numeroAutorizacion === "") {
					setError(true);
					MessageNotificacion('Debe ingresar N° Autorización','error');
					isValidated = false;
					return {isValidated}
				}
				if (dataEditEvent.coseguro === null || dataEditEvent.coseguro === "") {
					setError(true);
					MessageNotificacion('Debe ingresar Coseguro','error');
					isValidated = false;
					return {isValidated}
				}
				if (+dataEditEvent.numeroAutorizacion <= 0){
					setError(true);
					MessageNotificacion('No es posible asignar valores menores o iguales a cero.','error');
					isValidated = false;
					return {isValidated}
				}
				if (+dataEditEvent.coseguro < 0){
					setError(true);
					MessageNotificacion('No es posible asignar valores menores a cero.','error');
					isValidated = false;
					return {isValidated}
				}
			}
		}else {
			if (dataEditEvent.numeroAutorizacion === null || dataEditEvent.numeroAutorizacion === "") {
				setError(true);
				MessageNotificacion('Debe ingresar N° Autorización','error');
				isValidated = false;
				return {isValidated}
			}else if (+dataEditEvent.numeroAutorizacion <= 0){
				setError(true);
				MessageNotificacion('No es posible asignar valores menores o iguales a cero.','error');
				isValidated = false;
				return {isValidated}
			}

			if (dataEditEvent.requiereCoseguro){
				if (dataEditEvent.coseguro === null || dataEditEvent.coseguro === "") {
					setError(true);
					MessageNotificacion('Debe ingresar Coseguro','error');
					isValidated = false;
					return {isValidated}
				}else if (+dataEditEvent.coseguro < 0){
					setError(true);
					MessageNotificacion('No es posible asignar valores menores a cero.','error');
					isValidated = false;
					return {isValidated}
				}
			}
		}
		return {isValidated};
	}
	const OnClickCreate = () => {
		setError(false);
		setLoadingActive(true);
		
		const onlyDate = startOfDay(dataEditEvent.fecha);
		const body = {
			fechaSesion: onlyDate,
			numeroAutorizacion: +dataEditEvent.numeroAutorizacion,
			coseguro: +dataEditEvent.coseguro,
			tokenIntegracion: tokenModal.data
		};
		
		(stateOfCheckbox.manualCharge ? 
			api.postManualAuthorizationDocument(dataEditEvent.id, body) : api.postAuthorizationsDocument(dataEditEvent.id, body))
			.then((res) => {
				if(res.status === 200){
					MessageNotificacion("Sesión creada correctamente","success");
				}
			})
			.catch((err) => MessageNotificacion(err.response.data.errores,"error"))
			.finally(()=>{
				setAlertEditEvent(false);
				setLoadingActive(false);
			})
	}
	function HandleClickSubmit(){
		let {isValidated} = ValidateInputs();
		if (isValidated){
			if(tokenModal.data === null && dataEditEvent.integracion && dataEditEvent.requiereToken && !stateOfCheckbox.manualCharge){
				setTokenModal({...tokenModal, show: true});
			}else{
				OnClickCreate();
			}
		}
	}
	const OnClickClose = ()=>{
		setError(false);
		setAlertEditEvent(false);
		setDataEditEvent({...dataEditEvent, numeroAutorizacion: null});
		setStateOfCheckbox({...stateOfCheckbox, manualCharge: false, enableAuthNum: false});
	}
	const handleChangeAutorizacion = (e) => {
		setDataEditEvent({
			...dataEditEvent,
			[e.target.name]: e.target.value !== "" ? e.target.value : null,
		});
		setError(false);
	}
	const DownloadImages = (item) => {
		if (item.imagenes.length > 1) {
			item.imagenes.forEach((element) => {
				apiDownloadImage(
					element,
					item.documentoId,
					item.periodoMes,
					item.documentoPaciente
				);
			});
		} else {
			apiDownloadImage(
				item.imagenes,
				item.documentoId,
				item.periodoMes,
				item.documentoPaciente
			);
		}
	}
	function HandleCloseTokenModal(){
		setTokenModal({show: false, data: null});
	}
	const handleClickOpenModalPeriod = (nameOfPatient, documentId) => {
		setPresentDocumentModal({
			showModal: true,
			patient: nameOfPatient,
			documentId: documentId
		});
	}
	const handleClickPresentPatients = (documentId) =>{
		setLoadingActive(true);
		let documentSelected = []
		documentSelected.push(+documentId)
		const params = {
			documentosId: documentSelected
		}
		api.showDocumentPeriod(params)
		.then(resp=>{
			if (resp.status === 200) MessageNotificacion('Paciente presentado correctamente.',"success");
		})
		.catch(err => MessageNotificacion(err.response.data.errores,"error"))
		.finally(()=>{
			HandleClosePresentDocumentModal();
			setLoadingActive(false);
			loadData();
		})
	}
	const apiDownloadImage = (images, document, period, documentPatient) => {
		let image = {
			documentoId: !document ? document[0] : document,
			imagenId: !images.id ? images[0].id : images.id,
		};
		setLoadingActive(true);
		api
			.imagesDownload(image)
			.then((res) => {
				let extension = '.png';
				let tempFileName = '';
				if (period != '') {
					tempFileName =
						'Imagen - ' + period + '-' + documentPatient.toString();
				} else {
					tempFileName = 'Imagen - ' + documentPatient.toString();
				}

				let fileName = `${tempFileName}${extension}`;
				let blob = new Blob([res.data], {
					type: 'image/png',
				});
				saveAs(blob, fileName);
				MessageNotificacion('Imagen descargada correctamente',"success");
			})
			.catch((err) => MessageNotificacion(err.response.data.errores, "error"))
			.finally(() => setLoadingActive(false));
	}
	const HandleClosePresentDocumentModal = () =>{
		setPresentDocumentModal({
			showModal: false,
			patient: null,
			documentId: null
		});
	}
	const editValidate = (item)=>{
		setLoadingActive(true);
		api
			.getByIdDocument(item.documentoId)
			.then((resp) => {
				setDataEditEvent({
					id: item.documentoId,
					fecha: new Date(),
					sesiones: resp.data.fechaSesiones,
					idObraSocial: resp.data.paciente.obraSocial.id,
					fechaPedidoMedico: resp.data.fecha,
					numeroAfiliado: resp.data.paciente.numeroAfiliado,
					numeroDocumentoAfiliado: resp.data.paciente.documento,
					tipoDeSesiones: resp.data.sesiones,
					codigoPrestacion: null,
					cantidadPrestaciones: resp.data.paciente.obraSocial.cantidadMaximaSesiones,
					numeroAutorizacion: null,
					coseguro: null,
					requiereToken: resp.data.paciente.obraSocial.requiereToken,
					requiereAutorizacion: resp.data.paciente.obraSocial.autorizacion,
					requiereCoseguro: resp.data.paciente.obraSocial.aplicaCoseguro,
					integracion: resp.data.paciente.obraSocial.configuradaIntegracion
				});
				
				setAlertEditEvent(true);
			})
			.catch((err) => MessageNotificacion("No se pudo encontrar el paciente", "error"))
			.finally(()=>setLoadingActive(false))
	}
	const handleClickView = (documento)=>{
		if (devolutionSection){
			const aditionalData = {devolutionSection: true}
			history.push(
			'/paciente/ver/' + documento.documentoId,
			aditionalData,
			localStorage.setItem('viewListSession', true),
			localStorage.setItem('viewSession', false))
		}else if (documento.numeroAfiliado && documento.numeroFormulario){
			history.push(`/paciente/${documento.documentoId}/preautorizado/ver`);
		}
		else{
			history.push(
			'/paciente/ver/' + documento.documentoId,
			localStorage.setItem('viewListSession', true),
			localStorage.setItem('viewSession', false))
		}
	}
	const handleClickAddImage = (documentoId)=>{
		if (devolutionSection){history.push('/paciente/importar/' + documentoId);}
		else{history.push('/paciente/importar/' + documentoId);}
	}
	const handleClickEdit = (documentoId)=>{
		if(devolutionSection){
			const aditionalData = {devolutionSection: true}
			history.push(
			'/paciente/editar/' + documentoId,
			aditionalData,
			localStorage.setItem('viewListSession', false),
			localStorage.setItem('calculateSessions', true))
		}else{
			history.push(
			'/paciente/editar/' + documentoId,
			localStorage.setItem('viewListSession', false),
			localStorage.setItem('calculateSessions', true))
		}
	}
	const handleCloseOpenModalComment = ()=>{
		setOpenModalComment({
			...openModalComment,
			showModal: false
		});
	}
	const TableRowData = ({ item }) => {
		let fechaCierre = item.fechaCierre;
		let EndDateExceeded = false;
		let date = moment(dateToday).format();
		if (item.fechaCierre !== null) {
			if (isProfessional == true) {
				if (fechaCierre < date) {
					EndDateExceeded = true;
				}
			} else if (isAdmin == true) {
				if (fechaCierre < date) {
					EndDateExceeded = true;
				}
			}
		}
		return (
			<TableRow colSpan={6} key={item.documentoId}>
				<TableCell className='p-cell'>
					{moment(item.fechaPrimeraSesion).format('DD-MM-YYYY')}
				</TableCell>
				{!isProfessional && <TableCell className='p-cell'>
					{item.apellidoProfesional} {item.nombreProfesional}
				</TableCell>}
				<TableCell className='p-cell'>
					{item.apellidoPaciente} {item.nombrePaciente}
				</TableCell>
				<TableCell className='p-cell'>{item.documentoPaciente}</TableCell>
				<TableCell className='p-cell'>{item.obraSocial}</TableCell>
				<TableCell className='p-cell'>{item.numeroAutorizacion ?? "--"}</TableCell>
				<TableCell className='p-cell'>{item.numeroFormulario ?? "--"}</TableCell>
				<TableCell className='p-cell'>
					<Button.Group>
						{isView == true && (
								<IconButton
								className='btn-circle-blue'
								aria-label='search'
								onClick={() => handleClickView(item)}
							>
								<Tooltip placement="top" title="Ver">
									<SearchIcon />
								</Tooltip>
							</IconButton>
						)}
						{!item.multiple && item.periodoId === null && isProfessional && item.numeroFormulario === null && (
							
								<IconButton
								className='btn-circle-green'
								aria-label='search'
								onClick={() => {editValidate(item)}}
							>
								<Tooltip placement="top" title="Agregar Sesión">
									<AddIcon />
								</Tooltip>
							</IconButton>
						)}
						{isEdit == true &&
							EndDateExceeded == false &&
							item.periodoId === null && item.numeroFormulario === null &&(
								
									<IconButton
									className='btn-circle-yellow'
									aria-label='edit'
									onClick={() =>	handleClickEdit(item.documentoId)}
								>
									<Tooltip placement="top" title="Editar">
										<EditIcon />
									</Tooltip>
								</IconButton>
							)}

						<PopupImage
							reloadData={reloadData}
							MessageNotification={MessageNotificacion}
							dataToast={dataToast}
							setDataToast={setDataToast}
							dontDelete={isDelete == true && item.periodoId === null}
							data={item.imagenes}
							document={item.documentoId}
							title={
								'Imágenes de: ' +
								item.nombrePaciente +
								' ' +
								item.apellidoPaciente
							}
						/>

						{isExport == true &&
							isAdmin === false &&
							isSuperAdmin == false &&
							EndDateExceeded == false &&
							item.periodoId === null && (
								
									<IconButton
									className='btn-circle-green'
									onClick={() =>handleClickAddImage(item.documentoId)}
									aria-label='addImage'
								>
									<Tooltip placement="top" title="Nueva Imagen">
										<ImageIcon />
									</Tooltip>
								</IconButton>
							)}
						{isExport == true && item.imagenes.length > 0 && (
							
								<IconButton
								className='btn-circle-yellow'
								onClick={() => DownloadImages(item)}
							>
								<Tooltip placement="top" title="Descargar">
									<DownloadImage />
								</Tooltip>
							</IconButton>
						)}
						{!isAdmin && !devolutionSection &&  isDelete == true && item.periodoId === null && (
							
								<IconButton
								className='btn-circle-red'
								aria-label='delete'
								onClick={() => {
									setAlertDelete(true);
									setDataSweetAlert({
										id: item.documentoId,
										title: 'Borrar registro',
										content:
											'¿Está seguro de eliminar el registro de ' +
											item.nombrePaciente +
											' ' +
											item.apellidoPaciente +
											'?',
									});
								}}
							>
								<Tooltip placement="top" title="Eliminar">
									<DeleteIcon />
								</Tooltip>
							</IconButton>
						)}
						
							<IconButton
								onClick={() => printDocument(item.documentoId)}
								className='btn-circle-blue'
								aria-label='print'
							>
								<Tooltip placement="top" title="Imprimir">
									<PrintIcon />
								</Tooltip>
							</IconButton>

							{isProfessional && devolutionSection && 
							<IconButton
								onClick={() => handleClickOpenModalPeriod(item.apellidoPaciente +" "+ item.nombrePaciente, item.documentoId)}
								className='btn-circle-yellow'
								aria-label='print'
							>
								<Tooltip placement="top" title="Presentar">
									<AssignmentTurnedInIcon />
								</Tooltip>
							</IconButton>}

							{item.comentario != null && devolutionSection && (
									
								<IconButton
								className='btn-circle-blue contModalComment'
								aria-label='comment'
								onClick={()=>{
									setOpenModalComment({
										showModal: true,
										patient: item.nombrePaciente + " " + item.apellidoPaciente,
										comment: item.comentario,
									})
								}}
								>
									<Tooltip placement="top" title="Ver Comentario Devolución">
										<EmailIcon/>
									</Tooltip>
								</IconButton>
							)}
							
					</Button.Group>
				</TableCell>
			</TableRow>
		);
	}
	const MessageNotificacion = (message, type)=>{
		setDataToast({
			active: true,
			message: message ?? 'Acaba de ocurrir un error',
			type: type,
		})
	}

	return (
		<>
			{loadingActive && 
			<Dimmer className='loader-fixed' active inverted>
				<Loader size='big'>Cargando..</Loader>
			</Dimmer>}

			{dataTable && dataTable.length > 0 && (
				<Grid.Row>
					<TableContainer component={Paper} id='color-letter-table-tasks'>
						<Table aria-label='spanning table'>
							<TableHead  className='color-letter-inTable'>
								<TableRow>
									<TableCell className='letter-title-table not-padding-table-task'>
										<b>Fecha</b>
									</TableCell>
									{!isProfessional && <TableCell className='letter-title-table'>
										<b>Profesional</b>
									</TableCell>}
									<TableCell className='letter-title-table'>
										<b>Paciente</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>DNI</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Obra Social</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Nro. Autorización</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Nro. Formulario</b>
									</TableCell>
									<TableCell className='letter-title-table'>
										<b>Opciones </b>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{dataTable.map((item) => (
									<TableRowData key={item.documentoId} item={item} />
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid.Row>
			)}

			<Grid.Row centered>
				{dataTable && dataTable.length > 0 && (
					<Pagination
						className='pagination'
						count={totalCount}
						page={page}
						onChange={handleChange}
					/>
				)}
			</Grid.Row>

			<Grid.Row centered>
				{false && dataTable && dataTable.length === 0 && (
					<Grid.Row style={{ textAlign: '-webkit-center' }}>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<TableCell>
								<h5>No se encontraron resultados</h5>
							</TableCell>
						</TableContainer>
					</Grid.Row>
				)}
			</Grid.Row>

			 <Dialog
				open={presentDocumentModal.showModal}
				onClose={HandleClosePresentDocumentModal}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Presentar</DialogTitle>
				<DialogContent>
					<Typography>¿Seguro quieres presentar el paciente: ' {presentDocumentModal.patient} '?</Typography>
				</DialogContent>
				<DialogActions
				style={{
					textAlign: 'center',
					justifyContent: 'center',
				}}
				>
				<Button onClick={HandleClosePresentDocumentModal}>
					Cerrar
				</Button>
				<Button onClick={()=>handleClickPresentPatients(presentDocumentModal.documentId)} color="blue">
					Presentar
				</Button>
				</DialogActions>
			</Dialog>

			<Dialog
			open={openModalComment.showModal}
			onClose={handleCloseOpenModalComment}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title' style={{textAlign: "center"}}>Comentario de la devolución</DialogTitle>
			<DialogContent>
			<TextareaAutosize  
			readOnly
			className="commentReturn"
			style={{width: 350}}
			aria-label="minimum height" 
			minRows={4} 
			value={openModalComment.comment}
			/>
			</DialogContent>

			<DialogActions
			style={{
				textAlign: 'center',
				justifyContent: 'center',
			}}
			>
			<Button onClick={handleCloseOpenModalComment}>
				Cerrar
			</Button>
			</DialogActions>
			</Dialog>

			<Dialog
				open={alertEditEvent}
				onClose={() => setAlertEditEvent(false)}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Nueva Sesión</DialogTitle>
				<DialogContent>
					<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
						<KeyboardDatePicker
							autoOk
							className='size-date-picke-filter'
							clearable
							value={dataEditEvent.fecha}
							placeholder='Ingrese Fecha'
							onChange={(date) => {
								const onlyDate = startOfDay(date);
								setDataEditEvent({ ...dataEditEvent, fecha: onlyDate });
							}}
							format='dd/MM/yyyy'
							inputVariant='outlined'
							error={errorDate}
						/>
					</MuiPickersUtilsProvider>
					<TextField
						autoFocus
						margin='dense'
						type='number'
						className={dataEditEvent.integracion && !stateOfCheckbox.manualCharge ? "disabled-input" : ""}
						variant="outlined"
						disabled={dataEditEvent.integracion && !stateOfCheckbox.manualCharge}
						placeholder='N° Autorización'
						name='numeroAutorizacion'
						value={dataEditEvent.numeroAutorizacion}
						onChange={(e) => {
							handleChangeAutorizacion(e);
						}}
						error={error}
					/>
					<TextField
						autoFocus
						className={(!dataEditEvent.requiereCoseguro && !dataEditEvent.integracion) || (!dataEditEvent.requiereCoseguro && !stateOfCheckbox.manualCharge) ? "disabled-input" : ""}
						margin='dense'
						disabled={(!dataEditEvent.requiereCoseguro && !dataEditEvent.integracion)  || (!dataEditEvent.requiereCoseguro && !stateOfCheckbox.manualCharge)}
						type='number'
						variant="outlined"
						placeholder='Coseguro'
						name='coseguro'
						value={dataEditEvent.coseguro}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AttachMoneyOutlinedIcon/>
								</InputAdornment>
								)
						}}
						onChange={(e) => {
							handleChangeAutorizacion(e);
						}}
						error={error}
					/>
					{dataEditEvent.integracion &&
						<SwitchComponent 
							label="Carga Manual"
							name="manualCharge" 
							onChange={(e)=>{
								setStateOfCheckbox({...stateOfCheckbox, manualCharge: e.target.checked, enableAuthNum: e.target.checked});
								setDataEditEvent({
									...dataEditEvent,
									numeroAutorizacion: "",
									coseguro: ""
								})
							}} 
							stateOfChecked={stateOfCheckbox.manualCharge}/>}
				</DialogContent>
				<DialogActions
					style={{
						textAlign: 'center',
						justifyContent: 'center',
					}}
				>
					<Button onClick={OnClickClose}>
						Cancelar
					</Button>
					<Button onClick={HandleClickSubmit} color='green'>
						Agregar
					</Button>
				</DialogActions>
			</Dialog>

			<DialogDelete
				open={alertDelete}
				setOpen={setAlertDelete}
				title={dataSweetAlert.title}
				content={dataSweetAlert.content}
				actionConfirm={actionConfirm}
			/>

			{/* Modal Token TODO: PASARLO A UN COMPONENTE APARTE*/}
			<Dialog
				open={tokenModal.show}
				onClose={HandleCloseTokenModal}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Token</DialogTitle>
				<DialogContent style={{display: "grid", gap: 20}}>
					<TextField
						style={{ marginRight: 5 }}
						size='small'
						label='Token'
						name="token"
						id="token"
						error={error}
						value={tokenModal.data}
						onChange={(e) => {setTokenModal({...tokenModal, data: e.target.value})}}
						variant='outlined'
					/>
				</DialogContent>

				<DialogActions style={{justifyContent: 'center'}}>
					<Button onClick={HandleCloseTokenModal}>
						Cancelar
					</Button>
					<Button onClick={HandleClickSubmit} color='green'>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
