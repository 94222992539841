import DateFnsUtils from "@date-io/date-fns";
import { Tooltip } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "@material-ui/lab/Pagination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Table
} from "semantic-ui-react";
import api from "../../api";
import { Options } from "../../enums";

export default function Holidays({ setDataToast }) {
  const [page, setPage] = useState(1);
  const [loadingActive, setLoadingActive] = useState();
  const loadForPage = 20;
  const handleChange = (event, value) => {
    setPage(value);
    onClickViewList(value);
  };
  const [totalCount, setTotalCount] = useState();
  const [viewDate, setViewDate] = useState();
  const [dataTable, setDataTable] = useState();
  const [error, setError] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [dateFilter, setDateFilter] = useState({
    dateFrom: null,
    dateTo: null,
  });

  const [defaultValues, setDefaultValues] = useState({
    id: undefined,
    date: undefined,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: undefined,
  });
  const [isView, setView] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCloseNew = () => {
    setOpenNew(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpen = () => {
    setOpenNew(true);
  };

  function preDelete(id,fecha) {
    setDeleteDialog({ open: true, id: id ,fecha:fecha});
  }

  function closePreDelete() {
    setDeleteDialog({ ...deleteDialog, open: false });
  }

  function shortError(message) {
    setDataToast({
      active: true,
      message: message,
      type: "error",
    });
  }

  function onClickViewList(nroPage) {
    setLoadingActive(true);
    let DateFrom = dateFilter.dateFrom
      ? moment(dateFilter.dateFrom).format()
      : null;
    let DateTo = dateFilter.dateTo ? moment(dateFilter.dateTo).format() : null;

    api
      .getHolidayList(DateFrom, DateTo)
      .then((res) => {
        let data = res.data;
        let amount = 0;
        let currentPage = nroPage ? nroPage : page;
        let count = data.length;
        setTotalCount(count);
        amount = Math.ceil(count / loadForPage);

        let dataApiPaginate = data.slice(
          (currentPage - 1) * loadForPage,
          currentPage * loadForPage
        );

        let list = [];
        data.forEach((item) => {
          if (item.id !== 0) {
            list.push({
              id: item.id,
              fecha: item.fecha,
            });
          }
        });

        setDataTable(null);
        setDataTable(dataApiPaginate);
        setLoadingActive(false);
      })

      .catch((err) => {
        setDataToast({
          active: true,
          type: "error",
          message: "Ocurrió un error.",
        });
        setLoadingActive(false);
      });
  }

  function getHolidays(id) {
    setLoadingActive(true);
    api
      .getHoliday(id)
      .then((res) => {
        let data = res.data;

        setDefaultValues({
          id: data.id,
          date: data.fecha,
        });

        setOpenEdit(true);
        setViewDate(true);
        setLoadingActive(false);
      })
      .catch((err) => {
        setViewDate(false);
        setLoadingActive(false);
        shortError("Ocurrió un error.");
      });
  }

  function Edit() {
    setLoadingActive(true);
    if (defaultValues.date) {
      let body = {
        id: defaultValues.id,
        fecha: moment(defaultValues.date).format(),
      };

      api
        .putHoliday(body)
        .then((res) => {
          setDataToast({
            active: true,
            message: "Feriado editado correctamente.",
            type: "success",
          });
          onClickViewList(1);
          setLoadingActive(false);
        })

        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
          setLoadingActive(false);
        });

      setError(false);
      handleCloseEdit();
    } else {
      setError(true);
    }
  }

  const onClickCreate = () => {
    if (defaultValues.date) {
      let body = {
        fecha: moment(defaultValues.date).format(),
      };
      setLoadingActive(true);
      api
        .postHoliday(body)
        .then(() => {
          setDataToast({
            active: true,
            message: "Feriado creado correctamente.",
            type: "success",
          });
          onClickViewList(1);
          setLoadingActive(false);
        })

        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
          setLoadingActive(false);
        });

      setError(false);
      handleCloseNew();
    } else {
      setError(true);
    }
    defaultValues.date = null;
  };

  function deleteHolidays(id) {
    closePreDelete();
    setLoadingActive(true);

    api
      .deleteHoliday(id)
      .then((res) => {
        let newDataTable = [];
        dataTable.forEach((holiday) => {
          if (holiday.id !== id) {
            newDataTable.push(holiday);
          }
        });

        setDataTable(null);
        setDataTable(newDataTable);
        setDataToast({
          active: true,
          message: "Feriado eliminado correctamente.",
          type: "success",
        });
        setLoadingActive(false);
        onClickViewList(1);
      })
      .catch((err) => {
        if (err.response.data.errores) {
          setDataToast({
            active: true,
            message: err.response.data.errores,
            type: "error",
          });
        } else {
          shortError("Ocurrió un error");
        }
        setLoadingActive(false);
      });
  }

  useEffect(() => {
    let options = JSON.parse(localStorage.getItem("optionCruds"));
    options.forEach((element) => {
      if (element == Options.isView) {
        setView(true);
      } else if (element == Options.isCreate) {
        setCreate(true);
      } else if (element == Options.isEdit) {
        setEdit(true);
      } else if (element == Options.isDelete) {
        setDelete(true);
      }
    });
    onClickViewList(page);
  }, []);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Card className="color-border-dash">
            <CardContent className="center-responsive-filters">
              <Grid.Row className="header-section">
                <div className="titleAndVideo">
                  <span style={{ fontSize: 27 }}>Feriados</span>
                </div>
                <div>
                  {isCreate == true && 
                    <Button
                    className="btn-new-filter"
                    onClick={() => handleClickOpen()}
                    icon
                    labelPosition="left"
                    >
                      <Icon name="plus" />
                      Nuevo
                    </Button>
                  }
                </div>
                <Dialog
                  open={openNew}
                  onClose={handleCloseNew}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Nuevo</DialogTitle>
                  <DialogContent>
                    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        className="size-date-picke-filter"
                        clearable
                        placeholder="Fecha"
                        onChange={(date) =>
                          setDefaultValues({ ...defaultValues, date: date })
                        }
                        format="dd/MM/yyyy"
                        margin="normal"
                        inputVariant="outlined"
                        value={defaultValues.date}
                        error={error}
                      />
                    </MuiPickersUtilsProvider>
                  </DialogContent>

                  <DialogActions
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={handleCloseNew}>
                      Cancelar
                    </Button>
                    <Button onClick={onClickCreate} color="green">
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid.Row>
              <Grid.Row className="content-filter-list">
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter filter-input"
                    clearable
                    style={{ margin: 5 }}
                    value={dateFilter.dateFrom}
                    placeholder="Fecha Desde"
                    onChange={(date) =>
                      setDateFilter({ ...dateFilter, dateFrom: date })
                    }
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                  />
                  <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter filter-input"
                    clearable
                    style={{ margin: 5 }}
                    value={dateFilter.dateTo}
                    placeholder="Fecha Hasta"
                    minDate={new Date(dateFilter.dateFrom)}
                    onChange={(date) =>
                      setDateFilter({ ...dateFilter, dateTo: date })
                    }
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid.Row>
                <Button
                  className="btn-search-filter"
                  primary
                  icon
                  labelPosition="left"
                  onClick={() => onClickViewList()}
                  type="submit"
                >
                  <Icon name="search" />
                  Buscar
                </Button>
            </CardContent>
          </Card>
        </Grid.Row>
        {loadingActive ? (
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando..</Loader>
          </Dimmer>
        ) : (
          dataTable &&
          dataTable.length > 0 && (
            <Grid.Row>
              <TableContainer component={Paper} id='color-letter-table-tasks'>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="letter-title-table c-text-center" >
                        <b>Fecha</b>
                      </TableCell>
                      <TableCell className="letter-title-table c-text-center" >
                        <b>Opciones</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataTable.map(({ id, fecha }) => (
                      <TableRow key={id}>
                        <TableCell className="c-text-center p-cell" >
                          {moment(fecha).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell className="c-text-center p-cell" >
                          <Button.Group>
                            {isEdit == true && (
                            <IconButton
                              className="btn-circle-orange"
                              onClick={() => getHolidays(id)}
                            >
                              <Tooltip placement="top" title="Editar">
                                <CreateIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                          {isDelete == true && (
                            <IconButton
                              className="btn-circle-red"
                              onClick={() => preDelete(id,moment(fecha).format("DD/MM/YYYY"))}
                            >
                              <Tooltip placement="top" title="Eliminar">
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                          </Button.Group>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid.Row>
          )
        )}
        {dataTable && dataTable.length === 0 && (
          <Grid.Row style={{ textAlign: "-webkit-center" }}>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <TableCell>
                <h5>No se encontraron resultados</h5>
              </TableCell>
            </TableContainer>
          </Grid.Row>
        )}
        {dataTable && (
          <Grid.Row centered>
            <Pagination
              className="pagination"
              count={
                totalCount / loadForPage > parseInt(totalCount / loadForPage)
                  ? parseInt(totalCount / loadForPage) + 1
                  : parseInt(totalCount / loadForPage)
              }
              page={page}
              onChange={handleChange}
            />
          </Grid.Row>
        )}
      </Grid>

      {viewDate && (
        <Dialog
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Editar</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                className="size-date-picke-filter"
                clearable
                style={{ margin: 5 }}
                placeholder="Fecha"
                onChange={(date) =>
                  setDefaultValues({ ...defaultValues, date: date })
                }
                format="dd/MM/yyyy"
                margin="normal"
                inputVariant="outlined"
                value={defaultValues.date}
                error={error}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>

          <DialogActions
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => handleCloseEdit()}>
              Cancelar
            </Button>

            <Button onClick={Edit} color="green">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {deleteDialog != undefined && (
        <Dialog open={deleteDialog.open} onClose={setOpenDelete}>
          <DialogTitle id="form-dialog-title">Borrar Registro</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro de eliminar el registro del feriado {" "}
              {deleteDialog.fecha}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closePreDelete()}>Cancelar</Button>
            <Button onClick={() => deleteHolidays(deleteDialog.id)} color="red">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
