import DateFnsUtils from "@date-io/date-fns"
import { IconButton, TextField } from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'
import React, { useEffect } from 'react'
import ReactSelect from 'react-select'
import { Button, Grid, Icon, TextArea } from 'semantic-ui-react'
import { ICrudInstallmentModal } from "../../../../services/interfaces/Managment/ICrudInstallment"
import { GetProfesionals } from "../../../../services/services/Users"
import GeneralModalCrud from '../../../utils/GeneralModalCrud'
import PreviewViewInstallment from "./PreviewViewInstallment"
import GeneralModalShow from "../../../utils/GeneralModalShow"
import { selectTypeFormat } from "../../../../services/interfaces/utils/IUtilDeclaration"
import { TypeRoleNum } from "../../../../enums"

const NewModalInstallment: React.FC<ICrudInstallmentModal> = ({
    titleOfModal,
    modal,
    openModal, 
    values, 
    setValues,
    errorNotification,
    HandleChangeInput, 
    MessageNotification,
    OpenModalSelected,
    SendValues})=>{

        const [profesionalList, setProfesionalList] = React.useState<selectTypeFormat[]>([]);
        const [loaderSelect, setLoaderSelect] = React.useState({profesional: false});
        const [openPreview, setOpenPreview] = React.useState(false);

        useEffect(()=>{
            GetProfesionalList();
        },[])

    async function GetProfesionalList(){
        try{
            setLoaderSelect({profesional: true});
            const {status, data} = await GetProfesionals({rolId: TypeRoleNum.Professional, conPaginacion: false});
            if (status === 200){
                const RSUserList = data?.results?.map(item=>{return {value: item.id, label: `${item.apellido}, ${item.nombre}`}}) ?? [];
                setProfesionalList(RSUserList);
            }
        }catch(err: any){
            MessageNotification(err.message, "error");
        }finally{
             setLoaderSelect({profesional: false});
        }
    }
        
    function HandleCloseModal(){
       OpenModalSelected("close", `${modal === "create" ? "create" : "edit"}`, values);
    }

    function HandleSubmit(){
        SendValues();
    }

    return(
        <>
            <GeneralModalCrud 
                titleOfModal={titleOfModal}
                openModal={openModal} 
                HandleCloseModal={HandleCloseModal} 
                HandleSubmit={HandleSubmit}>
                    <Grid.Column style={{display:"flex", flexDirection: "column"}} className="cont-default-modal">
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                            <section style={{display: "flex", marginBottom: 15}}>
                                <KeyboardDatePicker
                                    autoOk
                                    className="size-date-picke-filter"
                                    clearable
                                    variant='inline'
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    inputVariant="outlined"
                                    placeholder='Fecha'
                                    value={values.fecha}
                                    error={errorNotification.fecha}
                                    onChange={(date)=>HandleChangeInput(date,"fecha")}
                                />
                                {values.fecha !== null && (
                                    <IconButton
                                        style={{ marginTop: 10 }}
                                        onClick={()=>setValues({...values, fecha: null})}
                                    >
                                        <CloseOutlinedIcon/>
                                    </IconButton>
                                )}
                            </section>
                        </MuiPickersUtilsProvider>
                        <ReactSelect
                            placeholder={`${values.profesionalNombre ?? "Profesionales..."}`}
                            options={profesionalList}
                            isLoading={loaderSelect.profesional}
                            isClearable={true}
                            className={errorNotification.profesionalId ? "errorNotificationInput" : ""}
                            onChange={(e)=>setValues({...values, profesionalId: e === null ? null : e.value, profesionalNombre: e === null ? null : e.label})}
                            styles={{
                                control: (s)=>({...s, maxWidth: 300})
                            }}
                        />
                        <TextField
                            style={{margin: "15px 0"}}
                            value={values.importe}
                            label='Importe'
                            type='number'
                            error={errorNotification.importe}
                            InputLabelProps={{shrink: true}}
                            onChange={(e)=>HandleChangeInput(e,"importe")}
                        />
                        <TextField
                            style={{margin: "15px 0"}}
                            value={values.cantidadCuotas}
                            label='Cantidad'
                            type='number'
                            error={errorNotification.cantidadCuotas}
                            InputLabelProps={{shrink: true}}
                            onChange={(e)=>HandleChangeInput(e,"cantidadCuotas")}
                        />
                        <TextArea
                            value={values.detalle} 
                            placeholder="Detalle..."
                            name="detalle"
                            className={`commentReturn ${errorNotification.detalle ? "errorNotificationInput" : ""}`}
                            onChange={(e: any)=>{
                                setValues({
                                ...values,
                                detalle: e.target.value
                            })
                            }}
                            style={{resize: "vertical", margin: "15px 0"}}
                        />
                        <Button
                            className='btn-search-filter'
                            primary
                            disabled={Object.values(values).some(item => item === null || item === "")}
                            icon
                            labelPosition='left'
                            onClick={()=>{setOpenPreview(true)}}
                            type='submit'
                            >
                            <Icon name='search' />
                            Vista Previa
                        </Button>
                    </Grid.Column>
            </GeneralModalCrud>
            <GeneralModalShow
            openModal={!Object.values(values).some(item => item === null || item === "") && openPreview}
            HandleClose={()=>{setOpenPreview(false)}}
            title="Vista previa - Cuotas"
            >
                <PreviewViewInstallment MessageNotification={MessageNotification} values={values}/>
            </GeneralModalShow>
        </>
    )
}

export default NewModalInstallment;