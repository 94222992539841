import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, makeStyles } from '@material-ui/core';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';
import ReactSelect from 'react-select';
import { IPracticeExtended, PracticeAmountRowRequest } from "../../../../../api/Interfaces/configurations/IAmounts";
import { StatusCode } from '../../../../../enums';
import { IPracticeCheck, IPracticeSelected } from "../../../../../services/interfaces/Configurations/AssignAmountToPractice";
import { IDataToAssign, IErrorNotificationAmountAssociation, ILoaderSelect, IModalAmountForOs } from "../../../../../services/interfaces/Configurations/IModalAmoutForOs";
import { selectTypeFormat } from "../../../../../services/interfaces/utils/IUtilDeclaration";
import { GetSocialWorkSelect, UpdateAmountSocialWork } from '../../../../../services/services/ConfigurationSocialWork';
import { GetPractices } from "../../../../../services/services/Practices";
import GeneralModalCrud from '../../../../utils/GeneralModalCrud';
import GeneralSimpleList from "../../../../utils/GeneralSimpleList";
import { _DEFAULT_VALUES_ModalAmountForOs } from "../../defaultValues";

const customStyles = makeStyles(()=>({
    contAmoutModal: {
        overflow: "visible",
    }
}))

const ModalAmountForOs: React.FC<IModalAmountForOs> = ({
    associationId,
    openModal, 
    titleOfModal, 
    modal, 
    setLoadingActive,
    CloseModal, 
    MessageNotification})=>{
    const classStyle = customStyles();
    const [practiceList, setPracticeList] = React.useState<IPracticeExtended[]>([]);
    const [socialWorkList, setSocialWorkList] = React.useState<selectTypeFormat[]>([]);
    const [loaderSelect, setLoaderSelect] = React.useState<ILoaderSelect>(_DEFAULT_VALUES_ModalAmountForOs._LOADER_SELECT);
    const [errors, setErrors] = React.useState<IErrorNotificationAmountAssociation>(_DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION);
    const [practiceSelected, setPracticeSelected] = React.useState<IPracticeSelected>({});
    const [dataToAssign, setDataToAssing] = React.useState<IDataToAssign>(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);

    React.useEffect(()=>{
        SocialWorklist();
    },[]);

    async function SocialWorklist(){
		try{
			setLoaderSelect({...loaderSelect, socialWork: true});
            const params = {
                asignadas: true,
                confirmada: true
            }
			const {status, data} = await GetSocialWorkSelect(params);
			if (status === StatusCode.Ok){
				const socialWorks: selectTypeFormat[] = data.results.map(item =>{
					return {value: item.id, label: item.nombre}
				});
				setSocialWorkList(socialWorks);
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores,"error")
		}finally{
			setLoaderSelect({...loaderSelect, socialWork: false});
		}
	}
    async function PracticeList({socialWorkId}){
		try{
            setLoadingActive(true);
			const {status, data} = await GetPractices(socialWorkId, true, false);
			if (status === StatusCode.Ok){
                const practiceList: IPracticeSelected = data.results.reduce((acc, actualValue)=>{
					return {
						...acc,
						[actualValue.id]: {
							idPractice: +actualValue.id,
							checked: false,
							amount: 0
						}
					}
				},{});
                setPracticeSelected(practiceList);
				setPracticeList(data.results);
                
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores,"error")
		}finally{
            setLoadingActive(false);
        }
	}
    function GetPracticesBySocialWorkSelected(e){
        if (e !== null){
            setDataToAssing({
                ...dataToAssign,
                socialWorkId: +e.value
            });
            PracticeList({socialWorkId: e.value});
        }
    }
    function HandleChangeDate(dateSelected){
        setDataToAssing({
            ...dataToAssign,
            date: dateSelected
        })
    }
    function ValidateInputs(){
        let objError: IErrorNotificationAmountAssociation = _DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION;
        let isValidated = true;
        const practiceList: IPracticeCheck[] = Object.values(practiceSelected);
        const arePracticeSelected = practiceList.every((practice: any) => practice.checked === false);
      
        if (dataToAssign.socialWorkId === null){
            MessageNotification("El campo 'Obra Social' es obligatorio.","error");
            objError.socialWorkId = true;
            isValidated = false;
        }else
        if (dataToAssign.date === null){
            MessageNotification("El campo 'fecha' es obligatorio.","error");
            objError.date = true;
            isValidated = false;
        }else
        if (arePracticeSelected){
            MessageNotification("Deberá seleccionar al menos una práctica.","error");
            isValidated = false;
            practiceList.forEach((practice: IPracticeCheck)=>{
                objError[practice.idPractice] = true;
            });
        }
        if (!arePracticeSelected) {
            const practiceSelectedWithCheck = practiceList.filter((practice: IPracticeCheck) => practice.checked === true);
            const valueSelectedWithZero = practiceSelectedWithCheck.some((practice: IPracticeCheck)=> practice.amount <= 0 || practice.amount > 9999999);
            if (valueSelectedWithZero){
                MessageNotification("El monto asignado deberá ser mayor a cero y menor a 9999999.","error");
                practiceSelectedWithCheck.forEach((practice: IPracticeCheck)=>{
                    if (practice.amount <= 0 || practice.amount > 9999999){
                        objError[practice.idPractice] = true;
                    }
                });
                isValidated = false;
            }
        }
        
        setErrors(objError);
        objError = _DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION;
        return isValidated
    }
    function HandleSubmit(){
        if (ValidateInputs()){
            AssingAmountSocialWorkAccount();
        }
    }
    async function AssingAmountSocialWorkAccount(){
		try{
			setLoadingActive(true);
            const filteredPracticeList = Object.values(practiceSelected).filter((practice: IPracticeCheck) => practice.checked);
            const request: PracticeAmountRowRequest[] = filteredPracticeList.map((practice: IPracticeCheck) => ({
                    id: practice.idPractice,
                    fecha: dataToAssign.date as Date,
                    monto: practice.amount
                }))
			const {status} = await UpdateAmountSocialWork(+associationId, dataToAssign.socialWorkId as number, request);
			if (status === StatusCode.Ok){
				CloseModal("close", "massiveEditAmount");
                setDataToAssing(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);
                setErrors(_DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION);
                setPracticeSelected({});
				MessageNotification("Montos actualizados correctamente.", "success");
			}
		}catch(err: any){
			MessageNotification(err.message ?? err.response.data.errores, "error");
		}
		finally{
			setLoadingActive(false);
		}
	}
    function HandleCheckAllPractices(e){
		const allPracticeChecked: IPracticeCheck | {} = practiceList.reduce((acc, actualValue)=>{
			return {
				...acc,
				[actualValue.id]: {
					...practiceSelected[actualValue.id],
					checked: e.target.checked,
					amount: e.target.checked ? +practiceSelected[actualValue.id]?.amount : 0
				}
			}
		},{});
		setPracticeSelected(allPracticeChecked);
	}
    function HandleAssignAmount(e){
		setPracticeSelected({
			...practiceSelected,
			[e.target.name]: {
				...practiceSelected[e.target.name],
				amount: +e.target.value
			}
		});
	}
	function HandleSelectPractice(e){
		setPracticeSelected({
			...practiceSelected,
			[e.target.name]: {
				idPractice: +e.target.name,
				checked: e.target.checked,
				amount: e.target.checked ? +practiceSelected[e.target.name]?.amount : 0
			}
		})
	}
    return(
    <GeneralModalCrud
    openModal={openModal}
    HandleCloseModal={()=>CloseModal("close", modal)}
    HandleSubmit={HandleSubmit}
    titleOfModal={titleOfModal}
    styles={`${classStyle.contAmoutModal} ${dataToAssign.socialWorkId && 'cont-AmountPracticeList'}`}
    >
        <>
        <ReactSelect
            placeholder="Obras Sociales..."
            options={socialWorkList}
            isLoading={loaderSelect.socialWork}
            className={errors.socialWorkId ? "errorNotificationInput" : ""}
            onChange={(e)=>GetPracticesBySocialWorkSelected(e)}
        />
        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
            <KeyboardDatePicker
                style={{ marginTop: 5 }}
                autoOk
                className='size-date-picke-filter'
                inputVariant='outlined'
                InputAdornmentProps={{ position: 'end' }}
                variant='inline'
                name='month'
                error={errors.date}
                format="dd/MM/yyyy"
                placeholder='Fecha'
                value={dataToAssign.date}
                onChange={(date)=> HandleChangeDate(date)}
            />
        </MuiPickersUtilsProvider>
        {dataToAssign.socialWorkId &&
        <GeneralSimpleList
        dataToRender={practiceList}
        isLoaderActive={false}
        paginated={false}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <Checkbox 
                            checked={ Object.values(practiceSelected).length > 0 && Object.values(practiceSelected).every((practice: IPracticeCheck) => practice.checked)}
                            onChange={(e)=>HandleCheckAllPractices(e)}
                            />
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Código - Práctica</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Monto</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {practiceList.map((item: IPracticeExtended) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <Checkbox
                                checked={practiceSelected[item.id]?.checked ?? false}
                                name={`${item.id}`}
                                onChange={(e)=>HandleSelectPractice(e)}
                                />
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.codigo ?? "No asignado"} - {item.alias ?? item.nombre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <TextField
                                    value={practiceSelected[item.id]?.amount ?? 0}
                                    type='number'
                                    size="small"
                                    disabled={practiceSelected[item.id]?.checked === false || !practiceSelected[item.id]}
                                    name={`${item.id}`}
                                    error={errors[item.id]}
                                    InputLabelProps={{shrink: true}}
                                    onChange={(e)=>HandleAssignAmount(e)}
                                    InputProps={{
                                        inputProps: {max: 9999999, min: 0},
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyOutlinedIcon/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralSimpleList>}
        </>

    </GeneralModalCrud>
    )
}

export default ModalAmountForOs;