import React from 'react';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { TextField } from '@material-ui/core';
import { CreateBank, EditBank } from '../../../../services/services/Bank';
import { StatusCode } from '../../../../enums';
import { Dimmer, Loader } from 'semantic-ui-react';
import { _DEFAULT_VALUES_BANK } from '../../defaultValues';
import { IBankSelect, INewModalBank } from '../../../../services/interfaces/Managment/ICrudBank';

const NewModalBank: React.FC<INewModalBank> = ({
    titleOfModal,
    bank,
    openModal, 
    filterObj,
    setFilterObj,
    HandleCloseModal,
    MessageNotification,
    ReloadData
}) =>{
    const [values, setValues] = React.useState<IBankSelect>(_DEFAULT_VALUES_BANK);
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [errorNotification, setErrorNotification] = React.useState<any>({});

    React.useEffect(()=>{
        if (bank.id !== 0){
            setValues({
                id: bank.id,
                nombre: bank.nombre
            });
        }
    },[]);

    //#region ENVIAR / CREAR / ACTUALIZAR
    function HandleSubmit(){
        if (ValidateInput()){
            if (bank.id === 0) HandleCreateBank();
            else HandleEditBank();
        }
    }
    async function HandleEditBank(){
        try{
            setLoadingActive(true);
            const params = {nombre: values.nombre}
            const {data, status} = await EditBank(bank.id, params);
            if (status === StatusCode.Ok){
                CloseModalAndDefaultValues();
                MessageNotification("Banco editado correctamente.", "success");
                ReloadData();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function HandleCreateBank(){
        try{
            setLoadingActive(true);
            const {data, status} = await CreateBank(values);
            if (status === StatusCode.Ok){
                CloseModalAndDefaultValues();
                MessageNotification("Banco creado correctamente.", "success");
                ReloadData();
            }
        }catch(err: any){
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function ValidateInput(){
        let isValidated = true;
        let objError = {};
        const inputNulls = Object.entries(values).filter(input => input[1] === null || input[1] === "");

        if (inputNulls.length > 0){
            isValidated = false;
            inputNulls.forEach(input => {
                objError = {...objError, [input[0]]: true}
            });
            MessageNotification("Todos los campos son obligatorios.", "error");
            setErrorNotification(objError);
        }
        return isValidated;
    }
    function CloseModalAndDefaultValues(){
        HandleCloseModal();
        setValues(_DEFAULT_VALUES_BANK);
    }
    function HandleChangeValues(e){
        setValues({
            ...values,
            nombre: e.target.value
        });
    }
    //#endregion
    
    return (
        <>
        {loadingActive ?
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
            :
            <GeneralModalCrud
            titleOfModal={titleOfModal}
            openModal={openModal}
            HandleCloseModal={HandleCloseModal}
            HandleSubmit={HandleSubmit}
            >
                <TextField
                value={values.nombre}
                label='Nombre'
                disabled={false}
                size="small"
                error={errorNotification.nombre}
                type='text'
                variant="outlined"
                onChange={(e)=> HandleChangeValues(e)}
                InputLabelProps={{shrink: true}}
                />
            </GeneralModalCrud>    
        }
        </>
    );
}

export default NewModalBank;