import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ReactSelect from 'react-select';
import {
	Button,
	Grid,
	Icon,
} from "semantic-ui-react";
import api from '../../../api';
import { IFilterSocialWorkRequest, ISocialWork, ISocialWorkAssignRequest, ISocialWorkCheckboxState, ISocialWorkCrudValues, ISocialWorkErrorInputs, ISocialWorkFilterSearch, ISocialWorkSelectListRequest } from '../../../api/Interfaces/configurations/ISocialWork';
import { SocialWorkEnum, StatusCode, TiposIntegracionEnum, TypeRole } from '../../../enums';
import { setObjFilterGrid } from '../../../redux/amount';
import { selectTypeFormat, typeOfActionForModal, typeOfModal } from "../../../services/interfaces/utils/IUtilDeclaration";
import { AssignSocialWork, AssingInstructiveForSocialWork, CreateSocialWorkByAssociation, DeleteSocialWork, EditSocialWork, GetInstructiveSocialWork, GetSocialWorkById, GetSocialWorkList, GetSocialWorkListByAssociation, GetSocialWorkSelect, ReactivateAssignedSocialWorkAccount } from '../../../services/services/ConfigurationSocialWork';
import EditorText from "../../QuillEditoText/EditorText";
import { toolbarConfig } from "../../QuillEditoText/configs/toolbarConfig";
import ButtonBox from '../../utils/ButtonBox';
import GeneralList from "../../utils/GeneralList";
import GeneralModalCrud from "../../utils/GeneralModalCrud";
import GeneralModalDelete from "../../utils/GeneralModalDelete";
import CrudSocialWork from './SupAdmin-SocWork/CrudSocialWork';
import ModalAmountForOs from './amount/modals/ModalAmountForOs';
import { _DEFAULT_ERROR_VALUES, _DEFAULT_FILTROS_VALUES, _DEFAULT_LOADER_SELECT, _DEFAULT_MODAL_VALUES, _DEFAULT_REACTIVE_VALUES, _DEFAULT_STATE_CHECKBOX, _DEFAULT_VALUES } from "./defaultValues";
import ModalOsForRegional from "./modals/ModalOsForRegional";
import ViewConfigSocialWork from './modals/ViewConfigSocialWork';
import ModalPracticeForAssociation from './modals/ModalPracticeForAssociation';

const OsForRegional: React.FC<any> = ({ setDataToast }: any) => {

	const history = useHistory();
	const { control } = useForm({});
	const loadForPage = 20;
	const disparador = useDispatch();
	const {objFilterGrid}: any = useSelector<any>(({amount}) => amount);
	//#region VARIABLES DE ESTADO
	const [text, setText] = React.useState<null | string>(null);
	const [page, setPage] = React.useState(1);
	const [loadingActive, setLoadingActive] = React.useState(false);
	const [RolType, setRolType] = React.useState<string>(JSON.parse(localStorage.getItem('TypeRole') ?? ""));
	const [colegioId, setColegioId] = React.useState<any>(localStorage.getItem('colegioId') ?? null);
	const [deleteIcon, setDeleteIcon] = React.useState(false);
	const [openModal, setOpenModal] = React.useState<any>(_DEFAULT_MODAL_VALUES);
	const [pagesToRender, setPagesToRender] = React.useState<number>(1);
	const [listaOs, setListaOs] = React.useState<selectTypeFormat[]>([]);
	const [regionalList, setRegionalList] = React.useState<selectTypeFormat[]>([]);
	const [dataTable, setDataTable] = React.useState<ISocialWork[]>([]);
	const [values, setValues] = React.useState<ISocialWorkCrudValues>(_DEFAULT_VALUES);
	const [StateCheckBox, setStateCheckBox] = React.useState<ISocialWorkCheckboxState>(_DEFAULT_STATE_CHECKBOX);
	const [reactivate, setReactivate] = React.useState(_DEFAULT_REACTIVE_VALUES);
	const [filtrosBusqueda, setFiltrosBusqueda] = React.useState<ISocialWorkFilterSearch>(objFilterGrid);
	const [errors, setErrors] = React.useState<ISocialWorkErrorInputs>(_DEFAULT_ERROR_VALUES);
	const [loaderSelect, setLoaderSelect] = React.useState(_DEFAULT_LOADER_SELECT);
	//#endregion

	React.useEffect(() => {
		if (RolType === TypeRole.Admin || RolType === TypeRole.Colegio){
			setStateCheckBox(_DEFAULT_STATE_CHECKBOX);
			OnClickViewList(page);
		}
	}, []);

	//#region Filtros de obra social y cuentas
	async function FiltroListaOs(isDeleted = false){
		setLoaderSelect({...loaderSelect, socialWork: true});
		try{
			let params: ISocialWorkSelectListRequest = {
				dadasDeBaja: isDeleted
			}
			const {status, data} = await GetSocialWorkSelect(params);
			const listOs = data.results.map(item =>{
				return{value: item.id, label: item.nombre}
			});
			if (status === StatusCode.Ok){
				setListaOs(listOs);
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores,"error")
		}
		finally{
			setLoaderSelect(_DEFAULT_LOADER_SELECT);
		}
	}
	async function FilterList(isDeleted = false){
		try{
			let params: ISocialWorkSelectListRequest = {
				dadasDeBaja: isDeleted,
			}
			setLoaderSelect({...loaderSelect, socialWork: true, account: true});
			let aditionalData = {
				association: {
					value: (RolType === TypeRole.Admin || RolType === TypeRole.Colegio) ? +colegioId : null
				}};
			const [socialWorks, accounts] = await Promise.all([GetSocialWorkSelect(params),api.GetAccountsList(aditionalData)]);
			
			const socialWorkList = socialWorks.data.results.map(item =>{
				return{value: item.id, label: item.nombre}
			});
			const accountList = accounts.data.map(item =>{
				return{value: item.id, label: item.nombre}
			});

			setListaOs(socialWorkList);
			setRegionalList(accountList);
		}catch(err: any){
			MessageNotification(err.response.data.errores,"error")
		}finally{
			setLoaderSelect(_DEFAULT_LOADER_SELECT);
		}
	}
	//#endregion

	async function OnClickViewList(nroPage = 1) {
		try{
			setLoadingActive(true);
			setDeleteIcon(false);
			let params: IFilterSocialWorkRequest | ISocialWorkSelectListRequest | {} = {};

			if (StateCheckBox.dadasDeBaja) setDeleteIcon(true);
			if (RolType === TypeRole.Admin){
				params = {
					obraSocialId: filtrosBusqueda.obraSocial.value,
					asignadas: true,
					dadasDeBaja: StateCheckBox.dadasDeBaja,
					pagina: nroPage,
					cantidad: loadForPage,
					conPaginacion: true
				}
			}

			if (RolType === TypeRole.Colegio){
				params = {
					cuentaId: filtrosBusqueda.cuenta.value,
					obraSocialId: filtrosBusqueda.obraSocial.value,
					pagina: nroPage,
					cantidad: loadForPage,
					confirmadas: null
				}
			}
			
			const {status, data} = RolType === TypeRole.Admin ? 
			await GetSocialWorkList(params) : 
			await GetSocialWorkListByAssociation(+colegioId, params);

			if (status === StatusCode.Ok){
				let socialWorkList = data?.results;

				if (RolType === TypeRole.Colegio){
					socialWorkList = data?.results.map(item => {
						return {
							id: item.id, 
							nombre: item.nombre, 
							alias: item.alias,
							confirmaObraSocial: item.confirmaObraSocial,
							regional: {
								id: item.regional.id,
								nombre: item.regional.nombre
							}
						}
					});
				}

				if(StateCheckBox.dadasDeBaja){
					setReactivate({
						reactivateModal: false,
						showReactivateIcon: true
					});
				}else{
					setStateCheckBox({
						..._DEFAULT_STATE_CHECKBOX,
						dadasDeBajaIcon: false
					});
					setReactivate({
						reactivateModal: false,
						showReactivateIcon: false
					});
				}

				setPagesToRender(data.quantity ?? 1);
				setDataTable(socialWorkList);
				
				if(RolType === TypeRole.Admin){
					FiltroListaOs();
				}else{
					FilterList();
				}
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores, "error");
		}finally{
			setLoadingActive(false);
		}
	}
	
	async function GetSocialWork(socialWork: any, reactivateOS = false, viewEdit = false) {
		try{
			setLoadingActive(true);
			const {status, data} = await GetSocialWorkById(socialWork.id, socialWork.regional?.id);
			if (status === StatusCode.Ok){
				const responseOs = {
					id: socialWork.id,
					nombre: data.results.nombre,
					alias: data.results.alias,
					cantidadMaximaSesiones:
						data.results.cantidadMaximaSesiones === null
							? 0
							: data.results.cantidadMaximaSesiones,
					apikey: data.results.integracionApiKey,
					clave: data.results.integracionClave,
					email: data.results.integracionEmail,
					codigoPrestador: data.results.codigoPrestador,
					terminal: data.results.terminal,
					esObraSocialColegio: data.results.esObraSocialColegio,
					debitoAutomatico: data.results.debitoAutomatico,
					afiliadoCantidadCaracteresMin: data.results.afiliadoCantidadCaracteresMin,
					afiliadoCantidadCaracteresMax: data.results.afiliadoCantidadCaracteresMax,
					tipoIntegracionId: data.results.tipoIntegracionId,
					aplicacionEmisora: data.results.aplicacionEmisora,
					aplicacionReceptora: data.results.aplicacionReceptora,
					institucionReceptora: data.results.institucionReceptora,
					afiliadoCantidadCaracteresGrupoFamiliar: data.results.afiliadoCantidadCaracteresGrupoFamiliar
				};
				setStateCheckBox({
					...StateCheckBox,
					autorizacion: data.results.autorizacion,
					nroSiniestro: data.results.numeroSiniestro,
					convenio: data.results.convenio,
					aplicaCoseguro: data.results.aplicaCoseguro,
					requiereToken: data.results.requiereToken,
					integracion: data.results.configuradaIntegracion,
					obraSocialDelColegio: data.results.esObraSocialColegio
				})

				if(reactivateOS){
					setReactivate({
						...reactivate,
						reactivateModal: true
					});
					OpenModalSelected("open", "reactivate", responseOs);
				}else{
					OpenModalSelected("open", viewEdit ? "view" : "edit", responseOs);
				}
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores, "error");
		}finally{
			setLoadingActive(false)
		}
	}

	//#region Asigar Obra social / editar / dar de baja
	async function AssingSocialWorkAccount(massiveUpdate = false){
		try{
			let isValidated = ValidateValues(StateCheckBox, values);
			if (isValidated) {
				setLoadingActive(true);
				let body: ISocialWorkAssignRequest= {
					convenio: StateCheckBox.convenio,
					autorizacion: StateCheckBox.autorizacion,
					numeroSiniestro: StateCheckBox.nroSiniestro,
					aplicaCoseguro: StateCheckBox.aplicaCoseguro,
					alias: values.alias,
					cantidadMaximaSesiones:
					values.cantidadMaximaSesiones === 0
					? null
					: values.cantidadMaximaSesiones,
					apiKeyIntegracion: values.apikey,
					claveIntegracion: values.clave,
					requiereToken: values.requiereToken,
					emailIntegracion: values.email,
					terminal: values.terminal,
					codigoPrestador: values.codigoPrestador,
					integracion: StateCheckBox.integracion,
					debitoAutomatico: values.debitoAutomatico,
					afiliadoCantidadCaracteresMin: values.afiliadoCantidadCaracteresMin,
					afiliadoCantidadCaracteresMax: values.afiliadoCantidadCaracteresMax,
					tipoIntegracionId: values.tipoIntegracionId,
					aplicacionEmisora: values.aplicacionEmisora,
					aplicacionReceptora: values.aplicacionReceptora,
					institucionReceptora: values.institucionReceptora,
					afiliadoCantidadCaracteresGrupoFamiliar: values.afiliadoCantidadCaracteresGrupoFamiliar
				};
				const {status} = massiveUpdate ? 
				await CreateSocialWorkByAssociation(+colegioId, values.id as number, body) : await AssignSocialWork(values.id as number, body);
				
				if (status === StatusCode.Ok){
					if (RolType === TypeRole.Colegio){
						OpenModalSelected("close", "massiveEditSocialWork");
					}else{
						OpenModalSelected("close", "create");
					}
					MessageNotification('Obra Social asignada correctamente.','success');
					OnClickViewList();
				}
			}
		}catch(err: any){
			MessageNotification(err.message ?? err.response.data.errores, "error");
		}
		finally{
			setLoadingActive(false);
		}
	}
	async function ReactivateSocialWorkAccount(){
		try{
			setReactivate({
				...reactivate,
				reactivateModal: false
			});
			setLoadingActive(true);
			const {status} = await ReactivateAssignedSocialWorkAccount(values.id as number);
			if (status === StatusCode.Ok){
				OpenModalSelected("close","reactivate");
				MessageNotification('Obra Social reactivada correctamente.','success');
				OnClickViewList();
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores, "error");
		}finally{
			setLoadingActive(false);
		}
	}
	async function EditAssignSocialWorkAccount() {
		try{
			let isValidated = ValidateValues(StateCheckBox, values);
			if (isValidated) {
				setLoadingActive(true);
				let body: ISocialWorkAssignRequest = {
					convenio: StateCheckBox.convenio,
					autorizacion: StateCheckBox.autorizacion,
					alias: values.alias,
					cantidadMaximaSesiones: values.cantidadMaximaSesiones === 0 ? null : values.cantidadMaximaSesiones,
					numeroSiniestro: StateCheckBox.nroSiniestro,
					aplicaCoseguro: StateCheckBox.aplicaCoseguro,
					apiKeyIntegracion: values.apikey,
					claveIntegracion: values.clave,
					requiereToken: StateCheckBox.integracion ? StateCheckBox.requiereToken : false,
					emailIntegracion: values.email,
					terminal: values.terminal,
					codigoPrestador: values.codigoPrestador,
					integracion: StateCheckBox.integracion,
					cuentaId: values.cuentaId,
					debitoAutomatico: values.debitoAutomatico,
					afiliadoCantidadCaracteresMin: values.afiliadoCantidadCaracteresMin,
					afiliadoCantidadCaracteresMax: values.afiliadoCantidadCaracteresMax,
					tipoIntegracionId: StateCheckBox.integracion ? values.tipoIntegracionId : null,
					aplicacionEmisora: StateCheckBox.integracion && values.tipoIntegracionId === TiposIntegracionEnum.Traditum ?  values.aplicacionEmisora : null,
					aplicacionReceptora: StateCheckBox.integracion && values.tipoIntegracionId === TiposIntegracionEnum.Traditum ? values.aplicacionReceptora : null,
					institucionReceptora: StateCheckBox.integracion && values.tipoIntegracionId === TiposIntegracionEnum.Traditum ? values.institucionReceptora : null,
					afiliadoCantidadCaracteresGrupoFamiliar: StateCheckBox.integracion && values.tipoIntegracionId === TiposIntegracionEnum.Traditum ? values.afiliadoCantidadCaracteresGrupoFamiliar : null,
				};

				const {status} = await EditSocialWork(values.id as number, body);
				
				if (status === StatusCode.Ok){
					MessageNotification('Obra Social editada correctamente.','success');
					OpenModalSelected("close","edit");
					OnClickViewList();
				}
			} 
		}catch(err: any){
			MessageNotification(err.message ?? err.response.data.errores, "error");
		}finally{
			setLoadingActive(false);
		}
	}
	async function DeleteSocialWorkAccount() {
		try{
			setLoadingActive(true);
			const {status} = await DeleteSocialWork(openModal.delete.data.id);
			if (status === StatusCode.Ok){
				OpenModalSelected("close","delete");
				MessageNotification("Obra Social dada de baja correctamente.", "success");
				OnClickViewList();
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores,"error");
		}finally{
			setLoadingActive(false);
		}
	}
	//#endregion

	//#region INSTRUCTIVOS OBRAS SOCIALES
	async function GetInstructiveById(socialWorkSelected){
		try{
			setLoadingActive(true);
			const {status, data} = await GetInstructiveSocialWork(socialWorkSelected.id);
			if (status === StatusCode.Ok || status === StatusCode.NotContent){
				setText(data.results);
				OpenModalSelected("open", "instructive", socialWorkSelected);
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores, "error");
		}finally{
			setLoadingActive(false);
		}
	}
	async function AssingInstructive(){
		try{
			setLoadingActive(true);
			const socialWorkId = openModal.instructive.data.id;
			const {status} = await AssingInstructiveForSocialWork(socialWorkId, text);
			if (status === StatusCode.Ok){
				MessageNotification("Instructivo asignado correctamente.", "success");
				OpenModalSelected("close", "instructive");
			}
		}catch(err: any){
			MessageNotification(err.response.data.errores, "error");
		}finally{
			setLoadingActive(false);
		}
	}
	//#endregion

	//#region metodos UTILS
	function MessageNotification(message: string, type: string){
		setDataToast({
				active: true,
				message: message || "Acaba de ocurrir un error",
				type: type,
			});
	}
	function HandleOnChangeText(text){
        setText(text);
    }
	function HandleChangePage(event, value){
		setPage(value);
		OnClickViewList(value);
	}
	function ValidateValues(stateOfCheckbox, values){
		if (values.id === null || values.id <= 0){
			MessageNotification("El campo 'Obras Sociales' no debe estar vacío.", "error");
			return false;
		}

		if (stateOfCheckbox.integracion){
			if (values.tipoIntegracionId === null || values.tipoIntegracionId === undefined){
				MessageNotification('Deberá seleccionar un "Tipo de Integración".', "error");
				return false;
			}
			if (values.tipoIntegracionId === TiposIntegracionEnum.Traditum && (values.aplicacionEmisora === null || values.aplicacionReceptora === null || values.institucionReceptora === null)) {
				MessageNotification('Deberá completar los campos "Aplicación Emisora", "Aplicación Receptora" e "Institución Receptora" si selecciono Traditum.', "error");
				return false;
			}
			if(values.tipoIntegracionId === TiposIntegracionEnum.Traditum && values.afiliadoCantidadCaracteresGrupoFamiliar === null) {
				MessageNotification('Deberá completar el campo "Cantidad de caracteres para integrante del Grupo Familiar" si selecciono Traditum.', "error");
				return false;
			}
			if (values.apikey === null || values.clave === null){
				MessageNotification("Debera de llenar los campos 'API-KEY' y 'Clave' si ha checkeado el recuadro 'Integración'.", "error");
				return false;
			}
			if (values.id === SocialWorkEnum.swissMedical || values.id === SocialWorkEnum.apross){
				if (!stateOfCheckbox.requiereToken){
					MessageNotification("El campo 'Requiere Token' para esta obra social es obligatorio.", "error");
					return false;
				}
				if (stateOfCheckbox.requiereToken && values.id === SocialWorkEnum.swissMedical){
					if(values.email === null || values.codigoPrestador === null || values.terminal === null){
						MessageNotification("Debera de llenar los campos 'Correo', 'Código Prestador' y 'Terminal' si ha checkeado el recuadro 'Requiere Token'.", "error");
						return false;
					}
				}
			}
		}
		if (values.cantidadMaximaSesiones < 0 || values.cantidadMaximaSesiones === null || values.cantidadMaximaSesiones > 1) {
			MessageNotification('El máximo de sesiones no puede ser negativo, estar vacío o ser mayor a 1.', "error");
			return false;
		}
		if (values.debitoAutomatico < 0) {
			MessageNotification('El campo "Debito Automático" no puede ser negativo.', "error");
			return false;
		}
		return true;
	}
	function OpenModalSelected(action: typeOfActionForModal, modal: typeOfModal, item?: any){ 
		const keys = Object.keys(openModal);
		const indexOfKey = Object.keys(openModal).findIndex(item => item === modal);
		const keyOfModalToOpen = keys[indexOfKey];

		//guardar los valores antes de abrir el modal.
		if (action === "close") {
			setErrors(_DEFAULT_ERROR_VALUES);
			setValues(_DEFAULT_VALUES);
			setStateCheckBox(_DEFAULT_STATE_CHECKBOX);
		} else {
			if (modal !== "create" && item) {
				setValues(item);
			}
		}

		setOpenModal({...openModal, [keyOfModalToOpen]: {show: action === "open", data: item}});
	}
	function SelectSocialWork(e){
		const filterToAssign = {...filtrosBusqueda, obraSocial: e === null ? _DEFAULT_FILTROS_VALUES.obraSocial : e};
		disparador(setObjFilterGrid(filterToAssign));
		setFiltrosBusqueda(filterToAssign);
	}
	function SelectAccount(e){
		const filterToAssign = {...filtrosBusqueda, cuenta: e === null ? _DEFAULT_FILTROS_VALUES.cuenta : e};
		disparador(setObjFilterGrid(filterToAssign));
		setFiltrosBusqueda(filterToAssign);
	}
	//#endregion
	const ButtonNew = ()=>{
		return (
			<Button
				className='btn-new-filter'
				onClick={()=>OpenModalSelected("open","create")}
				icon
				labelPosition='left'
			>
				<Icon name='plus' />
				Nuevo
			</Button>)
	}
	const ButtonMassiveUpdate = ()=>{
		return(
			<Grid.Row className="content-filter-list">
				<Button
					style={{width: "auto"}}
					className='btn-edit-header'
					onClick={()=>OpenModalSelected("open", "massiveAssignPractice")}
					icon
					labelPosition='left'
					>
						<Icon name='dollar sign' />
						Asig. Masiva de Prácticas
				</Button>
				<Button
				style={{width: "auto"}}
				className='btn-edit-header'
				onClick={()=>OpenModalSelected("open", "massiveEditAmount")}
				icon
				labelPosition='left'
				>
					<Icon name='dollar sign' />
					Asig. Montos
				</Button>
				<Button
				style={{width: "auto"}}
				className='btn-edit-header'
				onClick={()=>OpenModalSelected("open", "massiveEditSocialWork")}
				icon
				labelPosition='left'
				>
					<Icon name='cog' />
					Config. Obras Sociales
				</Button>
				<Button
					className='btn-new-filter'
					onClick={()=>history.push("/configuraciones/obras-sociales/confirmar")}
					icon
					labelPosition='left'
				>
					<Icon name='plus' />
					Confirm. Obra social
				</Button>
			</Grid.Row>
		)
	}
	const FilterComponents = ()=> (
			<div className="content-filter-list">
				<ReactSelect
					className="filter-input"
					options={listaOs}
					placeholder="Obras sociales..."
					isLoading={loaderSelect.socialWork}
					isClearable={true}
					value={filtrosBusqueda.obraSocial?.value === null ? null : filtrosBusqueda.obraSocial}
					onChange={(e)=> SelectSocialWork(e)}
					/>
					
					{TypeRole.Colegio === RolType &&
					<ReactSelect
					className="filter-input"
					options={regionalList}
					placeholder="Regionales..."
					isLoading={loaderSelect.account}
					isClearable={true}
					value={filtrosBusqueda.cuenta?.value === null ? null : filtrosBusqueda.cuenta}
					onChange={(e)=> SelectAccount(e)}
					/>}

				{TypeRole.Admin === RolType && 
				<FormControlLabel style={{marginLeft: 2}}
					control={
						<Checkbox
							checked={StateCheckBox.dadasDeBaja}
							onChange={(e) => {
								setStateCheckBox({
									...StateCheckBox,
									dadasDeBajaIcon: e.target.checked,
									dadasDeBaja: e.target.checked,
								});
							}}
						/>
					}
					label='Dadas de Baja'
				/>}
			</div>
	)

	return (
		<>
			{(RolType === TypeRole.Admin ||
			RolType === TypeRole.Colegio) &&
			<>
				<GeneralList
				titleOfSection="Obras Sociales"
				isLoaderActive={loadingActive}
				dataToRender={dataTable}
				initialPage={page}
				numPagesToRender={pagesToRender}
				FilterComponent={FilterComponents}
				ButtonNew={TypeRole.Admin === RolType ? ButtonNew : ButtonMassiveUpdate}
				onChangePage={HandleChangePage}
				HandleSearch={OnClickViewList}>
					<Table aria-label='spanning table'>
						<TableHead>
							<TableRow>
								<TableCell className='letter-title-table c-text-center p-cell'>
									<b>Obra Social</b>
								</TableCell>
								<TableCell className='letter-title-table c-text-center p-cell'>
									<b>Alias</b>
								</TableCell>
								{TypeRole.Colegio === RolType &&
								<>
								<TableCell className='letter-title-table c-text-center p-cell'>
									<b>Regional</b>
								</TableCell>
								<TableCell className='letter-title-table c-text-center p-cell'>
									<b>Confirmada</b>
								</TableCell>
								</>}
								<TableCell className='letter-title-table c-text-center p-cell'>
									<b>Opciones</b>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{dataTable.map((socialWork) => (
								<TableRow key={socialWork.id}>
									<TableCell className='c-text-center p-cell'>
										{socialWork.nombre}
									</TableCell>
									<TableCell className='c-text-center p-cell'>
										{socialWork.alias ?? "-"}
									</TableCell>
									{TypeRole.Colegio === RolType &&
									<>
									<TableCell className='c-text-center p-cell'>
										{socialWork.regional.nombre}
									</TableCell>
									<TableCell className='c-text-center p-cell'>
										 <Checkbox
											className="no-padding-checkbox p-cell"
											checked={socialWork.confirmaObraSocial}
											/>
									</TableCell>
									</>
									}
									<TableCell className='c-text-center p-cell' >
										<Button.Group>
											{reactivate.showReactivateIcon ? 
											<ButtonBox buttonToShow='reactivate' name='reactivate' HandleOnClik={()=>GetSocialWork(socialWork,true)}/>
											:
											<>
												{TypeRole.Admin === RolType && <ButtonBox buttonToShow='practice' name='practice' HandleOnClik={() => history.push(`/configuraciones/obras-sociales/${socialWork.id}/practicas`)}/>}
												
												{TypeRole.Admin === RolType && <ButtonBox buttonToShow='edit' name='edit' HandleOnClik={() => GetSocialWork(socialWork)}/>}

												{TypeRole.Colegio === RolType && <ButtonBox buttonToShow='view' name='view' HandleOnClik={() => GetSocialWork(socialWork, false, true)}/>}
												
												<ButtonBox buttonToShow='amount' name='amount' HandleOnClik={()=> history.push(`/configuraciones/obras-sociales/${socialWork.id}/montos`,{cuentaId: socialWork.regional?.id})}/>

												{!deleteIcon && TypeRole.Admin === RolType && 
												<ButtonBox buttonToShow='delete' name='delete' HandleOnClik={() =>OpenModalSelected("open", "delete", socialWork)}/>}
												
												{TypeRole.Admin === RolType && <ButtonBox buttonToShow='instructive' name='instructive' HandleOnClik={() =>GetInstructiveById(socialWork)}/>}
											</>}
										</Button.Group>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</GeneralList>

				{openModal.create.show && 
				<ModalOsForRegional
				isCreate={true}
				titleOfModal="Nuevo"
				values={values}
				roleType={RolType}
				openModal={openModal.create.show}
				errors={errors}
				control={control}
				stateOfCheckbox={StateCheckBox}
				setValues={setValues}
				setStateCheckBox={setStateCheckBox}
				MessageNotification={MessageNotification}
				CloseModal={()=>OpenModalSelected("close", "create")}
				SendValues={()=>AssingSocialWorkAccount()}
				/>}

				{openModal.massiveEditSocialWork.show && 
				<ModalOsForRegional
				isCreate={true}
				titleOfModal="Configuración de Obras Sociales"
				values={values}
				roleType={RolType}
				openModal={openModal.massiveEditSocialWork.show}
				errors={errors}
				control={control}
				stateOfCheckbox={StateCheckBox}
				setValues={setValues}
				setStateCheckBox={setStateCheckBox}
				MessageNotification={MessageNotification}
				CloseModal={()=>OpenModalSelected("close", "massiveEditSocialWork")}
				SendValues={()=>AssingSocialWorkAccount(true)}
				/>}
				
				{openModal.massiveAssignPractice.show && 
				<ModalPracticeForAssociation 
				associationId={colegioId}
				openModal={openModal.massiveAssignPractice.show}
				titleOfModal="Configuración de Obras Sociales - Prácticas"
				modal="massiveAssignPractice"
				setLoadingActive={setLoadingActive}
				CloseModal={()=>OpenModalSelected("close", "massiveAssignPractice")}
				MessageNotification={MessageNotification}
				/>
				}

				{openModal.massiveEditAmount.show && 
				<ModalAmountForOs 
				associationId={colegioId}
				openModal={openModal.massiveEditAmount.show}
				titleOfModal="Configuración de Obras Sociales - Montos"
				modal="massiveEditAmount"
				setLoadingActive={setLoadingActive}
				CloseModal={()=>OpenModalSelected("close", "massiveEditAmount")}
				MessageNotification={MessageNotification}
				/>
				}

				{openModal.edit.show && 
				<ModalOsForRegional
				isEdit={true}
				titleOfModal="Editar"
				values={values}
				roleType={RolType}
				openModal={openModal.edit.show}
				errors={errors}
				control={control}
				stateOfCheckbox={StateCheckBox}
				setValues={setValues}
				setStateCheckBox={setStateCheckBox}
				MessageNotification={MessageNotification}
				CloseModal={()=>OpenModalSelected("close", "edit")}
				SendValues={EditAssignSocialWorkAccount}
				/>}

				{openModal.view.show &&
				<ViewConfigSocialWork 
				isView={true}
				titleOfModal="Configuración"
				values={values}
				roleType={RolType}
				openModal={openModal.view.show}
				errors={errors}
				stateOfCheckbox={StateCheckBox}
				CloseModal={()=>OpenModalSelected("close", "view")}
				/>}

				{openModal.delete.show &&
				<GeneralModalDelete
				contentText={`¿Está seguro de eliminar la siguiente obra social: ${openModal.delete.data.nombre}?`}
				openModal={openModal.delete.show}
				HandleClose={()=>OpenModalSelected("close", "delete")}
				HandleSubmit={DeleteSocialWorkAccount}
				/>}

				{openModal.instructive.show &&
				<GeneralModalCrud
				titleOfModal="Asignar Instructivo"
				openModal={openModal.instructive.show}
				HandleCloseModal={()=>OpenModalSelected("close", "instructive")}
				HandleSubmit={AssingInstructive}
				>
					<EditorText 
					handleOnChange={HandleOnChangeText} 
					text={text} 
					toolbarOptions={toolbarConfig}
					placeholder={text === null || text === "" ? "Asigne el instructivo" : text}
					/>
				</GeneralModalCrud>}

				{openModal.reactivate.show &&
				<GeneralModalCrud
				titleOfModal="Reactivar Registro"
				openModal={openModal.reactivate.show}
				HandleCloseModal={()=>OpenModalSelected("close", "reactivate")}
				HandleSubmit={ReactivateSocialWorkAccount}
				>
					<DialogContentText>
							¿Está seguro de reactivar la siguiente Obra Social: {openModal.reactivate.data.nombre}?
					</DialogContentText>
				</GeneralModalCrud>}
			</>
			}
			
			{RolType === TypeRole.SuperAdmin &&
			<CrudSocialWork setDataToast={setDataToast}/>}
		</>
	);
}
export default OsForRegional;
