import DateFnsUtils from "@date-io/date-fns";
import { Card, DialogContent, Grid, TextField, Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { es } from 'date-fns/locale';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { StateOfLiquidation, StatusCode } from "../../../enums";
import { setDataAccreditationBalanceForLiquidation, setDataAccreditationForLiquidation, setDataDescountForLiquidation, setDataInstallmentForLiquidation, setDataLiquidation, setDataOfBillForLiquidation, setDataParamsRegionalForLiquidation, setRestoreStateLiquidation } from "../../../redux/generateLiquidation";
import { CreateLiquidation, GetBillForLiquidation, GetLiquidationById, GetNameForLiquidationByDate } from "../../../services/services/Liquidation";
import GeneralModalCrud from "../../utils/GeneralModalCrud";
import GeneralModalTabs from "../../utils/GeneralModalTabs";
import { _DEFAULT_BILL_LIQUIDATION_VALUES, _DEFAULT_LIQUIDATION_VALUES } from "../defaultValues";
import BillsForLiquidation from "../panelSection/liquidation/BillsForliquidation";
import DescountForLiquidation from "../panelSection/liquidation/DescountForLiquidation";
import { IAccreditationBalanceList, IAccreditationList, IDescountList, IEditLiquidation, IInstallmentList } from "../../../services/interfaces/Managment/IEditLiquidation";
import { IAditionalInfo, IBillUserWithManualDebit, INotificationExitLiquidation } from "../../../services/interfaces/Managment/INewLiquidation";
import { IAccreditationLiquidationResponse, IBalanceAccreditationLiquidationResponse, IDescountLiquidationResponse, IInstallmentLiquidationResponse, ILiquidacionRequest, IFactura } from "../../../api/Interfaces/managment/ILiquidation";
import { IBillLiquidationFilterObj, IModifiedBillLiquidation } from "../../../services/interfaces/Managment/IBillsForLiquidation";

const EditLiquidation: React.FC<IEditLiquidation> = ({setDataToast}) => {
    const {id} = useParams<any>();
    const history = useHistory();
    const location = useLocation<any>();
    const {
        liquidacion,
        facturas, 
        descuentos,
        acreditaciones, 
        cuotas, 
        acreditacionesSaldo
    }: any = useSelector<any>(({generateLiquidation}) => generateLiquidation);
    const disparador = useDispatch();
    const [aditionalInfo, setAditionalInfo] = React.useState<IAditionalInfo>(_DEFAULT_LIQUIDATION_VALUES._ADITIONAL_DATA);
    const [modals, setModals] = React.useState<INotificationExitLiquidation>(_DEFAULT_LIQUIDATION_VALUES._MODALS_NOTIFICATION);
    const [loadingActive, setLoadingActive] = React.useState(true);
    const [billDataToRender, setBillDataToRender] = React.useState<IModifiedBillLiquidation[]>([]);
    const [filterObj, setFilterObj] = React.useState<IBillLiquidationFilterObj>(_DEFAULT_BILL_LIQUIDATION_VALUES._FILTER_OBJ);
    
    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Necesario para algunos navegadores antiguos
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    React.useEffect(()=>{
        GetDataForLiquidation();
    },[]);

    //#region GETBYID / CREATE / DRAFT
    async function GetDataForLiquidation(){
        try{
            const {data, status} = await GetLiquidationById(location.state?.fromEdit ? id : 0)
            if (status === StatusCode.Ok){
                disparador(setDataLiquidation({nombre: data.results.nombre, fecha: data.results.fecha, estado: data.results.estado}));
            }
            setAditionalInfo({
                accreditationList: data.results.acreditaciones,
                descountList: data.results.descuentos,
                installmentList: data.results.cuotas,
                accreditationBalanceList: data.results.acreditacionesSaldo
            });
            setAllDataForLiquidation(data.results);
        }catch(err: any){
            MessageNotification(err?.response?.data?.errores ?? err.message, "error");
        }
    }
    async function GetNameAndDateLiquidation(date: any){
        try{
            const {data, status} = await GetNameForLiquidationByDate(date);
            if (status === StatusCode.Ok){
                disparador(setDataLiquidation({nombre: data.results, fecha: date, estado: null}));
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores ?? err.message, "error");
        }
    }
    async function HandleSubmitDataToSave(isCreating = false) {
        try {
            if (ValidateValues({ isCreating })) {    
                setLoadingActive(true);
                const requestNewLiquidation: ILiquidacionRequest = CreateLiquidationResponse();
                const { data, status } = await CreateLiquidation(requestNewLiquidation);
                if (status == StatusCode.Ok) {
                    if (isCreating) {
                        history.push(`/liquidaciones/${data.results.id}/resumenes`);
                    } else {
                        MessageNotification("Liquidación guardada como borrador.", "success");
                        history.push("/liquidaciones/lista");
                    }
                }
            }
        } catch (err: any) {
            MessageNotification(err.message ?? err.response.data.errores, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    function HandleSaveValuesLiquidation(){
        setModals(_DEFAULT_LIQUIDATION_VALUES._MODALS_NOTIFICATION);
        HandleSubmitDataToSave();
    }
    //#endregion

    //#region BILLData
    async function HandleGetBillsForLiquidation(arrBills){
        try{
            setLoadingActive(true);
            const {data, status} = await GetBillForLiquidation(id ?? 0, filterObj.pendingBills);
            if (status === StatusCode.Ok){
                const modifiedBill: IModifiedBillLiquidation[] = data.results.map(bill => {
                    return {
                        ...bill, 
                        montoDebitoAutomatico: arrBills[bill.id]?.montoDebitoAutomatico ?? (bill.monto * bill.porcentajeDebito) / 100,
                        montoDebitoManual: arrBills[bill.id]?.montoDebitoManual ?? 0,
                        montoTotalDebito: arrBills[bill.id]?.montoTotalDebito ?? 0,
                        marcado: arrBills[bill.id]?.marcado ?? false
                    }
                }).sort((a, b) => {
                    // Coloca los objetos con `marcado` en `true` al principio
                    return (b.marcado ? 1 : 0) - (a.marcado ? 1 : 0);
                  });
                setBillDataToRender(modifiedBill);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores ?? err.message, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function HandleChangeDate(dateSelected: MaterialUiPickersDate){
        GetNameAndDateLiquidation(dateSelected);
    }
    function ValidateValues({isCreating}){
        let isValidated = true;
       
        const areBillSelected =  Object.values(facturas).length > 0;
        const descountSelectedId = Object.values(descuentos).length > 0;
        const accreditationSelectedId = Object.values(acreditaciones).length > 0;
        const installmentSelectedId = Object.values(cuotas).length > 0;
        const accreditationBalanceId = Object.values(acreditacionesSaldo).length > 0;

        if (isCreating){
            if (!areBillSelected || (!descountSelectedId && !accreditationSelectedId && !installmentSelectedId && !accreditationBalanceId)){
                MessageNotification("Deberá de cargarle datos para avanzar de sección.", "error");
                isValidated = false;
            }
        }else{
            if (!areBillSelected && !descountSelectedId && !accreditationSelectedId && !installmentSelectedId && !accreditationBalanceId){
                MessageNotification("Deberá de asignar al menos un dato para guardar la liquidación.", "error");
                isValidated = false;
            }
        }
        return isValidated;
    }
    function CreateLiquidationResponse(){
        const billsData: IFactura[] = Object.values(facturas).map((bill: any) => {
            return {
                Id: bill.id,
                PorcentajeDebito: bill.porcentajeDebito
            };
        });
        const descountSelectedId: number[] = Object.values(descuentos).map((descount: any) => descount.descuentosId).flat();
        const accreditationSelectedId: number[] = Object.values(acreditaciones).map((accreditation: any) => accreditation.acreditacionesId).flat();
        const installmentSelectedId: number[] = Object.values(cuotas).map((installment: any) => installment.cuotasId).flat();
        const accreditationBalanceId: number[] = Object.values(acreditacionesSaldo).map((accreditationBalance: any) => accreditationBalance.idProfesional).flat();
        const billUserWitManualDebitObj: IBillUserWithManualDebit[] = Object.values(facturas)
        .filter((bill: any) => bill.usuarioFactura.length > 0)
        .map((billUserWitManualDebit: any) => billUserWitManualDebit.usuarioFactura)
        .flat()
        .filter(manualDebitWithAmount => manualDebitWithAmount.montoDebitoManual > 0 && manualDebitWithAmount.motivoDebitoManual !== "")
        .map((manualDebit:any) => {
            return {
                id: manualDebit.id,
                facturaId: manualDebit.FacturaId,
                profesionalId: manualDebit.UsuarioId,
                monto: manualDebit.montoDebitoManual,
                motivo: manualDebit.motivoDebitoManual
            }
        });

        const liquidationRequest: ILiquidacionRequest = {
            nombre: liquidacion.nombre,
            fecha: liquidacion.fecha,
            descripcion: liquidacion.nombre,
            estado: StateOfLiquidation.borrador,
            facturas: billsData,
            descuentosId: descountSelectedId,
            acreditacionesId: accreditationSelectedId,
            cuotasId: installmentSelectedId,
            debitosPorFacturaPorDetalleLiquidacion: billUserWitManualDebitObj,
            acreditacionesSaldoProfesionalesId: accreditationBalanceId,
        }
        return liquidationRequest;
    }
    function HandleCancelSaveValues(){
        disparador(setRestoreStateLiquidation());
        history.push("/liquidaciones/lista");
    }
    function HandleCancelLiquidationProcess(){
        const areBillSelected = Object.values(facturas).length > 0;
        const areDescountSelected = Object.values(descuentos).length > 0;
        const areAccreditationSelected = Object.values(acreditaciones).length > 0;
        const areInstallmentSelected = Object.values(cuotas).length > 0;
        const areAccreditationBalanceSelected = Object.values(acreditacionesSaldo).length > 0;
        
        if (areBillSelected || areDescountSelected || areAccreditationSelected || areInstallmentSelected || areAccreditationBalanceSelected){
            setModals({notificationRestoreLiquidation: {show: true, data: null}});
        }else{
            HandleCancelSaveValues();
        }
    }
    function HandleCloseModals(e){
        if (e.target.tagName === "BUTTON"){
            setModals({notificationRestoreLiquidation: {show: false, data: null}});
            HandleCancelSaveValues();
        }else{
            setModals({notificationRestoreLiquidation: {show: false, data: null}});
        }
    }
    function setAllDataForLiquidation(allAditionalDataForLiquidation: any){
        const {acreditaciones, descuentos, cuotas, acreditacionesSaldo, parametros} = allAditionalDataForLiquidation;
        //parametros
        disparador(setDataParamsRegionalForLiquidation(parametros));

        //acreditaciones
        const accreditationList: IAccreditationList = acreditaciones.filter((accreditation: IAccreditationLiquidationResponse) => accreditation.liquidacionId !== null)
            .map((accreditation: IAccreditationLiquidationResponse) => ({
                idProfesional: accreditation.profesional.id,
                acreditacionesId: accreditation.id
            }));
        disparador(setDataAccreditationForLiquidation(accreditationList));
        //descuentos
        const descountList: IDescountList = descuentos.filter((descount: IDescountLiquidationResponse)=> descount.liquidacionId !== null)
            .map((descount: IDescountLiquidationResponse) => ({
                idProfesional: descount.profesional.id,
                descuentosId: descount.id
            }));
        disparador(setDataDescountForLiquidation(descountList));
        //cuotas
        const installmentList: IInstallmentList = cuotas.filter((installment: IInstallmentLiquidationResponse)=> installment.liquidacionId !== null)
            .map((installment: IInstallmentLiquidationResponse) => ({
                idProfesional: installment.profesional.id,
                cuotasId: installment.id
            }));
        disparador(setDataInstallmentForLiquidation(installmentList));
        //acreditaciones saldo
        const accreditationBalanceList: IAccreditationBalanceList = acreditacionesSaldo.filter((accreditationBalance: IBalanceAccreditationLiquidationResponse)=> accreditationBalance.seleccionado)
            .map((accreditationBalance: IBalanceAccreditationLiquidationResponse) => ({
                idProfesional: accreditationBalance.profesional.id,
            }));
        disparador(setDataAccreditationBalanceForLiquidation(accreditationBalanceList));
        //facturas
        if (Object.values(facturas).length === 0){
            const billSelected = {};
            allAditionalDataForLiquidation.facturas.forEach((bill: any) => {
                billSelected[bill.id] = {
                    id: bill.id,
                    obraSocial: bill.obraSocial,
                    plan: bill.plan,
                    marcado: true,
                    total: bill.monto,
                    fecha: bill.fecha,
                    porcentajeDebito: bill.porcentajeDebito,
                    montoDebitoAutomatico: bill.montoDebitoAutomatico,
                    montoDebitoManual: bill.montoDebitoManual,
                    montoTotalDebito: bill.montoDebitoAutomatico + bill.montoDebitoManual,
                    usuarioFactura: []
                }
            });
            HandleGetBillsForLiquidation(billSelected);
            disparador(setDataOfBillForLiquidation(billSelected));
        }else{
            HandleGetBillsForLiquidation(facturas);
        }
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error",
            type
        });
    }
    //#endregion

    return (
        <>
        {loadingActive ? 
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
          :
          <Grid>
          <Grid item style={{marginBottom: 30}}>
            <Card className='color-border-dash'>
                <CardContent className='center-responsive-filters'>
                    <Grid className='header-section'>
                        <div className="titleAndVideo">
                            <span style={{ fontSize: 27, textAlign: "left", lineHeight: "1.03" }}>Editar Liquidación</span>
                        </div>
                        <div className="contBtnHeaderSection">
                            <Button onClick={HandleCancelLiquidationProcess}>Cancelar</Button>
                            <Button onClick={()=>HandleSubmitDataToSave(false)} color='green'>Guardar y Salir</Button> 
                            <Button onClick={()=>HandleSubmitDataToSave(true)} color='green'>Generar Liquidación</Button> 
                        </div>
                    </Grid>
                    <Grid className="row-filterList content-filter-list">
                        <TextField
                        className='filter-input'
                        style={{margin: "15px 0"}}
                        disabled={true}
                        value={liquidacion.nombre}
                        label='Nombre'
                        placeholder="Nombre de liquidación..."
                        type='text'
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink: true}}
                        />
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                className="size-date-picke-filter filter-input"
                                style={{ margin: 8 }}
                                value={liquidacion.fecha}
                                placeholder="Fecha"
                                onChange={(date: MaterialUiPickersDate)=>HandleChangeDate(date)}
                                format="dd/MM/yyyy"
                                margin="normal"
                                inputVariant="outlined"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </CardContent>
            </Card>
          </Grid>
          <GeneralModalTabs
          viewPanelDefault={0}
          arrPanels={[
              <BillsForLiquidation 
              MessageNotification={MessageNotification}
              dataToRender={billDataToRender}
              setDataToRender={setBillDataToRender}
              loadingActive={loadingActive}
              />, 
              <DescountForLiquidation aditionalInfo={aditionalInfo}/>
          ]}
          arrTittleOfPanels={["Facturas", "Descuentos"]}
          />
          </Grid>
          }

        <GeneralModalCrud
        titleOfModal="AVISO"
        openModal={modals.notificationRestoreLiquidation.show}
        HandleSubmit={HandleSaveValuesLiquidation}
        HandleCloseModal={HandleCloseModals}
        >
            <DialogContent>
                <Grid>
                    <Typography style={{margin: "6px 0"}}>
                        Tienes cambios pendientes de guardar.
                    </Typography>
                    <Typography style={{margin: "6px 0"}}>
                    ¿Desea guardar los cambios?
                    </Typography>
                </Grid>
            </DialogContent>
        </GeneralModalCrud>
      </>
    )
}

export default EditLiquidation;