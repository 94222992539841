import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import React, { useEffect, useState } from "react";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import api from "../../api";
import EditorText from "../QuillEditoText/EditorText";
import { toolbarConfig } from "../QuillEditoText/configs/toolbarConfig";
import InputForm from "../common/InputForm";
import SelectForm from "../common/SelectForm";
import "./Document.css";
import { SocialWorkEnum } from "../../enums";

export default function DocumentPatient({
  control,
  errors,
  setErrors,
  register,
  loadingInput,
  idSocialWork,
  setIdSocialWork,
  setState,
  onBlurInputSearch,
  setDataToast,
  isView,
  stateOfCheckbox,
  isEdit,
  setValue,
  setPractices,
  setSessionQuantity,
  setNumAutorization,
  setInfoSocialWork,
  setNumSiniester,
  setPlanSelected,
  planSelected,
  setCoinsuranceAmount,
  evaluateAmountsForPractices,
  setModalInstructive,
  modalInstructive,
  text,
  setText,
  setStateOfCheckbox,
  setDiagnosticModal,
  setNumMinCharacters,
  setSocialWorksPlan,
  socialWorksPlan,
  setNumMaxCharacters,
  setTipoIntegracionId,
  setValidAffiliateBtn,
  getValueForm
}) {
  
  const [socialWorks, setSocialWorks] = useState([]);
  const [loadingActive, setLoadingActive] = useState(true);
  const [loadingSelect, setLoadingSelect] = useState({
    socialWork: false,
    planSocialWork: false,
    instructiveSocialWork: false,
    validateIcon: false
  });

  useEffect(() => {
		setLoadingSelect({
			socialWork: true,
			planSocialWork: true,
      instructiveSocialWork: true,
      validateIcon: false
		});

    getSocialWorks();
	}, []);

  const getSocialWorks = ()=>{
    setLoadingActive(true);
    let params = {
      obraSocialId: idSocialWork.id
    }
    api
			.GetSocialWorkForSelect(params)
			.then((resp) => {
				let socialWorksArray = resp.data.map(item=>{
          return {
            id: item.id,
            name: item['nombre']
          }
        });
				socialWorks.length === 0 && setSocialWorks(socialWorksArray);
				setLoadingSelect({
					...loadingSelect,
					socialWork: false,
					planSocialWork: false
				});
			})
			.catch((err) => {
				setLoadingSelect({
					...loadingSelect,
					socialWork: false,
					planSocialWork: false
				});
				setDataToast({
					active: true,
					message: err.response.data.errores,
					type: 'error',
				});
			})
      .finally(() => setLoadingActive(false));
  }

	const getSocialWorksPlan = (event) => {
		if (event[0] && event[0].id) {
      let idSocialWorkSelected = event[0].id;
			setValue('socialWorkPlan', null);
      setValue("autorNum", "");
      setValue("coseguro", "");
      setStateOfCheckbox({...stateOfCheckbox, manualCharge: false});
      setText(null);
      setErrors({});
			setIdSocialWork({
        id: idSocialWorkSelected,
        active: true
      });
			setLoadingSelect({
        ...loadingSelect,
				planSocialWork: true,
        instructiveSocialWork: true,
        validateIcon: true
			});
      setStateOfCheckbox((prevState) => {
        return {...prevState, hasIntegration: false}
      });
      
			Promise.all([
        api.getSocialWorkById(idSocialWorkSelected),
				api.getSocialWorksPlan(idSocialWorkSelected),
				api.GetAmountForPractices(idSocialWorkSelected),
        api.getPractices(idSocialWorkSelected)
			])
      .then((resp) => {

					setPractices([]);
					setState({});
          setPlanSelected();
          setDiagnosticModal({show: false, data: {codigo: null, descripcion: null}});
          
          setNumMinCharacters(resp[0].data.afiliadoCantidadCaracteresMin);
          setNumMaxCharacters(resp[0].data.afiliadoCantidadCaracteresMax);
          setTipoIntegracionId(resp[0].data.tipoIntegracionId);

					let sessionQuantity = resp[0].data.cantidadMaximaSesiones === null ? 0 :  resp[0].data.cantidadMaximaSesiones;

          if (resp[0].data.autorizacion){
            setNumAutorization(true);
          }else{
            setNumAutorization(false);
            if (resp[0].data.configuradaIntegracion) {
              setDiagnosticModal({show: resp[0].data.id === SocialWorkEnum.apross, data: {codigo: null, descripcion: null}});
              setStateOfCheckbox((prevState) => {
                return {...prevState, hasIntegration: true}
              });
            }
          }
          if (resp[0].data.numeroSiniestro){
            setNumSiniester(true);
          }else{
            setNumSiniester(false);
          }
          
          if (resp[0].data.aplicaCoseguro){
            setCoinsuranceAmount(true);
          }else{
            setCoinsuranceAmount(false);
            if (resp[0].data.configuradaIntegracion) {
              setStateOfCheckbox((prevState) => {
                return {...prevState, hasIntegration: true}
              });
            }
          }
				
          //El instructivo puede venir NULL en caso de que nunca se asigno/edito
          //El instructivo si se edito y se guardo vacio, no es un "" sino es un "<p><br/></p>" por ende no se considera ni "" ni null, asique hay que validar el contenido de esa etiqueta
          //para mostrar o no el boton de instructivo. En este bloque se parsea lo que viene de la API para convertir a una etiqueta <p> y contemplar su contenido.
          if (resp[0].data.instructivo !== null){
            const DOMparser = new DOMParser().parseFromString(resp[0].data.instructivo, "text/html");
            const tag = DOMparser.body.firstChild;
        
            if (tag.innerText !== "") setText(resp[0].data.instructivo);
          }

					let socialWorksPlanArray = resp[1].data.map(item=>{
            return {
              id: item.id,
              name: item["nombre"]
            }
          });

          let practices = [];
          let practiceWithAmount = resp[2]?.data;
          let practiceWithOutAmount = resp[3]?.data?.resultados.map(item=>{
              return{
              monto: 0,
              id: item.id,
              practica: {
                alias: item.alias,
                nombre: item.nombre,
                practicaObraSocialCuentaId: item.practicaObraSocialCuentaId,
                codigo: item.codigo
              }
            }
				  });
          
          if (practiceWithAmount.length === 0 && practiceWithOutAmount.length === 0){
            setDataToast({
              active: true,
              message: "La obra social seleccionada no posee prácticas asignadas, considere asignar prácticas para seguir el proceso de carga.",
              type: 'error',
            });
            return;
          }

          practices = evaluateAmountsForPractices(practiceWithAmount, practiceWithOutAmount);

          let objStateChecks;
          practices.forEach(({monto,practica})=> {
            objStateChecks = {...objStateChecks, [practica.practicaObraSocialCuentaId]: {checked: false, monto: monto, codigo: practica.codigo}}
          });

          if (resp[0].data.configuradaIntegracion){
            const valueForm = getValueForm();
            if (valueForm.dni !== "" && valueForm.dni !== null){
              if (valueForm.affiliateNum !== "" && valueForm.affiliateNum !== null){
                if (resp[0].data.afiliadoCantidadCaracteresMax !== null && resp[0].data.afiliadoCantidadCaracteresMin !== null){
                  if (valueForm.affiliateNum.length < resp[0].data.afiliadoCantidadCaracteresMin || valueForm.affiliateNum.length > resp[0].data.afiliadoCantidadCaracteresMax){
                    setValidAffiliateBtn(true);
                  }
                }else{
                  setValidAffiliateBtn(true);
                }
              }
            }
          }else{
            setValidAffiliateBtn(false);
          }

          setModalInstructive({
            ...modalInstructive,
            aditionalInfo: {
              socialWorkId: idSocialWorkSelected,
              socialWorkName: resp[0].data.nombre,
              socialWorkAlias: resp[0].data.alias
            }
          });
          setSessionQuantity(sessionQuantity);
					setInfoSocialWork({
            numAutorizacion: resp[0].data.autorizacion,
            coinsecureAmount: resp[0].data.aplicaCoseguro,
						maximumSessions: resp[0].data.cantidadMaximaSesiones,
            tokenRequired: resp[0].data.requiereToken,
            hasIntegration: resp[0].data.configuradaIntegracion
					});
          setValue('socialWork', {
            id: idSocialWorkSelected,
            name: resp[0].data.nombre
          });
          setSocialWorksPlan(socialWorksPlanArray);
          setState(objStateChecks);
					setPractices(practices);
				})
				.catch((err) => {
					setDataToast({
						active: true,
						message: err.response.data.errores,
						type: 'error',
					});
				})
        .finally(()=>{
          	setLoadingSelect({
						...loadingSelect,
						planSocialWork: false,
						socialWork: false
					});
          setLoadingActive(false);
        })
		} else {
      setValue('socialWork', null);
			setValue('socialWorkPlan', null);
			setValue("autorNum", "");
      setValue("coseguro", "");
      setStateOfCheckbox({...stateOfCheckbox, manualCharge: false});
			setSocialWorksPlan([]);
			setPractices([]);
      setText(null);
      setErrors({});
      setNumAutorization(true);
      setCoinsuranceAmount(true);
      setIdSocialWork({id: null, active: false});
      setDiagnosticModal({show: false, data: {codigo: null, descripcion: null}});
      setNumMinCharacters(null);
      setNumMaxCharacters(null);
      setTipoIntegracionId(null);
      if (!isEdit){
          setStateOfCheckbox((prevState) => {
          return {...prevState, hasIntegration: false}
        });
      }
      setValidAffiliateBtn(false);
      setLoadingSelect({
          ...loadingSelect,
          instructiveSocialWork: false,
          validateIcon: false
        });
		}
	}

  function handleCloseDeleteModalInstructive(){
    setModalInstructive({
      ...modalInstructive,
      showModal: false,
    });
  }

  return (
    <>
      {loadingActive && (
        <Dimmer className="loader-fixed" active inverted>
          <Loader size="big">Cargando..</Loader>
        </Dimmer>
      )}
      <Grid.Row columns={2}>
        <Grid.Column id="column-patient-one">
          <section style={{position:"relative"}}>
              <InputForm
              disabled={isView}
              register={register({ required: true })}
              isRequired={true}
              type={"number"}
              label={"DNI"}
              name={"dni"}
              control={control}
              error={errors}
              loading={loadingInput.dni}
              onBlur={(event)=>onBlurInputSearch(event)}
            >
            </InputForm>
          </section>
          <InputForm
            disabled={isView}
            register={register({ required: true })}
            isRequired={true}
            type={"text"}
            label={"Nombre"}
            name={"namePatient"}
            control={control}
            error={errors}
          />
          <InputForm
            disabled={isView}
            register={register({ required: true })}
            isRequired={true}
            type={"text"}
            label={"Apellido"}
            name={"surnamePatient"}
            control={control}
            error={errors}
          />
          <InputForm
            disabled={isView}
            register={register({ required: true })}
            isRequired={true}
            type={"text"}
            label={"Nro. Afiliado"}
            name={"affiliateNum"}
            control={control}
            error={errors}
            onBlur={(event)=>onBlurInputSearch(event)}
          />
        </Grid.Column>
        <Grid.Column id="column-patient-two">
          <Grid.Row style={{display: "flex", alignItems: "center"}}>
            <section style={{position: "relative"}}>
              <SelectForm
                disabled={isView}
                label={"Obra Social"}
                name={"socialWork"}
                loading={loadingSelect.socialWork}
                control={control}
                menuItemList={socialWorks}
                actionChange={(event) => getSocialWorksPlan(event)}
                error={errors}
              >
                <section className="instructiveIcon">
                  {loadingSelect.instructiveSocialWork && <CircularProgress className="loader-input" /> }
                  {text !== null &&
                    <IconButton
                      className='custom-hover'
                      onClick={() =>setModalInstructive({...modalInstructive, showModal: true})}
                  >
                    <Tooltip placement="top" title="Instructivo">
                      <MenuBookIcon style={{fontSize:"1.1em"}}/>
                    </Tooltip>
                  </IconButton>}
                </section>  
            </SelectForm>
            </section>
          </Grid.Row>
          <SelectForm
            disabled={isView || !socialWorks}
            label={"Plan Obra Social"}
            name={"socialWorkPlan"}
            loading={loadingSelect.planSocialWork}
            control={control}
            menuItemList={planSelected ?? socialWorksPlan}
            error={errors}
          />
          <InputForm
            disabled={isView}
            register={register({ required: false })}
            isRequired={false}
            type={"number"}
            label={"Teléfono"}
            name={"phone"}
            control={control}
            error={errors}
          />
          <InputForm
            disabled={isView}
            register={register({ required: false })}
            isRequired={false}
            type={"text"}
            label={"Domicilio"}
            name={"address"}
            control={control}
            error={errors}
          />
        </Grid.Column>
      </Grid.Row>

      <Dialog open={modalInstructive.showModal} onClose={handleCloseDeleteModalInstructive}>
        <DialogTitle id='form-dialog-title'>
          Instructivo / {modalInstructive.aditionalInfo.socialWorkName} - {modalInstructive.aditionalInfo.socialWorkAlias}
        </DialogTitle>
        <DialogContent>
            <EditorText  
            text={text} 
            toolbarOptions={toolbarConfig} 
            isReadOnly={true}
            placeholder={text === null || text === "" ? "No posee un instructivo asignado" : text}
            />
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
            <Button color='gray' onClick={handleCloseDeleteModalInstructive}>
                Cerrar
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
