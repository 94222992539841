import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
  name: "dashboard",
  initialState: {
    dataTable: [],
    filters: {
      socialWork: undefined,
      status: undefined,
      auth: undefined,
      patient: undefined,
      dateFrom: null,
      dateTo: null,
      page: 1,
      quantity: 20,
    },
  },
  reducers: {
    setDataTable(state, { payload }) {
      state.dataTable = payload || [];
    },
    setFilters(state, { payload }) {
      state.filters = payload;
    },
  },
});

export const { setDataTable, setFilters } = actions;

export default reducer;
