import { Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import React from 'react'
import { IGeneralModalShow } from "../../services/interfaces/utils/IGeneralModalShow";

const GeneralModalShow: React.FC<IGeneralModalShow> = ({
  openModal, 
  children, 
  title, 
  content, 
  styles,
  HandleClose})=>{
    return (
        <Dialog
        open={openModal}
        onClose={HandleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent className={styles ? styles : ""}>
          {children ? 
            children
            :
            <TextareaAutosize  
            readOnly
            className="commentReturn"
            aria-label="minimum height" 
            minRows={6} 
            value={content}
            />
          }
        </DialogContent>

        <DialogActions
          style={{
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Button onClick={HandleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default GeneralModalShow;